"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClassConfiguration = /** @class */ (function () {
    function ClassConfiguration() {
    }
    return ClassConfiguration;
}());
exports.ClassConfiguration = ClassConfiguration;
var ClassConfigurationDTO = /** @class */ (function () {
    function ClassConfigurationDTO() {
    }
    return ClassConfigurationDTO;
}());
exports.ClassConfigurationDTO = ClassConfigurationDTO;
var MatchingConfiguration = /** @class */ (function () {
    function MatchingConfiguration(init) {
        this.leftAddedClassDefinitionPaths = [];
        this.rightAddedClassDefinitionPaths = [];
        Object.assign(this, init);
    }
    return MatchingConfiguration;
}());
exports.MatchingConfiguration = MatchingConfiguration;
var MatchingEntityMappingConfiguration = /** @class */ (function () {
    function MatchingEntityMappingConfiguration() {
    }
    return MatchingEntityMappingConfiguration;
}());
exports.MatchingEntityMappingConfiguration = MatchingEntityMappingConfiguration;
