"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-dialog-title[_ngcontent-%COMP%]{margin:0}.mat-dialog-title[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{padding:0 0 6px;margin:0}mat-dialog-content[_ngcontent-%COMP%]{height:calc(100% - 52px - 20px);overflow:hidden}mat-dialog-content[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%], mat-dialog-content[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{padding:0;margin:0}mat-dialog-content[_ngcontent-%COMP%]   .browse-table[_ngcontent-%COMP%]{overflow-x:hidden;overflow-y:scroll;display:block;table-layout:fixed;width:450px;max-height:225px;background-color:#e6e4e4;border:1px solid #3e7ddb}mat-dialog-content[_ngcontent-%COMP%]   .browse-table[_ngcontent-%COMP%]   .table-row[_ngcontent-%COMP%]{min-height:0;padding-top:4px;padding-bottom:4px;cursor:pointer;z-index:4}mat-dialog-content[_ngcontent-%COMP%]   .browse-table[_ngcontent-%COMP%]   .table-row[_ngcontent-%COMP%]:hover{background-color:#3e7ddb;color:#fff}mat-dialog-content[_ngcontent-%COMP%]   .browse-table[_ngcontent-%COMP%]   .table-header-cell[_ngcontent-%COMP%]{position:-webkit-sticky;position:sticky;top:0;z-index:5;background-color:#3e7ddb;text-align:start}mat-dialog-content[_ngcontent-%COMP%]   .browse-table[_ngcontent-%COMP%]   .table-entry-cell[_ngcontent-%COMP%]{overflow:hidden}mat-dialog-content[_ngcontent-%COMP%]   .browse-table[_ngcontent-%COMP%]   .table-date-entry-cell[_ngcontent-%COMP%]{font-size:12px}"];
exports.styles = styles;
