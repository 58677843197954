<div class="overlay-content m-8">

    <!--
        Class Definition
    -->

    <div *ngIf="inputData.classDefinition">
        <div class="field-container">
            <div class="field-label">Name *</div>
            <mat-form-field style="width: 100%" appearance="outline" #label>
                <input matInput name="name" [(ngModel)]="inputData.classDefinition.name" required />
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
            <div style="width: 50%">
                <div class="normal-label">Icon</div>

                <div fxLayout="row" fxLayoutAlign="start end">
                    <div class="icon-wrapper" (click)=changeIconClicked()>
                        <img *ngIf="inputData.classDefinition.imagePath" [src]="inputData.classDefinition.imagePath"
                            width="60" height="60" style="cursor: pointer" matTooltip="icon">
                    </div>
                    <a style="cursor: pointer; line-height: 0.7;" class="pl-4" (click)="changeIconClicked()">ändern</a>
                </div>
            </div>

            <div style="width: 50%">
                <div class="normal-label">Sonstiges</div>

                <mat-checkbox labelPosition="after" [(ngModel)]="inputData.classDefinition.collector">
                    Collector-Klasse
                </mat-checkbox>
                <!-- <mat-checkbox labelPosition="after" [(ngModel)]="inputData.classDefinition.root">
                    Root-Klasse
                </mat-checkbox> -->
            </div>
        </div>


        <div class="pt-4">
            <div fxLayout="row" fxLayoutAlign="start start">

                <div style="width: 65%;" class="mt-4 mr-4">
                    <div class="normal-label">Properties</div>
                    <div class="pl-12 outline-border properties-section">

                        <div *ngIf="entryList.length <= 0" class="pt-12" style="text-align: center">
                            Keine Properties vorhanden
                        </div>

                        <div *ngFor="let entry of entryList; let i = index" [style]="getEntryStyle(i)"
                            class="property-entry">

                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div style="width: 15px; height: 15px;" class="ml-8">
                                    <img width="15" height="15" [src]="getImagePathPropertyType(entry.type)">
                                </div>

                                <div class="pl-8 property-entry-label">
                                    {{ entry.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="width: 35%;" class="mt-4 ml-4">
                    <div class="normal-label">Aktionen</div>
                    <div class="outline-border" style="height: 63px;">
                        <div style="padding-top: 3px"></div>

                        <button class="overlay-button" mat-stroked-button type="button" color="accent"
                            (click)="addPropertyClicked()">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon>add</mat-icon>
                                <div>Hinzu</div>
                            </div>
                        </button>

                        <div style="padding-top: 2px"></div>

                        <button class="overlay-button" mat-stroked-button type="button" color="accent"
                            (click)="removeClicked()">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon>remove</mat-icon>
                                <div>Entfernen</div>
                            </div>
                        </button>
                    </div>

                    <div style="height: 27px"></div>

                    <div class="normal-label">Eintragserfassung</div>
                    <div class="outline-border" style="height: 34px">
                        <div style="padding-top: 3px"></div>

                        <button class="overlay-button" mat-stroked-button type="button" color="accent"
                            (click)="previewClicked()">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon>pages</mat-icon>
                                <div>Vorschau</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div style="position: absolute; bottom: 8px; left: 8px">
        <button class="primary-button-dark" mat-stroked-button type="button" (click)="onSubmit()">OK</button>
        <button class="secondary-button-dark ml-8" mat-stroked-button type="button"
            (click)="onCancel()">Schließen</button>
    </div>
</div>