"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var util_1 = require("util");
var matching_configuration_service_1 = require("app/main/content/_service/configuration/matching-configuration.service");
var class_configuration_service_1 = require("app/main/content/_service/configuration/class-configuration.service");
var configurations_1 = require("app/main/content/_model/configurator/configurations");
var browse_sub_dialog_component_1 = require("../../../class-configurator/_dialogs/browse-sub-dialog/browse-sub-dialog.component");
var forms_1 = require("@angular/forms");
var string_unique_validator_1 = require("app/main/content/_validator/string-unique.validator");
var NewMatchingDialogComponent = /** @class */ (function () {
    function NewMatchingDialogComponent(dialogRef, data, classConfigurationService, matchingConfigurationService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.classConfigurationService = classConfigurationService;
        this.matchingConfigurationService = matchingConfigurationService;
        this.loaded = false;
        this.showErrors = false;
        this.showDuplicateError = false;
    }
    NewMatchingDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                Promise.all([
                    this.matchingConfigurationService.getAllMatchingConfigurationsByTenantId(this.data.tenantId).toPromise().then(function (matchingConfigurations) {
                        _this.dialogForm = new forms_1.FormGroup({
                            label: new forms_1.FormControl('', [forms_1.Validators.required, string_unique_validator_1.stringUniqueValidator(matchingConfigurations.map(function (c) { return c.name; }))]),
                        });
                    }),
                    this.classConfigurationService.getAllClassConfigurationsByTenantId(this.data.tenantId)
                        .toPromise().then(function (classConfigurations) {
                        _this.recentClassConfigurations = classConfigurations;
                        _this.allClassConfigurations = classConfigurations;
                        _this.recentClassConfigurations = _this.recentClassConfigurations.sort(function (a, b) { return b.timestamp.valueOf() - a.timestamp.valueOf(); });
                        if (_this.recentClassConfigurations.length > 4) {
                            _this.recentClassConfigurations = _this.recentClassConfigurations.slice(0, 4);
                        }
                    })
                ]).then(function () {
                    _this.loaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    NewMatchingDialogComponent.prototype.leftItemSelected = function (c) {
        this.data.leftIsUser = false;
        this.data.leftClassConfiguration = c;
    };
    NewMatchingDialogComponent.prototype.rightItemSelected = function (c) {
        this.data.rightIsUser = false;
        this.data.rightClassConfiguration = c;
    };
    NewMatchingDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    NewMatchingDialogComponent.prototype.onOKClick = function () {
        var _this = this;
        this.showDuplicateError = false;
        this.data.label = this.dialogForm.controls['label'].value;
        if ((!util_1.isNullOrUndefined(this.data.leftClassConfiguration) || this.data.leftIsUser) &&
            (!util_1.isNullOrUndefined(this.data.rightClassConfiguration) || this.data.rightIsUser) &&
            !util_1.isNullOrUndefined(this.data.label)) {
            var leftId = this.data.leftClassConfiguration.id;
            var rightId = this.data.rightClassConfiguration.id;
            this.matchingConfigurationService.getMatchingConfigurationByUnorderedClassConfigurationIds(leftId, rightId)
                .toPromise().then(function (ret) {
                if (util_1.isNullOrUndefined(ret)) {
                    _this.dialogRef.close(_this.data);
                }
                else {
                    _this.showDuplicateError = true;
                    _this.showErrors = true;
                }
            });
        }
        else {
            this.showErrors = true;
        }
    };
    NewMatchingDialogComponent.prototype.handleBrowseClick = function (sourceReference) {
        var e_1, _a;
        this.browseDialogData = new browse_sub_dialog_component_1.ClassBrowseSubDialogData();
        this.browseDialogData.title = 'Durchsuchen';
        this.browseDialogData.sourceReference = sourceReference;
        this.browseDialogData.entries = [];
        try {
            for (var _b = __values(this.allClassConfigurations), _c = _b.next(); !_c.done; _c = _b.next()) {
                var classConfiguration = _c.value;
                this.browseDialogData.entries.push({
                    id: classConfiguration.id,
                    name: classConfiguration.name,
                    date: new Date(classConfiguration.timestamp),
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.browseMode = true;
        this.dialogForm.updateValueAndValidity();
    };
    NewMatchingDialogComponent.prototype.handleBrowseBackClick = function () {
        this.browseMode = false;
    };
    NewMatchingDialogComponent.prototype.handleUseUserClick = function (sourceReference) {
        var classConfiguration = new configurations_1.ClassConfiguration();
        classConfiguration.id = 'user';
        classConfiguration.name = 'Benutzer';
        if (sourceReference === 'LEFT') {
            this.data.leftClassConfiguration = classConfiguration;
            this.data.leftIsUser = true;
        }
        else if (sourceReference === 'RIGHT') {
            this.data.rightClassConfiguration = classConfiguration;
            this.data.rightIsUser = true;
        }
    };
    NewMatchingDialogComponent.prototype.handleReturnFromBrowse = function (event) {
        if (!event.cancelled) {
            var classConfiguration = this.allClassConfigurations.find(function (c) { return c.id === event.entryId; });
            if (event.sourceReference === 'LEFT') {
                this.data.leftClassConfiguration = classConfiguration;
            }
            else if (event.sourceReference === 'RIGHT') {
                this.data.rightClassConfiguration = classConfiguration;
            }
        }
        this.browseMode = false;
    };
    NewMatchingDialogComponent.prototype.displayErrorMessage = function (key) {
        if (this.dialogForm.get(key).hasError('required')) {
            return "Pflichtfeld";
        }
        else if (this.dialogForm.get(key).hasError('stringunique')) {
            return "Name bereits vorhanden";
        }
    };
    return NewMatchingDialogComponent;
}());
exports.NewMatchingDialogComponent = NewMatchingDialogComponent;
