"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var FuseNavigationService = /** @class */ (function () {
    function FuseNavigationService() {
        this.flatNavigation = [];
        this.onItemCollapsed = new rxjs_1.Subject;
        this.onItemCollapseToggled = new rxjs_1.Subject;
    }
    /**
     * Get flattened navigation array
     * @param navigation
     * @returns {any[]}
     */
    FuseNavigationService.prototype.getFlatNavigation = function (navigation) {
        var e_1, _a;
        try {
            for (var navigation_1 = __values(navigation), navigation_1_1 = navigation_1.next(); !navigation_1_1.done; navigation_1_1 = navigation_1.next()) {
                var navItem = navigation_1_1.value;
                if (navItem.type === 'item') {
                    this.flatNavigation.push({
                        title: navItem.title,
                        type: navItem.type,
                        icon: navItem.icon || false,
                        url: navItem.url
                    });
                    continue;
                }
                if (navItem.type === 'collapse' || navItem.type === 'group') {
                    if (navItem.children) {
                        this.getFlatNavigation(navItem.children);
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (navigation_1_1 && !navigation_1_1.done && (_a = navigation_1.return)) _a.call(navigation_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return this.flatNavigation;
    };
    return FuseNavigationService;
}());
exports.FuseNavigationService = FuseNavigationService;
