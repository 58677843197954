"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("util");
// validation function
function stringsUnique(string1, string2) {
    return function (control) {
        if (util_1.isNullOrUndefined(string1) || util_1.isNullOrUndefined(string2)) {
            return null;
        }
        if (string1 === '' || string2 === '') {
            return null;
        }
        return string1 === string2 ? { 'stringsUnique': { 'stringsUnique': false, 'string1': string1, 'string2': string2 } } : null;
    };
}
exports.stringsUnique = stringsUnique;
