"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var class_1 = require("app/main/content/_model/configurator/class");
var router_1 = require("@angular/router");
var InstanceCreationResultComponent = /** @class */ (function () {
    function InstanceCreationResultComponent(router, route) {
        this.router = router;
        this.route = route;
        this.navigateBack = new core_1.EventEmitter();
    }
    InstanceCreationResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.queryParams = params;
        });
        this.jsonString = JSON.stringify(this.resultClassInstance);
    };
    InstanceCreationResultComponent.prototype.handleAnotherClick = function () {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(["main/instance-editor"], { queryParams: this.queryParams });
    };
    InstanceCreationResultComponent.prototype.handleFinishedClick = function () {
        this.navigateBack.emit(true);
    };
    return InstanceCreationResultComponent;
}());
exports.InstanceCreationResultComponent = InstanceCreationResultComponent;
