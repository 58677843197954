"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var class_configuration_service_1 = require("app/main/content/_service/configuration/class-configuration.service");
var configurations_1 = require("app/main/content/_model/configurator/configurations");
var forms_1 = require("@angular/forms");
var relationship_service_1 = require("app/main/content/_service/meta/core/relationship/relationship.service");
var class_definition_service_1 = require("app/main/content/_service/meta/core/class/class-definition.service");
var string_unique_validator_1 = require("app/main/content/_validator/string-unique.validator");
var util_1 = require("util");
var response_service_1 = require("app/main/content/_service/response.service");
var environment_1 = require("environments/environment");
var NewClassConfigurationDialogComponent = /** @class */ (function () {
    function NewClassConfigurationDialogComponent(dialogRef, data, classConfigurationService, relationshipsService, classDefintionService, responseService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.classConfigurationService = classConfigurationService;
        this.relationshipsService = relationshipsService;
        this.classDefintionService = classDefintionService;
        this.responseService = responseService;
        this.loaded = false;
    }
    NewClassConfigurationDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.configuratorMode = environment_1.environment.MODE;
                this.classConfigurationService
                    .getAllClassConfigurations()
                    .toPromise()
                    .then(function (classConfigurations) {
                    _this.allClassConfigurations = classConfigurations;
                    _this.dialogForm = new forms_1.FormGroup({
                        label: new forms_1.FormControl('', util_1.isNullOrUndefined(_this.data.classConfiguration) ?
                            [forms_1.Validators.required, string_unique_validator_1.stringUniqueValidator(_this.allClassConfigurations.map(function (c) { return c.name; }))]
                            : [forms_1.Validators.required, string_unique_validator_1.stringUniqueValidator(_this.allClassConfigurations.map(function (c) { return c.name; }), [_this.data.classConfiguration.name])]),
                        description: new forms_1.FormControl(''),
                    });
                    if (!util_1.isNullOrUndefined(_this.data.classConfiguration)) {
                        _this.showEditDialog = true;
                        _this.dialogForm
                            .get('label')
                            .setValue(_this.data.classConfiguration.name);
                        _this.dialogForm
                            .get('description')
                            .setValue(_this.data.classConfiguration.description);
                    }
                    if (!_this.showEditDialog) {
                        _this.dialogForm.addControl('type', new forms_1.FormControl('', forms_1.Validators.required));
                        _this.dialogForm.get('type').setValue('iVolunteer');
                        _this.dialogForm.updateValueAndValidity();
                    }
                    // ----DEBUG
                    // this.recentMatchingConfigurations.push(...this.recentMatchingConfigurations);
                    // this.recentMatchingConfigurations.push(...this.recentMatchingConfigurations);
                    // ----
                    _this.loaded = true;
                    console.log(_this.data.tenantId);
                });
                return [2 /*return*/];
            });
        });
    };
    NewClassConfigurationDialogComponent.prototype.displayErrorMessage = function (key) {
        if (this.dialogForm.get(key).hasError('required')) {
            return 'Pflichtfeld';
        }
        else if (this.dialogForm.get(key).hasError('stringunique')) {
            return 'Name bereits vorhanden';
        }
    };
    NewClassConfigurationDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    NewClassConfigurationDialogComponent.prototype.onCreateClick = function () {
        var _this = this;
        if (this.checkFormInvalid()) {
            return;
        }
        var formValues = this.getFormValues();
        var classConfiguration = new configurations_1.ClassConfiguration();
        classConfiguration.tenantId = this.data.tenantId;
        classConfiguration.name = formValues.name;
        classConfiguration.description = formValues.description;
        this.responseService.sendClassConfiguratorResponse(this.data.redirectUrl, { classConfiguration: classConfiguration, tenantId: this.data.tenantId }, null, 'new', this.dialogForm.value.type).toPromise()
            .then(function (cc) {
            _this.data.classConfiguration = cc;
            Promise.all([
                _this.relationshipsService
                    .getRelationshipsById(_this.data.classConfiguration.relationshipIds).toPromise()
                    .then(function (ret) {
                    _this.data.relationships = ret;
                }),
                _this.classDefintionService
                    .getClassDefinitionsById(_this.data.classConfiguration.classDefinitionIds).toPromise()
                    .then(function (ret) {
                    _this.data.classDefinitions = ret;
                })
            ]).then(function () {
                _this.dialogRef.close(_this.data);
            });
        });
    };
    NewClassConfigurationDialogComponent.prototype.onSaveClick = function () {
        var _this = this;
        if (this.checkFormInvalid()) {
            return;
        }
        var formValues = this.getFormValues();
        this.classConfigurationService
            .saveClassConfigurationMeta(this.data.classConfiguration.id, formValues.name, formValues.description)
            .toPromise().then(function (ret) {
            _this.data.classConfiguration = ret;
            _this.dialogRef.close(_this.data);
        });
    };
    NewClassConfigurationDialogComponent.prototype.checkFormInvalid = function () {
        this.dialogForm.get('label').markAsTouched();
        this.dialogForm.get('description').markAsTouched();
        return this.dialogForm.invalid;
    };
    NewClassConfigurationDialogComponent.prototype.getFormValues = function () {
        var name = this.dialogForm.get('label').value;
        var description = this.dialogForm.get('description').value;
        return { name: name, description: description };
    };
    return NewClassConfigurationDialogComponent;
}());
exports.NewClassConfigurationDialogComponent = NewClassConfigurationDialogComponent;
