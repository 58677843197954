<div mat-dialog-title>
  <table>
    <tr>
      <td>
        <mat-icon>save</mat-icon>
      </td>
      <td>
        <h1>Speichern unter</h1>
      </td>
    </tr>
  </table>
</div>
<mat-dialog-content *ngIf="loaded">

  <form [formGroup]="dialogForm">
    <mat-label>Bezeichnung Konfiguration*</mat-label>
    <mat-form-field style="width: 100%" appearance="outline" #label>
      <input matInput name="label" class="form-control" formControlName="label" required />
    </mat-form-field>


    <mat-label>Beschreibung (optional)</mat-label>
    <mat-form-field style="width: 100%" appearance="outline">
      <textarea matInput name="description" formControlName="description" matTextareaAutosize matAutosizeMinRows=5
        matAutosizeMaxRows=5></textarea>
    </mat-form-field>

  </form>

  <div class="pt-24"></div>

  <div class="browse-button">
    <div class="browse-button-text" (click)="handleBrowseClick()">Existierende Überschreiben</div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>

  <button class="primary-button-dark" mat-stroked-button type="button" (click)="onOKClick()">OK</button>
  <button class="secondary-button-dark" mat-stroked-button type="button"
    [mat-dialog-close]="undefined">Abbrechen</button>

</mat-dialog-actions>