"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var relationship_1 = require("app/main/content/_model/configurator/relationship");
var matching_1 = require("app/main/content/_model/matching");
var property_1 = require("app/main/content/_model/configurator/property/property");
var ɵ0 = relationship_1.RelationshipType.INHERITANCE, ɵ1 = relationship_1.RelationshipType.ASSOCIATION;
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
var relationshipPalettes = {
    id: 'relationships',
    label: 'Relationships',
    rows: [
        {
            id: ɵ0,
            label: relationship_1.RelationshipType.getLabelFromRelationshipType(relationship_1.RelationshipType.INHERITANCE),
            imgPath: '/assets/icons/class_editor/relationships/inheritance.png',
            type: relationship_1.RelationshipType.INHERITANCE,
            shape: undefined,
        },
        {
            id: ɵ1,
            label: relationship_1.RelationshipType.getLabelFromRelationshipType(relationship_1.RelationshipType.ASSOCIATION),
            imgPath: '/assets/icons/class_editor/relationships/aggregation.png',
            type: relationship_1.RelationshipType.ASSOCIATION,
            shape: undefined,
        },
    ],
};
var ɵ2 = matching_1.MatchingOperatorType.EQUAL, ɵ3 = matching_1.MatchingOperatorType.LESS, ɵ4 = matching_1.MatchingOperatorType.GREATER, ɵ5 = matching_1.MatchingOperatorType.LESS_EQUAL, ɵ6 = matching_1.MatchingOperatorType.GREATER_EQUAL, ɵ7 = matching_1.MatchingOperatorType.EXISTS, ɵ8 = matching_1.MatchingOperatorType.ALL;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
exports.ɵ7 = ɵ7;
exports.ɵ8 = ɵ8;
var matchingOperatorPalettes = [
    {
        id: ɵ2,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.EQUAL),
        imgPath: '/assets/icons/class_editor/matching/equal_reduced.png',
        type: 'matchingOperator',
    },
    {
        id: ɵ3,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.LESS),
        imgPath: '/assets/icons/class_editor/matching/lt_reduced.png',
        type: 'matchingOperator',
    },
    {
        id: ɵ4,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.GREATER),
        imgPath: '/assets/icons/class_editor/matching/gt_reduced.png',
        type: 'matchingOperator',
    },
    {
        id: ɵ5,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.LESS_EQUAL),
        imgPath: '/assets/icons/class_editor/matching/lteq_reduced.png',
        type: 'matchingOperator',
    },
    {
        id: ɵ6,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.GREATER_EQUAL),
        imgPath: '/assets/icons/class_editor/matching/gteq_reduced.png',
        type: 'matchingOperator',
    },
    {
        id: ɵ7,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.EXISTS),
        imgPath: '/assets/icons/class_editor/matching/exists_reduced.png',
        type: 'matchingOperator',
    },
    {
        id: ɵ8,
        label: matching_1.MatchingOperatorType.getLabelForMatchingOperatorType(matching_1.MatchingOperatorType.ALL),
        imgPath: '/assets/icons/class_editor/matching/all_reduced.png',
        type: 'matchingOperator',
    },
];
var matchingConnectorPalettes = [
    {
        id: 'connector',
        label: 'verbinden',
        imgPath: '/assets/mxgraph_resources/images/connect.gif',
        type: 'connector',
    },
];
var deleteOperationPalette = {
    id: 'delete',
    label: 'löschen',
    imgPath: '/assets/mxgraph_resources/images/delete.gif',
    type: 'operation',
};
var ɵ9 = property_1.PropertyType.TEXT, ɵ10 = property_1.PropertyType.LONG_TEXT, ɵ11 = property_1.PropertyType.WHOLE_NUMBER, ɵ12 = property_1.PropertyType.FLOAT_NUMBER, ɵ13 = property_1.PropertyType.BOOL, ɵ14 = property_1.PropertyType.DATE, ɵ15 = property_1.PropertyType.LIST, ɵ16 = property_1.PropertyType.TREE, ɵ17 = property_1.PropertyType.LOCATION;
exports.ɵ9 = ɵ9;
exports.ɵ10 = ɵ10;
exports.ɵ11 = ɵ11;
exports.ɵ12 = ɵ12;
exports.ɵ13 = ɵ13;
exports.ɵ14 = ɵ14;
exports.ɵ15 = ɵ15;
exports.ɵ16 = ɵ16;
exports.ɵ17 = ɵ17;
var propertyTypePalettes = [
    {
        id: ɵ9,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.TEXT),
        imgPath: '/assets/icons/datatypes/s.png',
    },
    {
        id: ɵ10,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.LONG_TEXT),
        imgPath: '/assets/icons/datatypes/s.png',
    },
    {
        id: ɵ11,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.WHOLE_NUMBER),
        imgPath: '/assets/icons/datatypes/n.png',
    },
    {
        id: ɵ12,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.FLOAT_NUMBER),
        imgPath: '/assets/icons/datatypes/f.png',
    },
    {
        id: ɵ13,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.BOOL),
        imgPath: '/assets/icons/datatypes/tf2.png',
    },
    {
        id: ɵ14,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.DATE),
        imgPath: '/assets/icons/datatypes/d.png',
    },
    {
        id: ɵ15,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.LIST),
        imgPath: '/assets/icons/datatypes/o.png',
    },
    {
        id: ɵ16,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.TREE),
        imgPath: '/assets/icons/datatypes/null.png',
    },
    {
        id: ɵ17,
        label: property_1.PropertyType.getLabelForPropertyType(property_1.PropertyType.LOCATION),
        imgPath: '/assets/icons/datatypes/null.png',
    },
];
var mxStyles = {
    // Classes
    classNormal: 'editable=0;' +
        'shape=swimlane;resizable=0;' +
        'fillColor=#000e8a;strokeColor=#000e8a;fontColor=#FFFFFF;fontSize=14;',
    classTree: 'shape=swimlane;resizable=0;foldable=0;' +
        'fillColor=#B05800;fontColor=#FFFFFF;strokeColor=#B05800;fontSize=14;' +
        'portConstraint=north;',
    classFlexprodCollector: 'editable=0;' +
        'shape=swimlane;resizable=0;' +
        'fillColor=#700000;fontColor=#FFFFFF;strokeColor=#700000;fontSize=14;',
    classHighlighted: 'editable=0;resizable=0;selectable=0;foldable=0;' +
        'shape=swimlane;' +
        'fillColor=#700000;fontColor=#FFFFFF;strokeColor=#700000;fontSize=14;',
    classDisabled: 'editable=0;resizable=0;selectable=0;foldable=0;' +
        'shape=swimlane;' +
        'fillColor=#707070;fontColor=#FFFFFF;strokeColor=#707070;fontSize=14;',
    property: 'movable=0;resizable=0;editable=0;deletable=0;selectable=0;' +
        'fillColor=rgb(54,115,41);fontColor=#FFFFFF;strokeColor=#FFFFFF;align=left;html=1;overflow=hidden;fontSize=14;',
    propertyTree: 'movable=0;resizable=0;editable=0;deletable=0;selectable=0;' +
        'fillColor=#B05800;fontColor=#FFFFFF;strokeColor=#FFFFFF;fontSize=14;align=left;html=1;overflow=hidden;' +
        'portConstraint=eastwest',
    // Icons
    optionsIcon: 'shape=image;image=/assets/icons/class_editor/options_icon.png;noLabel=1;imageBackground=none;imageBorder=none;' +
        'movable=0;resizable=0;editable=0;deletable=0;selectable=0;',
    addClassSameLevelIcon: 'shape=image;image=/assets/mxgraph_resources/images/right_blue.png;noLabel=1;imageBackground=none;imageBorder=none;' +
        'movable=0;resizable=0;editable=0;deletable=0;selectable=0;',
    addClassNewLevelIcon: 'shape=image;image=/assets/mxgraph_resources/images/down_blue.png;noLabel=1;imageBackground=none;imageBorder=none;' +
        'movable=0;resizable=0;editable=0;deletable=0;selectable=0;',
    addClassPlusIcon: 'shape=image;image=/assets/icons/class_editor/plus_icon.png;noLabel=1;imageBackground=none;imageBorder=none;' +
        'movable=0;resizable=0;editable=0;deletable=0;selectable=0;',
    // Relationships
    inheritanceTree: 'sideToSideEdgeStyle=1;startArrow=classic;endArrow=none;curved=1;html=1;strokeColor=#B05800',
    inheritance: 'sideToSideEdgeStyle=1;endArrow=none;startArrow=block;startSize=16;startFill=0;curved=1;html=1;strokeColor=#000e8a;',
    genericConnection: 'sideToSideEdgeStyle=1;endArrow=none;startArrow=none;curved=1;html=1;strokeColor=#000e8a;',
    association: 'endArrow=none;html=1;curved=1;' + 'edgeStyle=orthogonalEdgeStyle;',
    associationCell: 'resizable=0;html=1;align=left;verticalAlign=bottom;labelBackgroundColor=#ffffff;fontSize=10;',
    aggregationAssocation: 'endArrow=none;html=1;startArrow=diamondThin;startSize=15;startFill=0;exitPerimeter=1;' +
        'strokeColor=#000e8a;' +
        'curved=1;' +
        'edgeStyle=orthogonalEdgeStyle;',
    composition: 'endArrow=none;html=1;startArrow=diamondThin;startSize=15;startFill=1;exitPerimeter=1;' +
        'strokeColor=#000e8a;' +
        'curved=1;' +
        'edgeStyle=orthogonalEdgeStyle;',
    // Matching
    matchingRowHeader: 'movable=0;resizable=0;editable=0;deletable=0;selectable=0;' +
        'fillColor=#000000;fontColor=#FFFFFF;strokeColor=#000000;' +
        'align=center;html=1;overflow=hidden;fontSize=30;fontFamily=roboto;fontStyle=bold;',
    matchingAddButton: 'movable=0;resizable=0;editable=0;deletable=0;selectable=0;' +
        'fillColor=rgb(62,125,219);fontColor=#FFFFFF;strokeColor=rgb(62,125,219);' +
        'align=center;verticalAlign=top;html=1;overflow=hidden;fontSize=30;fontFamily=roboto;fontStyle=bold;' +
        'rounded=1;',
    matchingConnector: 'endArrow=classic;startArrow=none;html=1;curved=1;' +
        'editable=0;selectable=1;deletable=1;' +
        'edgeStyle=orthogonalEdgeStyle;',
    matchingOperator: 'resizable=0;editable=0;deletable=1;selectable=1;' +
        'portConstraint=eastwest',
    matchingClassSeparator: 'movable=0;resizable=0;editable=0;deletable=0;selectable=0;foldable=0;' +
        'fillColor=#000e8a;fontColor=#FFFFFF;strokeColor=#000e8a;align=center;html=1;overflow=hidden;fontSize=14;' +
        'portConstraint=eastwest;',
    matchingProperty: 'movable=0;resizable=0;editable=0;deletable=0;selectable=0;foldable=0;' +
        'fillColor=rgb(54,115,41);fontColor=#FFFFFF;strokeColor=#FFFFFF;align=left;html=1;overflow=hidden;fontSize=14;' +
        'portConstraint=eastwest',
    matchingClassNormal: 'shape=swimlane;movable=0;resizable=0;editable=0;deletable=0;selectable=0;foldable=0;' +
        'fillColor=#000e8a;strokeColor=#000e8a;fontColor=#FFFFFF;fontSize=14;' +
        'portConstraint=eastwest',
    matchingClassFlexprodCollector: 'shape=swimlane;movable=0;resizable=0;editable=0;deletable=0;selectable=0;foldable=0;' +
        'fillColor=#700000;fontColor=#FFFFFF;strokeColor=#700000;fontSize=14;' +
        'portConstraint=eastwest',
};
var cellTypes = {
    property: { label: 'property', icon: '' },
    property_tree: { label: 'property_tree', icon: '' },
    add: { label: 'add', icon: '' },
    add_association: { label: 'property', icon: '' },
    add_class_new_level: {},
    add_class_same_level: {},
    remove: {},
    inheritance: {},
    association: {},
    inheritance_tree: {},
    association_label: {},
};
var CConstants = /** @class */ (function () {
    function CConstants() {
    }
    // public static sidebarPalettes = sidebarPalettes;
    CConstants.relationshipPalettes = relationshipPalettes;
    CConstants.propertyTypePalettes = propertyTypePalettes;
    CConstants.matchingOperatorPalettes = matchingOperatorPalettes;
    CConstants.matchingConnectorPalettes = matchingConnectorPalettes;
    CConstants.deleteOperationPalette = deleteOperationPalette;
    CConstants.mxStyles = mxStyles;
    CConstants.cellTypes = cellTypes;
    return CConstants;
}());
exports.CConstants = CConstants;
