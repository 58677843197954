"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var confirm_dialog_component_1 = require("../confirm-dialog/confirm-dialog.component");
var new_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/new-dialog/new-dialog.component");
var open_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/open-dialog/open-dialog.component");
var confirm_save_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/confirm-save-dialog/confirm-save-dialog.component");
var delete_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/delete-dialog/delete-dialog.component");
var form_preview_dialog_component_1 = require("../../../configuration/class-instance-configurator/form-preview-dialog/form-preview-dialog.component");
var form_preview_export_dialog_component_1 = require("../../../configuration/class-instance-configurator/form-preview-export-dialog/form-preview-export-dialog.component");
var icon_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/icon-dialog/icon-dialog.component");
var property_creation_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/property-creation-dialog/property-creation-dialog.component");
var new_dialog_component_2 = require("../../../configuration/matching-configurator/_dialogs/new-dialog/new-dialog.component");
var open_dialog_component_2 = require("../../../configuration/matching-configurator/_dialogs/open-dialog/open-dialog.component");
var delete_dialog_component_2 = require("../../../configuration/matching-configurator/_dialogs/delete-dialog/delete-dialog.component");
var add_property_dialog_component_1 = require("../add-property-dialog/add-property-dialog.component");
var remove_dialog_component_1 = require("../remove-dialog/remove-dialog.component");
var util_1 = require("util");
var add_class_definition_dialog_component_1 = require("../../../configuration/matching-configurator/_dialogs/add-class-definition-dialog/add-class-definition-dialog.component");
var edit_meta_dialog_component_1 = require("../../../configuration/matching-configurator/_dialogs/edit-meta-dialog/edit-meta-dialog.component");
var DialogFactoryDirective = /** @class */ (function () {
    function DialogFactoryDirective(dialog) {
        this.dialog = dialog;
    }
    DialogFactoryDirective.prototype.confirmationDialog = function (title, description) {
        var dialogRef = this.dialog.open(confirm_dialog_component_1.ConfirmDialogComponent, {
            width: '500px',
            data: { title: title, description: description }
        });
        var ret = false;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            if (result) {
                ret = result;
            }
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return ret;
        });
    };
    /**
     * ******CLASS CONFIGURATION******
     */
    DialogFactoryDirective.prototype.openNewClassConfigurationDialog = function (tenantId, redirectUrl, currentClassConfiguration) {
        var dialogRef = this.dialog.open(new_dialog_component_1.NewClassConfigurationDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: {
                classConfiguration: currentClassConfiguration,
                tenantId: tenantId,
                redirectUrl: redirectUrl
            },
            disableClose: true
        });
        var returnData;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnData = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnData;
        });
    };
    DialogFactoryDirective.prototype.openOpenClassConfigurationDialog = function (tenantId) {
        var dialogRef = this.dialog.open(open_dialog_component_1.OpenClassConfigurationDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: { configurator: undefined, tenantId: tenantId },
            disableClose: true
        });
        var returnData;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnData = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnData;
        });
    };
    DialogFactoryDirective.prototype.openSaveClassConfigurationConfirmationDialog = function (classConfiguration, classDefinitions, relationships, deletedClassDefinitionIds, deletedRelationshipIds, tenantId, redirectUrl) {
        var dialogRef = this.dialog.open(confirm_save_dialog_component_1.ConfirmClassConfigurationSaveDialogComponent, {
            width: '500px',
            data: {
                classConfiguration: classConfiguration,
                classDefinitions: classDefinitions,
                relationships: relationships,
                deletedClassDefinitionIds: deletedClassDefinitionIds,
                deletedRelationshipIds: deletedRelationshipIds,
                tenantId: tenantId,
                redirectUrl: redirectUrl,
            }
        });
        var returnData;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnData = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnData;
        });
    };
    DialogFactoryDirective.prototype.openDeleteClassConfigurationDialog = function (tenantId, redirectUrl) {
        var dialogRef = this.dialog.open(delete_dialog_component_1.DeleteClassConfigurationDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: { configurator: undefined, tenantId: tenantId, redirectUrl: redirectUrl },
            disableClose: true
        });
        var returnData;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnData = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnData;
        });
    };
    DialogFactoryDirective.prototype.openChangeIconDialog = function (currentImagePath) {
        var dialogRef = this.dialog.open(icon_dialog_component_1.ChangeIconDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: { imagePath: currentImagePath },
            disableClose: true
        });
        var imagePath;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            if (!util_1.isNullOrUndefined(result)) {
                imagePath = result.imagePath;
            }
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return imagePath;
        });
    };
    DialogFactoryDirective.prototype.openPropertyCreationDialog = function () {
        var dialogRef = this.dialog.open(property_creation_dialog_component_1.PropertyCreationDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            height: '90vh',
            minHeight: '90vh',
            data: {},
            disableClose: true
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    /**
     * ******INSTANTIATION******
     */
    DialogFactoryDirective.prototype.openInstanceFormPreviewDialog = function (classDefinitions, relationships, rootClassDefinition) {
        var dialogRef = this.dialog.open(form_preview_dialog_component_1.ClassInstanceFormPreviewDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            height: '90vh',
            minHeight: '90vh',
            data: {
                classDefinitions: classDefinitions,
                relationships: relationships,
                rootClassDefinition: rootClassDefinition
            },
            disableClose: true
        });
        var returnData;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnData = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnData;
        });
    };
    DialogFactoryDirective.prototype.openPreviewExportDialog = function (classConfigurationIds) {
        var dialogRef = this.dialog.open(form_preview_export_dialog_component_1.ClassInstanceFormPreviewExportDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            height: '90vh',
            minHeight: '90vh',
            data: {
                classConfigurationIds: classConfigurationIds
            },
            disableClose: true
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    /*
     *  Matching-Configurator Dialogs
     */
    DialogFactoryDirective.prototype.openNewMatchingDialog = function (tenantId) {
        var dialogRef = this.dialog.open(new_dialog_component_2.NewMatchingDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '450px',
            minHeight: '450px',
            data: { tenantId: tenantId },
            disableClose: true
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    DialogFactoryDirective.prototype.openEditMetaMatchingDialog = function (tenantId, matchingConfiguration) {
        var dialogRef = this.dialog.open(edit_meta_dialog_component_1.EditMetaMatchingConfigurationDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '200px',
            minHeight: '200px',
            data: { tenantId: tenantId, matchingConfiguration: matchingConfiguration },
            disableClose: true
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    DialogFactoryDirective.prototype.openOpenMatchingDialog = function (tenantId) {
        var dialogRef = this.dialog.open(open_dialog_component_2.OpenMatchingDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: { tenantId: tenantId },
            disableClose: true
        });
        var matchingConfiguration;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            if (!util_1.isNullOrUndefined(result)) {
                matchingConfiguration = result.matchingConfiguration;
            }
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return matchingConfiguration;
        });
    };
    DialogFactoryDirective.prototype.openDeleteMatchingDialog = function (tenantId, redirectUrl) {
        var dialogRef = this.dialog.open(delete_dialog_component_2.DeleteMatchingDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: { tenantId: tenantId, redirectUrl: redirectUrl },
            disableClose: true
        });
        var idsDeleted;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            if (!util_1.isNullOrUndefined(result)) {
                idsDeleted = result.idsToDelete;
            }
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return idsDeleted;
        });
    };
    DialogFactoryDirective.prototype.openAddClassDefinitionDialog = function (matchingEntityConfiguration, existingEntityPaths) {
        var dialogRef = this.dialog.open(add_class_definition_dialog_component_1.AddClassDefinitionDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '418px',
            minHeight: '418px',
            data: {
                matchingEntityConfiguration: matchingEntityConfiguration,
                existingEntityPaths: existingEntityPaths,
                addedEntities: []
            }
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    DialogFactoryDirective.prototype.openAddPropertyDialog = function (classDefinition, allClassDefinitions, allRelationships, tenantId) {
        var dialogRef = this.dialog.open(add_property_dialog_component_1.AddPropertyDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '418px',
            minHeight: '418px',
            data: {
                classDefinition: classDefinition,
                allClassDefinitions: allClassDefinitions,
                allRelationships: allRelationships,
                tenantId: tenantId,
            }
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    DialogFactoryDirective.prototype.openRemovePropertyDialog = function (classDefinition) {
        var dialogRef = this.dialog.open(remove_dialog_component_1.RemovePropertyDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '400px',
            minHeight: '400px',
            data: { classDefinition: classDefinition }
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    return DialogFactoryDirective;
}());
exports.DialogFactoryDirective = DialogFactoryDirective;
