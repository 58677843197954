"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".divider[_ngcontent-%COMP%]{border-bottom:1px solid #000;margin-left:20vw;margin-right:20vw}.volunteer-selection[_ngcontent-%COMP%]{padding-left:20px;padding-top:12px;width:calc(600px + 20vw);margin:0 auto}mat-expansion-panel[_ngcontent-%COMP%]{box-shadow:none;border:2px solid #000}mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%], mat-expansion-panel-header[_ngcontent-%COMP%]:hover{background-color:#047bca!important;border-radius:0}mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-description[_ngcontent-%COMP%], mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-title[_ngcontent-%COMP%], mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-description[_ngcontent-%COMP%], mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-title[_ngcontent-%COMP%]{color:#fff}"];
exports.styles = styles;
