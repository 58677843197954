"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var environment_1 = require("environments/environment");
var hmr_1 = require("hmr");
var __NgCli_bootstrap_1 = require("./app/app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
if (environment_1.environment.production) {
    core_1.enableProdMode();
}
var bootstrap = function () { return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory); };
var ɵ0 = bootstrap;
exports.ɵ0 = ɵ0;
if (environment_1.environment.hmr) {
    if (module["hot"]) {
        hmr_1.hmrBootstrap(module, bootstrap);
    }
    else {
        console.error("HMR is not enabled for webpack-dev-server!");
        console.log("Are you using the --hmr flag for ng serve?");
    }
}
else {
    core_1.enableProdMode();
    bootstrap();
}
