"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TreePropertyEntry = /** @class */ (function () {
    function TreePropertyEntry() {
        this.parents = [];
    }
    return TreePropertyEntry;
}());
exports.TreePropertyEntry = TreePropertyEntry;
var TreePropertyRelationship = /** @class */ (function () {
    function TreePropertyRelationship() {
    }
    return TreePropertyRelationship;
}());
exports.TreePropertyRelationship = TreePropertyRelationship;
var TreePropertyDefinition = /** @class */ (function () {
    function TreePropertyDefinition(init) {
        Object.assign(this, init);
    }
    return TreePropertyDefinition;
}());
exports.TreePropertyDefinition = TreePropertyDefinition;
