"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["@media (max-width:959px){[_nghost-%COMP%]   #fuse-shortcuts.show-mobile-panel[_ngcontent-%COMP%]{position:absolute;top:0;right:0;bottom:0;left:0;z-index:99;padding:0 8px}[_nghost-%COMP%]   #fuse-shortcuts.show-mobile-panel[_ngcontent-%COMP%]   .shortcuts[_ngcontent-%COMP%]{display:flex!important;flex:1;height:100%}}"];
exports.styles = styles;
