"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./navigation.component.scss.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./vertical/nav-group/nav-vertical-group.component.ngfactory");
var i3 = require("./vertical/nav-group/nav-vertical-group.component");
var i4 = require("./vertical/nav-collapse/nav-vertical-collapse.component.ngfactory");
var i5 = require("./vertical/nav-collapse/nav-vertical-collapse.component");
var i6 = require("./navigation.service");
var i7 = require("@angular/router");
var i8 = require("./vertical/nav-item/nav-vertical-item.component.ngfactory");
var i9 = require("./vertical/nav-item/nav-vertical-item.component");
var i10 = require("@angular/common");
var i11 = require("./horizontal/nav-collapse/nav-horizontal-collapse.component.ngfactory");
var i12 = require("./horizontal/nav-collapse/nav-horizontal-collapse.component");
var i13 = require("../../services/config.service");
var i14 = require("./horizontal/nav-item/nav-horizontal-item.component.ngfactory");
var i15 = require("./horizontal/nav-item/nav-horizontal-item.component");
var i16 = require("./navigation.component");
var styles_FuseNavigationComponent = [i0.styles];
var RenderType_FuseNavigationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseNavigationComponent, data: {} });
exports.RenderType_FuseNavigationComponent = RenderType_FuseNavigationComponent;
function View_FuseNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, i2.View_FuseNavVerticalGroupComponent_0, i2.RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 49152, null, 0, i3.FuseNavVerticalGroupComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-collapse", [], [[8, "className", 0], [2, "open", null]], null, null, i4.View_FuseNavVerticalCollapseComponent_0, i4.RenderType_FuseNavVerticalCollapseComponent)), i1.ɵdid(1, 114688, null, 0, i5.FuseNavVerticalCollapseComponent, [i6.FuseNavigationService, i7.Router], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavigationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-item", [], [[8, "className", 0]], null, null, i8.View_FuseNavVerticalItemComponent_0, i8.RenderType_FuseNavVerticalItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.FuseNavVerticalItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_3)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_4)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_5)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapse"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_2)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FuseNavigationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-collapse", [], [[8, "className", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseNavHorizontalCollapseComponent_0, i11.RenderType_FuseNavHorizontalCollapseComponent)), i1.ɵdid(1, 180224, null, 0, i12.FuseNavHorizontalCollapseComponent, [i13.FuseConfigService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-collapse", [], [[8, "className", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseNavHorizontalCollapseComponent_0, i11.RenderType_FuseNavHorizontalCollapseComponent)), i1.ɵdid(1, 180224, null, 0, i12.FuseNavHorizontalCollapseComponent, [i13.FuseConfigService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-item", [], [[8, "className", 0]], null, null, i14.View_FuseNavHorizontalItemComponent_0, i14.RenderType_FuseNavHorizontalItemComponent)), i1.ɵdid(1, 49152, null, 0, i15.FuseNavHorizontalItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_8)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_9)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_10)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapse"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavigationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_7)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FuseNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "nav"], ["id", "main-navigation"]], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "horizontal": 0, "vertical": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_6)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nav"; var currVal_1 = _ck(_v, 3, 0, (_co.layout === "horizontal"), (_co.layout === "vertical")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.layout === "vertical"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.layout === "horizontal"); _ck(_v, 7, 0, currVal_3); }, null); }
exports.View_FuseNavigationComponent_0 = View_FuseNavigationComponent_0;
function View_FuseNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-navigation", [], null, null, null, View_FuseNavigationComponent_0, RenderType_FuseNavigationComponent)), i1.ɵdid(1, 49152, null, 0, i16.FuseNavigationComponent, [], null, null)], null, null); }
exports.View_FuseNavigationComponent_Host_0 = View_FuseNavigationComponent_Host_0;
var FuseNavigationComponentNgFactory = i1.ɵccf("fuse-navigation", i16.FuseNavigationComponent, View_FuseNavigationComponent_Host_0, { layout: "layout", navigation: "navigation" }, {}, []);
exports.FuseNavigationComponentNgFactory = FuseNavigationComponentNgFactory;
