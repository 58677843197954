<h1 mat-dialog-title>Klassen-Konfiguration speichern</h1>
<mat-dialog-content class="m-4">
  Die existierende Konfiguration wird überschrieben. Erstellte
  Matching-Konfigurationen funktionieren möglicherweise nicht mehr.
</mat-dialog-content>
<mat-dialog-actions>
  <div style="display: block; margin: 0 auto; width: max-content">
    <button class="primary-button-dark" mat-stroked-button type="button" (click)="onOKClick()">Speichern</button>
    <button class="secondary-button-dark" mat-stroked-button type="button"
      [mat-dialog-close]="undefined">Abbrechen</button>
  </div>
</mat-dialog-actions>