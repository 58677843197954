"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["body.fuse-sidebar-folded .content-wrapper:last-child{padding-left:64px!important}body.fuse-sidebar-folded .content-wrapper:first-child{padding-right:64px!important}body.fuse-sidebar-folded .content-wrapper:first-child:last-child{padding-left:0!important;padding-right:0!important}fuse-sidebar.folded:not(.unfolded) .navbar-vertical .navbar-header{padding:0 13px}fuse-sidebar.folded:not(.unfolded) .navbar-vertical .navbar-header .logo .logo-text{opacity:0;transition:opacity .2s ease}fuse-navbar:not(.top-navbar){height:100%}fuse-navbar .navbar-vertical{display:flex;flex-direction:column;width:100%;height:100%}fuse-navbar .navbar-vertical .navbar-header{display:flex;align-items:center;justify-content:space-between;height:64px;min-height:64px;padding:0 16px 0 24px;transition:padding .2s ease;background-color:rgba(255,255,255,.05);box-shadow:0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)}fuse-navbar .navbar-vertical .navbar-header .logo{display:flex;align-items:center}fuse-navbar .navbar-vertical .navbar-header .logo .logo-icon{width:38px;height:38px}fuse-navbar .navbar-vertical .navbar-header .logo .logo-text{margin-left:8px;font-size:25px;font-weight:300;letter-spacing:.4px}fuse-navbar .navbar-vertical .navbar-content{flex:1}.top-navbar+#wrapper>.left-navbar{display:none!important}@media (max-width:1279px){.top-navbar+#wrapper>.left-navbar{display:flex!important}}"];
exports.styles = styles;
