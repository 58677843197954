"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var util_1 = require("util");
var dialog_factory_component_1 = require("app/main/content/_components/_shared/dialogs/_dialog-factory/dialog-factory.component");
var class_configuration_service_1 = require("app/main/content/_service/configuration/class-configuration.service");
var router_1 = require("@angular/router");
var TopMenuResponse = /** @class */ (function () {
    function TopMenuResponse() {
        this.classDefintions = [];
        this.relationships = [];
    }
    return TopMenuResponse;
}());
exports.TopMenuResponse = TopMenuResponse;
var rootMenuItems = [{ id: 1, label: 'Menü', icon: 'menu' }];
var subMenuItems = [
    {
        rootId: 1,
        id: 1,
        label: 'Neue Konfiguration',
        clickAction: 'newClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 2,
        label: 'Konfiguration öffnen',
        clickAction: 'openClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 3,
        label: 'Konfiguration speichern',
        clickAction: 'saveClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 3,
        label: 'Konfiguration löschen',
        clickAction: 'deleteClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 4,
        label: 'Eintrag erfassen',
        clickAction: 'createEditorClicked',
        icon: undefined
    }
];
var EditorTopMenuBarComponent = /** @class */ (function () {
    function EditorTopMenuBarComponent(route, dialogFactory, classConfigurationService) {
        this.route = route;
        this.dialogFactory = dialogFactory;
        this.classConfigurationService = classConfigurationService;
        this.rootMenuItems = rootMenuItems;
        this.subMenuItems = subMenuItems;
        this.currentRootId = 1;
        this.menuOptionClickedEvent = new core_1.EventEmitter();
    }
    EditorTopMenuBarComponent.prototype.ngOnInit = function () {
    };
    EditorTopMenuBarComponent.prototype.ngAfterViewInit = function () {
        this.submenuContainer.nativeElement.style.position = 'absolute';
        this.submenuContainer.nativeElement.style.overflow = 'hidden';
        this.submenuContainer.nativeElement.style.padding = '0px';
        this.submenuContainer.nativeElement.style.top = '29px';
        this.submenuContainer.nativeElement.style.left = '10px';
        this.submenuContainer.nativeElement.style.height = 'auto';
        this.submenuContainer.nativeElement.style.width = '200px';
        this.submenuContainer.nativeElement.style.background = 'white';
        this.submenuContainer.nativeElement.style.font =
            'Arial, Helvetica, sans-serif';
        this.submenuContainer.nativeElement.style.display = 'none';
        this.menubarContainer.nativeElement.style.position = 'absolute';
        this.menubarContainer.nativeElement.style.overflow = 'hidden';
        this.menubarContainer.nativeElement.style.right = '0px';
        this.menubarContainer.nativeElement.style.top = '0px';
        this.menubarContainer.nativeElement.style.left = '0px';
        this.menubarContainer.nativeElement.style.height = '35px';
        this.menubarContainer.nativeElement.style.background = 'white';
        this.menubarContainer.nativeElement.style.font =
            'Arial, Helvetica, sans-serif';
        this.titleBarTextContainer.nativeElement.style.maxWidth =
            // clientwidth of titlebar - margin left/right - icon left
            this.menubarContainer.nativeElement.clientWidth - 50 - 15 + 'px';
        var outer = this;
        document.addEventListener('click', function (event) {
            outer.handleHTMLClickEvent(event);
        });
    };
    EditorTopMenuBarComponent.prototype.handleHTMLClickEvent = function (event) {
        if (event.srcElement.className !== 'menuitem' &&
            event.srcElement.className !==
                'menuitem-icon mat-icon notranslate material-icons mat-icon-no-color') {
            this.submenuContainer.nativeElement.style.display = 'none';
        }
    };
    EditorTopMenuBarComponent.prototype.openSubmenu = function (event, rootItemId) {
        this.currentRootId = rootItemId;
        this.submenuContainer.nativeElement.style.display = 'block';
        var leftPosition = this.calculateLeftSpace(event.srcElement.offsetParent, rootItemId);
        this.submenuContainer.nativeElement.style.left = leftPosition + 'px';
    };
    EditorTopMenuBarComponent.prototype.calculateLeftSpace = function (offsetParent, rootItemId) {
        var e_1, _a;
        var space = 10;
        if (!util_1.isNullOrUndefined(offsetParent.children)) {
            try {
                for (var _b = __values(offsetParent.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var child = _c.value;
                    if (rootItemId - 1 <= 0) {
                        return space;
                    }
                    space += child.clientWidth;
                    rootItemId--;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    // menu functions
    EditorTopMenuBarComponent.prototype.newClicked = function () {
        if (!util_1.isNullOrUndefined(this.currentClassConfiguration)) {
            this.saveClicked('performNew');
        }
        else {
            this.performNew();
        }
    };
    EditorTopMenuBarComponent.prototype.performNew = function () {
        var _this = this;
        this.dialogFactory
            .openNewClassConfigurationDialog(this.tenantId, this.redirectUrl)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.currentClassConfiguration = ret.classConfiguration;
                _this.menuOptionClickedEvent.emit({ id: 'editor_new', payload: ret });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    EditorTopMenuBarComponent.prototype.editClicked = function () {
        this.performEdit();
    };
    EditorTopMenuBarComponent.prototype.performEdit = function () {
        var _this = this;
        this.dialogFactory
            .openNewClassConfigurationDialog(this.tenantId, this.redirectUrl, this.currentClassConfiguration)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.currentClassConfiguration = ret.classConfiguration;
                _this.menuOptionClickedEvent.emit({
                    id: 'editor_meta_edit',
                    payload: {
                        name: ret.classConfiguration.name,
                        description: ret.classConfiguration.description
                    }
                });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    EditorTopMenuBarComponent.prototype.openClicked = function () {
        if (!util_1.isNullOrUndefined(this.currentClassConfiguration)) {
            this.saveClicked('performOpen');
        }
        else {
            this.performOpen();
        }
    };
    EditorTopMenuBarComponent.prototype.performOpen = function () {
        var _this = this;
        this.dialogFactory
            .openOpenClassConfigurationDialog(this.tenantId)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.currentClassConfiguration = ret.classConfiguration;
                _this.menuOptionClickedEvent.emit({ id: 'editor_open', payload: ret });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    EditorTopMenuBarComponent.prototype.performOpenByid = function (classConfigurationId) {
        var _this = this;
        this.classConfigurationService.getAllForClassConfigurationInOne(classConfigurationId).toPromise()
            .then(function (dto) {
            if (!util_1.isNullOrUndefined(dto)) {
                _this.currentClassConfiguration = dto.classConfiguration;
                _this.menuOptionClickedEvent.emit({ id: 'editor_open', payload: dto });
            }
        })
            .catch(function (error) {
            if (error.status === 500 && error.statusText === 'OK') {
                console.error('no classConfiguration with this id: ' + classConfigurationId);
                _this.performOpen();
            }
        });
    };
    EditorTopMenuBarComponent.prototype.saveClicked = function (followingAction) {
        this.menuOptionClickedEvent.emit({
            id: 'editor_save',
            followingAction: followingAction
        });
    };
    EditorTopMenuBarComponent.prototype.performSave = function (classConfiguration, classDefintions, relationships, deletedClassDefinitions, deletedRelationships, actionAfter, tenantId, redirectUrl) {
        var _this = this;
        this.dialogFactory
            .openSaveClassConfigurationConfirmationDialog(classConfiguration, classDefintions, relationships, deletedClassDefinitions, deletedRelationships, tenantId, redirectUrl)
            .then(function (ret) {
            if (util_1.isNullOrUndefined(ret)) {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
            else {
                _this.menuOptionClickedEvent.emit({
                    id: 'editor_save_return',
                    payload: ret
                });
            }
            if (!util_1.isNullOrUndefined(actionAfter)) {
                _this[actionAfter]();
            }
        });
    };
    EditorTopMenuBarComponent.prototype.deleteClicked = function () {
        var _this = this;
        this.dialogFactory
            .openDeleteClassConfigurationDialog(this.tenantId, this.redirectUrl)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.menuOptionClickedEvent.emit({
                    id: 'editor_delete',
                    payload: ret
                });
                if (ret.idsToDelete.findIndex(function (e) { return e === _this.currentClassConfiguration.id; }) >= 0) {
                    _this.currentClassConfiguration = undefined;
                }
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    EditorTopMenuBarComponent.prototype.createEditorClicked = function () {
        if (!util_1.isNullOrUndefined(this.currentClassConfiguration)) {
            this.saveClicked('performCreate');
        }
        else {
            this.performCreate();
        }
    };
    EditorTopMenuBarComponent.prototype.performCreate = function () {
        this.menuOptionClickedEvent.emit({ id: 'editor_create_instance' });
    };
    EditorTopMenuBarComponent.prototype.ngOnChanges = function () {
        var eventResponseAction = this.eventResponse.action;
        var eventFollowingAction = this.eventResponse.followingAction;
        var eventClassConfiguration = this.eventResponse.classConfiguration;
        var eventClassConfigurationId = this.eventResponse.classConfigurationId;
        var eventClassDefinitions = this.eventResponse.classDefintions;
        var eventRelationships = this.eventResponse.relationships;
        var eventDeletedClassDefinitions = this.eventResponse
            .deletedClassDefinitions;
        var eventDeletedRelationships = this.eventResponse.deletedRelationships;
        var eventTenantId = this.eventResponse.tenantId;
        var eventRedirectUrl = this.eventResponse.redirectUrl;
        this.eventResponse = new TopMenuResponse();
        if (eventResponseAction === 'save') {
            if (!util_1.isNullOrUndefined(eventClassConfiguration)) {
                this.performSave(eventClassConfiguration, eventClassDefinitions, eventRelationships, eventDeletedClassDefinitions, eventDeletedRelationships, eventFollowingAction, eventTenantId, eventRedirectUrl);
            }
        }
        else if (eventResponseAction === 'open') {
            if (!util_1.isNullOrUndefined(eventClassConfigurationId)) {
                this.performOpenByid(eventClassConfigurationId);
            }
        }
    };
    return EditorTopMenuBarComponent;
}());
exports.EditorTopMenuBarComponent = EditorTopMenuBarComponent;
