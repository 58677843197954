"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var class_configuration_service_1 = require("app/main/content/_service/configuration/class-configuration.service");
var forms_1 = require("@angular/forms");
var relationship_service_1 = require("app/main/content/_service/meta/core/relationship/relationship.service");
var class_definition_service_1 = require("app/main/content/_service/meta/core/class/class-definition.service");
var SaveClassConfigurationAsDialogComponent = /** @class */ (function () {
    function SaveClassConfigurationAsDialogComponent(dialogRef, data, classConfigurationService, relationshipsService, classDefintionService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.classConfigurationService = classConfigurationService;
        this.relationshipsService = relationshipsService;
        this.classDefintionService = classDefintionService;
        this.dialogForm = new forms_1.FormGroup({
            label: new forms_1.FormControl(''),
            description: new forms_1.FormControl('')
        });
    }
    SaveClassConfigurationAsDialogComponent.prototype.ngOnInit = function () {
        this.loaded = true;
    };
    SaveClassConfigurationAsDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    SaveClassConfigurationAsDialogComponent.prototype.onOKClick = function () {
        // if (this.dialogForm.invalid) {
        //   this.dialogForm.get('label').markAsTouched();
        //   this.dialogForm.get('description').markAsTouched();
        // } else {
        //   const classConfiguration = new ClassConfiguration();
        //   classConfiguration.name = this.dialogForm.get('label').value;
        //   classConfiguration.description = this.dialogForm.get('description').value;
        //   classConfiguration.relationshipIds = this.data.relationships.map(r => r.id);
        //   classConfiguration.classDefinitionIds = this.data.classDefinitions.map(c => c.id);
        //   Promise.all([
        //     this.relationshipsService.addAndUpdateRelationships(this.data.marketplace, this.data.relationships).toPromise().then((ret: Relationship) => {
        //       console.log(ret);
        //     }),
        //     this.classDefintionService.addOrUpdateClassDefintions(this.data.marketplace, this.data.classDefinitions).toPromise().then((ret: ClassDefinition) => {
        //       console.log(ret);
        //     })
        //   ]).then(() => {
        //     this.classConfigurationService.createNewClassConfiguration(this.data.marketplace, classConfiguration).toPromise().then((ret: ClassConfiguration) => {
        //       console.log(ret);
        //       this.data.classConfiguration = ret;
        //     }).then(() => {
        //       console.log('finished');
        //       this.dialogRef.close(this.data);
        //     });
        //   });
        // }
    };
    SaveClassConfigurationAsDialogComponent.prototype.handleBrowseClick = function () {
        // this.classConfigurationService.getAllClassConfigurations().toPromise().then((classConfigurations: ClassConfiguration[]) => {
        //   this.allClassConfigurations = classConfigurations;
        //   // ----DEBUG
        //   // this.recentMatchingConfigurations.push(...this.recentMatchingConfigurations);
        //   // this.recentMatchingConfigurations.push(...this.recentMatchingConfigurations);
        //   // ----
        //   this.loaded = true;
        // });
    };
    return SaveClassConfigurationAsDialogComponent;
}());
exports.SaveClassConfigurationAsDialogComponent = SaveClassConfigurationAsDialogComponent;
