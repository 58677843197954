"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var class_instance_form_editor_component_1 = require("./class-instance-form-editor.component");
var routes = [
    { path: '', component: class_instance_form_editor_component_1.ClassInstanceFormEditorComponent }
    // { path: ':marketplaceId', component: ClassInstanceFormEditorComponent }
    // {path: ':marketplaceId/:classId/:showMaxGluehtemperatur', component: ClassInstanceFormEditorComponent}
];
var ClassInstanceFormEditorModule = /** @class */ (function () {
    function ClassInstanceFormEditorModule() {
    }
    return ClassInstanceFormEditorModule;
}());
exports.ClassInstanceFormEditorModule = ClassInstanceFormEditorModule;
