"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".example-tree-invisible[_ngcontent-%COMP%]{display:none}.example-tree[_ngcontent-%COMP%]   li[_ngcontent-%COMP%], .example-tree[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]{margin-top:0;margin-bottom:0;list-style-type:none}.mat-tree-node[_ngcontent-%COMP%]{min-height:0;height:25px;white-space:nowrap!important;overflow:visible!important}ul[_ngcontent-%COMP%]{margin-left:2px;padding-left:0;list-style:none;border:none;overflow:visible!important}li[_ngcontent-%COMP%]{position:relative;padding:0 0 0 10px;line-height:0}.example-tree[_ngcontent-%COMP%]{overflow:scroll;max-height:50vh;padding-bottom:10px}"];
exports.styles = styles;
