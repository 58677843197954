"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".folded:not(.unfolded)   [_nghost-%COMP%]   .nav-link[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{opacity:0;transition:opacity .2s ease}.folded:not(.unfolded)   .open[_nghost-%COMP%]   .children[_ngcontent-%COMP%]{display:none!important}[_nghost-%COMP%]   .nav-link[_ngcontent-%COMP%]   .collapse-arrow[_ngcontent-%COMP%]{transition:transform .3s ease-in-out,opacity .25s ease-in-out .1s;transform:rotate(0)}[_nghost-%COMP%] > .children[_ngcontent-%COMP%]{overflow:hidden}.open[_nghost-%COMP%] > .nav-link[_ngcontent-%COMP%]   .collapse-arrow[_ngcontent-%COMP%]{transform:rotate(90deg)}.nav-link-title[_ngcontent-%COMP%]{font-size:17px}"];
exports.styles = styles;
