"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var anonym_guard_1 = require("./_guard/anonym.guard");
var router_1 = require("@angular/router");
var ɵ0 = function () {
    return Promise.resolve().then(function () { return require("./_components/dashboard/dashboard.module.ngfactory"); }).then(function (m) { return m.FuseDashboardModuleNgFactory; });
}, ɵ1 = function () {
    return Promise.resolve().then(function () { return require("./_components/configuration/property-configurator/list/property-list.module.ngfactory"); }).then(function (m) { return m.PropertyListModuleNgFactory; });
}, ɵ2 = function () {
    return Promise.resolve().then(function () { return require("./_components/configuration/property-configurator/builder/property-build-form.module.ngfactory"); }).then(function (m) { return m.PropertyBuildFormModuleNgFactory; });
}, ɵ3 = function () {
    return Promise.resolve().then(function () { return require("./_components/configuration/class-configurator/configurator.module.ngfactory"); }).then(function (m) { return m.ConfiguratorModuleNgFactory; });
}, ɵ4 = function () {
    return Promise.resolve().then(function () { return require("./_components/configuration/class-instance-configurator/form-editor/class-instance-form-editor.module.ngfactory"); }).then(function (m) { return m.ClassInstanceFormEditorModuleNgFactory; });
}, ɵ5 = function () {
    return Promise.resolve().then(function () { return require("./_components/configuration/matching-configurator/matching-configurator.module.ngfactory"); }).then(function (m) { return m.MatchingConfiguratorModuleNgFactory; });
}, ɵ6 = function () {
    return Promise.resolve().then(function () { return require("./_components/task-select/task-select.module.ngfactory"); }).then(function (m) { return m.FuseTaskSelectModuleNgFactory; });
}, ɵ7 = function () {
    return Promise.resolve().then(function () { return require("./_components/_shared/invalid-parameters/invalid-parameters.module.ngfactory"); }).then(function (m) { return m.InvalidParametersModuleNgFactory; });
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
exports.ɵ7 = ɵ7;
var routes = [
    {
        path: 'main/dashboard',
        loadChildren: ɵ0,
        canActivate: [anonym_guard_1.AnonymGuard]
    },
    {
        path: 'main/properties/all',
        loadChildren: ɵ1,
        canActivate: [anonym_guard_1.AnonymGuard]
    },
    {
        path: 'main/property-builder',
        loadChildren: ɵ2,
        canActivate: [anonym_guard_1.AnonymGuard]
    },
    {
        path: 'main/class-configurator',
        loadChildren: ɵ3,
        canActivate: [anonym_guard_1.AnonymGuard]
    },
    {
        path: 'main/instance-editor',
        loadChildren: ɵ4,
        canActivate: [anonym_guard_1.AnonymGuard]
    },
    {
        path: 'main/matching-configurator',
        loadChildren: ɵ5,
        canActivate: [anonym_guard_1.AnonymGuard]
    },
    {
        path: 'main/task-select',
        loadChildren: ɵ6,
        canActivate: [anonym_guard_1.AnonymGuard],
    },
    {
        path: 'main/invalid-parameters',
        loadChildren: ɵ7,
        canActivate: [anonym_guard_1.AnonymGuard],
    },
];
var FuseContentModule = /** @class */ (function () {
    function FuseContentModule() {
    }
    return FuseContentModule;
}());
exports.FuseContentModule = FuseContentModule;
