"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var form_1 = require("app/main/content/_model/configurator/form");
var dynamic_form_item_control_service_1 = require("app/main/content/_service/dynamic-form-item-control.service");
var FormContainerComponent = /** @class */ (function () {
    function FormContainerComponent(formItemControlService) {
        this.formItemControlService = formItemControlService;
        this.result = new core_1.EventEmitter();
        this.tupleSelected = new core_1.EventEmitter();
        this.errorEvent = new core_1.EventEmitter();
        this.arrayIndex = 0;
        this.values = [];
    }
    FormContainerComponent.prototype.ngOnInit = function () {
        this.localExpanded = this.expanded;
    };
    FormContainerComponent.prototype.handleResultEvent = function (event) {
        var _this = this;
        this.values.push(event.value);
        this.arrayIndex++;
        if (this.arrayIndex === this.formEntry.formGroup.controls['entries'].length) {
            var retValue = this.values;
            var valueKey = Object.keys(event.value)[0];
            var split = valueKey.split('.');
            valueKey = valueKey.substr(0, valueKey.length - split[0].length - 1);
            retValue = {};
            retValue[valueKey] = this.values;
            Promise.all([this.result.emit({ formConfigurationId: event.formConfigurationId, value: retValue })]).then(function () {
                _this.arrayIndex = 0;
                _this.values = [];
            });
        }
    };
    FormContainerComponent.prototype.handleErrorEvent = function (evt) {
        this.expanded = true;
        this.localExpanded = true;
        this.errorEvent.emit(evt);
    };
    FormContainerComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    FormContainerComponent.prototype.handleTupleSelection = function (evt) {
        this.tupleSelected.emit(evt);
    };
    FormContainerComponent.prototype.onAddSubEntryClicked = function () {
        var formArray = this.formEntry.formGroup.controls['entries'];
        formArray.push(this.formItemControlService.getControlForSubEntry(this.formEntry.formItems));
    };
    FormContainerComponent.prototype.onRemoveSubEntryClicked = function (index) {
        var formArray = this.formEntry.formGroup.controls['entries'];
        formArray.removeAt(index);
    };
    FormContainerComponent.prototype.handlePanelOpened = function () {
        this.localExpanded = true;
    };
    FormContainerComponent.prototype.handlePanelClosed = function () {
        this.localExpanded = false;
    };
    FormContainerComponent.prototype.getPanelExpandedState = function () {
        return this.localExpanded;
    };
    return FormContainerComponent;
}());
exports.FormContainerComponent = FormContainerComponent;
