"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".overlay-content[_ngcontent-%COMP%]   .confirm-button[_ngcontent-%COMP%]{border-radius:4px;background-color:#fff;padding:0 10px;line-height:20px;border:1px solid currentColor}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-infix{padding:0 0 10px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-flex{padding-left:5px;padding-right:5px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-wrapper{padding:0}"];
exports.styles = styles;
