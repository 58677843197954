"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MatchingDataRequestDTO = /** @class */ (function () {
    function MatchingDataRequestDTO() {
    }
    return MatchingDataRequestDTO;
}());
exports.MatchingDataRequestDTO = MatchingDataRequestDTO;
var MatchingEntityMappings = /** @class */ (function () {
    function MatchingEntityMappings() {
    }
    return MatchingEntityMappings;
}());
exports.MatchingEntityMappings = MatchingEntityMappings;
var MatchingEntity = /** @class */ (function () {
    function MatchingEntity() {
    }
    return MatchingEntity;
}());
exports.MatchingEntity = MatchingEntity;
var MatchingOperatorRelationship = /** @class */ (function () {
    function MatchingOperatorRelationship() {
    }
    return MatchingOperatorRelationship;
}());
exports.MatchingOperatorRelationship = MatchingOperatorRelationship;
var MatchingEntityType;
(function (MatchingEntityType) {
    MatchingEntityType["PROPERTY"] = "PROPERTY";
    MatchingEntityType["CLASS"] = "CLASS";
})(MatchingEntityType = exports.MatchingEntityType || (exports.MatchingEntityType = {}));
var MatchingOperatorType;
(function (MatchingOperatorType) {
    MatchingOperatorType["EQUAL"] = "EQUAL";
    MatchingOperatorType["LESS"] = "LESS";
    MatchingOperatorType["GREATER"] = "GREATER";
    MatchingOperatorType["LESS_EQUAL"] = "LESS_EQUAL";
    MatchingOperatorType["GREATER_EQUAL"] = "GREATER_EQUAL";
    MatchingOperatorType["EXISTS"] = "EXISTS";
    MatchingOperatorType["ALL"] = "ALL";
})(MatchingOperatorType = exports.MatchingOperatorType || (exports.MatchingOperatorType = {}));
(function (MatchingOperatorType) {
    function getLabelForMatchingOperatorType(matchingOperatorType) {
        switch (matchingOperatorType) {
            case MatchingOperatorType.EQUAL:
                return "gleich";
            case MatchingOperatorType.LESS:
                return "kleiner";
            case MatchingOperatorType.GREATER:
                return "größer";
            case MatchingOperatorType.LESS_EQUAL:
                return "kleiner oder gleich";
            case MatchingOperatorType.GREATER_EQUAL:
                return "größer oder gleich";
            case MatchingOperatorType.EXISTS:
                return "existiert";
            case MatchingOperatorType.ALL:
                return "alle";
        }
    }
    MatchingOperatorType.getLabelForMatchingOperatorType = getLabelForMatchingOperatorType;
})(MatchingOperatorType = exports.MatchingOperatorType || (exports.MatchingOperatorType = {}));
