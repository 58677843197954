"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dynamic-form-block.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../dynamic-form-item/dynamic-form-item.component.ngfactory");
var i3 = require("../dynamic-form-item/dynamic-form-item.component");
var i4 = require("../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i5 = require("@angular/material/button");
var i6 = require("@angular/cdk/a11y");
var i7 = require("@angular/platform-browser/animations");
var i8 = require("@angular/flex-layout/flex");
var i9 = require("@angular/flex-layout/core");
var i10 = require("@angular/common");
var i11 = require("@angular/forms");
var i12 = require("../../../../_service/dynamic-form-item-control.service");
var i13 = require("./dynamic-form-block.component");
var styles_DynamicFormBlockComponent = [i0.styles];
var RenderType_DynamicFormBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicFormBlockComponent, data: { "animation": [{ type: 7, name: "simpleFadeAnimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: 600 }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 600 }, options: null }], options: {} }] } });
exports.RenderType_DynamicFormBlockComponent = RenderType_DynamicFormBlockComponent;
function View_DynamicFormBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form-row"]], [[24, "@simpleFadeAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-form-item", [], null, [[null, "tupleSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tupleSelected" === en)) {
        var pd_0 = (_co.handleTupleSelection($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormItemComponent_0, i2.RenderType_DynamicFormItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.DynamicFormItemComponent, [], { formItem: [0, "formItem"], form: [1, "form"], submitPressed: [2, "submitPressed"] }, { tupleSelected: "tupleSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.form; var currVal_3 = _co.submitPressed; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); }); }
function View_DynamicFormBlockComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Weiter"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DynamicFormBlockComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Speichern"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DynamicFormBlockComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "pt-8"], ["fxLayout", "row"], ["fxLayoutAlign", "space-around center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i8.DefaultLayoutDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutStyleBuilder], i9.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i8.DefaultLayoutAlignDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutAlignStyleBuilder], i9.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "secondary"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Abbrechen"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormBlockComponent_3)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormBlockComponent_4)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-around center"; _ck(_v, 2, 0, currVal_1); var currVal_4 = "secondary"; _ck(_v, 4, 0, currVal_4); var currVal_5 = !_co.lastEntry; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.lastEntry; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_DynamicFormBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i11.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 540672, null, 0, i11.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i11.ControlContainer, null, [i11.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i11.NgControlStatusGroup, [[4, i11.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormBlockComponent_1)), i1.ɵdid(6, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormBlockComponent_2)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.formItems; _ck(_v, 6, 0, currVal_8); var currVal_9 = !_co.hideButtons; _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
exports.View_DynamicFormBlockComponent_0 = View_DynamicFormBlockComponent_0;
function View_DynamicFormBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-dynamic-form-block", [], null, null, null, View_DynamicFormBlockComponent_0, RenderType_DynamicFormBlockComponent)), i1.ɵprd(512, null, i12.DynamicFormItemControlService, i12.DynamicFormItemControlService, []), i1.ɵdid(2, 638976, null, 0, i13.DynamicFormBlockComponent, [i12.DynamicFormItemControlService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_DynamicFormBlockComponent_Host_0 = View_DynamicFormBlockComponent_Host_0;
var DynamicFormBlockComponentNgFactory = i1.ɵccf("app-dynamic-form-block", i13.DynamicFormBlockComponent, View_DynamicFormBlockComponent_Host_0, { formItems: "formItems", hideButtons: "hideButtons", formConfiguration: "formConfiguration", form: "form", finishClicked: "finishClicked" }, { resultEvent: "resultEvent", cancelEvent: "cancelEvent", tupleSelected: "tupleSelected", errorEvent: "errorEvent" }, []);
exports.DynamicFormBlockComponentNgFactory = DynamicFormBlockComponentNgFactory;
