"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var options_overlay_content_component_1 = require("../options-overlay-content/options-overlay-content.component");
var OVERLAY_WIDTH = 160;
var OVERLAY_HEIGHT = 100;
var TreePropertyOptionsOverlayControlComponent = /** @class */ (function () {
    function TreePropertyOptionsOverlayControlComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.overlayClosed = new core_1.EventEmitter();
    }
    TreePropertyOptionsOverlayControlComponent.prototype.ngOnInit = function () {
        this.toggleOverlay();
    };
    TreePropertyOptionsOverlayControlComponent.prototype.ngOnChanges = function () {
        if (this.displayOverlay) {
            this.ngOnInit();
        }
    };
    TreePropertyOptionsOverlayControlComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    TreePropertyOptionsOverlayControlComponent.prototype.toggleOverlay = function () {
        this.changeDetector.detectChanges();
        if (this.displayOverlay) {
            var yPos = this.overlayEvent.clientY;
            var xPos = this.overlayEvent.clientX;
            if (yPos + OVERLAY_HEIGHT > window.innerHeight) {
                yPos = window.innerHeight - OVERLAY_HEIGHT;
            }
            if (xPos + OVERLAY_WIDTH > window.innerWidth) {
                xPos = window.innerWidth - OVERLAY_WIDTH;
            }
            this.overlayDiv.nativeElement.style.top = yPos + 'px';
            this.overlayDiv.nativeElement.style.left = xPos + 'px';
            this.overlayDiv.nativeElement.style.position = 'fixed';
            this.overlayDiv.nativeElement.style.width = OVERLAY_WIDTH + 'px';
            this.overlayDiv.nativeElement.style.height = OVERLAY_HEIGHT + 'px';
        }
    };
    TreePropertyOptionsOverlayControlComponent.prototype.handleResultEvent = function (event) {
        this.displayOverlay = false;
        this.overlayClosed.emit(event);
    };
    return TreePropertyOptionsOverlayControlComponent;
}());
exports.TreePropertyOptionsOverlayControlComponent = TreePropertyOptionsOverlayControlComponent;
