"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FuseMatSidenavHelperService = /** @class */ (function () {
    function FuseMatSidenavHelperService() {
        this.sidenavInstances = [];
    }
    FuseMatSidenavHelperService.prototype.setSidenav = function (id, instance) {
        this.sidenavInstances[id] = instance;
    };
    FuseMatSidenavHelperService.prototype.getSidenav = function (id) {
        return this.sidenavInstances[id];
    };
    return FuseMatSidenavHelperService;
}());
exports.FuseMatSidenavHelperService = FuseMatSidenavHelperService;
