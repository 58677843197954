"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./options-overlay-control.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../options-overlay-content/options-overlay-content.component.ngfactory");
var i3 = require("../options-overlay-content/options-overlay-content.component");
var i4 = require("@angular/common");
var i5 = require("./options-overlay-control.component");
var styles_TreePropertyOptionsOverlayControlComponent = [i0.styles];
var RenderType_TreePropertyOptionsOverlayControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreePropertyOptionsOverlayControlComponent, data: {} });
exports.RenderType_TreePropertyOptionsOverlayControlComponent = RenderType_TreePropertyOptionsOverlayControlComponent;
function View_TreePropertyOptionsOverlayControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["overlayDiv", 1]], null, 2, "div", [["class", "mat-elevation-z4 overlay-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "tree-property-options-overlay-content", [], null, [[null, "result"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("result" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TreePropertyOptionsOverlayContentComponent_0, i2.RenderType_TreePropertyOptionsOverlayContentComponent)), i1.ɵdid(2, 114688, null, 0, i3.TreePropertyOptionsOverlayContentComponent, [], { inputData: [0, "inputData"] }, { result: "result" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overlayContent; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TreePropertyOptionsOverlayControlComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { overlayDiv: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreePropertyOptionsOverlayControlComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayOverlay; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_TreePropertyOptionsOverlayControlComponent_0 = View_TreePropertyOptionsOverlayControlComponent_0;
function View_TreePropertyOptionsOverlayControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tree-property-options-overlay-control", [], null, null, null, View_TreePropertyOptionsOverlayControlComponent_0, RenderType_TreePropertyOptionsOverlayControlComponent)), i1.ɵdid(1, 638976, null, 0, i5.TreePropertyOptionsOverlayControlComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TreePropertyOptionsOverlayControlComponent_Host_0 = View_TreePropertyOptionsOverlayControlComponent_Host_0;
var TreePropertyOptionsOverlayControlComponentNgFactory = i1.ɵccf("tree-property-options-overlay-control", i5.TreePropertyOptionsOverlayControlComponent, View_TreePropertyOptionsOverlayControlComponent_Host_0, { displayOverlay: "displayOverlay", overlayContent: "overlayContent", overlayEvent: "overlayEvent" }, { overlayClosed: "overlayClosed" }, []);
exports.TreePropertyOptionsOverlayControlComponentNgFactory = TreePropertyOptionsOverlayControlComponentNgFactory;
