<div #buildForm class="page-layout blank right-sidenav p-24">
  <customizable-header [displayNavigateBack]="false" headerText="Property-Baukasten">
  </customizable-header>

  <div class="mat-white-bg mat-elevation-z4 p-24 mt-24" *ngIf="loaded">
    <app-builder-container [redirectUrl]="redirectUrl" [tenantId]="tenantId" [builderType]="builderType"
      [entryId]="entryId" sourceString="builder" (result)="handleResultEvent($event)"
      (management)="handleManagementEvent($event, buildForm)">
    </app-builder-container>
  </div>

  <div class="center-div" *ngIf="!loaded">
    <mat-spinner></mat-spinner>
  </div>
</div>