"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var FlatPropertyDefinitionService = /** @class */ (function () {
    function FlatPropertyDefinitionService(http) {
        this.http = http;
    }
    FlatPropertyDefinitionService.prototype.getAllPropertyDefinitons = function () {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/all");
    };
    FlatPropertyDefinitionService.prototype.getAllPropertyDefinitonsForTenant = function (tenantId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/all/tenant/" + tenantId);
    };
    FlatPropertyDefinitionService.prototype.getPropertyDefinitionById = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/" + id);
    };
    FlatPropertyDefinitionService.prototype.createNewPropertyDefinition = function (propertyDefinition) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/new", propertyDefinition);
    };
    FlatPropertyDefinitionService.prototype.createNewPropertyDefinitions = function (propertyDefinitions) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/new/multiple", propertyDefinitions);
    };
    FlatPropertyDefinitionService.prototype.updatePropertyDefintion = function (propertyDefinition) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/update", propertyDefinition);
    };
    FlatPropertyDefinitionService.prototype.updatePropertyDefintions = function (propertyDefinitions) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/update/multiple", propertyDefinitions);
    };
    FlatPropertyDefinitionService.prototype.deletePropertyDefinition = function (id) {
        return this.http.delete(environment_1.environment.CONFIGURATOR_URL + "/property-definition/flat/" + id + "/delete");
    };
    FlatPropertyDefinitionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FlatPropertyDefinitionService_Factory() { return new FlatPropertyDefinitionService(i0.ɵɵinject(i1.HttpClient)); }, token: FlatPropertyDefinitionService, providedIn: "root" });
    return FlatPropertyDefinitionService;
}());
exports.FlatPropertyDefinitionService = FlatPropertyDefinitionService;
