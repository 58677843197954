"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".menu-radio-group[_ngcontent-%COMP%]{display:flex;flex-direction:column;margin:5px 0}.menu-radio-group[_ngcontent-%COMP%]   .menu-radio-button[_ngcontent-%COMP%]{margin:5px}"];
exports.styles = styles;
