"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var util_1 = require("util");
var myMxCell_1 = require("../../../myMxCell");
var class_configuration_service_1 = require("app/main/content/_service/configuration/class-configuration.service");
var utils_and_constants_1 = require("../../../class-configurator/utils-and-constants");
var relationship_1 = require("app/main/content/_model/configurator/relationship");
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var CLASSDEFINITION_CELL_WIDTH = 110;
var CLASSDEFINITION_CELL_HEIGHT = 40;
var AddClassDefinitionGraphDialogComponent = /** @class */ (function () {
    function AddClassDefinitionGraphDialogComponent(dialogRef, dialogData, classConfigurationServce) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.classConfigurationServce = classConfigurationServce;
    }
    AddClassDefinitionGraphDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.classConfigurationServce.getAllForClassConfigurationInOne(this.dialogData.matchingEntityConfiguration.classConfigurationId).toPromise()];
                    case 1:
                        _a.graphData = (_b.sent());
                        this.loaded = true;
                        this.initGraph();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddClassDefinitionGraphDialogComponent.prototype.initGraph = function () {
        this.graph = new mx.mxGraph(this.graphContainer.nativeElement);
        this.graph.isCellSelectable = function (cell) {
            var state = this.view.getState(cell);
            var style = state != null ? state.style : this.getCellStyle(cell);
            return (this.isCellsSelectable() && !this.isCellLocked(cell) && style['selectable'] !== 0);
        };
        this.graph.getCursorForCell = function (cell) {
            if (util_1.isNullOrUndefined(cell.cellType) || cell.getStyle() === utils_and_constants_1.CConstants.mxStyles.classDisabled) {
                return 'default';
            }
            if (cell.cellType === myMxCell_1.MyMxCellType.CLASS) {
                return mx.mxConstants.CURSOR_TERMINAL_HANDLE;
            }
        };
        if (!mx.mxClient.isBrowserSupported()) {
            mx.mxUtils.error('Browser is not supported!', 200, false);
        }
        else {
            // Disables the built-in context menu
            mx.mxEvent.disableContextMenu(this.graphContainer.nativeElement);
            this.graph.setPanning(true);
            this.addMouseListeners(this.graph);
            var rootCell = this.createGraph();
            this.setLayout();
            this.executeLayout(rootCell);
            this.graph.setEnabled(false);
        }
    };
    AddClassDefinitionGraphDialogComponent.prototype.addMouseListeners = function (graph) {
        var outer = this;
        graph.addMouseListener({
            highlightedCell: undefined,
            deselectedCell: undefined,
            mouseDown: function (evt, state) { },
            mouseMove: function (evt, state) {
                var _this = this;
                if (this.pointerOnClass(state)) {
                    var cell_1 = state.state.cell;
                    var addedEntities = outer.dialogData.addedEntities;
                    if (addedEntities.findIndex(function (e) { return e.classDefinition.id === cell_1.id; }) !== -1) {
                        return;
                    }
                    this.highlightedCell = cell_1;
                    outer.graph.setCellStyle(utils_and_constants_1.CConstants.mxStyles.classHighlighted, [cell_1]);
                }
                else {
                    var addedEntities = outer.dialogData.addedEntities;
                    if ((!util_1.isNullOrUndefined(this.highlightedCell)
                        && addedEntities.findIndex(function (e) { return e.classDefinition.id === _this.highlightedCell.id; }) === -1)
                        || !util_1.isNullOrUndefined(this.deselectedCell)) {
                        outer.graph.setCellStyle(utils_and_constants_1.CConstants.mxStyles.classNormal, [this.highlightedCell]);
                        this.deselectedCell = undefined;
                    }
                    this.highlightedCell = undefined;
                }
            },
            mouseUp: function (evt, state) {
                if (!this.pointerOnClass(state)) {
                    return;
                }
                var entities = outer.dialogData.matchingEntityConfiguration.mappings.entities;
                var cell = state.state.cell;
                if (cell.getStyle() === utils_and_constants_1.CConstants.mxStyles.classDisabled) {
                    return;
                }
                var entity = entities.find(function (e) { return e.classDefinition.id === cell.id; });
                if (outer.dialogData.addedEntities.findIndex(function (e) { return e.classDefinition.id === cell.id; }) === -1) {
                    outer.dialogData.addedEntities.push(entity);
                    outer.graph.setCellStyle(utils_and_constants_1.CConstants.mxStyles.classHighlighted, [cell]);
                    outer.addCheckedOverlay(cell);
                }
                else {
                    outer.dialogData.addedEntities = outer.dialogData.addedEntities.filter(function (e) { return e.classDefinition.id !== cell.id; });
                    outer.graph.setCellStyle(utils_and_constants_1.CConstants.mxStyles.classNormal, [cell]);
                    this.deselectedCell = cell;
                    outer.graph.removeCellOverlay(cell, undefined);
                }
            },
            pointerOnClass: function (state) {
                return !util_1.isNullOrUndefined(state) && !util_1.isNullOrUndefined(state.state) && !util_1.isNullOrUndefined(state.state.cell) && state.state.cell.cellType === myMxCell_1.MyMxCellType.CLASS;
            },
        });
    };
    AddClassDefinitionGraphDialogComponent.prototype.createGraph = function () {
        this.graph.getModel().beginUpdate();
        var rootCell = this.addClassDefinitions(this.graphData.classDefinitions);
        this.addRelationships(this.graphData.relationships);
        this.graph.getModel().endUpdate();
        return rootCell;
    };
    AddClassDefinitionGraphDialogComponent.prototype.addClassDefinitions = function (classDefinitions) {
        var e_1, _a;
        var rootCell;
        try {
            for (var classDefinitions_1 = __values(classDefinitions), classDefinitions_1_1 = classDefinitions_1.next(); !classDefinitions_1_1.done; classDefinitions_1_1 = classDefinitions_1.next()) {
                var classDefinition = classDefinitions_1_1.value;
                var cell = this.addClassDefinitionCell(classDefinition);
                if (cell.root) {
                    rootCell = cell;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (classDefinitions_1_1 && !classDefinitions_1_1.done && (_a = classDefinitions_1.return)) _a.call(classDefinitions_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return rootCell;
    };
    AddClassDefinitionGraphDialogComponent.prototype.addClassDefinitionCell = function (classDefinition) {
        var mxStyle = this.dialogData.existingEntityPaths.findIndex(function (p) { return p.endsWith(classDefinition.id); }) !== -1
            ? utils_and_constants_1.CConstants.mxStyles.classDisabled
            : this.dialogData.addedEntities.findIndex(function (e) { return e.classDefinition.id === classDefinition.id; }) !== -1
                ? utils_and_constants_1.CConstants.mxStyles.classHighlighted
                : utils_and_constants_1.CConstants.mxStyles.classNormal;
        var cell = this.graph.insertVertex(this.graph.getDefaultParent(), classDefinition.id, classDefinition.name, 0, 0, CLASSDEFINITION_CELL_WIDTH, CLASSDEFINITION_CELL_HEIGHT, mxStyle);
        cell.root = false;
        cell.cellType = myMxCell_1.MyMxCellType.CLASS;
        if (cell.getStyle() === utils_and_constants_1.CConstants.mxStyles.classDisabled || cell.getStyle() === utils_and_constants_1.CConstants.mxStyles.classHighlighted) {
            this.addCheckedOverlay(cell);
        }
        return cell;
    };
    AddClassDefinitionGraphDialogComponent.prototype.addCheckedOverlay = function (cell) {
        var overlay = new mx.mxCellOverlay(new mx.mxImage('/assets/icons/class_editor/check-solid_white.png', 14, 14), 'Overlay', mx.mxConstants.ALIGN_LEFT, mx.mxConstants.ALIGN_TOP, new mx.mxPoint(9, 10));
        this.graph.addCellOverlay(cell, overlay);
    };
    AddClassDefinitionGraphDialogComponent.prototype.addRelationships = function (relationships) {
        var e_2, _a;
        try {
            for (var relationships_1 = __values(relationships), relationships_1_1 = relationships_1.next(); !relationships_1_1.done; relationships_1_1 = relationships_1.next()) {
                var relationship = relationships_1_1.value;
                this.createRelationshipCellById(relationship);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (relationships_1_1 && !relationships_1_1.done && (_a = relationships_1.return)) _a.call(relationships_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    AddClassDefinitionGraphDialogComponent.prototype.createRelationshipCellById = function (relationship) {
        var source = this.graph.getModel().getCell(relationship.source);
        var target = this.graph.getModel().getCell(relationship.target);
        var style = relationship.relationshipType === relationship_1.RelationshipType.INHERITANCE ?
            utils_and_constants_1.CConstants.mxStyles.inheritance : relationship.relationshipType === relationship_1.RelationshipType.ASSOCIATION ?
            utils_and_constants_1.CConstants.mxStyles.association : utils_and_constants_1.CConstants.mxStyles.association;
        var cell = this.graph.insertEdge(this.graph.getDefaultParent(), relationship.id, '', source, target, style);
        cell.cellType = myMxCell_1.MyMxCellType.TREE_CONNECTOR;
        return cell;
    };
    AddClassDefinitionGraphDialogComponent.prototype.setLayout = function () {
        this.layout = new mx.mxCompactTreeLayout(this.graph, false, false);
        this.layout.levelDistance = 50;
        this.layout.alignRanks = true;
        this.layout.minEdgeJetty = 50;
        this.layout.prefHozEdgeSep = 5;
        this.layout.resetEdges = false;
        this.layout.edgeRouting = true;
    };
    AddClassDefinitionGraphDialogComponent.prototype.executeLayout = function (rootCell) {
        if (!util_1.isNullOrUndefined(this.layout)) {
            this.layout.execute(this.graph.getDefaultParent(), rootCell);
        }
    };
    return AddClassDefinitionGraphDialogComponent;
}());
exports.AddClassDefinitionGraphDialogComponent = AddClassDefinitionGraphDialogComponent;
