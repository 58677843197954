"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./result.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var i3 = require("../../../../../../../../@fuse/services/config.service");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/flex-layout/flex");
var i6 = require("@angular/flex-layout/core");
var i7 = require("../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i8 = require("@angular/material/button");
var i9 = require("@angular/cdk/a11y");
var i10 = require("@angular/platform-browser/animations");
var i11 = require("./result.component");
var i12 = require("@angular/router");
var styles_InstanceCreationResultComponent = [i0.styles];
var RenderType_InstanceCreationResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstanceCreationResultComponent, data: {} });
exports.RenderType_InstanceCreationResultComponent = RenderType_InstanceCreationResultComponent;
function View_InstanceCreationResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "page-layout right-sidenav p-24"], ["fusePerfectScrollbar", ""]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i2.FusePerfectScrollbarDirective, [i1.ElementRef, i3.FuseConfigService, i4.Platform], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "alert alert-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Eintrag wurde erfolgreich erstellt. "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "pt-20"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(6, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleAnotherClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Noch einen Eintrag erfassen "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleFinishedClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Fertig "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "row"; _ck(_v, 5, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 6, 0, currVal_1); var currVal_4 = "primary"; _ck(_v, 9, 0, currVal_4); var currVal_7 = "primary"; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 12).disabled || null); var currVal_6 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_5, currVal_6); }); }
exports.View_InstanceCreationResultComponent_0 = View_InstanceCreationResultComponent_0;
function View_InstanceCreationResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instance-creation-result", [], null, null, null, View_InstanceCreationResultComponent_0, RenderType_InstanceCreationResultComponent)), i1.ɵdid(1, 114688, null, 0, i11.InstanceCreationResultComponent, [i12.Router, i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_InstanceCreationResultComponent_Host_0 = View_InstanceCreationResultComponent_Host_0;
var InstanceCreationResultComponentNgFactory = i1.ɵccf("app-instance-creation-result", i11.InstanceCreationResultComponent, View_InstanceCreationResultComponent_Host_0, { resultClassInstance: "resultClassInstance" }, { navigateBack: "navigateBack" }, []);
exports.InstanceCreationResultComponentNgFactory = InstanceCreationResultComponentNgFactory;
