"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".page-layout[_ngcontent-%COMP%]   .form-field[_ngcontent-%COMP%]{width:100%}.page-layout[_ngcontent-%COMP%]   .slide-toggle-field[_ngcontent-%COMP%]{width:100%;line-height:100%}.page-layout[_ngcontent-%COMP%]   .mat-form-field--no-underline[_ngcontent-%COMP%]   .mat-input-underline[_ngcontent-%COMP%]{background-color:transparent;width:100%}.page-layout[_ngcontent-%COMP%]   .nested-border[_ngcontent-%COMP%]{border:1px solid #000;border-radius:.000001px;padding-left:10px;padding-right:5px;padding-bottom:5px}"];
exports.styles = styles;
