"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var dynamic_form_item_control_service_1 = require("../../../../_service/dynamic-form-item-control.service");
var util_1 = require("util");
var form_1 = require("app/main/content/_model/configurator/form");
require("jquery");
var DynamicFormBlockComponent = /** @class */ (function () {
    function DynamicFormBlockComponent(formItemControlService) {
        this.formItemControlService = formItemControlService;
        this.formItems = [];
        this.resultEvent = new core_1.EventEmitter();
        this.cancelEvent = new core_1.EventEmitter();
        this.tupleSelected = new core_1.EventEmitter();
        this.errorEvent = new core_1.EventEmitter();
    }
    DynamicFormBlockComponent.prototype.ngOnInit = function () {
        if (util_1.isNullOrUndefined(this.form)) {
            this.form = this.formItemControlService.toFormGroup(this.formItems);
        }
        this.submitPressed = false;
    };
    DynamicFormBlockComponent.prototype.ngOnChanges = function () {
        if (this.finishClicked) {
            this.onSubmit();
        }
    };
    DynamicFormBlockComponent.prototype.onSubmit = function () {
        var e_1, _a;
        this.submitPressed = true;
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            try {
                for (var _b = __values(this.formItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    if (item.controlType.startsWith('tree')) {
                        this.form.controls[item.key].setValue(item.value);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.fireResultEvent();
        }
        else {
            // Mark errornous Fields
            this.markFormAsTouched(this.formItems, this.form);
            this.errorEvent.emit(true);
            // focus on first error using jQuery
            $('input.ng-invalid').first().focus();
        }
        this.submitPressed = false;
    };
    DynamicFormBlockComponent.prototype.markFormAsTouched = function (formItem, control) {
        var e_2, _a;
        try {
            for (var formItem_1 = __values(formItem), formItem_1_1 = formItem_1.next(); !formItem_1_1.done; formItem_1_1 = formItem_1.next()) {
                var item = formItem_1_1.value;
                control.controls[item.key].markAsTouched();
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (formItem_1_1 && !formItem_1_1.done && (_a = formItem_1.return)) _a.call(formItem_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    DynamicFormBlockComponent.prototype.fireResultEvent = function () {
        this.resultEvent.emit(new form_1.FormEntryReturnEventData(this.formConfiguration.id, this.form.value));
    };
    DynamicFormBlockComponent.prototype.handleCancel = function () {
        this.cancelEvent.emit('cancel');
    };
    DynamicFormBlockComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    DynamicFormBlockComponent.prototype.handleTupleSelection = function (evt) {
        this.tupleSelected.emit(evt);
    };
    return DynamicFormBlockComponent;
}());
exports.DynamicFormBlockComponent = DynamicFormBlockComponent;
