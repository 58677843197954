<div mat-dialog-title>
  <table>
    <tr>
      <td>
        <mat-icon>add</mat-icon>
      </td>
      <td>
        <h1>Property hinzufügen</h1>
      </td>
    </tr>
  </table>
</div>

<mat-dialog-content>
  <div *ngIf="loaded">
    <mat-tab-group #tabGroup (selectedTabChange)="tabIndex = $event.index">
      <mat-tab label="Flat Properties">
        <mat-table #flatPropertyTable class="property-table" [dataSource]="flatPropertyDataSource" matSort
          fusePerfectScrollbar>
          <ng-container matColumnDef="checkbox">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? flatPropertySelection.toggle(row) : null"
                [checked]="flatPropertySelection.isSelected(row)" [disabled]="isFlatPropertyRowDisabled(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <div fxLayout="column" fxLayoutAlign="start start">
                <div class="name-label">{{ entry.name }}</div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Datentyp</mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <div>
                {{ entry.type }}
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onFlatRowClick(row)"></mat-row>
        </mat-table>

        <mat-form-field appearance="outline" class="search-field">
          <input matInput (keyup)="applyFlatPropertyFilter($event)" placeholder="Suchen..." />
        </mat-form-field>

      </mat-tab>

      <mat-tab label="Tree Properties">
        <mat-table #treePropertyTable class="property-table" [dataSource]="treePropertyDataSource" matSort
          fusePerfectScrollbar>
          <ng-container matColumnDef="checkbox">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? treePropertySelection.toggle(row) : null"
                [checked]="treePropertySelection.isSelected(row)" [disabled]="isTreePropertyRowDisabled(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <div fxLayout="column" fxLayoutAlign="start start">
                <div class="name-label">{{ entry.name }}</div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Datentyp</mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <div>
                ENUM
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onTreeRowClick(row)"></mat-row>
        </mat-table>

        <mat-form-field appearance="outline" class="search-field">
          <input matInput (keyup)="applyTreePropertyFilter($event)" placeholder="Suchen..." />
        </mat-form-field>

      </mat-tab>

    </mat-tab-group>
  </div>

  <div *ngIf="!loaded" class="center-spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="loaded">

  <button class="primary-button-dark" mat-stroked-button type="button" (click)="onSubmit()">OK</button>

  <button *ngIf="tabIndex === 0" class="primary-button-light" mat-stroked-button type="button"
    (click)="createNewClicked('flat')">
    Neues Flat-Property
  </button>

  <button *ngIf="tabIndex === 1" class="primary-button-light" mat-stroked-button type="button"
    (click)="createNewClicked('tree')">
    Neues Tree-Property
  </button>

  <button class="secondary-button-dark" mat-stroked-button type="button"
    [mat-dialog-close]="undefined">Abbrechen</button>


</mat-dialog-actions>