"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./property-creation-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/dialog");
var i3 = require("../../../property-configurator/builder/builder-container/builder-container.component.ngfactory");
var i4 = require("../../../property-configurator/builder/builder-container/builder-container.component");
var i5 = require("@angular/router");
var i6 = require("../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i7 = require("@angular/material/icon");
var i8 = require("@angular/common");
var i9 = require("./property-creation-dialog.component");
var styles_PropertyCreationDialogComponent = [i0.styles];
var RenderType_PropertyCreationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropertyCreationDialogComponent, data: {} });
exports.RenderType_PropertyCreationDialogComponent = RenderType_PropertyCreationDialogComponent;
function View_PropertyCreationDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-builder-container", [["class", "m-4"], ["sourceString", "dialog"]], null, [[null, "result"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("result" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BuilderContainerComponent_0, i3.RenderType_BuilderContainerComponent)), i1.ɵdid(3, 114688, null, 0, i4.BuilderContainerComponent, [i5.Router, i5.ActivatedRoute], { builderType: [0, "builderType"], sourceString: [1, "sourceString"], tenantId: [2, "tenantId"], redirectUrl: [3, "redirectUrl"] }, { result: "result" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.builderType; var currVal_1 = "dialog"; var currVal_2 = _co.data.tenantId; var currVal_3 = _co.redirectUrl; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PropertyCreationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Neues Property erstellen"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertyCreationDialogComponent_1)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_3 = _co.loaded; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
exports.View_PropertyCreationDialogComponent_0 = View_PropertyCreationDialogComponent_0;
function View_PropertyCreationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "property-creation-dialog", [], null, null, null, View_PropertyCreationDialogComponent_0, RenderType_PropertyCreationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.PropertyCreationDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PropertyCreationDialogComponent_Host_0 = View_PropertyCreationDialogComponent_Host_0;
var PropertyCreationDialogComponentNgFactory = i1.ɵccf("property-creation-dialog", i9.PropertyCreationDialogComponent, View_PropertyCreationDialogComponent_Host_0, {}, {}, []);
exports.PropertyCreationDialogComponentNgFactory = PropertyCreationDialogComponentNgFactory;
