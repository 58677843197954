<div #contentDiv id="contentDiv" class="page-layout blank p-20 pl-0" fusePerfectScrollbar *ngIf="loaded">
  <div *ngIf="!showResultPage">
    <div>
      <mat-accordion multi="true" displayMode="flat">
        <app-form-container [formEntry]="currentFormConfiguration.formEntry"
          [formConfiguration]="currentFormConfiguration" [finishClicked]="finishClicked" expanded="true"
          (result)="handleResultEvent($event)" (tupleSelected)="handleTupleSelection($event)"
          (errorEvent)="handleErrorEvent($event)">
        </app-form-container>
      </mat-accordion>
    </div>

    <div class="pt-12 divider"></div>

    <!-- <div class="volunteer-selection">
      <mat-accordion displayMode="flat">
        <mat-expansion-panel #volunteerPanel>
          <mat-expansion-panel-header expandedHeight="40px">
            <mat-panel-title>
              Freiwillige Zuteilen (optional)
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-instance-creation-volunteer-list [tenantAdmin]="tenantAdmin"
            (selectedVolunteers)="selectedVolunteers = $event" *ngIf="volunteerPanel.expanded">
          </app-instance-creation-volunteer-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-20 mb-20">
      <div></div>
      <div fxLayout="row">
        <button [disabled]="!canSubmitForm" class="primary-button-dark" mat-stroked-button type="button"
          (click)="handleFinishClick()">
          Fertig
        </button>

        <div class="pr-8"></div>

        <button class="secondary-button-dark" mat-stroked-button type="button" (click)="handleCancelEvent()">
          Abbrechen
        </button>
      </div>
      <div></div>
    </div>
  </div>

  <div *ngIf="showResultPage">
    <app-instance-creation-result [resultClassInstance]="resultClassInstance" (navigateBack)="handleCancelEvent()">
    </app-instance-creation-result>
  </div>
</div>