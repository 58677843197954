"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FormEntry = /** @class */ (function () {
    function FormEntry() {
        this.formItems = [];
    }
    return FormEntry;
}());
exports.FormEntry = FormEntry;
var FormConfiguration = /** @class */ (function () {
    function FormConfiguration() {
    }
    return FormConfiguration;
}());
exports.FormConfiguration = FormConfiguration;
var FormConfigurationPreviewRequest = /** @class */ (function () {
    function FormConfigurationPreviewRequest(classDefinitions, relationships, rootClassDefinition) {
        this.classDefinitions = classDefinitions;
        this.relationships = relationships;
        this.rootClassDefinition = rootClassDefinition;
    }
    return FormConfigurationPreviewRequest;
}());
exports.FormConfigurationPreviewRequest = FormConfigurationPreviewRequest;
var FormEntryReturnEventData = /** @class */ (function () {
    function FormEntryReturnEventData(formConfigurationId, value) {
        this.value = value;
        this.formConfigurationId = formConfigurationId;
    }
    return FormEntryReturnEventData;
}());
exports.FormEntryReturnEventData = FormEntryReturnEventData;
