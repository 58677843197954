"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var form_1 = require("app/main/content/_model/configurator/form");
var FormPreviewEntryComponent = /** @class */ (function () {
    function FormPreviewEntryComponent() {
        this.result = new core_1.EventEmitter();
        this.tupleSelected = new core_1.EventEmitter();
    }
    FormPreviewEntryComponent.prototype.ngOnInit = function () {
    };
    FormPreviewEntryComponent.prototype.handleResultEvent = function () {
        if (this.formEntry.formGroup.valid) {
            this.result.emit(new form_1.FormEntryReturnEventData(this.formConfiguration.id, this.formEntry.formGroup.value));
        }
        else {
            console.log('invalid');
            //   const invalidKeys: string[] = [];
            //   for (const key of Object.keys(this.formEntry.formGroup.controls)) {
            //     if (this.formEntry.formGroup.controls[key].invalid) {
            //       invalidKeys.push(key);
            //     }
            //   }
            //   console.log("invalid");
            //   console.log(invalidKeys);
            //   if (invalidKeys.length === 1 && invalidKeys[0].endsWith('unableToContinue')) {
            //     this.result.emit(new FormEntryReturnEventData(this.formEntry.formGroup, this.formConfiguration.id));
            // }
        }
    };
    FormPreviewEntryComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    FormPreviewEntryComponent.prototype.handleTupleSelection = function (opt) {
        console.log(opt);
        this.emitTupleSelectionEvent({ selection: opt, formEntry: this.formEntry });
    };
    FormPreviewEntryComponent.prototype.emitTupleSelectionEvent = function (evt) {
        this.tupleSelected.emit(evt);
    };
    FormPreviewEntryComponent.prototype.ngOnChanges = function () {
        if (this.exportClicked) {
            this.handleResultEvent();
        }
    };
    FormPreviewEntryComponent.prototype.addFormItemEntry = function (formItem) {
        if (formItem.controlType === 'tuple') {
            this.hasUnableToContinueProperty = true;
        }
        else {
            return '- ' + formItem.label;
        }
    };
    return FormPreviewEntryComponent;
}());
exports.FormPreviewEntryComponent = FormPreviewEntryComponent;
