"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var matching_1 = require("app/main/content/_model/matching");
var utils_and_constants_1 = require("../../../class-configurator/utils-and-constants");
var MatchingOptionsOverlayContentComponent = /** @class */ (function () {
    function MatchingOptionsOverlayContentComponent() {
        this.resultRelationship = new core_1.EventEmitter();
        this.matchingOperatorPalettes = utils_and_constants_1.CConstants.matchingOperatorPalettes;
        this.fuzzynessValid = true;
        this.weightingValid = true;
    }
    MatchingOptionsOverlayContentComponent.prototype.ngOnInit = function () {
        this.matchingOperatorType = this.overlayRelationship.matchingOperatorType;
        this.necessary = this.overlayRelationship.necessary;
        this.weighting = this.overlayRelationship.weighting;
        this.fuzzyness = this.overlayRelationship.fuzzyness;
    };
    MatchingOptionsOverlayContentComponent.prototype.getImagePathForMatchingOperatorType = function (type) {
        return (this.matchingOperatorPalettes.find(function (p) { return p.id === type; }).imgPath);
    };
    MatchingOptionsOverlayContentComponent.prototype.getLabelForMatchingOperatorType = function (type) {
        return (this.matchingOperatorPalettes.find(function (p) { return p.id === type; }).label);
    };
    MatchingOptionsOverlayContentComponent.prototype.matchingOperatorChanged = function (paletteItem) {
        this.matchingOperatorType = paletteItem.id;
    };
    MatchingOptionsOverlayContentComponent.prototype.onSubmit = function () {
        this.fuzzynessValid = this.checkFuzzyness();
        this.weightingValid = this.checkWeighting();
        if (!this.fuzzynessValid) {
            this.fixFuzzyness();
        }
        if (!this.weightingValid) {
            this.fixWeighting();
        }
        if (this.fuzzynessValid && this.weightingValid) {
            // RETURN
            this.overlayRelationship.matchingOperatorType = this.matchingOperatorType;
            this.overlayRelationship.necessary = this.necessary;
            this.overlayRelationship.fuzzyness = this.fuzzyness;
            this.overlayRelationship.weighting = this.weighting;
            this.resultRelationship.emit(this.overlayRelationship);
        }
    };
    MatchingOptionsOverlayContentComponent.prototype.checkFuzzyness = function () {
        return this.fuzzyness >= 0 && this.fuzzyness <= 100;
    };
    MatchingOptionsOverlayContentComponent.prototype.fixFuzzyness = function () {
        if (this.fuzzyness < 0) {
            this.fuzzyness = 0;
        }
        if (this.fuzzyness > 100) {
            this.fuzzyness = 100;
        }
    };
    MatchingOptionsOverlayContentComponent.prototype.checkWeighting = function () {
        return this.weighting >= 0 && this.weighting <= 9999;
    };
    MatchingOptionsOverlayContentComponent.prototype.fixWeighting = function () {
        if (this.weighting < 0) {
            this.weighting = 0;
        }
        if (this.weighting > 9999) {
            this.weighting = 9999;
        }
    };
    MatchingOptionsOverlayContentComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    MatchingOptionsOverlayContentComponent.prototype.sliderValueChanged = function (evt) {
        this.fuzzyness = evt.value;
    };
    return MatchingOptionsOverlayContentComponent;
}());
exports.MatchingOptionsOverlayContentComponent = MatchingOptionsOverlayContentComponent;
