"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dashboard_component_1 = require("./dashboard.component");
var routes = [
    {
        path: "",
        component: dashboard_component_1.DashboardComponent
    }
];
var FuseDashboardModule = /** @class */ (function () {
    function FuseDashboardModule() {
    }
    return FuseDashboardModule;
}());
exports.FuseDashboardModule = FuseDashboardModule;
