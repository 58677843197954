"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
require("hammerjs");
var de_1 = require("@angular/common/locales/de");
var common_1 = require("@angular/common");
common_1.registerLocaleData(de_1.default, "de-AT");
var appRoutes = [
    {
        path: "**",
        redirectTo: "main/dashboard",
    },
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
