"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var MatchingOperatorRelationshipService = /** @class */ (function () {
    function MatchingOperatorRelationshipService(httpClient) {
        this.httpClient = httpClient;
    }
    MatchingOperatorRelationshipService.prototype.getMatchingOperatorRelationshipByMatchingConfiguration = function (matchingConfiguratorId) {
        return this.httpClient.get(environment_1.environment.CONFIGURATOR_URL + "/matching-operator-relationship/" + matchingConfiguratorId);
    };
    MatchingOperatorRelationshipService.prototype.saveMatchingOperatorRelationships = function (relationships, matchingConfiguratorId) {
        return this.httpClient.post(environment_1.environment.CONFIGURATOR_URL + "/matching-operator-relationship/" + matchingConfiguratorId, relationships);
    };
    MatchingOperatorRelationshipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatchingOperatorRelationshipService_Factory() { return new MatchingOperatorRelationshipService(i0.ɵɵinject(i1.HttpClient)); }, token: MatchingOperatorRelationshipService, providedIn: "root" });
    return MatchingOperatorRelationshipService;
}());
exports.MatchingOperatorRelationshipService = MatchingOperatorRelationshipService;
