"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var DynamicFormItemControlService = /** @class */ (function () {
    function DynamicFormItemControlService() {
    }
    DynamicFormItemControlService.prototype.toFormGroup = function (formItems) {
        var fb = new forms_1.FormBuilder();
        var outerGroup = fb.group({});
        var array = fb.array([]);
        var innerGroup = fb.group({});
        var ret = this.addChildToGroup(fb, formItems, innerGroup);
        array.push(innerGroup);
        outerGroup.setControl("entries", array);
        return outerGroup;
    };
    //Debug
    DynamicFormItemControlService.prototype.displayFormGroup = function (fg) {
        console.log("DISPLAYING FORMGROUP: ");
        console.log("RAW: ");
        console.log(fg);
        console.log("CONTROLS: ");
        console.log(fg.controls);
        console.log("VALUES");
        console.log(fg.value);
    };
    DynamicFormItemControlService.prototype.addChildToGroup = function (fb, formItems, parent) {
        formItems.forEach(function (formItem) {
            if (formItem.controlType === 'location') {
                parent.addControl(formItem.key, new forms_1.FormGroup({
                    label: new forms_1.FormControl(''),
                    longLatEnabled: new forms_1.FormControl(''),
                    longitude: new forms_1.FormControl(''),
                    latitude: new forms_1.FormControl(''),
                }));
            }
            else {
                parent.addControl(formItem.key, fb.control(formItem.value, formItem.validators));
            }
        });
        return parent;
    };
    DynamicFormItemControlService.prototype.getControlForSubEntry = function (formitem) {
        var fb = new forms_1.FormBuilder();
        return this.addChildToGroup(fb, formitem, fb.group({}));
    };
    return DynamicFormItemControlService;
}());
exports.DynamicFormItemControlService = DynamicFormItemControlService;
