"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".overlay-layout[_ngcontent-%COMP%]{background:#fff;border-radius:5px;z-index:5;border:2px solid #3e7ddb}.overlay-layout[_ngcontent-%COMP%]   .overlay-title-bar[_ngcontent-%COMP%]{background-color:#3e7ddb;color:#fff}.overlay-layout[_ngcontent-%COMP%]   .overlay-title-bar[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{color:#fff;cursor:pointer}"];
exports.styles = styles;
