"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_factory_component_1 = require("../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var router_1 = require("@angular/router");
var objectid_service_1 = require("app/main/content/_service/objectid.service.");
var class_1 = require("app/main/content/_model/configurator/class");
var relationship_1 = require("app/main/content/_model/configurator/relationship");
var popup_menu_1 = require("./popup-menu");
var top_menu_bar_component_1 = require("./top-menu-bar/top-menu-bar.component");
var myMxCell_1 = require("../myMxCell");
var utils_and_constants_1 = require("./utils-and-constants");
var property_1 = require("app/main/content/_model/configurator/property/property");
var util_1 = require("util");
var options_overlay_control_component_1 = require("./options-overlay/options-overlay-control/options-overlay-control.component");
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var ClassConfiguratorComponent = /** @class */ (function () {
    function ClassConfiguratorComponent(route, router, objectIdService, dialogFactory) {
        this.route = route;
        this.router = router;
        this.objectIdService = objectIdService;
        this.dialogFactory = dialogFactory;
    }
    /**
     * ******INITIALIZATION******
     */
    ClassConfiguratorComponent.prototype.ngOnInit = function () {
        this.classDefinitions = [];
        this.deletedClassIds = [];
        this.relationships = [];
        this.deletedRelationshipIds = [];
        this.rightSidebarVisible = true;
        this.eventResponse = new top_menu_bar_component_1.TopMenuResponse();
        this.relationshipType = relationship_1.RelationshipType.INHERITANCE;
        this.confirmDelete = true;
        this.deleteRelationships = true;
        this.clickToDeleteMode = false;
        this.quickEditMode = false;
    };
    ClassConfiguratorComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (!mx.mxClient.isBrowserSupported()) {
            mx.mxUtils.error('Browser is not supported!', 200, false);
            return;
        }
        this.graph = new mx.mxGraph(this.graphContainer.nativeElement);
        this.graph.isCellSelectable = function (cell) {
            var state = this.view.getState(cell);
            var style = state != null ? state.style : this.getCellStyle(cell);
            return (this.isCellsSelectable() &&
                !this.isCellLocked(cell) &&
                style['selectable'] !== 0);
        };
        this.graph.getCursorForCell = function (cell) {
            if (cell.cellType === myMxCell_1.MyMxCellType.FLAT_PROPERTY ||
                cell.cellType === myMxCell_1.MyMxCellType.ADD_PROPERTY_ICON ||
                cell.cellType === myMxCell_1.MyMxCellType.REMOVE_ICON ||
                cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_SAME_LEVEL_ICON ||
                cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON ||
                cell.cellType === myMxCell_1.MyMxCellType.ADD_ASSOCIATION_ICON ||
                cell.cellType === myMxCell_1.MyMxCellType.OPTIONS_ICON ||
                (_this.clickToDeleteMode && cell.cellType === myMxCell_1.MyMxCellType.CLASS) ||
                (_this.clickToDeleteMode && myMxCell_1.MyMxCellType.isRelationship(cell.cellType))) {
                return mx.mxConstants.CURSOR_TERMINAL_HANDLE;
            }
        };
        var modelGetStyle = this.graph.model.getStyle;
        this.graph.model.getStyle = function (cell) {
            if (cell === null) {
                return null;
            }
            var style = modelGetStyle.apply(this, arguments);
            if (this.isCollapsed(cell)) {
                style = style + ';shape=rectangle';
            }
            return style;
        };
        mx.mxEvent.disableContextMenu(this.graphContainer.nativeElement);
        // tslint:disable-next-line: no-unused-expression
        new mx.mxRubberband(this.graph);
        this.graph.setPanning(true);
        this.graph.popupMenuHandler = this.createPopupMenu(this.graph);
        this.graph.tooltipHandler = new mx.mxTooltipHandler(this.graph, 100);
        /**
         * ******EVENT LISTENERS******
         */
        this.graph.addListener(mx.mxEvent.CLICK, function (sender, evt) {
            var mouseEvent = evt.getProperty('event');
            _this.clickToDeleteMode && mouseEvent.button === 0
                ? _this.handleClickToDeleteEvent(evt)
                : mouseEvent.button === 0 && _this.graph.enabled
                    ? _this.handleMXGraphLeftClickEvent(evt)
                    : '';
        });
        this.graph.addListener(mx.mxEvent.FOLD_CELLS, function (sender, evt) {
            var cells = evt.getProperty('cells');
            var cell = cells.pop();
            _this.handleMXGraphFoldEvent(cell);
        });
        this.graph.addListener(mx.mxEvent.DOUBLE_CLICK, function (sender, evt) {
            _this.handleMXGraphDoubleClickEvent(evt);
        });
        this.openPreviousClassConfiguration();
    };
    ClassConfiguratorComponent.prototype.createPopupMenu = function (graph) {
        this.popupMenu = new popup_menu_1.EditorPopupMenu(this);
        return this.popupMenu.createPopupMenuHandler(graph);
    };
    /**
     * ******CONTENT-RELATED FUNCTIONS******
     */
    ClassConfiguratorComponent.prototype.loadServerContent = function () {
        this.parseGraphContent();
        if (util_1.isNullOrUndefined(this.layout)) {
            this.setLayout();
        }
        this.executeLayout();
        this.modelUpdated = true;
    };
    ClassConfiguratorComponent.prototype.clearEditor = function () {
        this.rootCellSet = false;
        this.rootCell = undefined;
        this.graph.getModel().beginUpdate();
        try {
            this.graph.getModel().clear();
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    ClassConfiguratorComponent.prototype.parseGraphContent = function () {
        this.parseIncomingClasses();
        this.parseIncomingRelationships();
        this.rootCellSet = false;
    };
    /**
     * ******CLASSES******
     */
    ClassConfiguratorComponent.prototype.parseIncomingClasses = function () {
        var e_1, _a;
        try {
            this.graph.getModel().beginUpdate();
            try {
                for (var _b = __values(this.classDefinitions), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var c = _c.value;
                    this.insertClassIntoGraph(c);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    ClassConfiguratorComponent.prototype.insertClassIntoGraph = function (classDefinition, geometry, replaceCell) {
        var cell;
        var style = utils_and_constants_1.CConstants.mxStyles.classNormal;
        if (util_1.isNullOrUndefined(geometry)) {
            geometry = new mx.mxGeometry(0, 0, 110, 45);
        }
        if (!util_1.isNullOrUndefined(replaceCell)) {
            cell = replaceCell;
            var childCells = this.graph.removeCellsFromParent(this.graph.getChildCells(cell));
            this.graph.removeCells(childCells, false);
            this.graph.setCellStyle(style, [cell]);
        }
        else {
            cell = new mx.mxCell(classDefinition.name, geometry, style);
        }
        cell.root = classDefinition.root;
        cell.writeProtected = classDefinition.writeProtected;
        if (cell.root) {
            if (!this.rootCellSet) {
                this.rootCell = cell;
                cell.root = true;
                this.rootCellSet = true;
            }
            else {
                cell.root = false;
                console.error('Root cell already set - only one root cell per graph allowed');
            }
        }
        cell.setCollapsed(false);
        cell.cellType = myMxCell_1.MyMxCellType.CLASS;
        cell.classArchetype = classDefinition.classArchetype;
        cell.value = classDefinition.name;
        cell.setVertex(true);
        cell.setConnectable(true);
        if (!util_1.isNullOrUndefined(classDefinition.imagePath)) {
            var overlay = new mx.mxCellOverlay(new mx.mxImage(classDefinition.imagePath, 30, 30), 'Overlay', mx.mxConstants.ALIGN_RIGHT, mx.mxConstants.ALIGN_TOP);
            this.graph.addCellOverlay(cell, overlay);
        }
        cell.id = !util_1.isNullOrUndefined(classDefinition.id)
            ? classDefinition.id
            : this.objectIdService.getNewObjectId();
        cell.geometry.alternateBounds = new mx.mxRectangle(0, 0, 110, 50);
        cell.geometry.setRect(cell.geometry.x, cell.geometry.y, cell.geometry.width, classDefinition.properties.length * 20 + 80);
        // create properties
        var yLocation = 5;
        yLocation = this.addPropertiesToCell(cell, classDefinition.properties, yLocation);
        if (cell.classArchetype !== class_1.ClassArchetype.ROOT) {
            // next icon
            var nextIcon = this.graph.insertVertex(cell, 'add_class_same_level_icon', 'Partnerklasse hinzufügen', 85, yLocation + 50, 20, 20, utils_and_constants_1.CConstants.mxStyles.addClassSameLevelIcon);
            nextIcon.setConnectable(false);
            nextIcon.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_SAME_LEVEL_ICON;
            // down icon
            var downIcon = this.graph.insertVertex(cell, 'add_class_next_level_icon', 'Unterklasse hinzufügen', 65, yLocation + 50, 20, 20, utils_and_constants_1.CConstants.mxStyles.addClassNewLevelIcon);
            downIcon.setConnectable(false);
            downIcon.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON;
        }
        // options icon
        var optionsIcon = this.graph.insertVertex(cell, 'options', 'Optionen', 5, yLocation + 50, 20, 20, utils_and_constants_1.CConstants.mxStyles.optionsIcon);
        optionsIcon.setConnectable(false);
        optionsIcon.cellType = myMxCell_1.MyMxCellType.OPTIONS_ICON;
        return util_1.isNullOrUndefined(replaceCell) ? this.graph.addCell(cell) : cell;
    };
    /**
     * ******PROPERTIES******
     */
    ClassConfiguratorComponent.prototype.addPropertiesToCell = function (cell, properties, yLocation) {
        var e_2, _a;
        try {
            for (var properties_1 = __values(properties), properties_1_1 = properties_1.next(); !properties_1_1.done; properties_1_1 = properties_1.next()) {
                var p = properties_1_1.value;
                var propertyCell = void 0;
                if (p.type !== property_1.PropertyType.TREE) {
                    propertyCell = this.graph.insertVertex(cell, p.id, p.name, 5, yLocation + 45, 100, 20, utils_and_constants_1.CConstants.mxStyles.property);
                    propertyCell.cellType = myMxCell_1.MyMxCellType.FLAT_PROPERTY;
                }
                else {
                    propertyCell = this.graph.insertVertex(cell, p.id, p.name, 5, yLocation + 45, 100, 20, utils_and_constants_1.CConstants.mxStyles.propertyTree);
                    propertyCell.cellType = myMxCell_1.MyMxCellType.TREE_PROPERTY;
                }
                propertyCell.setConnectable(false);
                yLocation += 20;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (properties_1_1 && !properties_1_1.done && (_a = properties_1.return)) _a.call(properties_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return yLocation;
    };
    /**
     * ******RELATIONSHIPS******
     */
    ClassConfiguratorComponent.prototype.parseIncomingRelationships = function () {
        var e_3, _a;
        try {
            this.graph.getModel().beginUpdate();
            try {
                for (var _b = __values(this.relationships), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var r = _c.value;
                    r = this.convertAggregation(r);
                    this.insertRelationshipIntoGraph(r, new mx.mxPoint(0, 0));
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    ClassConfiguratorComponent.prototype.insertRelationshipIntoGraph = function (r, coords) {
        var parent = this.graph.getDefaultParent();
        var source = this.graph
            .getModel()
            .getCell(r.source);
        var target = this.graph
            .getModel()
            .getCell(r.target);
        var style;
        var type;
        if (r.relationshipType === relationship_1.RelationshipType.INHERITANCE) {
            style = utils_and_constants_1.CConstants.mxStyles.inheritance;
            type = myMxCell_1.MyMxCellType.INHERITANCE;
        }
        else if (r.relationshipType === relationship_1.RelationshipType.ASSOCIATION) {
            style = utils_and_constants_1.CConstants.mxStyles.association;
            type = myMxCell_1.MyMxCellType.ASSOCIATION;
        }
        else {
            console.error('Invalid Relationshiptype');
        }
        var geometry = new mx.mxGeometry(coords.x, coords.y, 0, 0);
        var cell = new mx.mxCell(undefined, geometry, style);
        cell.id = r.id;
        cell.geometry.relative = true;
        cell.edge = true;
        cell.cellType = type;
        cell.writeProtected = target.writeProtected;
        return this.graph.addEdge(cell, parent, source, target);
    };
    ClassConfiguratorComponent.prototype.updateRelationshipInGraph = function (relationship) {
        var style;
        var type;
        if (relationship.relationshipType === relationship_1.RelationshipType.INHERITANCE) {
            style = utils_and_constants_1.CConstants.mxStyles.inheritance;
            type = myMxCell_1.MyMxCellType.INHERITANCE;
        }
        else if (relationship.relationshipType === relationship_1.RelationshipType.ASSOCIATION) {
            style = utils_and_constants_1.CConstants.mxStyles.association;
            type = myMxCell_1.MyMxCellType.ASSOCIATION;
        }
        else {
            console.error('Invalid Relationshiptype');
        }
        this.graph
            .getModel()
            .getCell(relationship.id)
            .setStyle(style);
        this.graph
            .getModel()
            .getCell(relationship.id).cellType = type;
    };
    /**
     * ******DELETE******
     */
    ClassConfiguratorComponent.prototype.deleteCells = function (cells) {
        var _this = this;
        // if (cells.length === 1 && cells[0].writeProtected) { return; }
        if (this.confirmDelete) {
            this.dialogFactory
                .confirmationDialog('Löschen Bestätigen', 'Wirklich löschen?')
                .then(function (ret) {
                ret ? _this.performDelete(cells) : '';
            });
        }
        else {
            this.performDelete(cells);
        }
    };
    ClassConfiguratorComponent.prototype.performDelete = function (cells) {
        // cells = cells.filter((c: MyMxCell) => !c.writeProtected);
        var removedCells = this.graph.removeCells(cells, this.deleteRelationships);
        !util_1.isNullOrUndefined(removedCells) ? this.deleteFromModel(removedCells) : '';
    };
    ClassConfiguratorComponent.prototype.deleteFromModel = function (removedCells) {
        var e_4, _a;
        var _loop_1 = function (cell) {
            if (cell.cellType === myMxCell_1.MyMxCellType.CLASS) {
                this_1.classDefinitions = this_1.classDefinitions.filter(function (c) { return c.id !== cell.id; });
                this_1.deletedClassIds.push(cell.id);
            }
            else if (myMxCell_1.MyMxCellType.isRelationship(cell.cellType)) {
                this_1.relationships = this_1.relationships.filter(function (r) { return r.id !== cell.id; });
                this_1.deletedRelationshipIds.push(cell.id);
            }
        };
        var this_1 = this;
        try {
            for (var removedCells_1 = __values(removedCells), removedCells_1_1 = removedCells_1.next(); !removedCells_1_1.done; removedCells_1_1 = removedCells_1.next()) {
                var cell = removedCells_1_1.value;
                _loop_1(cell);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (removedCells_1_1 && !removedCells_1_1.done && (_a = removedCells_1.return)) _a.call(removedCells_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
    };
    /**
     * ******LAYOUT AND DRAWING******
     */
    ClassConfiguratorComponent.prototype.setLayout = function () {
        this.layout = new mx.mxCompactTreeLayout(this.graph, false, false);
        this.layout.levelDistance = 50;
        this.layout.alignRanks = true;
        this.layout.minEdgeJetty = 50;
        this.layout.prefHozEdgeSep = 5;
        this.layout.resetEdges = true;
        this.layout.edgeRouting = true;
    };
    ClassConfiguratorComponent.prototype.executeLayout = function () {
        try {
            this.layout.execute(this.graph.getDefaultParent(), this.rootCell);
        }
        catch (error) {
            if (!(error instanceof TypeError) && this.relationships.length >= 0) {
                console.error(error);
            }
        }
    };
    /**
     * ******EVENT HANDLING******
     */
    ClassConfiguratorComponent.prototype.handleMXGraphLeftClickEvent = function (event) {
        var cell = event.getProperty('cell');
        this.currentSelectedCell = cell;
        if (util_1.isNullOrUndefined(cell)) {
            return;
        }
        var parent = cell.getParent();
        if (cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_SAME_LEVEL_ICON) {
            var ret = this.addClassWithRelationship(cell, this.graph.getIncomingEdges(parent)[0].source.id);
            var addedClass = ret.class;
            var addedRelationship = ret.relationship;
            this.insertClassIntoGraph(addedClass, new mx.mxGeometry(parent.geometry.x + 130, parent.geometry.y, 110, 45));
            this.classDefinitions.push(addedClass);
            this.insertRelationshipIntoGraph(addedRelationship, new mx.mxPoint(0, 0));
            this.relationships.push(addedRelationship);
            if (!this.quickEditMode) {
                this.executeLayout();
            }
        }
        if (cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON) {
            var ret = this.addClassWithRelationship(cell, parent.id);
            var addedClass = ret.class;
            var addedRelationship = ret.relationship;
            this.insertClassIntoGraph(addedClass, new mx.mxGeometry(parent.geometry.x, parent.geometry.y + parent.geometry.height + 20, 110, 45));
            this.classDefinitions.push(addedClass);
            this.insertRelationshipIntoGraph(addedRelationship, new mx.mxPoint(0, 0));
            this.relationships.push(addedRelationship);
            if (!this.quickEditMode) {
                this.executeLayout();
            }
        }
        if (cell.cellType === myMxCell_1.MyMxCellType.OPTIONS_ICON) {
            this.openOverlay(cell.getParent(), event);
        }
    };
    ClassConfiguratorComponent.prototype.addClassWithRelationship = function (iconCell, sourceId) {
        var addedClass = new class_1.ClassDefinition();
        addedClass.configurationId = this.classConfiguration.id;
        var parentClassArchetype = iconCell.getParent().classArchetype;
        addedClass.id = this.objectIdService.getNewObjectId();
        addedClass.classArchetype = parentClassArchetype;
        addedClass.name =
            'Neue Klasse\n(' +
                class_1.ClassArchetype.getClassArchetypeLabel(addedClass.classArchetype) +
                ')';
        addedClass.tenantId = this.tenantId;
        addedClass.properties = [];
        var addedRelationship = new relationship_1.Relationship();
        addedRelationship.relationshipType = this.relationshipType;
        if (addedRelationship.relationshipType === relationship_1.RelationshipType.ASSOCIATION) {
            addedRelationship.sourceCardinality = relationship_1.AssociationCardinality.ONE;
            addedRelationship.targetCardinality = relationship_1.AssociationCardinality.ONE;
        }
        addedRelationship.source = sourceId;
        addedRelationship.target = addedClass.id;
        addedRelationship.id = this.objectIdService.getNewObjectId();
        return { class: addedClass, relationship: addedRelationship };
    };
    ClassConfiguratorComponent.prototype.handleMXGraphDoubleClickEvent = function (event) {
        this.openOverlay(event.getProperty('cell'), event);
    };
    ClassConfiguratorComponent.prototype.handleMXGraphFoldEvent = function (cell) {
        var e_5, _a;
        var edges = this.graph.getOutgoingEdges(cell);
        if (util_1.isNullOrUndefined(edges)) {
            return;
        }
        try {
            for (var edges_1 = __values(edges), edges_1_1 = edges_1.next(); !edges_1_1.done; edges_1_1 = edges_1.next()) {
                var edge = edges_1_1.value;
                if (util_1.isNullOrUndefined(edge) || util_1.isNullOrUndefined(edge.target)) {
                    continue;
                }
                if (cell.isCollapsed()) {
                    this.setAllCellsInvisibleRec(cell);
                }
                if (edge.target.isCollapsed() && !cell.isCollapsed()) {
                    this.setNextCellVisible(cell);
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (edges_1_1 && !edges_1_1.done && (_a = edges_1.return)) _a.call(edges_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        this.executeLayout();
        this.modelUpdated = true;
    };
    ClassConfiguratorComponent.prototype.handleClickToDeleteEvent = function (event) {
        var cell = event.getProperty('cell');
        if (!util_1.isNullOrUndefined(cell)) {
            if (cell.cellType === myMxCell_1.MyMxCellType.CLASS ||
                myMxCell_1.MyMxCellType.isRelationship(cell.cellType)) {
                this.deleteCells([cell]);
            }
        }
    };
    ClassConfiguratorComponent.prototype.clickToDeleteModeToggled = function () {
        this.graph.setEnabled(!this.clickToDeleteMode);
    };
    /**
     * ******OPTIONS OVERLAY******
     */
    ClassConfiguratorComponent.prototype.openOverlay = function (cell, event) {
        if (!util_1.isNullOrUndefined(cell) &&
            (cell.cellType === myMxCell_1.MyMxCellType.CLASS ||
                myMxCell_1.MyMxCellType.isRelationship(cell.cellType))) {
            this.overlayEvent = event.getProperty('event');
            this.overlayContent = new options_overlay_control_component_1.OptionsOverlayContentData();
            this.overlayContent.allClassDefinitions = this.classDefinitions;
            this.overlayContent.tenantId = this.tenantId;
            if (cell.cellType === myMxCell_1.MyMxCellType.CLASS) {
                this.overlayType = 'CLASS';
                this.overlayContent.classDefinition = this.classDefinitions.find(function (c) { return c.id === cell.id; });
                this.overlayContent.allRelationships = this.relationships;
            }
            else if (myMxCell_1.MyMxCellType.isRelationship(cell.cellType)) {
                this.overlayContent.relationship = this.relationships.find(function (r) { return r.id === cell.id; });
                this.overlayType = 'RELATIONSHIP';
            }
            this.graph.setEnabled(false);
            this.displayOverlay = true;
        }
    };
    ClassConfiguratorComponent.prototype.handleOverlayClosedEvent = function (event) {
        this.graph.setEnabled(true);
        this.displayOverlay = false;
        // tslint:disable-next-line: no-unused-expression
        !util_1.isNullOrUndefined(event)
            ? this.handleModelChanges(event, this.overlayType)
            : '';
        this.overlayContent = undefined;
        this.overlayType = undefined;
        this.overlayEvent = undefined;
    };
    ClassConfiguratorComponent.prototype.handleModelChanges = function (overlayData, overlayType) {
        var redrawCell;
        if (overlayType === 'CLASS') {
            redrawCell = this.handleClassDefinitionModelChanges(overlayData.classDefinition);
        }
        else if (overlayType === 'RELATIONSHIP') {
            redrawCell = this.handleRelationshipModelChanges(overlayData.relationship);
        }
        else {
            console.error('invalid overlayType: ' + overlayType);
        }
        this.executeLayout();
    };
    ClassConfiguratorComponent.prototype.handleClassDefinitionModelChanges = function (classDefinition) {
        var i = this.classDefinitions.findIndex(function (c) { return c.id === classDefinition.id; });
        var existingClassDefinition = this.classDefinitions[i];
        var cell = this.graph.getModel().getCell(classDefinition.id);
        try {
            this.graph.getModel().beginUpdate();
            this.insertClassIntoGraph(classDefinition, undefined, cell);
        }
        finally {
            this.graph.getModel().endUpdate();
        }
        this.classDefinitions[i] = classDefinition;
        if (!this.quickEditMode &&
            classDefinition.properties.length !==
                existingClassDefinition.properties.length) {
            return cell;
        }
    };
    ClassConfiguratorComponent.prototype.handleRelationshipModelChanges = function (relationship) {
        var i = this.relationships.findIndex(function (r) { return r.id === relationship.id; });
        var cell = this.graph.getModel().getCell(relationship.id);
        try {
            this.graph.getModel().beginUpdate();
            this.updateRelationshipInGraph(relationship);
        }
        finally {
            this.graph.getModel().endUpdate();
        }
        this.relationships[i] = relationship;
        if (!this.quickEditMode) {
            return cell;
        }
    };
    /**
     * ******COLLAPSING******
     */
    ClassConfiguratorComponent.prototype.setAllCellsInvisibleRec = function (cell) {
        var e_6, _a;
        var edges = this.graph.getOutgoingEdges(cell);
        try {
            for (var edges_2 = __values(edges), edges_2_1 = edges_2.next(); !edges_2_1.done; edges_2_1 = edges_2.next()) {
                var edge = edges_2_1.value;
                if (!edge.target.isCollapsed()) {
                    this.graph.swapBounds(edge.target, true);
                    this.graph.getModel().setCollapsed(edge.target, true);
                }
                this.graph.getModel().setVisible(edge.target, false);
                this.setAllCellsInvisibleRec(edge.target);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (edges_2_1 && !edges_2_1.done && (_a = edges_2.return)) _a.call(edges_2);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    ClassConfiguratorComponent.prototype.setNextCellVisible = function (cell) {
        var e_7, _a;
        var edges = this.graph.getOutgoingEdges(cell);
        try {
            for (var edges_3 = __values(edges), edges_3_1 = edges_3.next(); !edges_3_1.done; edges_3_1 = edges_3.next()) {
                var edge = edges_3_1.value;
                this.graph.getModel().setVisible(edge.target, true);
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (edges_3_1 && !edges_3_1.done && (_a = edges_3.return)) _a.call(edges_3);
            }
            finally { if (e_7) throw e_7.error; }
        }
    };
    /**
     * ******ZOOMING******
     */
    ClassConfiguratorComponent.prototype.zoomInEvent = function () {
        this.graph.zoomIn();
    };
    ClassConfiguratorComponent.prototype.zoomOutEvent = function () {
        this.graph.zoomOut();
    };
    ClassConfiguratorComponent.prototype.zoomResetEvent = function () {
        this.graph.zoomActual();
        this.resetViewport();
    };
    ClassConfiguratorComponent.prototype.resetViewport = function () {
        var rootCell = this.graph
            .getModel()
            .getChildCells(this.graph.getDefaultParent())
            .find(function (c) { return c.root; });
        if (!util_1.isNullOrUndefined(rootCell)) {
            this.graph.scrollCellToVisible(rootCell, true);
            var translate = this.graph.view.getTranslate();
            var windowHeight = window.innerHeight;
            this.graph.view.setTranslate(translate.x, translate.y - windowHeight / 2 + (rootCell.geometry.height + 25));
        }
    };
    /**
     * ******MENU FUNCTIONS******
     */
    ClassConfiguratorComponent.prototype.consumeMenuOptionClickedEvent = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.eventResponse = new top_menu_bar_component_1.TopMenuResponse();
                switch (event.id) {
                    case 'editor_save': {
                        this.updateModel();
                        this.createSaveEvent(event);
                        break;
                    }
                    case 'editor_save_return': {
                        this.openGraph(event.payload.classConfiguration, event.payload.classDefinitions, event.payload.relationships);
                        break;
                    }
                    case 'editor_save_as': {
                        console.error('not implemented');
                        break;
                    }
                    case 'editor_new': {
                        this.openGraph(event.payload.classConfiguration, event.payload.classDefinitions, event.payload.relationships);
                        break;
                    }
                    case 'editor_open': {
                        this.openGraph(event.payload.classConfiguration, event.payload.classDefinitions, event.payload.relationships);
                        break;
                    }
                    case 'editor_delete': {
                        if (!util_1.isNullOrUndefined(event.payload.idsToDelete) &&
                            !util_1.isNullOrUndefined(this.classConfiguration) &&
                            event.payload.idsToDelete.find(function (id) { return id === _this.classConfiguration.id; })) {
                            this.openGraph(undefined, [], []);
                        }
                        break;
                    }
                    case 'editor_create_instance': {
                        this.showInstanceForm();
                        break;
                    }
                    case 'editor_meta_edit': {
                        this.classConfiguration.name = event.payload.name;
                        this.classConfiguration.description = event.payload.description;
                        break;
                    }
                    case 'cancelled': {
                        break;
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ClassConfiguratorComponent.prototype.openPreviousClassConfiguration = function () {
        var classConfigurationId;
        this.route.queryParams.subscribe(function (params) {
            classConfigurationId = params['ccId'];
        });
        if (!util_1.isNullOrUndefined(classConfigurationId)) {
            this.createOpenClassConfigurationByIdEvent(classConfigurationId);
        }
    };
    ClassConfiguratorComponent.prototype.createOpenClassConfigurationByIdEvent = function (id) {
        this.eventResponse = new top_menu_bar_component_1.TopMenuResponse();
        this.eventResponse.action = 'open';
        this.eventResponse.classConfigurationId = id;
        this.eventResponse.tenantId = this.tenantId;
    };
    ClassConfiguratorComponent.prototype.createSaveEvent = function (event) {
        this.eventResponse.action = 'save';
        this.eventResponse.classConfiguration = this.classConfiguration;
        this.eventResponse.followingAction = event.followingAction;
        this.eventResponse.classDefintions = this.classDefinitions;
        this.eventResponse.relationships = this.relationships;
        this.eventResponse.deletedClassDefinitions = this.deletedClassIds;
        this.eventResponse.deletedRelationships = this.deletedRelationshipIds;
        this.eventResponse.tenantId = this.tenantId;
        this.eventResponse.redirectUrl = this.redirectUrl;
    };
    ClassConfiguratorComponent.prototype.openGraph = function (classConfiguration, classDefinitions, relationships) {
        this.classConfiguration = classConfiguration;
        this.relationships = relationships;
        this.classDefinitions = classDefinitions;
        this.deletedClassIds = [];
        this.deletedRelationshipIds = [];
        this.clearEditor();
        if (!util_1.isNullOrUndefined(classConfiguration)) {
            this.loadServerContent();
        }
    };
    ClassConfiguratorComponent.prototype.updateModel = function () {
        var e_8, _a, e_9, _b;
        // store current connections in relationships
        var allCells = this.graph
            .getModel()
            .getChildren(this.graph.getDefaultParent());
        var _loop_2 = function (cd) {
            var cell = allCells.find(function (c) { return c.id === cd.id; });
            if (!util_1.isNullOrUndefined(cell) && cell.cellType === myMxCell_1.MyMxCellType.CLASS) {
                cd.root = cell.root;
                cd.name = cell.value;
            }
        };
        try {
            for (var _c = __values(this.classDefinitions), _d = _c.next(); !_d.done; _d = _c.next()) {
                var cd = _d.value;
                _loop_2(cd);
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_8) throw e_8.error; }
        }
        var _loop_3 = function (r) {
            var cell = allCells.find(function (c) { return c.id === r.id; });
            if (!util_1.isNullOrUndefined(cell.source)) {
                r.source = cell.source.id;
            }
            if (!util_1.isNullOrUndefined(cell.target)) {
                r.target = cell.target.id;
            }
        };
        try {
            for (var _e = __values(this.relationships), _f = _e.next(); !_f.done; _f = _e.next()) {
                var r = _f.value;
                _loop_3(r);
            }
        }
        catch (e_9_1) { e_9 = { error: e_9_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_9) throw e_9.error; }
        }
        // update the configurator save file
        if (!util_1.isNullOrUndefined(this.classConfiguration)) {
            this.classConfiguration.classDefinitionIds = this.classDefinitions.map(function (c) { return c.id; });
            this.classConfiguration.relationshipIds = this.relationships.map(function (r) { return r.id; });
        }
    };
    ClassConfiguratorComponent.prototype.showSidebar = function () {
        this.rightSidebarVisible = true;
        this.rightSidebarContainer.nativeElement.style.borderLeft =
            'solid 2px black';
        this.rightSidebarContainer.nativeElement.style.height = 'auto';
        this.rightSidebarContainer.nativeElement.style.width = '300px';
    };
    ClassConfiguratorComponent.prototype.hideSidebar = function () {
        this.rightSidebarVisible = false;
        this.rightSidebarContainer.nativeElement.style.borderLeft = 'none';
        this.rightSidebarContainer.nativeElement.style.height = '35px';
        this.rightSidebarContainer.nativeElement.style.width = '35px';
    };
    /**
     * ******INSTANTIATION******
     */
    ClassConfiguratorComponent.prototype.showInstanceForm = function () {
        if (util_1.isNullOrUndefined(this.currentSelectedCell) ||
            this.currentSelectedCell.cellType !== myMxCell_1.MyMxCellType.CLASS) {
            this.currentSelectedCell = this.rootCell;
        }
        // this.router.navigate(
        //   [`main/instance-editor/${this.globalInfo.marketplace.id}`],
        //   {
        //     queryParams: {
        //       0: this.currentSelectedCell.id,
        //       returnTo: 'classConfigurator'
        //     }
        //   }
        // );
    };
    ClassConfiguratorComponent.prototype.showExportDialog = function () {
        var rootCell = this.graph.getSelectionCell();
        if (!util_1.isNullOrUndefined(rootCell)) {
            this.dialogFactory.openPreviewExportDialog([rootCell.id]).then(function () { });
        }
    };
    /**
     * ******KEY LISTENER/HANDLER******
     */
    ClassConfiguratorComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.key === 'Delete') {
            var cells = this.graph.getSelectionCells();
            this.deleteCells(cells);
        }
    };
    /**
     * ******DEBUGGING******
     */
    ClassConfiguratorComponent.prototype.convertAggregation = function (r) {
        if (r.relationshipType === relationship_1.RelationshipType.AGGREGATION) {
            r.relationshipType = relationship_1.RelationshipType.ASSOCIATION;
            r.targetCardinality = relationship_1.AssociationCardinality.ONE;
            r.sourceCardinality = relationship_1.AssociationCardinality.ONE;
        }
        return r;
    };
    ClassConfiguratorComponent.prototype.showZoomLevel = function () {
        var scale = this.graph.view.getScale();
        console.log(this.graph.view.getScale());
        this.graph.zoomActual();
        console.log(this.graph.view.getScale());
        this.graph.view.setScale(scale);
    };
    ClassConfiguratorComponent.prototype.doShitWithGraph = function (graph) {
        console.log(graph);
    };
    ClassConfiguratorComponent.prototype.printModelToConsole = function () {
        console.log(this.classDefinitions);
        console.log(this.relationships);
        console.log(this.classConfiguration);
    };
    return ClassConfiguratorComponent;
}());
exports.ClassConfiguratorComponent = ClassConfiguratorComponent;
