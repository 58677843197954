"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var tree_property_definition_service_1 = require("app/main/content/_service/meta/core/property/tree-property-definition.service");
var util_1 = require("util");
var DeleteTreePropertyDefinitionDialogData = /** @class */ (function () {
    function DeleteTreePropertyDefinitionDialogData() {
    }
    return DeleteTreePropertyDefinitionDialogData;
}());
exports.DeleteTreePropertyDefinitionDialogData = DeleteTreePropertyDefinitionDialogData;
var DeleteTreePropertyDefinitionDialogComponent = /** @class */ (function () {
    function DeleteTreePropertyDefinitionDialogComponent(dialogRef, data, treePropertyDefinitionService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.treePropertyDefinitionService = treePropertyDefinitionService;
    }
    DeleteTreePropertyDefinitionDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.data.idsToDelete = [];
                this.treePropertyDefinitionService
                    .getAllPropertyDefinitionsForTenant(this.data.tenantId)
                    .toPromise()
                    .then(function (treePropertyDefinitions) {
                    if (!util_1.isNullOrUndefined(treePropertyDefinitions)) {
                        _this.treePropertyDefinitions = treePropertyDefinitions;
                        _this.checkboxStates = Array(treePropertyDefinitions.length);
                        _this.checkboxStates.fill(false);
                        _this.loaded = true;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    DeleteTreePropertyDefinitionDialogComponent.prototype.handleRowClick = function (entry) {
        console.log(entry);
    };
    DeleteTreePropertyDefinitionDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        this.treePropertyDefinitionService
            .deletePropertyDefinitions(this.data.idsToDelete)
            .toPromise()
            .then(function () {
            _this.dialogRef.close(undefined);
        });
    };
    DeleteTreePropertyDefinitionDialogComponent.prototype.handleCancelClick = function () { };
    DeleteTreePropertyDefinitionDialogComponent.prototype.hasNoTreePropertyDefinitions = function () {
        return (util_1.isNullOrUndefined(this.treePropertyDefinitions) ||
            this.treePropertyDefinitions.length <= 0);
    };
    DeleteTreePropertyDefinitionDialogComponent.prototype.handleCheckboxClicked = function (checked, entry, index) {
        if (!util_1.isNullOrUndefined(index)) {
            this.checkboxStates[index] = checked;
        }
        if (checked) {
            this.data.idsToDelete.push(entry.id);
        }
        else {
            var deleteIndex = this.data.idsToDelete.findIndex(function (e) { return e === entry.id; });
            this.data.idsToDelete.splice(deleteIndex, 1);
        }
    };
    DeleteTreePropertyDefinitionDialogComponent.prototype.handleCheckboxRowClicked = function (event, index, entry) {
        event.stopPropagation();
        this.handleCheckboxClicked(!this.checkboxStates[index], entry, index);
    };
    return DeleteTreePropertyDefinitionDialogComponent;
}());
exports.DeleteTreePropertyDefinitionDialogComponent = DeleteTreePropertyDefinitionDialogComponent;
