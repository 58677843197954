"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_factory_component_1 = require("app/main/content/_components/_shared/dialogs/_dialog-factory/dialog-factory.component");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var flat_property_definition_service_1 = require("app/main/content/_service/meta/core/property/flat-property-definition.service");
var tree_property_definition_service_1 = require("app/main/content/_service/meta/core/property/tree-property-definition.service");
var util_1 = require("util");
var tree_property_1 = require("app/main/content/_model/configurator/property/tree-property");
var property_1 = require("app/main/content/_model/configurator/property/property");
var response_service_1 = require("app/main/content/_service/response.service");
var environment_1 = require("environments/environment");
var PropertyListComponent = /** @class */ (function () {
    function PropertyListComponent(router, route, flatPropertyDefinitionService, treePropertyDefinitionService, dialogFactory, responseService) {
        this.router = router;
        this.route = route;
        this.flatPropertyDefinitionService = flatPropertyDefinitionService;
        this.treePropertyDefinitionService = treePropertyDefinitionService;
        this.dialogFactory = dialogFactory;
        this.responseService = responseService;
        this.dataSource = new material_1.MatTableDataSource();
        this.displayedColumns = ['type', 'name', 'filler', 'actions'];
    }
    PropertyListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoaded = false;
        this.customOnly = true;
        this.dropdownFilterValue = 'all';
        this.dataSource.filterPredicate = function (data, filter) {
            return data.name.toLowerCase().includes(filter);
        };
        this.route.queryParams.subscribe(function (params) {
            if (((environment_1.environment.MODE === 'iVolunteer' || environment_1.environment.MODE === 'all') && (util_1.isNullOrUndefined(params['tenantId']) || util_1.isNullOrUndefined(params['redirect'])))
                ||
                    (environment_1.environment.MODE === 'flexprod' && util_1.isNullOrUndefined(params['tenantId']))) {
                _this.router.navigate(['main/invalid-parameters']);
            }
            else {
                _this.tenantId = params['tenantId'];
                _this.redirectUrl = params['redirect'];
            }
        });
        this.loadAllProperties();
    };
    PropertyListComponent.prototype.applyFiltersFromParams = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.applyFilters(params);
        });
    };
    PropertyListComponent.prototype.onRowSelect = function (p) {
        // this.router.navigate([
        //   '/main/properties/' + this.marketplace.id + '/' + p.id,
        // ]);
    };
    PropertyListComponent.prototype.loadAllProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                Promise.all([
                    this.flatPropertyDefinitionService
                        .getAllPropertyDefinitonsForTenant(this.tenantId)
                        .toPromise().then(function (propertyDefinitions) {
                        _this.propertyDefinitions = propertyDefinitions;
                    }),
                    this.treePropertyDefinitionService
                        .getAllPropertyDefinitionsForTenant(this.tenantId)
                        .toPromise().then(function (treePropertyDefinitions) {
                        _this.treePropertyDefinitions = treePropertyDefinitions;
                    }),
                ]).then(function () {
                    _this.updatePropertyEntryList();
                    _this.applyFiltersFromParams();
                    _this.isLoaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    PropertyListComponent.prototype.updatePropertyEntryList = function () {
        var _a, _b;
        this.propertyEntries = [];
        if (!util_1.isNullOrUndefined(this.propertyEntries)) {
            (_a = this.propertyEntries).push.apply(_a, __spread(this.propertyDefinitions));
        }
        if (!util_1.isNullOrUndefined(this.treePropertyDefinitions)) {
            (_b = this.propertyEntries).push.apply(_b, __spread(this.treePropertyDefinitions.map(function (e) { return ({
                id: e.id,
                name: e.name,
                type: property_1.PropertyType.TREE,
                timestamp: e.timestamp,
                custom: e.custom,
            }); })));
        }
        this.dataSource.data = this.propertyEntries;
    };
    PropertyListComponent.prototype.applyTypeFilter = function () {
        switch (this.dropdownFilterValue) {
            case 'all':
                if (this.customOnly) {
                    this.dataSource.data = this.propertyEntries.filter(function (entry) { return entry.custom; });
                }
                else {
                    this.dataSource.data = this.propertyEntries;
                }
                break;
            case 'flat':
                this.dataSource.data = this.propertyEntries.filter(function (entry) { return entry.type !== property_1.PropertyType.TREE; });
                if (this.customOnly) {
                    this.dataSource.data = this.dataSource.data.filter(function (entry) { return entry.custom; });
                }
                break;
            case 'tree':
                this.dataSource.data = this.propertyEntries.filter(function (entry) { return entry.type === property_1.PropertyType.TREE; });
                if (this.customOnly) {
                    this.dataSource.data = this.dataSource.data.filter(function (entry) { return entry.custom; });
                }
                break;
            default:
                console.error('undefined type');
        }
        this.patchFilterParam('filter', this.dropdownFilterValue);
    };
    PropertyListComponent.prototype.handleTextFilterEvent = function (event) {
        this.applyTextFilter(this.textSearchValue);
    };
    PropertyListComponent.prototype.applyTextFilter = function (filterValue) {
        if (util_1.isNullOrUndefined(filterValue) || filterValue.length <= 0) {
            this.dataSource.filter = null;
            this.patchFilterParam('searchString', null);
        }
        else {
            this.dataSource.filter = filterValue.trim().toLowerCase();
            this.patchFilterParam('searchString', filterValue);
        }
    };
    PropertyListComponent.prototype.applyFilters = function (params) {
        if (!util_1.isNullOrUndefined(params['filter'])) {
            this.dropdownFilterValue = params['filter'];
        }
        else {
            this.dropdownFilterValue = 'all';
        }
        this.applyTypeFilter();
        if (!util_1.isNullOrUndefined(params['searchString'])) {
            this.applyTextFilter(params['searchString']);
            this.textSearchValue = params['searchString'];
        }
    };
    PropertyListComponent.prototype.patchFilterParam = function (key, value) {
        var _a;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: (_a = {}, _a[key] = value, _a),
            queryParamsHandling: 'merge',
            skipLocationChange: true,
        });
    };
    PropertyListComponent.prototype.handleCustomOnlyToggle = function () {
        this.applyTypeFilter();
    };
    PropertyListComponent.prototype.viewPropertyAction = function (property) {
        // this.router.navigate(
        //   ['main/property/detail/view/' + this.marketplace.id + '/' + property.id],
        //   { queryParams: { ref: 'list' } }
        // );
    };
    PropertyListComponent.prototype.newAction = function (key) {
        this.router.navigate(['main/property-builder'], {
            queryParams: { type: key, tenantId: this.tenantId, redirect: this.redirectUrl },
        });
    };
    PropertyListComponent.prototype.editAction = function (entry) {
        var builderType = entry.type === property_1.PropertyType.TREE ? 'tree' : 'flat';
        this.router.navigate(['main/property-builder/' + entry.id], { queryParams: { type: builderType, tenantId: this.tenantId, redirect: this.redirectUrl } });
    };
    PropertyListComponent.prototype.deleteAction = function (entry) {
        var _this = this;
        this.dialogFactory
            .confirmationDialog('Löschen', 'Dieser Vorgang kann nicht rückgeängig gemacht werden')
            .then(function (ret) {
            if (ret && entry.type !== property_1.PropertyType.TREE) {
                _this.responseService.sendPropertyConfiguratorResponse(_this.redirectUrl, [new property_1.FlatPropertyDefinition(entry)], undefined, "delete").toPromise().then(function () {
                    // this.flatPropertyDefinitionService.deletePropertyDefinition(entry.id).toPromise().then(() => {
                    _this.deleteFromLists('flat', entry.id);
                    // });
                }).catch(function (error) {
                    console.error("error - rollback");
                    console.log(error);
                });
            }
            else if (ret && entry.type === property_1.PropertyType.TREE) {
                var id = entry.id, name_1 = entry.name, timestamp = entry.timestamp, custom = entry.custom;
                _this.responseService.sendPropertyConfiguratorResponse(_this.redirectUrl, undefined, [new tree_property_1.TreePropertyDefinition({ id: id, name: name_1, timestamp: timestamp, custom: custom })], "delete").toPromise().then(function () {
                    // this.treePropertyDefinitionService.deletePropertyDefinition(entry.id).toPromise().then(() => {
                    _this.deleteFromLists('tree', entry.id);
                    // });
                }).catch(function (error) {
                    console.error("error - rollback");
                    console.log(error);
                });
            }
        });
    };
    PropertyListComponent.prototype.deleteFromLists = function (key, id) {
        key === 'tree'
            ? this.treePropertyDefinitions.filter(function (e) { return e.id !== id; })
            : this.propertyDefinitions.filter(function (e) { return e.id !== id; });
        this.propertyEntries = this.propertyEntries.filter(function (e) { return e.id !== id; });
        this.dataSource.data = this.dataSource.data.filter(function (e) { return e.id !== id; });
    };
    PropertyListComponent.prototype.getPropertyTypeLabel = function (propertyType) {
        return property_1.PropertyType.getLabelForPropertyType(propertyType);
    };
    return PropertyListComponent;
}());
exports.PropertyListComponent = PropertyListComponent;
