"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dynamic_form_item_service_1 = require("app/main/content/_service/dynamic-form-item.service");
var dynamic_form_item_control_service_1 = require("app/main/content/_service/dynamic-form-item-control.service");
var material_1 = require("@angular/material");
var class_definition_service_1 = require("app/main/content/_service/meta/core/class/class-definition.service");
var util_1 = require("util");
var ClassInstanceFormPreviewExportDialogComponent = /** @class */ (function () {
    function ClassInstanceFormPreviewExportDialogComponent(dialogRef, data, classDefinitionService, formItemService, formItemControlService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.classDefinitionService = classDefinitionService;
        this.formItemService = formItemService;
        this.formItemControlService = formItemControlService;
        this.isLoaded = false;
        this.exportClicked = false;
        this.results = [];
    }
    ClassInstanceFormPreviewExportDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.returnedClassInstances = [];
                this.expectedNumberOfResults = 0;
                this.classDefinitionService.getFormConfigurations(this.data.classConfigurationIds)
                    .toPromise()
                    .then(function (formConfigurations) {
                    var e_1, _a, e_2, _b;
                    _this.formConfigurations = formConfigurations;
                    try {
                        for (var _c = __values(_this.formConfigurations), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var config = _d.value;
                            var classProperties = [];
                            try {
                                for (var _e = (e_2 = void 0, __values(config.formEntry.classProperties)), _f = _e.next(); !_f.done; _f = _e.next()) {
                                    var classProperty = _f.value;
                                    classProperty.id = classProperty.name;
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            config.formEntry = _this.addQuestionsAndFormGroup(config.formEntry, config.formEntry.id);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                })
                    .then(function () {
                    _this.currentFormConfiguration = _this.formConfigurations.pop();
                    _this.isLoaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.addQuestionsAndFormGroup = function (formEntry, idPrefix) {
        var e_3, _a, e_4, _b;
        formEntry.formItems = this.formItemService.getFormItemsFromProperties(formEntry.classProperties, idPrefix);
        try {
            // clear validators for everything except the unableToContinue Property-FormItem
            for (var _c = __values(formEntry.formItems), _d = _c.next(); !_d.done; _d = _c.next()) {
                var formItem = _d.value;
                if (formItem.controlType !== 'tuple') {
                    formItem.validators = [];
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_3) throw e_3.error; }
        }
        formEntry.formGroup = this.formItemControlService.toFormGroup(formEntry.formItems);
        if (!util_1.isNullOrUndefined(formEntry.formItems) && formEntry.formItems.length > 0) {
            this.expectedNumberOfResults++;
        }
        if (!util_1.isNullOrUndefined(formEntry.subEntries)) {
            try {
                for (var _e = __values(formEntry.subEntries), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var subEntry = _f.value;
                    var newIdPrefix = subEntry.id;
                    subEntry = this.addQuestionsAndFormGroup(subEntry, newIdPrefix);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return formEntry;
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.handleTupleSelection = function (evt) {
        var _this = this;
        console.log('handling selection');
        var unableToContinueControl;
        // let unableToContinueControlKey: string;
        var unableToContinueQuestion;
        var pathPrefix;
        this.results = [];
        Object.keys(evt.formEntry.formGroup.controls).forEach(function (c) {
            if (c.endsWith('unableToContinue')) {
                // unableToContinueControlKey = c;
                unableToContinueControl = evt.formEntry.formGroup.controls[c];
                pathPrefix = c.replace(/\.[^.]*unableToContinue/, '');
            }
        });
        unableToContinueQuestion = evt.formEntry.formItems.find(function (item) { return item.key.endsWith('unableToContinue'); });
        this.classDefinitionService.getFormConfigurationChunk(pathPrefix, evt.selection.id)
            .toPromise()
            .then(function (retFormEntry) {
            var currentFormEntry = _this.getFormEntry(pathPrefix, _this.currentFormConfiguration.formEntry.id, _this.currentFormConfiguration.formEntry);
            var unableToContinueProperty = currentFormEntry.classProperties.find(function (p) { return p.id.endsWith('unableToContinue'); });
            unableToContinueProperty.defaultValues = [evt.selection];
            retFormEntry.classProperties.push(unableToContinueProperty);
            retFormEntry = _this.addQuestionsAndFormGroup(retFormEntry, pathPrefix);
            currentFormEntry.classDefinitions = retFormEntry.classDefinitions;
            currentFormEntry.classProperties = retFormEntry.classProperties;
            currentFormEntry.formGroup = retFormEntry.formGroup;
            currentFormEntry.imagePath = retFormEntry.imagePath;
            currentFormEntry.formItems = retFormEntry.formItems;
            currentFormEntry.subEntries = retFormEntry.subEntries;
            _this.expectedNumberOfResults = 0;
            _this.calculateExpectedResults(_this.currentFormConfiguration.formEntry);
        });
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.getFormEntry = function (pathString, currentPath, currentFormEntry) {
        if (currentPath === pathString) {
            return currentFormEntry;
        }
        currentFormEntry = currentFormEntry.subEntries.find(function (e) {
            return pathString.startsWith(e.id);
        });
        return this.getFormEntry(pathString, currentFormEntry.id, currentFormEntry);
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.calculateExpectedResults = function (formEntry) {
        var e_5, _a;
        this.expectedNumberOfResults++;
        try {
            for (var _b = __values(formEntry.subEntries), _c = _b.next(); !_c.done; _c = _b.next()) {
                var subFormEntry = _c.value;
                this.calculateExpectedResults(subFormEntry);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.handleExportClick = function () {
        this.exportClicked = true;
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.handleResultEvent = function (event) {
        var _this = this;
        this.results.push(event);
        console.log('actual vs expected');
        console.log(this.results.length + 'vs' + this.expectedNumberOfResults);
        // const unableToContinue = this.containsUnsetUnableToContinue(this.results.map(fg => fg.formGroup));
        if (this.results.length ===
            this.expectedNumberOfResults /*&& !unableToContinue*/) {
            this.doExport();
            setTimeout(function () {
                _this.exportClicked = false;
            });
        }
        else {
            this.exportClicked = false;
            // if (unableToContinue) {
            //   this.results.pop();
            // } else {
            //   // this.results = [];
            // }
        }
    };
    // private containsUnsetUnableToContinue(formGroups: FormGroup[]) {
    //   for (const formGroup of formGroups) {
    //     Object.keys(formGroup.controls).forEach(k => {
    //       if (k.endsWith('unableToContinue')) {
    //         return true;
    //       }
    //     });
    //   }
    //   return false;
    // }
    ClassInstanceFormPreviewExportDialogComponent.prototype.doExport = function () {
        var json = '{' + '"tenantId": "' + this.data.tenantId;
        '", ' + this.addClassToJSON(this.currentFormConfiguration.formEntry) + '}';
        this.exportFile([json]);
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.addClassToJSON = function (formEntry) {
        return ('"classDefinitionId": "' +
            formEntry.classDefinitions[0].id +
            '", ' +
            '"properties": [' +
            this.addPropertiesToJSON(formEntry) +
            '],' +
            '"subClassInstances": [' +
            this.addClassesToJSON(formEntry.subEntries) +
            ']');
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.addClassesToJSON = function (formEntries) {
        var returnString = '';
        for (var i = 0; i < formEntries.length; i++) {
            returnString += '{';
            returnString += this.addClassToJSON(formEntries[i]);
            returnString += '}';
            if (i < formEntries.length - 1) {
                returnString += ',';
            }
        }
        return returnString;
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.addPropertiesToJSON = function (formEntry) {
        var returnString = '{';
        for (var i = 0; i < formEntry.formItems.length; i++) {
            if (formEntry.formItems[i].controlType !== 'tuple') {
                returnString += " \"" + formEntry.formItems[i].label + "\": \"\"";
                if (i < formEntry.formItems.length - 1) {
                    returnString += ',';
                }
            }
            else {
                returnString = returnString.substring(0, returnString.length - 1);
            }
        }
        returnString += '}';
        return returnString;
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.exportFile = function (content) {
        var blob = new Blob(content, { type: 'application/json' });
        var url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = 'export.json';
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
            link.remove();
        }, 100);
    };
    ClassInstanceFormPreviewExportDialogComponent.prototype.handleCloseClick = function () {
        // console.log("handle close click");
        this.dialogRef.close(this.data);
    };
    return ClassInstanceFormPreviewExportDialogComponent;
}());
exports.ClassInstanceFormPreviewExportDialogComponent = ClassInstanceFormPreviewExportDialogComponent;
