"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var util_1 = require("util");
var BuilderContainerComponent = /** @class */ (function () {
    function BuilderContainerComponent(router, route) {
        this.router = router;
        this.route = route;
        this.result = new core_1.EventEmitter();
        this.management = new core_1.EventEmitter();
    }
    BuilderContainerComponent.prototype.ngOnInit = function () { };
    BuilderContainerComponent.prototype.onSelectionChange = function () {
        if (this.sourceString !== 'dialog') {
            var queryParams = { type: this.builderType };
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
                skipLocationChange: true,
            });
        }
    };
    BuilderContainerComponent.prototype.handleResultEvent = function (event) {
        this.result.emit(event);
    };
    BuilderContainerComponent.prototype.handleManagementEvent = function (event) {
        this.management.emit(event);
    };
    BuilderContainerComponent.prototype.isRadioGroupDisabled = function () {
        return !util_1.isNullOrUndefined(this.entryId);
    };
    BuilderContainerComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    return BuilderContainerComponent;
}());
exports.BuilderContainerComponent = BuilderContainerComponent;
