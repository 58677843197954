"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ClassConfigurationService = /** @class */ (function () {
    function ClassConfigurationService(http) {
        this.http = http;
    }
    ClassConfigurationService.prototype.getAllClassConfigurations = function () {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/all");
    };
    ClassConfigurationService.prototype.getAllClassConfigurationsByTenantId = function (tenantId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/all/tenant/" + tenantId);
    };
    ClassConfigurationService.prototype.getAllClassConfigurationById = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/" + id);
    };
    ClassConfigurationService.prototype.getClassConfigurationByName = function (name) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/by-name/" + name);
    };
    ClassConfigurationService.prototype.getAllForClassConfigurationInOne = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/all-in-one/" + id);
    };
    ClassConfigurationService.prototype.createNewEmptyClassConfiguration = function (tenantId, name, description) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/new-empty", [tenantId, name, description]);
    };
    ClassConfigurationService.prototype.createNewClassConfiguration = function (tenantId, name, description) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/new", [tenantId, name, description]);
    };
    ClassConfigurationService.prototype.saveClassConfiguration = function (classConfiguration) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/save", classConfiguration);
    };
    ClassConfigurationService.prototype.saveFullClassConfiguration = function (req) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/save-all-in-one", req);
    };
    ClassConfigurationService.prototype.saveClassConfigurationMeta = function (id, name, description) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/" + id + "/save-meta/", [name, description]);
    };
    ClassConfigurationService.prototype.deleteClassConfiguration = function (id) {
        return this.http.delete(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/" + id + "/delete");
    };
    ClassConfigurationService.prototype.deleteClassConfigurations = function (ids) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/class-configuration/delete-multiple", ids);
    };
    ClassConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassConfigurationService_Factory() { return new ClassConfigurationService(i0.ɵɵinject(i1.HttpClient)); }, token: ClassConfigurationService, providedIn: "root" });
    return ClassConfigurationService;
}());
exports.ClassConfigurationService = ClassConfigurationService;
