"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./options-overlay-control.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/flex-layout/flex");
var i3 = require("@angular/flex-layout/core");
var i4 = require("../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i5 = require("@angular/material/icon");
var i6 = require("../options-overlay-content/options-overlay-content.component.ngfactory");
var i7 = require("../options-overlay-content/options-overlay-content.component");
var i8 = require("@angular/common");
var i9 = require("./options-overlay-control.component");
var styles_MatchingOptionsOverlayControlComponent = [i0.styles];
var RenderType_MatchingOptionsOverlayControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatchingOptionsOverlayControlComponent, data: {} });
exports.RenderType_MatchingOptionsOverlayControlComponent = RenderType_MatchingOptionsOverlayControlComponent;
function View_MatchingOptionsOverlayControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["overlayDiv", 1]], null, 11, "div", [["class", "mat-elevation-z4 overlay-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "overlay-title-bar"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "pl-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Einstellungen"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["aria-label", "Schlie\u00DFen"], ["class", "mat-icon notranslate"], ["color", "primary"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeOverlay($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" close "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "matching-options-overlay-content", [], null, [[null, "resultRelationship"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resultRelationship" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatchingOptionsOverlayContentComponent_0, i6.RenderType_MatchingOptionsOverlayContentComponent)), i1.ɵdid(11, 114688, null, 0, i7.MatchingOptionsOverlayContentComponent, [], { overlayRelationship: [0, "overlayRelationship"] }, { resultRelationship: "resultRelationship" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 3, 0, currVal_1); var currVal_4 = "primary"; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.overlayRelationship; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).inline; var currVal_3 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
function View_MatchingOptionsOverlayControlComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { overlayDiv: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingOptionsOverlayControlComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayOverlay; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_MatchingOptionsOverlayControlComponent_0 = View_MatchingOptionsOverlayControlComponent_0;
function View_MatchingOptionsOverlayControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "matching-options-overlay-control", [], null, null, null, View_MatchingOptionsOverlayControlComponent_0, RenderType_MatchingOptionsOverlayControlComponent)), i1.ɵdid(1, 638976, null, 0, i9.MatchingOptionsOverlayControlComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MatchingOptionsOverlayControlComponent_Host_0 = View_MatchingOptionsOverlayControlComponent_Host_0;
var MatchingOptionsOverlayControlComponentNgFactory = i1.ɵccf("matching-options-overlay-control", i9.MatchingOptionsOverlayControlComponent, View_MatchingOptionsOverlayControlComponent_Host_0, { displayOverlay: "displayOverlay", overlayRelationship: "overlayRelationship", overlayEvent: "overlayEvent" }, { overlayClosed: "overlayClosed" }, []);
exports.MatchingOptionsOverlayControlComponentNgFactory = MatchingOptionsOverlayControlComponentNgFactory;
