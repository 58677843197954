"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/material/core");
var GermanDateAdapter = /** @class */ (function (_super) {
    __extends(GermanDateAdapter, _super);
    function GermanDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GermanDateAdapter.prototype.parse = function (value) {
        if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
            var str = value.split('.');
            if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
                return null;
            }
            return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
        }
        var timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    };
    return GermanDateAdapter;
}(core_1.NativeDateAdapter));
exports.GermanDateAdapter = GermanDateAdapter;
