"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*prettier-ignore*/
var core_1 = require("@angular/core");
var OVERLAY_WIDTH = 400;
var OVERLAY_HEIGHT = 390;
var OptionsOverlayContentData = /** @class */ (function () {
    function OptionsOverlayContentData() {
    }
    return OptionsOverlayContentData;
}());
exports.OptionsOverlayContentData = OptionsOverlayContentData;
var ClassOptionsOverlayControlComponent = /** @class */ (function () {
    function ClassOptionsOverlayControlComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.overlayClosed = new core_1.EventEmitter();
        this.model = new OptionsOverlayContentData();
    }
    ClassOptionsOverlayControlComponent.prototype.ngOnInit = function () {
        this.model = $.extend(true, {}, this.overlayContent);
        this.toggleInboxOverlay();
    };
    ClassOptionsOverlayControlComponent.prototype.ngOnChanges = function () {
        if (this.displayOverlay) {
            this.ngOnInit();
        }
    };
    ClassOptionsOverlayControlComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    ClassOptionsOverlayControlComponent.prototype.toggleInboxOverlay = function () {
        this.changeDetector.detectChanges();
        if (this.displayOverlay) {
            var yPos = this.overlayEvent.clientY;
            var xPos = this.overlayEvent.clientX;
            if (yPos + OVERLAY_HEIGHT > window.innerHeight) {
                yPos = window.innerHeight - OVERLAY_HEIGHT;
            }
            if (xPos + OVERLAY_WIDTH > window.innerWidth) {
                xPos = window.innerWidth - OVERLAY_WIDTH;
            }
            this.overlayDiv.nativeElement.style.top = yPos + 'px';
            this.overlayDiv.nativeElement.style.left = xPos + 'px';
            this.overlayDiv.nativeElement.style.position = 'fixed';
            this.overlayDiv.nativeElement.style.width = OVERLAY_WIDTH + 'px';
            this.overlayDiv.nativeElement.style.height = OVERLAY_HEIGHT + 'px';
        }
    };
    ClassOptionsOverlayControlComponent.prototype.handleResultEvent = function (event) {
        this.displayOverlay = false;
        this.overlayClosed.emit(event);
    };
    ClassOptionsOverlayControlComponent.prototype.closeOverlay = function () {
        this.displayOverlay = false;
        this.overlayClosed.emit(undefined);
    };
    return ClassOptionsOverlayControlComponent;
}());
exports.ClassOptionsOverlayControlComponent = ClassOptionsOverlayControlComponent;
