"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["div#graphContainer[_ngcontent-%COMP%]{z-index:240;position:absolute;overflow:scroll;left:0;top:65px;right:0;bottom:0;background:#fff;width:100%}div#paletteContainer[_ngcontent-%COMP%]{position:absolute;overflow:hidden;padding:2px;right:0;left:0;height:30px;top:35px;background:#fff;font:\"Arial, Helvetica, sans-serif\"}"];
exports.styles = styles;
