<ng-container *ngIf="!item.hidden">

    <!-- normal collapse -->
    <a class="nav-link" *ngIf="!item.url && !item.function" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a class="nav-link" *ngIf="item.url && !item.function"
       [routerLink]="[item.url]" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" *ngIf="!item.url && item.function" (click)="item.function()" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link" *ngIf="item.url && item.function" (click)="item.function()"
       [routerLink]="[item.url]" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <img  class="nav-link-icon" *ngIf="item.icon" src="assets/icons/{{item.icon}}.svg">
        <!--<mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>-->
        <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
              [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{item.badge.title}}
        </span>
        <mat-icon class="collapse-arrow">keyboard_arrow_right</mat-icon>
    </ng-template>

    <div class="children" [ngClass]="{'open': isOpen}">

        <div class="{{fuseSettings.colorClasses.navbar}}">

            <ng-container *ngFor="let item of item.children">
                <fuse-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-horizontal-item>
                <fuse-nav-horizontal-collapse *ngIf="item.type=='collapse'"
                                              [item]="item"></fuse-nav-horizontal-collapse>
                <fuse-nav-horizontal-collapse *ngIf="item.type=='group'" [item]="item"></fuse-nav-horizontal-collapse>
            </ng-container>

        </div>

    </div>

</ng-container>
