<div mat-dialog-title>
  <table>
    <tr>
      <td>
        <mat-icon>delete</mat-icon>
      </td>
      <td>
        <h1>Tree-Property-Definition löschen</h1>
      </td>
    </tr>
  </table>
</div>
<mat-dialog-content *ngIf="loaded">

  <div class="delete-dialog-content pt-8">

    <h2>Löschen</h2>


    <table class="browse-table" cellspacing=0 cellpadding=3>
      <col width="20px" />
      <col width="305px" />
      <col width="125px" />

      <tr style="background-color: rgb(62, 125, 219); color: white;">
        <th class="table-header-cell"></th>
        <th class="table-header-cell">Name</th>
        <th class="table-header-cell">Datum</th>

      </tr>
      <tr *ngFor="let entry of treePropertyDefinitions; let i = index" class="table-row"
        (click)="handleCheckboxRowClicked($event, i, entry)">
        <td class="table-entry-cell">
          <mat-checkbox [(ngModel)]="checkboxStates[i]" (change)="handleCheckboxClicked($event.checked, entry)"
            (click)="$event.stopPropagation();">
          </mat-checkbox>
        </td>
        <td class="table-entry-cell">{{entry.name}}</td>
        <td class="table-date-entry-cell">{{entry.timestamp | date:'dd.MM.yyyy - HH:mm:ss' }}</td>
      </tr>
      <div *ngIf="hasNoTreePropertyDefinitions()" style="text-align: center">
        keine Tree-Property-Definitionen verfügbar
      </div>
    </table>

  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button class="primary-button-dark" mat-stroked-button type="button" (click)="onSubmit()">OK</button>
  <button class="secondary-button-dark" mat-stroked-button type="button"
    [mat-dialog-close]="undefined">Abbrechen</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
</mat-dialog-actions>