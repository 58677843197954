"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var property_list_component_1 = require("./property-list.component");
var routes = [
    { path: '', component: property_list_component_1.PropertyListComponent }
];
var PropertyListModule = /** @class */ (function () {
    function PropertyListModule() {
    }
    return PropertyListModule;
}());
exports.PropertyListModule = PropertyListModule;
