"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".overlay-content[_ngcontent-%COMP%]   .overlay-button[_ngcontent-%COMP%]{width:100px;border-radius:4px;background-color:#fff;padding:0 10px;line-height:20px;display:block;margin:0 auto;border:1px solid currentColor}.overlay-content[_ngcontent-%COMP%]   .icon-wrapper[_ngcontent-%COMP%]{width:65px;height:65px;background:#f0f0f0;cursor:pointer;outline:grey solid 1px}.overlay-content[_ngcontent-%COMP%]   .field-container[_ngcontent-%COMP%]{margin-top:-16px}.overlay-content[_ngcontent-%COMP%]   .field-container[_ngcontent-%COMP%]   .field-label[_ngcontent-%COMP%]{z-index:1;display:inline;position:relative;top:15px;left:8px;font-size:12px;background:#fff}.overlay-content[_ngcontent-%COMP%]   .normal-label[_ngcontent-%COMP%]{z-index:1;display:inline;position:relative;font-size:12px;background:#fff}.overlay-content[_ngcontent-%COMP%]   .properties-section[_ngcontent-%COMP%]{height:143px;overflow-y:scroll;overflow-x:hidden}.overlay-content[_ngcontent-%COMP%]   .properties-section[_ngcontent-%COMP%]   .property-entry[_ngcontent-%COMP%]{cursor:pointer}.overlay-content[_ngcontent-%COMP%]   .properties-section[_ngcontent-%COMP%]   .property-entry[_ngcontent-%COMP%]   .property-entry-label[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:195px}.overlay-content[_ngcontent-%COMP%]   .properties-section[_ngcontent-%COMP%]   .property-entry[_ngcontent-%COMP%]:hover{background-color:#3e7ddb;color:#fff}.overlay-content[_ngcontent-%COMP%]   .outline-border[_ngcontent-%COMP%]{border:1px solid #505050;border-radius:4px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-infix{padding:0 0 10px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-flex{padding-left:5px;padding-right:5px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-wrapper{padding:0}"];
exports.styles = styles;
