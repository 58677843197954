"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var item_1 = require("app/main/content/_model/dynamic-forms/item");
var forms_1 = require("@angular/forms");
var LocationItemComponent = /** @class */ (function () {
    function LocationItemComponent() {
    }
    LocationItemComponent.prototype.ngOnInit = function () {
        this.loaded = false;
        this.locationGroup = this.form.controls[this.formItem.key];
        this.loaded = true;
    };
    LocationItemComponent.prototype.handleLongLatEnabledChange = function () {
    };
    return LocationItemComponent;
}());
exports.LocationItemComponent = LocationItemComponent;
