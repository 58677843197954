"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var class_configuration_service_1 = require("app/main/content/_service/configuration/class-configuration.service");
var util_1 = require("util");
var response_service_1 = require("app/main/content/_service/response.service");
var DeleteClassConfigurationDialogData = /** @class */ (function () {
    function DeleteClassConfigurationDialogData() {
    }
    return DeleteClassConfigurationDialogData;
}());
exports.DeleteClassConfigurationDialogData = DeleteClassConfigurationDialogData;
var DeleteClassConfigurationDialogComponent = /** @class */ (function () {
    function DeleteClassConfigurationDialogComponent(dialogRef, data, classConfigurationService, responseService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.classConfigurationService = classConfigurationService;
        this.responseService = responseService;
        this.datasource = new material_1.MatTableDataSource();
        this.currentSortType = 'az';
    }
    DeleteClassConfigurationDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.data.idsToDelete = [];
                this.classConfigurationService.getAllClassConfigurationsByTenantId(this.data.tenantId)
                    .toPromise()
                    .then(function (classConfigurations) {
                    _this.allClassConfigurations = classConfigurations;
                    _this.datasource.data = classConfigurations;
                    _this.sortClicked('date');
                    _this.loaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    DeleteClassConfigurationDialogComponent.prototype.handleCheckboxRowClicked = function (row) {
        if (this.data.idsToDelete.findIndex(function (id) { return row.id === id; }) === -1) {
            this.data.idsToDelete.push(row.id);
        }
        else {
            this.data.idsToDelete = this.data.idsToDelete.filter(function (id) { return id !== row.id; });
        }
    };
    DeleteClassConfigurationDialogComponent.prototype.isSelected = function (row) {
        return this.data.idsToDelete.findIndex(function (id) { return id === row.id; }) !== -1;
    };
    DeleteClassConfigurationDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        this.responseService.sendClassConfiguratorResponse(this.data.redirectUrl, null, this.data.idsToDelete, "delete").toPromise().then(function () {
            _this.dialogRef.close(_this.data);
        }).catch(function (error) {
            console.log("error - rollback");
            console.log(error);
        });
    };
    DeleteClassConfigurationDialogComponent.prototype.applyFilter = function (event) {
        this.currentFilter = event.target.value;
        this.datasource.filter = this.currentFilter.trim().toLowerCase();
    };
    DeleteClassConfigurationDialogComponent.prototype.sortClicked = function (sortKey) {
        if (this.currentSortKey === sortKey) {
            this.switchSortType();
        }
        else {
            this.currentSortType = 'az';
        }
        if (sortKey === 'date') {
            this.datasource.data = this.allClassConfigurations.sort(function (a, b) { return b.timestamp.valueOf() - a.timestamp.valueOf(); });
        }
        if (sortKey === 'name') {
            this.datasource.data = this.allClassConfigurations.sort(function (a, b) { return b.name.trim().localeCompare(a.name.trim()); });
        }
        if (this.currentSortType === 'za') {
            this.datasource.data.reverse();
        }
        this.currentSortKey = sortKey;
        if (!util_1.isNullOrUndefined(this.currentFilter)) {
            this.datasource.filter = this.currentFilter.trim().toLowerCase();
        }
    };
    DeleteClassConfigurationDialogComponent.prototype.switchSortType = function () {
        this.currentSortType === 'az' ? this.currentSortType = 'za' : this.currentSortType = 'az';
    };
    return DeleteClassConfigurationDialogComponent;
}());
exports.DeleteClassConfigurationDialogComponent = DeleteClassConfigurationDialogComponent;
