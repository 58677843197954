"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var material_1 = require("@angular/material");
var collections_1 = require("@angular/cdk/collections");
var util_1 = require("util");
var add_class_definition_graph_dialog_component_1 = require("../add-class-definition-graph-dialog/add-class-definition-graph-dialog.component");
var AddClassDefinitionDialogComponent = /** @class */ (function () {
    function AddClassDefinitionDialogComponent(dialogRef, data, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this.dataSource = new material_1.MatTableDataSource();
        this.displayedColumns = ['checkbox', 'label', 'type'];
    }
    AddClassDefinitionDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var entities;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                this.tabIndex = 0;
                this.dataSource.data = this.data.matchingEntityConfiguration.mappings.entities;
                this.selection = new collections_1.SelectionModel(true, []);
                entities = this.dataSource.data.filter(function (e) { return _this.data.existingEntityPaths.find(function (path) { return path === e.path; }); });
                if (!util_1.isNullOrUndefined(entities)) {
                    (_a = this.selection).select.apply(_a, __spread(entities));
                }
                this.loaded = true;
                return [2 /*return*/];
            });
        });
    };
    AddClassDefinitionDialogComponent.prototype.isRowDisabled = function (row) {
        return !util_1.isNullOrUndefined(this.data.existingEntityPaths.find(function (path) { return path === row.path; }));
    };
    AddClassDefinitionDialogComponent.prototype.applyFilter = function (event) {
        var filterValue = event.target.value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    AddClassDefinitionDialogComponent.prototype.onRowClick = function (row) {
        if (this.isRowDisabled(row)) {
            return;
        }
        this.selection.isSelected(row) ? this.selection.deselect(row) : this.selection.select(row);
    };
    AddClassDefinitionDialogComponent.prototype.openGraphDialog = function () {
        var _this = this;
        this.openAddClassDefinitionDialog(this.data, this.selection.selected).then(function (ret) {
            var _a;
            if (!util_1.isNullOrUndefined(ret)) {
                _this.selection.clear();
                (_a = _this.selection).select.apply(_a, __spread(ret.addedEntities));
            }
        });
    };
    AddClassDefinitionDialogComponent.prototype.onSubmit = function (submitAll) {
        // Add selected, but filter out existing ones
        var _a;
        var _this = this;
        if (submitAll) {
            (_a = this.selection).select.apply(_a, __spread(this.dataSource.data));
        }
        var added;
        if (util_1.isNullOrUndefined(this.data.existingEntityPaths)) {
            added = this.selection.selected;
        }
        else {
            added = this.selection.selected.filter(function (p) {
                return _this.data.existingEntityPaths.findIndex(function (q) { return p.path === q; }) === -1;
            });
        }
        this.data.addedEntities = added;
        this.dialogRef.close(this.data);
    };
    AddClassDefinitionDialogComponent.prototype.openAddClassDefinitionDialog = function (data, selected) {
        var dialogRef = this.dialog.open(add_class_definition_graph_dialog_component_1.AddClassDefinitionGraphDialogComponent, {
            width: '90vw',
            height: '90vh',
            data: {
                matchingEntityConfiguration: data.matchingEntityConfiguration,
                existingEntityPaths: data.existingEntityPaths,
                addedEntities: selected,
            },
        });
        var returnValue;
        dialogRef.beforeClosed().toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef.afterClosed().toPromise()
            .then(function () {
            return returnValue;
        });
    };
    return AddClassDefinitionDialogComponent;
}());
exports.AddClassDefinitionDialogComponent = AddClassDefinitionDialogComponent;
