"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["mat-expansion-panel-header[_ngcontent-%COMP%]{background:#f0f0f0}mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%], mat-expansion-panel-header[_ngcontent-%COMP%]:hover{background-color:#3e7ddb!important}mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-description[_ngcontent-%COMP%], mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-title[_ngcontent-%COMP%], mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-description[_ngcontent-%COMP%], mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-title[_ngcontent-%COMP%]{color:#fff}"];
exports.styles = styles;
