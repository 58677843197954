"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tree-property-graph-editor.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/flex-layout/flex");
var i3 = require("@angular/flex-layout/core");
var i4 = require("../../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i5 = require("@angular/material/button");
var i6 = require("@angular/cdk/a11y");
var i7 = require("@angular/platform-browser/animations");
var i8 = require("./options-overlay/options-overlay-control/options-overlay-control.component.ngfactory");
var i9 = require("./options-overlay/options-overlay-control/options-overlay-control.component");
var i10 = require("./tree-property-graph-editor.component");
var i11 = require("../../../../../../_service/objectid.service.");
var styles_TreePropertyGraphEditorComponent = [i0.styles];
var RenderType_TreePropertyGraphEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreePropertyGraphEditorComponent, data: {} });
exports.RenderType_TreePropertyGraphEditorComponent = RenderType_TreePropertyGraphEditorComponent;
function View_TreePropertyGraphEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { graphContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["graphContainer", 1]], null, 0, "div", [["id", "graphContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "mt-8"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "primary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSaveClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Speichern"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "primary-button-dark ml-8"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSaveAndBackClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Speichern und Schlie\u00DFen "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "secondary-button-dark ml-28"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBackClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Zur\u00FCck"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "tree-property-options-overlay-control", [], null, [[null, "overlayClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("overlayClosed" === en)) {
        var pd_0 = (_co.handleOverlayClosed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_TreePropertyOptionsOverlayControlComponent_0, i8.RenderType_TreePropertyOptionsOverlayControlComponent)), i1.ɵdid(15, 638976, null, 0, i9.TreePropertyOptionsOverlayControlComponent, [i1.ChangeDetectorRef], { displayOverlay: [0, "displayOverlay"], overlayContent: [1, "overlayContent"], overlayEvent: [2, "overlayEvent"] }, { overlayClosed: "overlayClosed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 4, 0, currVal_1); var currVal_8 = _co.displayOverlay; var currVal_9 = _co.overlayContent; var currVal_10 = _co.overlayEvent; _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 9).disabled || null); var currVal_5 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 12).disabled || null); var currVal_7 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_6, currVal_7); }); }
exports.View_TreePropertyGraphEditorComponent_0 = View_TreePropertyGraphEditorComponent_0;
function View_TreePropertyGraphEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tree-property-graph-editor", [], null, [["document", "keypress"]], function (_v, en, $event) { var ad = true; if (("document:keypress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TreePropertyGraphEditorComponent_0, RenderType_TreePropertyGraphEditorComponent)), i1.ɵdid(1, 1097728, null, 0, i10.TreePropertyGraphEditorComponent, [i11.ObjectIdService], null, null)], null, null); }
exports.View_TreePropertyGraphEditorComponent_Host_0 = View_TreePropertyGraphEditorComponent_Host_0;
var TreePropertyGraphEditorComponentNgFactory = i1.ɵccf("app-tree-property-graph-editor", i10.TreePropertyGraphEditorComponent, View_TreePropertyGraphEditorComponent_Host_0, { treePropertyDefinition: "treePropertyDefinition" }, { result: "result", management: "management" }, []);
exports.TreePropertyGraphEditorComponentNgFactory = TreePropertyGraphEditorComponentNgFactory;
