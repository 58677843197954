"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClassDefinition = /** @class */ (function () {
    function ClassDefinition() {
        this.properties = [];
    }
    return ClassDefinition;
}());
exports.ClassDefinition = ClassDefinition;
var ClassInstance = /** @class */ (function () {
    function ClassInstance(classDefinition, properties) {
        this.name = classDefinition.name;
        this.classDefinitionId = classDefinition.id;
        this.properties = properties;
        this.marketplaceId = classDefinition.marketplaceId;
        this.timestamp = new Date();
        this.userId = null;
        this.classArchetype = classDefinition.classArchetype;
        this.visible = classDefinition.visible;
        this.tabId = classDefinition.tabId;
    }
    return ClassInstance;
}());
exports.ClassInstance = ClassInstance;
var ClassInstanceDTO = /** @class */ (function () {
    function ClassInstanceDTO() {
    }
    return ClassInstanceDTO;
}());
exports.ClassInstanceDTO = ClassInstanceDTO;
var ClassArchetype;
(function (ClassArchetype) {
    ClassArchetype["COMPETENCE"] = "COMPETENCE";
    ClassArchetype["TASK"] = "TASK";
    ClassArchetype["FUNCTION"] = "FUNCTION";
    ClassArchetype["ACHIEVEMENT"] = "ACHIEVEMENT";
    ClassArchetype["TREE_HEAD"] = "TREE_HEAD";
    ClassArchetype["TREE_ENTRY"] = "TREE_ENTRY";
    ClassArchetype["ROOT"] = "ROOT";
    // COMPETENCE_HEAD = 'COMPETENCE_HEAD',
    // TASK_HEAD = 'TASK_HEAD',
    // FUNCTION_HEAD = 'FUNCTION_HEAD',
    // ACHIEVEMENT_HEAD = 'ACHIEVEMENT_HEAD',
    ClassArchetype["FLEXPROD"] = "FLEXPROD";
})(ClassArchetype = exports.ClassArchetype || (exports.ClassArchetype = {}));
(function (ClassArchetype) {
    function getClassArchetypeLabel(classArchetype) {
        switch (classArchetype) {
            case 'COMPETENCE':
                return 'Kompetenz';
            case 'TASK':
                return 'Tätigkeit';
            case 'FUNCTION':
                return 'Funktion';
            case 'ACHIEVEMENT':
                return 'Verdienst';
            case 'TREE_HEAD':
                return 'Tree head';
            case 'TREE_ENTRY':
                return 'Tree entry';
            case 'ROOT':
                return 'Root';
            case 'FLEXPROD':
                return 'Flexprod';
        }
    }
    ClassArchetype.getClassArchetypeLabel = getClassArchetypeLabel;
})(ClassArchetype = exports.ClassArchetype || (exports.ClassArchetype = {}));
