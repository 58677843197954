"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./confirm-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/dialog");
var i3 = require("../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i4 = require("@angular/material/button");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("./confirm-dialog.component");
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
exports.RenderType_ConfirmDialogComponent = RenderType_ConfirmDialogComponent;
function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(5, null, [" ", "\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "primary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).dialogRef.close(i1.ɵnov(_v, 9).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Ja"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "secondary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Nein"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = "button"; var currVal_8 = true; _ck(_v, 9, 0, currVal_7, currVal_8); var currVal_13 = "button"; var currVal_14 = undefined; _ck(_v, 13, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.description; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 9).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 9).type; var currVal_5 = (i1.ɵnov(_v, 10).disabled || null); var currVal_6 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = (i1.ɵnov(_v, 13).ariaLabel || null); var currVal_10 = i1.ɵnov(_v, 13).type; var currVal_11 = (i1.ɵnov(_v, 14).disabled || null); var currVal_12 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_9, currVal_10, currVal_11, currVal_12); }); }
exports.View_ConfirmDialogComponent_0 = View_ConfirmDialogComponent_0;
function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.ConfirmDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
exports.View_ConfirmDialogComponent_Host_0 = View_ConfirmDialogComponent_Host_0;
var ConfirmDialogComponentNgFactory = i1.ɵccf("confirm-dialog", i7.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
exports.ConfirmDialogComponentNgFactory = ConfirmDialogComponentNgFactory;
