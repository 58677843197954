"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./invalid-parameters.component");
var styles_InvalidParametersComponent = [];
var RenderType_InvalidParametersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InvalidParametersComponent, data: {} });
exports.RenderType_InvalidParametersComponent = RenderType_InvalidParametersComponent;
function View_InvalidParametersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["style", "display: block; margin: 0 auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Invalid or no required parameters provided, provide "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["tenantId"])), (_l()(), i0.ɵted(-1, null, [" and/or "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["redirect"])), (_l()(), i0.ɵted(-1, null, [" parameters. "]))], null, null); }
exports.View_InvalidParametersComponent_0 = View_InvalidParametersComponent_0;
function View_InvalidParametersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_InvalidParametersComponent_0, RenderType_InvalidParametersComponent)), i0.ɵdid(1, 114688, null, 0, i1.InvalidParametersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_InvalidParametersComponent_Host_0 = View_InvalidParametersComponent_Host_0;
var InvalidParametersComponentNgFactory = i0.ɵccf("ng-component", i1.InvalidParametersComponent, View_InvalidParametersComponent_Host_0, {}, {}, []);
exports.InvalidParametersComponentNgFactory = InvalidParametersComponentNgFactory;
