"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var AnonymGuard = /** @class */ (function () {
    function AnonymGuard() {
    }
    AnonymGuard.prototype.canActivate = function () {
        return true;
    };
    AnonymGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnonymGuard_Factory() { return new AnonymGuard(); }, token: AnonymGuard, providedIn: "root" });
    return AnonymGuard;
}());
exports.AnonymGuard = AnonymGuard;
