"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var TreePropertyDefinitionService = /** @class */ (function () {
    function TreePropertyDefinitionService(http) {
        this.http = http;
    }
    TreePropertyDefinitionService.prototype.getAllPropertyDefinitions = function () {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/all");
    };
    TreePropertyDefinitionService.prototype.getAllPropertyDefinitionsForTenant = function (tenantId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/all/tenant/" + tenantId);
    };
    TreePropertyDefinitionService.prototype.getPropertyDefinitionById = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/" + id);
    };
    TreePropertyDefinitionService.prototype.getPropertyDefinitionByName = function (name) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/by-name/" + name);
    };
    TreePropertyDefinitionService.prototype.newPropertyDefinition = function (treePropertyDefinition) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/new", treePropertyDefinition);
    };
    TreePropertyDefinitionService.prototype.savePropertyDefinition = function (treePropertyDefinition) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/save", treePropertyDefinition);
    };
    TreePropertyDefinitionService.prototype.deletePropertyDefinition = function (id) {
        return this.http.delete(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/" + id + "/delete");
    };
    TreePropertyDefinitionService.prototype.deletePropertyDefinitions = function (ids) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/property-definition/tree/delete-multiple", ids);
    };
    TreePropertyDefinitionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TreePropertyDefinitionService_Factory() { return new TreePropertyDefinitionService(i0.ɵɵinject(i1.HttpClient)); }, token: TreePropertyDefinitionService, providedIn: "root" });
    return TreePropertyDefinitionService;
}());
exports.TreePropertyDefinitionService = TreePropertyDefinitionService;
