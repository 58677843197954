"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./nav-horizontal-item.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("@angular/material/core");
var i5 = require("@angular/cdk/platform");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("@ngx-translate/core");
var i8 = require("./nav-horizontal-item.component");
var styles_FuseNavHorizontalItemComponent = [i0.styles];
var RenderType_FuseNavHorizontalItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseNavHorizontalItemComponent, data: {} });
exports.RenderType_FuseNavHorizontalItemComponent = RenderType_FuseNavHorizontalItemComponent;
function View_FuseNavHorizontalItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "nav-link mat-ripple"], ["matRipple", ""], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4], [2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), i1.ɵdid(7, 212992, null, 0, i4.MatRipple, [i1.ElementRef, i1.NgZone, i5.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_3)), i1.ɵdid(9, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 2, 0, _co.item.url); _ck(_v, 1, 0, currVal_3); var currVal_4 = _ck(_v, 6, 0, (_co.item.exactMatch || false)); var currVal_5 = "active"; _ck(_v, 3, 0, currVal_4, currVal_5); _ck(_v, 7, 0); var currVal_6 = i1.ɵnov(_v.parent, 7); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = i1.ɵnov(_v, 7).unbounded; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "nav-link mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.item.function() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i4.MatRipple, [i1.ElementRef, i1.NgZone, i5.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_5)), i1.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = i1.ɵnov(_v.parent, 7); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).unbounded; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavHorizontalItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "nav-link mat-ripple"], ["matRipple", ""], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4], [2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.item.function() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), i1.ɵdid(7, 212992, null, 0, i4.MatRipple, [i1.ElementRef, i1.NgZone, i5.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_7)), i1.ɵdid(9, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 2, 0, _co.item.url); _ck(_v, 1, 0, currVal_3); var currVal_4 = _ck(_v, 6, 0, (_co.item.exactMatch || false)); var currVal_5 = "active"; _ck(_v, 3, 0, currVal_4, currVal_5); _ck(_v, 7, 0); var currVal_6 = i1.ɵnov(_v.parent, 7); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = i1.ɵnov(_v, 7).unbounded; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "nav-link-icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "assets/icons/", _co.item.icon, ".svg"); _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavHorizontalItemComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "nav-link-badge"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0, "color": 1 }), i1.ɵdid(4, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.item.badge.bg, _co.item.badge.fg); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.item.badge.translate; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.badge.title; _ck(_v, 5, 0, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_9)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "nav-link-title"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_10)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.icon; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item.translate; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.item.badge; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.title; _ck(_v, 4, 0, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_6)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["itemContent", 2]], null, 0, null, View_FuseNavHorizontalItemComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.url; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.item.function; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.item.url && _co.item.function); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavHorizontalItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_FuseNavHorizontalItemComponent_0 = View_FuseNavHorizontalItemComponent_0;
function View_FuseNavHorizontalItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-item", [], [[8, "className", 0]], null, null, View_FuseNavHorizontalItemComponent_0, RenderType_FuseNavHorizontalItemComponent)), i1.ɵdid(1, 49152, null, 0, i8.FuseNavHorizontalItemComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
exports.View_FuseNavHorizontalItemComponent_Host_0 = View_FuseNavHorizontalItemComponent_Host_0;
var FuseNavHorizontalItemComponentNgFactory = i1.ɵccf("fuse-nav-horizontal-item", i8.FuseNavHorizontalItemComponent, View_FuseNavHorizontalItemComponent_Host_0, { item: "item" }, {}, []);
exports.FuseNavHorizontalItemComponentNgFactory = FuseNavHorizontalItemComponentNgFactory;
