"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var item_1 = require("app/main/content/_model/dynamic-forms/item");
var util_1 = require("util");
var forms_1 = require("@angular/forms");
var material_1 = require("@angular/material");
var SingleSelectTreeItemComponent = /** @class */ (function () {
    function SingleSelectTreeItemComponent(renderer) {
        this.renderer = renderer;
        this.datasource = new material_1.MatTableDataSource();
    }
    SingleSelectTreeItemComponent.prototype.ngOnInit = function () {
        var _a;
        this.listOptions = [];
        (_a = this.listOptions).push.apply(_a, __spread(this.formItem.options));
        this.datasource.data = this.listOptions;
    };
    SingleSelectTreeItemComponent.prototype.ngAfterViewInit = function () {
        this.listContainerDom.nativeElement.style.display = 'none';
    };
    SingleSelectTreeItemComponent.prototype.onShowList = function () {
        this.listContainerDom.nativeElement.style.display = '';
        this.renderer.removeClass(this.formItemContainerDom.nativeElement, 'form-item-container-closed');
        this.renderer.addClass(this.formItemContainerDom.nativeElement, 'form-item-container-open');
        this.showList = true;
    };
    SingleSelectTreeItemComponent.prototype.onHideList = function () {
        this.listContainerDom.nativeElement.style.display = 'none';
        this.renderer.removeClass(this.formItemContainerDom.nativeElement, 'form-item-container-open');
        this.renderer.addClass(this.formItemContainerDom.nativeElement, 'form-item-container-closed');
        this.showList = false;
    };
    SingleSelectTreeItemComponent.prototype.onSelectOption = function (option) {
        this.form.controls[this.formItem.key].setValue(option);
        this.formItem.value = option;
        this.onHideList();
    };
    SingleSelectTreeItemComponent.prototype.onSelectClear = function () {
        this.formItem.value = null;
        this.onHideList();
    };
    SingleSelectTreeItemComponent.prototype.getFormItemValue = function () {
        return util_1.isNullOrUndefined(this.formItem.value) ? null : this.formItem.value.value;
    };
    SingleSelectTreeItemComponent.prototype.calculateSpaces = function (level) {
        level = 8 * level + 8;
        return level + 'px';
    };
    SingleSelectTreeItemComponent.prototype.calculateIndent = function (level) {
        return level + 'px';
    };
    SingleSelectTreeItemComponent.prototype.getChevrons = function (level) {
        var s = '>';
        s = s.repeat(level);
        return s;
    };
    SingleSelectTreeItemComponent.prototype.applyFilter = function (event) {
        var filterValue = event.target.value;
        this.datasource.filter = filterValue.trim().toLowerCase();
    };
    return SingleSelectTreeItemComponent;
}());
exports.SingleSelectTreeItemComponent = SingleSelectTreeItemComponent;
