"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var relationship_1 = require("app/main/content/_model/configurator/relationship");
var utils_and_constants_1 = require("../../utils-and-constants");
var platform_browser_1 = require("@angular/platform-browser");
var util_1 = require("util");
var dialog_factory_component_1 = require("app/main/content/_components/_shared/dialogs/_dialog-factory/dialog-factory.component");
var options_overlay_control_component_1 = require("../options-overlay-control/options-overlay-control.component");
var ClassOptionsOverlayContentComponent = /** @class */ (function () {
    function ClassOptionsOverlayContentComponent(dialogFactory, _sanitizer) {
        this.dialogFactory = dialogFactory;
        this._sanitizer = _sanitizer;
        this.resultData = new core_1.EventEmitter();
        this.relationshipPalettes = utils_and_constants_1.CConstants.relationshipPalettes;
        this.propertyTypePalettes = utils_and_constants_1.CConstants.propertyTypePalettes;
    }
    ClassOptionsOverlayContentComponent.prototype.ngOnInit = function () {
        this.updatePropertiesList();
    };
    ClassOptionsOverlayContentComponent.prototype.onSubmit = function () {
        this.resultData.emit(this.inputData);
    };
    ClassOptionsOverlayContentComponent.prototype.onCancel = function () {
        this.resultData.emit(undefined);
    };
    ClassOptionsOverlayContentComponent.prototype.changeIconClicked = function () {
        var _this = this;
        this.dialogFactory
            .openChangeIconDialog(this.inputData.classDefinition.imagePath)
            .then(function (result) {
            _this.inputData.classDefinition.imagePath = result;
        });
    };
    ClassOptionsOverlayContentComponent.prototype.getImagePathForRelationship = function (relationshipType) {
        return this.relationshipPalettes.rows.find(function (r) { return r.id === relationshipType; })
            .imgPath;
    };
    ClassOptionsOverlayContentComponent.prototype.getLabelForRelationship = function (relationshipType) {
        return relationship_1.RelationshipType.getLabelFromRelationshipType(relationshipType);
    };
    ClassOptionsOverlayContentComponent.prototype.getImagePathPropertyType = function (propertyType) {
        return this.propertyTypePalettes.find(function (p) { return p.id === propertyType; }).imgPath;
    };
    ClassOptionsOverlayContentComponent.prototype.getEntryStyle = function (index) {
        if (index < this.entryList.length - 1) {
            return this._sanitizer.bypassSecurityTrustStyle('height: 20px; border-bottom: solid 1px rgb(80, 80, 80)');
        }
        else {
            return this._sanitizer.bypassSecurityTrustStyle('height: 20px; border-bottom: none');
        }
    };
    ClassOptionsOverlayContentComponent.prototype.addPropertyClicked = function () {
        var _this = this;
        this.dialogFactory
            .openAddPropertyDialog(this.inputData.classDefinition, this.inputData.allClassDefinitions, this.inputData.allRelationships, this.inputData.tenantId)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.inputData.classDefinition.properties =
                    ret.classDefinition.properties;
                _this.updatePropertiesList();
            }
        });
    };
    ClassOptionsOverlayContentComponent.prototype.removeClicked = function () {
        var _this = this;
        this.dialogFactory
            .openRemovePropertyDialog(this.inputData.classDefinition)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.inputData.classDefinition.properties =
                    ret.classDefinition.properties;
                _this.updatePropertiesList();
            }
        });
    };
    ClassOptionsOverlayContentComponent.prototype.previewClicked = function () {
        this.dialogFactory
            .openInstanceFormPreviewDialog(this.inputData.allClassDefinitions, this.inputData.allRelationships, this.inputData.classDefinition)
            .then(function () { });
    };
    ClassOptionsOverlayContentComponent.prototype.updatePropertiesList = function () {
        var _a;
        this.entryList = [];
        (_a = this.entryList).push.apply(_a, __spread(this.inputData.classDefinition.properties));
    };
    return ClassOptionsOverlayContentComponent;
}());
exports.ClassOptionsOverlayContentComponent = ClassOptionsOverlayContentComponent;
