"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var property_1 = require("../_model/configurator/property/property");
var util_1 = require("util");
var forms_1 = require("@angular/forms");
var constraint_1 = require("../_model/configurator/constraint");
var item_1 = require("../_model/dynamic-forms/item");
var location_1 = require("../_model/configurator/property/location");
var i0 = require("@angular/core");
var DynamicFormItemService = /** @class */ (function () {
    function DynamicFormItemService() {
        this.key = 0;
    }
    DynamicFormItemService.prototype.getFormItemsFromProperties = function (classProperties, idPrefix) {
        var formItems = this.createFormItemsFromProperties(classProperties, idPrefix);
        return formItems.sort(function (a, b) { return a.order - b.order; });
    };
    DynamicFormItemService.prototype.createFormItemsFromProperties = function (classProperties, idPrefix) {
        var e_1, _a;
        var formItems = [];
        try {
            for (var classProperties_1 = __values(classProperties), classProperties_1_1 = classProperties_1.next(); !classProperties_1_1.done; classProperties_1_1 = classProperties_1.next()) {
                var property = classProperties_1_1.value;
                var formItem = this.createFormItemFromProperty(property);
                if (!util_1.isNullOrUndefined(idPrefix)) {
                    formItem.key = idPrefix + '.' + property.id;
                }
                else {
                    formItem.key = property.id;
                }
                formItem.label = property.name;
                formItem.order = property.position;
                var validatorData = this.getValidatorData(property.propertyConstraints, property.type);
                if (!util_1.isNullOrUndefined(validatorData)) {
                    formItem.validators = validatorData.validators;
                    formItem.messages = validatorData.messages;
                }
                formItem.required = property.required;
                if (property.required) {
                    formItem.validators.push(forms_1.Validators.required);
                }
                formItems.push(formItem);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (classProperties_1_1 && !classProperties_1_1.done && (_a = classProperties_1.return)) _a.call(classProperties_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return formItems;
    };
    DynamicFormItemService.prototype.createFormItemFromProperty = function (property) {
        var formItem;
        if (property.computed) {
            formItem = new item_1.ComputedFormItem({
                value: util_1.isNullOrUndefined(property_1.ClassProperty.getDefaultValue(property)) ? '' : property_1.ClassProperty.getDefaultValue(property),
                returnType: property.type,
            });
        }
        else {
            if (property.type === property_1.PropertyType.TEXT) {
                if (util_1.isNullOrUndefined(property.allowedValues) || property.allowedValues.length <= 0) {
                    formItem = new item_1.TextboxFormItem({
                        value: property_1.ClassProperty.getDefaultValue(property),
                    });
                }
                else {
                    if (property.multiple) {
                        formItem = new item_1.DropdownMultipleFormItem({
                            values: property.defaultValues,
                            options: this.setAsListValues(property.allowedValues)
                        });
                    }
                    else {
                        formItem = new item_1.DropdownFormItem({
                            value: property_1.ClassProperty.getDefaultValue(property),
                            options: property.allowedValues
                        });
                    }
                }
            }
            else if (property.type === property_1.PropertyType.WHOLE_NUMBER || property.type === property_1.PropertyType.FLOAT_NUMBER) {
                if (util_1.isNullOrUndefined(property.allowedValues) || property.allowedValues.length <= 0) {
                    formItem = new item_1.NumberBoxFormItem({
                        value: property_1.ClassProperty.getDefaultValue(property),
                    });
                }
                else {
                    if (property.multiple) {
                        formItem = new item_1.DropdownMultipleFormItem({
                            values: property.defaultValues,
                            options: property.allowedValues
                        });
                    }
                    else {
                        formItem = new item_1.NumberDropdownFormItem({
                            value: property_1.ClassProperty.getDefaultValue(property),
                            options: property.allowedValues
                        });
                    }
                }
            }
            else if (property.type === property_1.PropertyType.LONG_TEXT) {
                formItem = new item_1.TextAreaFormItem({
                    value: property_1.ClassProperty.getDefaultValue(property),
                });
            }
            else if (property.type === property_1.PropertyType.BOOL) {
                formItem = new item_1.SlideToggleFormItem({
                    value: property_1.ClassProperty.getDefaultValue(property),
                });
            }
            else if (property.type === property_1.PropertyType.TREE) {
                if (property.multiple) {
                    formItem = new item_1.MultipleSelectionTreeFormItem({
                        values: property.defaultValues,
                        options: property.allowedValues
                    });
                }
                else {
                    formItem = new item_1.SingleSelectionTreeFormItem({
                        values: property.defaultValues,
                        value: property_1.ClassProperty.getDefaultValue(property),
                        options: property.allowedValues
                    });
                }
            }
            else if (property.type === property_1.PropertyType.DATE) {
                formItem = new item_1.DatepickerFormItem({
                    value: this.setDateValue(property_1.ClassProperty.getDefaultValue(property)),
                });
            }
            else if (property.type === property_1.PropertyType.TUPLE) {
                if (!util_1.isNullOrUndefined(property.allowedValues) && property.allowedValues.length > 0) {
                    formItem = new item_1.TupleDropdownFormItem({
                        options: property.allowedValues,
                        value: property_1.ClassProperty.getDefaultValue(property)
                    });
                }
            }
            else if (property.type === property_1.PropertyType.LOCATION) {
                formItem = new item_1.LocationFormItem({
                    options: property.allowedValues,
                    value: util_1.isNullOrUndefined(property_1.ClassProperty.getDefaultValue(property)) ? new location_1.Location() : property_1.ClassProperty.getDefaultValue(property),
                });
            }
            else {
                console.log('property kind not implemented: ' + property.type);
                formItem = new item_1.GenericFormItem({});
            }
        }
        return formItem;
    };
    DynamicFormItemService.prototype.setAsListValues = function (values) {
        var ret = [];
        if (!util_1.isNullOrUndefined(values)) {
            for (var i = 0; i < values.length; i++) {
                ret.push({ key: values[i], value: values[i] });
            }
        }
        return ret;
    };
    DynamicFormItemService.prototype.setDateValue = function (value) {
        return !util_1.isNullOrUndefined ? new Date(value) : undefined;
    };
    DynamicFormItemService.prototype.getValidatorData = function (propertyConstraints, propertyType) {
        var e_2, _a;
        var validators = [];
        var messages = new Map();
        if (!util_1.isNullOrUndefined(propertyConstraints)) {
            try {
                for (var propertyConstraints_1 = __values(propertyConstraints), propertyConstraints_1_1 = propertyConstraints_1.next(); !propertyConstraints_1_1.done; propertyConstraints_1_1 = propertyConstraints_1.next()) {
                    var constraint = propertyConstraints_1_1.value;
                    var singleValidatorData = this.convertRuleToValidator(constraint, propertyType);
                    if (!util_1.isNullOrUndefined(singleValidatorData)) {
                        validators.push(singleValidatorData.validator);
                        messages.set(singleValidatorData.key, constraint.message);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (propertyConstraints_1_1 && !propertyConstraints_1_1.done && (_a = propertyConstraints_1.return)) _a.call(propertyConstraints_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return { validators: validators, messages: messages };
        }
        else {
            return undefined;
        }
    };
    DynamicFormItemService.prototype.convertRuleToValidator = function (propertyConstraint, propertyType) {
        var validator;
        var key;
        switch (constraint_1.ConstraintType[propertyConstraint.constraintType]) {
            case constraint_1.ConstraintType.PATTERN:
                // console.log("adding regex_pattern Validator" + rule.regex);
                validator = forms_1.Validators.pattern(propertyConstraint.value);
                key = 'pattern';
                break;
            case constraint_1.ConstraintType.MAX_LENGTH:
                // console.log("adding max_length Validator" + rule.value);
                validator = forms_1.Validators.maxLength(propertyConstraint.value);
                key = 'maxlength';
                break;
            case constraint_1.ConstraintType.MIN_LENGTH:
                // console.log("adding min_length Validator: " + rule.value);
                validator = forms_1.Validators.minLength(propertyConstraint.value);
                key = 'minlength';
                break;
            case constraint_1.ConstraintType.MAX:
                // console.log("adding max Validator" + rule.value);
                validator = forms_1.Validators.max(propertyConstraint.value);
                key = 'max';
                break;
            case constraint_1.ConstraintType.MIN:
                // console.log("adding min Validator" + rule.value);
                validator = forms_1.Validators.min(propertyConstraint.value);
                key = 'min';
                break;
            default:
                console.error('VALIDATORS: switch-default should not happen');
                break;
        }
        var ret = { key: key, validator: validator };
        return ret;
    };
    DynamicFormItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicFormItemService_Factory() { return new DynamicFormItemService(); }, token: DynamicFormItemService, providedIn: "root" });
    return DynamicFormItemService;
}());
exports.DynamicFormItemService = DynamicFormItemService;
