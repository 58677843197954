"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./property-build-form.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./builder-container/builder-container.component.ngfactory");
var i3 = require("./builder-container/builder-container.component");
var i4 = require("@angular/router");
var i5 = require("../../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory");
var i6 = require("@angular/material/progress-spinner");
var i7 = require("@angular/cdk/platform");
var i8 = require("@angular/common");
var i9 = require("@angular/platform-browser/animations");
var i10 = require("../../../_shared/header/header.component.ngfactory");
var i11 = require("../../../_shared/header/header.component");
var i12 = require("./property-build-form.component");
var styles_PropertyBuildFormComponent = [i0.styles];
var RenderType_PropertyBuildFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropertyBuildFormComponent, data: {} });
exports.RenderType_PropertyBuildFormComponent = RenderType_PropertyBuildFormComponent;
function View_PropertyBuildFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-white-bg mat-elevation-z4 p-24 mt-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-builder-container", [["sourceString", "builder"]], null, [[null, "result"], [null, "management"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("result" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("management" === en)) {
        var pd_1 = (_co.handleManagementEvent($event, i1.ɵnov(_v.parent, 0)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_BuilderContainerComponent_0, i2.RenderType_BuilderContainerComponent)), i1.ɵdid(2, 114688, null, 0, i3.BuilderContainerComponent, [i4.Router, i4.ActivatedRoute], { builderType: [0, "builderType"], entryId: [1, "entryId"], sourceString: [2, "sourceString"], tenantId: [3, "tenantId"], redirectUrl: [4, "redirectUrl"] }, { result: "result", management: "management" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.builderType; var currVal_1 = _co.entryId; var currVal_2 = "builder"; var currVal_3 = _co.tenantId; var currVal_4 = _co.redirectUrl; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_PropertyBuildFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i5.View_MatSpinner_0, i5.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i6.MatSpinner, [i1.ElementRef, i7.Platform, [2, i8.DOCUMENT], [2, i9.ANIMATION_MODULE_TYPE], i6.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PropertyBuildFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["buildForm", 1]], null, 6, "div", [["class", "page-layout blank right-sidenav p-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "customizable-header", [["headerText", "Property-Baukasten"]], null, null, null, i10.View_HeaderComponent_0, i10.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i11.HeaderComponent, [], { headerText: [0, "headerText"], displayNavigateBack: [1, "displayNavigateBack"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertyBuildFormComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertyBuildFormComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Property-Baukasten"; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.loaded; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.loaded; _ck(_v, 6, 0, currVal_3); }, null); }
exports.View_PropertyBuildFormComponent_0 = View_PropertyBuildFormComponent_0;
function View_PropertyBuildFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-property-build-form", [], null, null, null, View_PropertyBuildFormComponent_0, RenderType_PropertyBuildFormComponent)), i1.ɵdid(1, 114688, null, 0, i12.PropertyBuildFormComponent, [i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PropertyBuildFormComponent_Host_0 = View_PropertyBuildFormComponent_Host_0;
var PropertyBuildFormComponentNgFactory = i1.ɵccf("app-property-build-form", i12.PropertyBuildFormComponent, View_PropertyBuildFormComponent_Host_0, {}, {}, []);
exports.PropertyBuildFormComponentNgFactory = PropertyBuildFormComponentNgFactory;
