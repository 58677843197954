"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PropertyConstraint = /** @class */ (function () {
    function PropertyConstraint() {
    }
    return PropertyConstraint;
}());
exports.PropertyConstraint = PropertyConstraint;
var ConstraintType;
(function (ConstraintType) {
    ConstraintType["MIN"] = "MIN";
    ConstraintType["MAX"] = "MAX";
    ConstraintType["MIN_LENGTH"] = "MIN_LENGTH";
    ConstraintType["MAX_LENGTH"] = "MAX_LENGTH";
    ConstraintType["PATTERN"] = "PATTERN";
})(ConstraintType = exports.ConstraintType || (exports.ConstraintType = {}));
(function (ConstraintType) {
    var labelAssignment = [
        { type: ConstraintType.MIN, label: 'Minimum' },
        { type: ConstraintType.MAX, label: 'maximum' },
        { type: ConstraintType.MIN_LENGTH, label: 'Mindestlänge' },
        { type: ConstraintType.MAX_LENGTH, label: 'Höchstlänge' },
        { type: ConstraintType.PATTERN, label: 'Regex Pattern' },
    ];
    function getLabelForConstraintType(constraintType) {
        var assignment = labelAssignment.find(function (la) { return la.type === constraintType; });
        if (assignment === undefined) {
            return constraintType;
        }
        else {
            return assignment.label;
        }
    }
    ConstraintType.getLabelForConstraintType = getLabelForConstraintType;
})(ConstraintType = exports.ConstraintType || (exports.ConstraintType = {}));
