"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("util");
// validation function
function propertyNameUniqueValidator(properties, currentProperty) {
    return function (control) {
        if (util_1.isNullOrUndefined(properties)) {
            return null;
        }
        // TODO REMOVE OR ELSE IT DOESNT WORK
        else {
            return null;
        }
        var item = properties.find(function (prop) {
            return prop.name.toLocaleLowerCase() === control.value.toLocaleLowerCase();
        });
        // if (!isNullOrUndefined(item) && !isNullOrUndefined(currentProperty) && currentProperty.id == item.id) {
        //     item = undefined;
        // }
        // return !isNullOrUndefined(item) ? {'propertynameunique': {'propertynameunique': item, 'actual': control.value}} : null;
    };
}
exports.propertyNameUniqueValidator = propertyNameUniqueValidator;
