"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("util");
var Location = /** @class */ (function () {
    function Location(label, long, lat) {
        this.label = label;
        this.longLatEnabled = !util_1.isNullOrUndefined(long) && !util_1.isNullOrUndefined(lat);
        this.longitude = long;
        this.latitude = lat;
    }
    return Location;
}());
exports.Location = Location;
