"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MatchingBrowseSubDialogData = /** @class */ (function () {
    function MatchingBrowseSubDialogData() {
    }
    return MatchingBrowseSubDialogData;
}());
exports.MatchingBrowseSubDialogData = MatchingBrowseSubDialogData;
var BrowseMatchingSubDialogComponent = /** @class */ (function () {
    function BrowseMatchingSubDialogComponent() {
        this.subDialogReturn = new core_1.EventEmitter();
    }
    BrowseMatchingSubDialogComponent.prototype.ngOnInit = function () {
        // DEBUG
    };
    BrowseMatchingSubDialogComponent.prototype.handleRowClick = function (entry) {
        this.subDialogReturn.emit({ cancelled: false, entryId: entry.id });
    };
    BrowseMatchingSubDialogComponent.prototype.handleBackClick = function () {
        this.subDialogReturn.emit({ cancelled: true, entryId: undefined });
    };
    return BrowseMatchingSubDialogComponent;
}());
exports.BrowseMatchingSubDialogComponent = BrowseMatchingSubDialogComponent;
