"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-dialog-title[_ngcontent-%COMP%]{margin:0;display:block;position:relative;top:0;right:0;left:0}.mat-dialog-title[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{padding:0 0 6px;margin:0}mat-dialog-content[_ngcontent-%COMP%]{height:calc(100% - 52px);max-height:none}mat-dialog-content[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%], mat-dialog-content[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{padding:0;margin:0}mat-dialog-actions[_ngcontent-%COMP%]{display:none}"];
exports.styles = styles;
