"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{display:flex;flex:0 1 auto;z-index:3}[_nghost-%COMP%]   .mat-toolbar[_ngcontent-%COMP%]{background:inherit;color:inherit;box-shadow:0 -1px 1px -1px rgba(0,0,0,.2),0 0 1px 0 rgba(0,0,0,.14),0 -1px 3px 0 rgba(0,0,0,.12)}.above[_nghost-%COMP%]{position:relative;z-index:99}"];
exports.styles = styles;
