"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["mat-expansion-panel[_ngcontent-%COMP%]{box-shadow:none;border:2px solid #000}.class-mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%], .class-mat-expansion-panel-header[_ngcontent-%COMP%]:hover{background-color:#047bca!important;border-radius:0}.class-mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-description[_ngcontent-%COMP%], .class-mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-title[_ngcontent-%COMP%], .class-mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-description[_ngcontent-%COMP%], .class-mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-title[_ngcontent-%COMP%]{color:#fff}.class-mat-expansion-panel-header[_ngcontent-%COMP%]{background-color:#047bca!important;border-radius:0}.class-mat-expansion-panel-header[_ngcontent-%COMP%]   mat-panel-description[_ngcontent-%COMP%], .class-mat-expansion-panel-header[_ngcontent-%COMP%]   mat-panel-title[_ngcontent-%COMP%]{color:#fff}.property-mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%], .property-mat-expansion-panel-header[_ngcontent-%COMP%]:hover{background-color:#367329!important;border-radius:0}.property-mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-description[_ngcontent-%COMP%], .property-mat-expansion-panel-header.mat-expanded[_ngcontent-%COMP%]   mat-panel-title[_ngcontent-%COMP%], .property-mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-description[_ngcontent-%COMP%], .property-mat-expansion-panel-header[_ngcontent-%COMP%]:hover   mat-panel-title[_ngcontent-%COMP%]{color:#fff}.property-mat-expansion-panel-header[_ngcontent-%COMP%]{background-color:#367329!important;border-radius:0}.property-mat-expansion-panel-header[_ngcontent-%COMP%]   mat-panel-description[_ngcontent-%COMP%], .property-mat-expansion-panel-header[_ngcontent-%COMP%]   mat-panel-title[_ngcontent-%COMP%]{color:#fff}"];
exports.styles = styles;
