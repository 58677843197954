<div fxLayout="row" fxLayoutAlign="start start" #menubarContainer id="menubarContainer">

  <div class="titlebar" fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngFor="let item of rootMenuItems" class="pl-4">
      <div class="menuitem" (click)="openSubmenu($event, item.id)">
        <mat-icon class="menuitem-icon">{{item.icon}}</mat-icon>
      </div>
    </div>

    <div class="title-bar-text-container" fxLayout="row" fxLayoutAlign="start center" #titlebarTextContainer
      id="titlebarTextContainer" (click)="editClicked()">
      <h2 *ngIf="currentMatchingConfiguration" class="title-bar-text">{{currentMatchingConfiguration.name}}</h2>
      <div class="title-bar-text-icon">
        <mat-icon>edit</mat-icon>
      </div>
    </div>

    <div></div>
  </div>
</div>

<div #submenuContainer id="submenuContainer">

  <div *ngFor="let item of subMenuItems">
    <div class="menuitem" *ngIf="currentRootId == item.rootId" (click)="this[item.clickAction]()">
      {{item.label}}</div>
  </div>
</div>