<div class="overlay-content m-8">




    <div class="pb-4" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="start start" style="width: 185px">
            <mat-label>Operator</mat-label>

            <button mat-stroked-button [matMenuTriggerFor]="menu" style="width: 165px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div></div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div>
                            <img width="40" height="40"
                                [src]="getImagePathForMatchingOperatorType(matchingOperatorType)">
                        </div>

                        <div class="pl-4">
                            {{getLabelForMatchingOperatorType(matchingOperatorType)}}
                        </div>
                    </div>
                    <div></div>
                </div>
            </button>
            <mat-menu #menu="matMenu" class="custom">
                <button *ngFor="let p of matchingOperatorPalettes" mat-button fxLayout="column"
                    fxLayoutAlign="space-between center" [matTooltip]="p.label" (click)="matchingOperatorChanged(p)">
                    <div></div>
                    <div style="width: 40px; height: 40px"><img width="40" height="40" [src]="p.imgPath"></div>
                    <div></div>
                </button>
            </mat-menu>

        </div>

        <mat-checkbox labelPosition="after" [(ngModel)]="necessary">
            <div style="font-size: 14px">Notwendig</div>
        </mat-checkbox>
    </div>

    <mat-divider></mat-divider>

    <div class="pt-4">
        <mat-label>Unschärfe (in %)</mat-label>
        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-slider min="0" max="100" step="" tickInterval="10" style="width: 100%" [ngModel]="fuzzyness"
                (input)="sliderValueChanged($event)"></mat-slider>
            <mat-form-field style="width: 55px; padding-left: 4px" appearance="outline">
                <input matInput type="number" id="fuzzyness" [(ngModel)]="fuzzyness" min=0 max=100
                    style=" text-align: right">
            </mat-form-field>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-label>Gewichtung</mat-label>
        <mat-form-field style="width: 70px; padding-left: 4px" appearance="outline">
            <input matInput type="number" id="weighting" [(ngModel)]="weighting" style="text-align: right" min="0"
                max="9999">
        </mat-form-field>
    </div>


    <div *ngIf="!fuzzynessValid || !weightingValid" style="font-weight: 700">
        Ungültige Werte entdeckt und korrigiert.
    </div>


    <div style="position: absolute; bottom: 8px; right: 8px">
        <button class="primary-button-light" mat-stroked-button type="button" (click)="onSubmit()">Speichern</button>
    </div>
</div>