"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("util");
var FlatPropertyDefinition = /** @class */ (function () {
    function FlatPropertyDefinition(init) {
        Object.assign(this, init);
    }
    return FlatPropertyDefinition;
}());
exports.FlatPropertyDefinition = FlatPropertyDefinition;
var ClassProperty = /** @class */ (function () {
    function ClassProperty() {
    }
    ClassProperty.getDefaultValue = function (templateProperty) {
        if (!util_1.isNullOrUndefined(templateProperty.defaultValues) && templateProperty.defaultValues.length >= 1) {
            return templateProperty.defaultValues[0];
        }
        else {
            return null;
        }
    };
    return ClassProperty;
}());
exports.ClassProperty = ClassProperty;
var PropertyInstance = /** @class */ (function () {
    function PropertyInstance(classProperty, values) {
        this.id = classProperty.id;
        this.name = classProperty.name;
        this.values = values;
        this.allowedValues = classProperty.allowedValues;
        this.type = classProperty.type;
        this.required = classProperty.required;
        this.position = classProperty.position;
        this.propertyConstraints = classProperty.propertyConstraints;
        this.visible = classProperty.visible;
        this.tabId = classProperty.tabId;
        if (classProperty.type === PropertyType.TREE) {
            var rootValue_1 = this.values[0];
            if (util_1.isNullOrUndefined(rootValue_1)) {
                return;
            }
            var i = classProperty
                .allowedValues.findIndex(function (a) { return a.id === rootValue_1.id; });
            var currentLevel = rootValue_1.level;
            for (i; i >= 0; i--) {
                var currentAllowedValue = this.allowedValues[i];
                if (currentAllowedValue.level < currentLevel) {
                    // this.values.push(classProperty.allowedValues[i]);
                    rootValue_1.parents.push(currentAllowedValue);
                    currentLevel--;
                }
            }
        }
    }
    PropertyInstance.getValue = function (propertyInstance) {
        if (!util_1.isNullOrUndefined(propertyInstance.values) && propertyInstance.values.length >= 1) {
            return propertyInstance.values[0];
        }
        else {
            return null;
        }
    };
    return PropertyInstance;
}());
exports.PropertyInstance = PropertyInstance;
var PropertyType;
(function (PropertyType) {
    PropertyType["TEXT"] = "TEXT";
    PropertyType["LONG_TEXT"] = "LONG_TEXT";
    PropertyType["WHOLE_NUMBER"] = "WHOLE_NUMBER";
    PropertyType["FLOAT_NUMBER"] = "FLOAT_NUMBER";
    PropertyType["BOOL"] = "BOOL";
    PropertyType["DATE"] = "DATE";
    PropertyType["COMPETENCE"] = "COMPETENCE";
    PropertyType["LIST"] = "LIST";
    PropertyType["MAP"] = "MAP";
    PropertyType["GRAPH"] = "GRAPH";
    PropertyType["MULTI"] = "MULTI";
    PropertyType["TUPLE"] = "TUPLE";
    PropertyType["TREE"] = "TREE";
    PropertyType["LOCATION"] = "LOCATION";
})(PropertyType = exports.PropertyType || (exports.PropertyType = {}));
(function (PropertyType) {
    var labelAssignment = [
        { type: PropertyType.TEXT, label: 'Text' },
        { type: PropertyType.LONG_TEXT, label: 'Textfeld' },
        { type: PropertyType.WHOLE_NUMBER, label: 'Ganze Zahl' },
        { type: PropertyType.FLOAT_NUMBER, label: 'Kommazahl' },
        { type: PropertyType.BOOL, label: 'Boolean' },
        { type: PropertyType.DATE, label: 'Datum' },
        { type: PropertyType.TUPLE, label: 'Tupel' },
        { type: PropertyType.TREE, label: 'Tree-Property' },
        { type: PropertyType.LOCATION, label: 'Ort' },
    ];
    function getLabelForPropertyType(propertyType) {
        var assignment = labelAssignment.find(function (la) { return la.type === propertyType; });
        if (assignment === undefined) {
            return propertyType;
        }
        else {
            return assignment.label;
        }
    }
    PropertyType.getLabelForPropertyType = getLabelForPropertyType;
})(PropertyType = exports.PropertyType || (exports.PropertyType = {}));
var PropertyItem = /** @class */ (function () {
    function PropertyItem() {
    }
    return PropertyItem;
}());
exports.PropertyItem = PropertyItem;
var PropertyParentSubTemplate = /** @class */ (function () {
    function PropertyParentSubTemplate() {
    }
    return PropertyParentSubTemplate;
}());
exports.PropertyParentSubTemplate = PropertyParentSubTemplate;
var PropertyParentTemplate = /** @class */ (function () {
    function PropertyParentTemplate() {
    }
    return PropertyParentTemplate;
}());
exports.PropertyParentTemplate = PropertyParentTemplate;
var Rule = /** @class */ (function () {
    function Rule() {
    }
    return Rule;
}());
exports.Rule = Rule;
var RuleKind;
(function (RuleKind) {
    RuleKind["REQUIRED"] = "REQUIRED";
    RuleKind["REQUIRED_TRUE"] = "REQUIRED_TRUE";
    RuleKind["REGEX_PATTERN"] = "REGEX_PATTERN";
    RuleKind["MAX_LENGTH"] = "MAX_LENGTH";
    RuleKind["MIN_LENGTH"] = "MIN_LENGTH";
    RuleKind["MAX"] = "MAX";
    RuleKind["MIN"] = "MIN";
    RuleKind["REQUIRED_OTHER"] = "REQUIRED_OTHER";
    RuleKind["MIN_OTHER"] = "MIN_OTHER";
    RuleKind["MAX_OTHER"] = "MAX_OTHER";
})(RuleKind = exports.RuleKind || (exports.RuleKind = {}));
