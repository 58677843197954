"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var matching_1 = require("app/main/content/_model/matching");
var MatchingOptionsOverlayControlComponent = /** @class */ (function () {
    function MatchingOptionsOverlayControlComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.overlayClosed = new core_1.EventEmitter();
    }
    MatchingOptionsOverlayControlComponent.prototype.ngOnInit = function () {
        this.toggleInboxOverlay();
    };
    MatchingOptionsOverlayControlComponent.prototype.ngOnChanges = function () {
        this.toggleInboxOverlay();
    };
    MatchingOptionsOverlayControlComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    MatchingOptionsOverlayControlComponent.prototype.toggleInboxOverlay = function () {
        this.changeDetector.detectChanges();
        if (this.displayOverlay) {
            var yPos = this.overlayEvent.clientY;
            var xPos = this.overlayEvent.clientX;
            if (yPos + 275 > window.innerHeight) {
                yPos = window.innerHeight - 275;
            }
            this.overlayDiv.nativeElement.style.top = yPos + 'px';
            this.overlayDiv.nativeElement.style.left = xPos + 'px';
            this.overlayDiv.nativeElement.style.position = 'fixed';
            this.overlayDiv.nativeElement.style.width = '300px';
            this.overlayDiv.nativeElement.style.height = '275px';
        }
    };
    MatchingOptionsOverlayControlComponent.prototype.handleResultEvent = function (event) {
        this.displayOverlay = false;
        this.overlayClosed.emit(event);
    };
    MatchingOptionsOverlayControlComponent.prototype.closeOverlay = function ($event) {
        this.displayOverlay = false;
        this.overlayClosed.emit(undefined);
    };
    return MatchingOptionsOverlayControlComponent;
}());
exports.MatchingOptionsOverlayControlComponent = MatchingOptionsOverlayControlComponent;
