"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var ConfirmDialogComponent = /** @class */ (function () {
    function ConfirmDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ConfirmDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return ConfirmDialogComponent;
}());
exports.ConfirmDialogComponent = ConfirmDialogComponent;
