<div #overlayDiv *ngIf="displayOverlay" class="mat-elevation-z4 overlay-layout">

    <div fxLayout="row" fxLayoutAlign="space-between center" class="overlay-title-bar">
        <div class="pl-4">Einstellungen</div>
        <div></div>
        <mat-icon color="primary" aria-label="Schließen" (click)="closeOverlay($event)">
            close
        </mat-icon>

    </div>

    <matching-options-overlay-content [overlayRelationship]="overlayRelationship"
        (resultRelationship)="handleResultEvent($event)">
    </matching-options-overlay-content>
</div>