"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var matching_configurator_component_1 = require("./matching-configurator.component");
var routes = [
    { path: '', component: matching_configurator_component_1.MatchingConfiguratorComponent }
];
var MatchingConfiguratorModule = /** @class */ (function () {
    function MatchingConfiguratorModule() {
    }
    return MatchingConfiguratorModule;
}());
exports.MatchingConfiguratorModule = MatchingConfiguratorModule;
