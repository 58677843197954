"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ClassPropertyRequestObject = /** @class */ (function () {
    function ClassPropertyRequestObject() {
    }
    return ClassPropertyRequestObject;
}());
exports.ClassPropertyRequestObject = ClassPropertyRequestObject;
var ClassPropertyService = /** @class */ (function () {
    function ClassPropertyService(http) {
        this.http = http;
    }
    ClassPropertyService.prototype.getAllClassPropertiesFromClass = function (classDefintionId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/" + classDefintionId + "/all");
    };
    ClassPropertyService.prototype.getClassPropertyById = function (classDefintionId, classPropertyId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/" + classDefintionId + "/" + classPropertyId);
    };
    ClassPropertyService.prototype.updateClassProperty = function (classDefintionId, classProperty) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/" + classDefintionId + "/update", classProperty);
    };
    ClassPropertyService.prototype.getClassPropertyFromDefinitionById = function (flatPropertyDefinitionIds, treePropertyDefinitionIds) {
        var requestObject = { flatPropertyDefinitionIds: flatPropertyDefinitionIds, treePropertyDefinitionIds: treePropertyDefinitionIds };
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/get-classproperty-from-definition-by-id", requestObject);
    };
    ClassPropertyService.prototype.addPropertiesToClassDefinitionById = function (id, propIds) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/" + id + "/add-properties-by-id", propIds);
    };
    ClassPropertyService.prototype.addPropertiesToClassDefinition = function (id, propsToAdd) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/" + id + "/add-properties", propsToAdd);
    };
    ClassPropertyService.prototype.removePropertiesFromClassDefinition = function (id, propIds) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/property/class/" + id + "/remove-properties", propIds);
    };
    ClassPropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassPropertyService_Factory() { return new ClassPropertyService(i0.ɵɵinject(i1.HttpClient)); }, token: ClassPropertyService, providedIn: "root" });
    return ClassPropertyService;
}());
exports.ClassPropertyService = ClassPropertyService;
