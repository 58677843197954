"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var util_1 = require("util");
var tree_1 = require("@angular/cdk/tree");
var material_1 = require("@angular/material");
var class_configurator_component_1 = require("../class-configurator.component");
var EditorTreeViewComponent = /** @class */ (function () {
    function EditorTreeViewComponent() {
        this.treeControl = new tree_1.NestedTreeControl(function (node) { return node.children; });
        this.dataSource = new material_1.MatTreeNestedDataSource();
        this.hasChild = function (_, node) { return !!node.children && node.children.length > 0; };
    }
    EditorTreeViewComponent.prototype.ngOnInit = function () {
        this.oldEditorInstance = Object.assign({}, this.editorInstance);
        this.constructJsonFromGraph();
    };
    EditorTreeViewComponent.prototype.ngDoCheck = function () {
        if (this.editorInstance.modelUpdated) {
            this.constructJsonFromGraph();
            this.editorInstance.modelUpdated = false;
        }
        this.oldEditorInstance = Object.assign({}, this.editorInstance);
    };
    // TODO
    EditorTreeViewComponent.prototype.constructJsonFromGraph = function () {
        var e_1, _a;
        var vertices = this.editorInstance.graph.getChildVertices(this.editorInstance.graph.getDefaultParent());
        var roots = vertices.filter(function (cell) {
            return cell.root;
        });
        var graphNodes = [];
        try {
            for (var roots_1 = __values(roots), roots_1_1 = roots_1.next(); !roots_1_1.done; roots_1_1 = roots_1.next()) {
                var root = roots_1_1.value;
                var length_1 = graphNodes.push({ id: root.id, name: root.value, type: 'root', cell: root, visible: root.isVisible() });
                graphNodes[length_1 - 1].children = this.addChildrenToGraphNode(graphNodes[length_1 - 1], vertices);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (roots_1_1 && !roots_1_1.done && (_a = roots_1.return)) _a.call(roots_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.dataSource.data = graphNodes;
        this.treeControl.dataNodes = graphNodes;
        this.treeControl.expandAll();
    };
    EditorTreeViewComponent.prototype.addChildrenToGraphNode = function (node, vertices) {
        var e_2, _a;
        var edges = this.editorInstance.graph.getEdges(node.cell, undefined, false, true);
        if (!util_1.isNullOrUndefined(edges)) {
            node.children = [];
            var _loop_1 = function (edge) {
                var child = vertices.find(function (cell) {
                    return cell.id === edge.target.id;
                });
                if (!util_1.isNullOrUndefined(child)) {
                    var length_2 = node.children.push({ id: child.id, name: child.value, type: 'node', cell: child, visible: child.isVisible() });
                    node.children[length_2 - 1].children = this_1.addChildrenToGraphNode(node.children[length_2 - 1], vertices);
                }
            };
            var this_1 = this;
            try {
                for (var edges_1 = __values(edges), edges_1_1 = edges_1.next(); !edges_1_1.done; edges_1_1 = edges_1.next()) {
                    var edge = edges_1_1.value;
                    _loop_1(edge);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (edges_1_1 && !edges_1_1.done && (_a = edges_1.return)) _a.call(edges_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return node.children;
    };
    EditorTreeViewComponent.prototype.ngOnChanges = function () {
    };
    return EditorTreeViewComponent;
}());
exports.EditorTreeViewComponent = EditorTreeViewComponent;
