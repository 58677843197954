<div class="overlay-content m-8">

    <div *ngIf="inputData.relationship">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="normal-label">Beziehungstyp</div>

            <button mat-stroked-button [matMenuTriggerFor]="relationshipMenu" style="width: 165px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div></div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div>
                            <img width="40" height="40"
                                [src]="getImagePathForRelationship(inputData.relationship.relationshipType)">
                        </div>

                        <div class="pl-4">
                            {{getLabelForRelationship(inputData.relationship.relationshipType)}}
                        </div>
                    </div>
                    <div></div>
                </div>
            </button>


            <mat-menu #relationshipMenu="matMenu" class="custom">
                <button *ngFor="let p of relationshipPalettes.rows" mat-button fxLayout="column"
                    fxLayoutAlign="space-between center" [matTooltip]="p.label"
                    (click)=onRelationshipTypeSelect(p.type)>
                    <div></div>
                    <div style="width: 150px; height: 40px" fxLayout="row" fxLayoutAlign="start center">
                        <img width="40" height="40" [src]="p.imgPath">
                        <div class="pl-8">
                            {{p.label}}
                        </div>
                    </div>
                    <div></div>
                </button>
            </mat-menu>



            <div *ngIf="inputData.relationship.relationshipType == 'ASSOCIATION'" class="pt-12">
                <div class="normal-label">Kardinalitäten</div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div
                        style="background: blue; color: white; margin: 4px 0 4px 4px; padding: 8px; width: 120px; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                        {{sourceClass.name}}
                    </div>
                    <div>
                        <div style="width: 50px" fxLayout="row" fxLayoutAlign="start center">
                            <div style="width: 25px; text-align: start; padding-left: 4px">
                                {{getCardinalityLabel('source')}}</div>
                            <div style="width: 25px; text-align: end; padding-right: 4px">
                                {{getCardinalityLabel('target')}}</div>
                        </div>
                        <div style="width: 50px; border-top: solid black 1px"></div>
                    </div>
                    <div
                        style="background: blue; color: white; margin: 4px 4px 4px 0; padding: 8px; width: 120px; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                        {{targetClass.name}}
                    </div>

                    <button mat-icon-button [matMenuTriggerFor]="cardMenu" style="width: 25px">
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>


                </div>
                <!-- <div>{{sourceClass.name}} 1 &#x02500;&#x02500;&#x02500; N {{targetClass.name}}</div> -->

            </div>
        </div>
    </div>

    <mat-menu #cardMenu="matMenu">
        <button mat-menu-item (click)="onChangeCardinalities('1:1')">1:1</button>
        <button mat-menu-item (click)="onChangeCardinalities('1:N')">1:N</button>
    </mat-menu>





    <div style="position: absolute; bottom: 8px; left: 8px">
        <button class="primary-button-dark" mat-stroked-button type="button" (click)="onSubmit()">OK</button>
        <button class="secondary-button-dark ml-8" mat-stroked-button type="button"
            (click)="onCancel()">Schließen</button>
    </div>
</div>