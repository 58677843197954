"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./options-overlay-control.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../class-options-overlay-content/class-options-overlay-content.component.ngfactory");
var i3 = require("../class-options-overlay-content/class-options-overlay-content.component");
var i4 = require("../../../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var i5 = require("@angular/platform-browser");
var i6 = require("../relationship-options-overlay-content/relationship-options-overlay-content.component.ngfactory");
var i7 = require("../relationship-options-overlay-content/relationship-options-overlay-content.component");
var i8 = require("@angular/flex-layout/flex");
var i9 = require("@angular/flex-layout/core");
var i10 = require("../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i11 = require("@angular/material/icon");
var i12 = require("@angular/common");
var i13 = require("./options-overlay-control.component");
var styles_ClassOptionsOverlayControlComponent = [i0.styles];
var RenderType_ClassOptionsOverlayControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClassOptionsOverlayControlComponent, data: {} });
exports.RenderType_ClassOptionsOverlayControlComponent = RenderType_ClassOptionsOverlayControlComponent;
function View_ClassOptionsOverlayControlComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "class-options-overlay-content", [], null, [[null, "resultData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resultData" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ClassOptionsOverlayContentComponent_0, i2.RenderType_ClassOptionsOverlayContentComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClassOptionsOverlayContentComponent, [i4.DialogFactoryDirective, i5.DomSanitizer], { inputData: [0, "inputData"] }, { resultData: "resultData" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ClassOptionsOverlayControlComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "relationship-options-overlay-content", [], null, [[null, "resultData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resultData" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_RelationshipOptionsOverlayContentComponent_0, i6.RenderType_RelationshipOptionsOverlayContentComponent)), i1.ɵdid(1, 114688, null, 0, i7.RelationshipOptionsOverlayContentComponent, [], { inputData: [0, "inputData"] }, { resultData: "resultData" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ClassOptionsOverlayControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["overlayDiv", 1]], null, 13, "div", [["class", "mat-elevation-z4 overlay-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "overlay-title-bar"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i8.DefaultLayoutDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutStyleBuilder], i9.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i8.DefaultLayoutAlignDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutAlignStyleBuilder], i9.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "pl-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Einstellungen"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["aria-label", "Schlie\u00DFen"], ["class", "mat-icon notranslate"], ["color", "primary"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeOverlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" close "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassOptionsOverlayControlComponent_2)), i1.ɵdid(11, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassOptionsOverlayControlComponent_3)), i1.ɵdid(13, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 3, 0, currVal_1); var currVal_4 = "primary"; _ck(_v, 8, 0, currVal_4); var currVal_5 = (_co.overlayType == "CLASS"); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.overlayType == "RELATIONSHIP"); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).inline; var currVal_3 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
function View_ClassOptionsOverlayControlComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { overlayDiv: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassOptionsOverlayControlComponent_1)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayOverlay; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_ClassOptionsOverlayControlComponent_0 = View_ClassOptionsOverlayControlComponent_0;
function View_ClassOptionsOverlayControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "class-options-overlay-control", [], null, null, null, View_ClassOptionsOverlayControlComponent_0, RenderType_ClassOptionsOverlayControlComponent)), i1.ɵdid(1, 638976, null, 0, i13.ClassOptionsOverlayControlComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ClassOptionsOverlayControlComponent_Host_0 = View_ClassOptionsOverlayControlComponent_Host_0;
var ClassOptionsOverlayControlComponentNgFactory = i1.ɵccf("class-options-overlay-control", i13.ClassOptionsOverlayControlComponent, View_ClassOptionsOverlayControlComponent_Host_0, { displayOverlay: "displayOverlay", overlayType: "overlayType", overlayContent: "overlayContent", overlayEvent: "overlayEvent" }, { overlayClosed: "overlayClosed" }, []);
exports.ClassOptionsOverlayControlComponentNgFactory = ClassOptionsOverlayControlComponentNgFactory;
