"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var response_service_1 = require("../../../response.service");
var util_1 = require("util");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../../response.service");
var ClassInstanceService = /** @class */ (function () {
    function ClassInstanceService(http, responseService) {
        this.http = http;
        this.responseService = responseService;
    }
    ClassInstanceService.prototype.createNewClassInstances = function (classInstances, redirectUrl) {
        if (util_1.isNullOrUndefined(classInstances) || classInstances.length <= 0) {
            return new Promise(function () { return null; });
        }
        var classInstance = classInstances.pop();
        return this.responseService.sendClassInstanceConfiguratorResponse(redirectUrl, classInstance).toPromise().then(function () {
            return classInstance;
        });
    };
    ClassInstanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassInstanceService_Factory() { return new ClassInstanceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ResponseService)); }, token: ClassInstanceService, providedIn: "root" });
    return ClassInstanceService;
}());
exports.ClassInstanceService = ClassInstanceService;
