"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./class-instance-form-editor.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/expansion");
var i3 = require("./form-container/form-container.component.ngfactory");
var i4 = require("./form-container/form-container.component");
var i5 = require("../../../../_service/dynamic-form-item-control.service");
var i6 = require("@angular/flex-layout/flex");
var i7 = require("@angular/flex-layout/core");
var i8 = require("../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i9 = require("@angular/material/button");
var i10 = require("@angular/cdk/a11y");
var i11 = require("@angular/platform-browser/animations");
var i12 = require("./result/result.component.ngfactory");
var i13 = require("./result/result.component");
var i14 = require("@angular/router");
var i15 = require("../../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var i16 = require("../../../../../../../@fuse/services/config.service");
var i17 = require("@angular/cdk/platform");
var i18 = require("@angular/common");
var i19 = require("../../../../_service/dynamic-form-item.service");
var i20 = require("./class-instance-form-editor.component");
var i21 = require("../../../../_service/meta/core/class/class-definition.service");
var i22 = require("../../../../_service/meta/core/class/class-instance.service");
var i23 = require("../../../../_service/objectid.service.");
var styles_ClassInstanceFormEditorComponent = [i0.styles];
var RenderType_ClassInstanceFormEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClassInstanceFormEditorComponent, data: {} });
exports.RenderType_ClassInstanceFormEditorComponent = RenderType_ClassInstanceFormEditorComponent;
function View_ClassInstanceFormEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "mat-accordion", [["class", "mat-accordion"], ["displayMode", "flat"], ["multi", "true"]], null, null, null, null, null)), i1.ɵprd(6144, null, i2.MAT_ACCORDION, null, [i2.MatAccordion]), i1.ɵdid(4, 1720320, null, 1, i2.MatAccordion, [], { multi: [0, "multi"], displayMode: [1, "displayMode"] }, null), i1.ɵqud(603979776, 2, { _headers: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-form-container", [["expanded", "true"]], null, [[null, "result"], [null, "tupleSelected"], [null, "errorEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("result" === en)) {
        var pd_0 = (_co.handleResultEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("tupleSelected" === en)) {
        var pd_1 = (_co.handleTupleSelection($event) !== false);
        ad = (pd_1 && ad);
    } if (("errorEvent" === en)) {
        var pd_2 = (_co.handleErrorEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_FormContainerComponent_0, i3.RenderType_FormContainerComponent)), i1.ɵdid(7, 114688, null, 0, i4.FormContainerComponent, [i5.DynamicFormItemControlService], { formEntry: [0, "formEntry"], formConfiguration: [1, "formConfiguration"], finishClicked: [2, "finishClicked"], expanded: [3, "expanded"] }, { result: "result", tupleSelected: "tupleSelected", errorEvent: "errorEvent" }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "pt-12 divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["class", "mt-20 mb-20"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(10, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(11, 671744, null, 0, i6.DefaultLayoutAlignDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutAlignStyleBuilder], i7.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(14, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "primary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleFinishClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, [" Fertig "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "pr-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "secondary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Abbrechen "])), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "true"; var currVal_1 = "flat"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.currentFormConfiguration.formEntry; var currVal_3 = _co.currentFormConfiguration; var currVal_4 = _co.finishClicked; var currVal_5 = "true"; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "row"; _ck(_v, 10, 0, currVal_6); var currVal_7 = "space-between center"; _ck(_v, 11, 0, currVal_7); var currVal_8 = "row"; _ck(_v, 14, 0, currVal_8); var currVal_11 = !_co.canSubmitForm; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var currVal_9 = (i1.ɵnov(_v, 16).disabled || null); var currVal_10 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_9, currVal_10); var currVal_12 = (i1.ɵnov(_v, 20).disabled || null); var currVal_13 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_12, currVal_13); }); }
function View_ClassInstanceFormEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-instance-creation-result", [], null, [[null, "navigateBack"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("navigateBack" === en)) {
        var pd_0 = (_co.handleCancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_InstanceCreationResultComponent_0, i12.RenderType_InstanceCreationResultComponent)), i1.ɵdid(2, 114688, null, 0, i13.InstanceCreationResultComponent, [i14.Router, i14.ActivatedRoute], { resultClassInstance: [0, "resultClassInstance"] }, { navigateBack: "navigateBack" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultClassInstance; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ClassInstanceFormEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["contentDiv", 1]], null, 5, "div", [["class", "page-layout blank p-20 pl-0"], ["fusePerfectScrollbar", ""], ["id", "contentDiv"]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i15.FusePerfectScrollbarDirective, [i1.ElementRef, i16.FuseConfigService, i17.Platform], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassInstanceFormEditorComponent_2)), i1.ɵdid(3, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassInstanceFormEditorComponent_3)), i1.ɵdid(5, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = !_co.showResultPage; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showResultPage; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ClassInstanceFormEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { contentDiv: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClassInstanceFormEditorComponent_1)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_ClassInstanceFormEditorComponent_0 = View_ClassInstanceFormEditorComponent_0;
function View_ClassInstanceFormEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-class-instance-form-editor", [], null, null, null, View_ClassInstanceFormEditorComponent_0, RenderType_ClassInstanceFormEditorComponent)), i1.ɵprd(512, null, i19.DynamicFormItemService, i19.DynamicFormItemService, []), i1.ɵprd(512, null, i5.DynamicFormItemControlService, i5.DynamicFormItemControlService, []), i1.ɵdid(3, 114688, null, 0, i20.ClassInstanceFormEditorComponent, [i14.Router, i14.ActivatedRoute, i21.ClassDefinitionService, i22.ClassInstanceService, i19.DynamicFormItemService, i5.DynamicFormItemControlService, i23.ObjectIdService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
exports.View_ClassInstanceFormEditorComponent_Host_0 = View_ClassInstanceFormEditorComponent_Host_0;
var ClassInstanceFormEditorComponentNgFactory = i1.ɵccf("app-class-instance-form-editor", i20.ClassInstanceFormEditorComponent, View_ClassInstanceFormEditorComponent_Host_0, {}, {}, []);
exports.ClassInstanceFormEditorComponentNgFactory = ClassInstanceFormEditorComponentNgFactory;
