"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var DynamicFormItemBase = /** @class */ (function () {
    function DynamicFormItemBase(options) {
        if (options === void 0) { options = {}; }
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.values = options.values || undefined;
        this.validators = options.validators || undefined;
        this.required = options.required || false;
        this.messages = options.messages || undefined;
        this.subItems = options.subItems || undefined;
    }
    return DynamicFormItemBase;
}());
exports.DynamicFormItemBase = DynamicFormItemBase;
var TextboxFormItem = /** @class */ (function (_super) {
    __extends(TextboxFormItem, _super);
    function TextboxFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'textbox';
        _this.type = options['type'] || '';
        return _this;
    }
    return TextboxFormItem;
}(DynamicFormItemBase));
exports.TextboxFormItem = TextboxFormItem;
var TextAreaFormItem = /** @class */ (function (_super) {
    __extends(TextAreaFormItem, _super);
    function TextAreaFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'textarea';
        _this.type = options['type'] || '';
        return _this;
    }
    return TextAreaFormItem;
}(DynamicFormItemBase));
exports.TextAreaFormItem = TextAreaFormItem;
var NumberBoxFormItem = /** @class */ (function (_super) {
    __extends(NumberBoxFormItem, _super);
    function NumberBoxFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'numberbox';
        _this.type = 'number';
        _this.type = options['type'] || '';
        return _this;
    }
    return NumberBoxFormItem;
}(DynamicFormItemBase));
exports.NumberBoxFormItem = NumberBoxFormItem;
var DropdownFormItem = /** @class */ (function (_super) {
    __extends(DropdownFormItem, _super);
    function DropdownFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'dropdown';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return DropdownFormItem;
}(DynamicFormItemBase));
exports.DropdownFormItem = DropdownFormItem;
var NumberDropdownFormItem = /** @class */ (function (_super) {
    __extends(NumberDropdownFormItem, _super);
    function NumberDropdownFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'numberdropdown';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return NumberDropdownFormItem;
}(DynamicFormItemBase));
exports.NumberDropdownFormItem = NumberDropdownFormItem;
var RadioButtonFormItem = /** @class */ (function (_super) {
    __extends(RadioButtonFormItem, _super);
    function RadioButtonFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'radiobutton';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return RadioButtonFormItem;
}(DynamicFormItemBase));
exports.RadioButtonFormItem = RadioButtonFormItem;
var DatepickerFormItem = /** @class */ (function (_super) {
    __extends(DatepickerFormItem, _super);
    function DatepickerFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'datepicker';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return DatepickerFormItem;
}(DynamicFormItemBase));
exports.DatepickerFormItem = DatepickerFormItem;
var SliderFormItem = /** @class */ (function (_super) {
    __extends(SliderFormItem, _super);
    function SliderFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'slider';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return SliderFormItem;
}(DynamicFormItemBase));
exports.SliderFormItem = SliderFormItem;
var SlideToggleFormItem = /** @class */ (function (_super) {
    __extends(SlideToggleFormItem, _super);
    function SlideToggleFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'slidetoggle';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return SlideToggleFormItem;
}(DynamicFormItemBase));
exports.SlideToggleFormItem = SlideToggleFormItem;
var DropdownMultipleFormItem = /** @class */ (function (_super) {
    __extends(DropdownMultipleFormItem, _super);
    function DropdownMultipleFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'dropdown-multiple';
        _this.options = [];
        _this.values = [];
        _this.options = options['options'] || [];
        _this.values = options['values'] || [];
        return _this;
    }
    return DropdownMultipleFormItem;
}(DynamicFormItemBase));
exports.DropdownMultipleFormItem = DropdownMultipleFormItem;
var MultipleFormItem = /** @class */ (function (_super) {
    __extends(MultipleFormItem, _super);
    // values: {key: string, value: string}[] = [];
    // subQuestions: DynamicFormItemBase<any>[] = [];
    function MultipleFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'multiple';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
        // this.values = options['values'] || [];
        // this.subQuestions = options['subQuestions'] || [];
    }
    return MultipleFormItem;
}(DynamicFormItemBase));
exports.MultipleFormItem = MultipleFormItem;
var GenericFormItem = /** @class */ (function (_super) {
    __extends(GenericFormItem, _super);
    function GenericFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'generic';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return GenericFormItem;
}(DynamicFormItemBase));
exports.GenericFormItem = GenericFormItem;
var SingleSelectionTreeFormItem = /** @class */ (function (_super) {
    __extends(SingleSelectionTreeFormItem, _super);
    // +++ TODO
    function SingleSelectionTreeFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'tree-single-select';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return SingleSelectionTreeFormItem;
}(DynamicFormItemBase));
exports.SingleSelectionTreeFormItem = SingleSelectionTreeFormItem;
var MultipleSelectionTreeFormItem = /** @class */ (function (_super) {
    __extends(MultipleSelectionTreeFormItem, _super);
    // +++ TODO
    function MultipleSelectionTreeFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'tree-multiple-select';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return MultipleSelectionTreeFormItem;
}(DynamicFormItemBase));
exports.MultipleSelectionTreeFormItem = MultipleSelectionTreeFormItem;
var TupleDropdownFormItem = /** @class */ (function (_super) {
    __extends(TupleDropdownFormItem, _super);
    function TupleDropdownFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'tuple';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return TupleDropdownFormItem;
}(DynamicFormItemBase));
exports.TupleDropdownFormItem = TupleDropdownFormItem;
var LocationFormItem = /** @class */ (function (_super) {
    __extends(LocationFormItem, _super);
    function LocationFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'location';
        return _this;
    }
    return LocationFormItem;
}(DynamicFormItemBase));
exports.LocationFormItem = LocationFormItem;
var ComputedFormItem = /** @class */ (function (_super) {
    __extends(ComputedFormItem, _super);
    function ComputedFormItem(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'computed';
        _this.returnType = options['returnType'] || [];
        return _this;
    }
    return ComputedFormItem;
}(DynamicFormItemBase));
exports.ComputedFormItem = ComputedFormItem;
