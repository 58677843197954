"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var property_build_form_component_1 = require("./property-build-form.component");
var routes = [
    // { path: ':marketplaceId/:entryId', component: PropertyBuildFormComponent },
    { path: '', component: property_build_form_component_1.PropertyBuildFormComponent },
    { path: ':entryId', component: property_build_form_component_1.PropertyBuildFormComponent },
];
var PropertyBuildFormModule = /** @class */ (function () {
    function PropertyBuildFormModule() {
    }
    return PropertyBuildFormModule;
}());
exports.PropertyBuildFormModule = PropertyBuildFormModule;
