"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var invalid_parameters_component_1 = require("./invalid-parameters.component");
var router_1 = require("@angular/router");
var routes = [{ path: '', component: invalid_parameters_component_1.InvalidParametersComponent }];
var InvalidParametersModule = /** @class */ (function () {
    function InvalidParametersModule() {
    }
    return InvalidParametersModule;
}());
exports.InvalidParametersModule = InvalidParametersModule;
