import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'invalid-parameters.component.html',
})
export class InvalidParametersComponent implements OnInit {

  ngOnInit() {

  }

}
