"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["fuse-sidebar{display:flex;flex-direction:column;flex:1 0 auto;position:absolute;top:0;bottom:0;overflow-x:hidden;overflow-y:auto;width:280px;min-width:280px;max-width:280px;z-index:1000;transition-property:transform,width,min-width,max-width;transition-duration:150ms;transition-timing-function:ease-in-out;box-shadow:0 2px 8px 0 rgba(0,0,0,.35)}fuse-sidebar.left-aligned{left:0;transform:translateX(-100%)}fuse-sidebar.right-aligned{right:0;transform:translateX(100%)}fuse-sidebar.open{transform:translateX(0)}fuse-sidebar.locked-open{position:relative!important;transform:translateX(0)!important}fuse-sidebar.folded{position:absolute!important;top:0;bottom:0}fuse-sidebar.folded:not(.unfolded){width:64px;min-width:64px;max-width:64px}.fuse-sidebar-overlay{position:absolute;top:0;bottom:0;left:0;right:0;z-index:3;background-color:rgba(0,0,0,.6);opacity:0}"];
exports.styles = styles;
