<div mat-dialog-title>
  <table>
    <tr>
      <td>
        <mat-icon>delete</mat-icon>
      </td>
      <td>
        <h1>Property Entfernen</h1>
      </td>
    </tr>
  </table>
</div>

<mat-dialog-content>
  <div *ngIf="loaded">
    <mat-table #propertyTable class="property-table" [dataSource]="datasource" matSort fusePerfectScrollbar>
      <ng-container matColumnDef="checkbox">

        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [disabled]="isDisabled(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="label">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let entry">
          <div fxLayout="column" fxLayoutAlign="start start">
            <div>{{ entry.name }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Datentyp</mat-header-cell>
        <mat-cell *matCellDef="let entry">
          <div>
            {{ entry.type }}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></mat-row>
    </mat-table>

    <mat-form-field appearance="outline" class="search-field">
      <input matInput (keyup)="applyFilter($event)" placeholder="Suchen..." />
    </mat-form-field>
  </div>

  <div *ngIf="!loaded" class="center-spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="loaded">
  <button class="primary-button-dark" mat-stroked-button type="button" (click)="onSubmit()">OK</button>
  <button class="secondary-button-dark" mat-stroked-button type="button"
    [mat-dialog-close]="undefined">Abbrechen</button>
</mat-dialog-actions>