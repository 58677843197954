"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var item_1 = require("../../../../_model/dynamic-forms/item");
var util_1 = require("util");
var DynamicFormItemComponent = /** @class */ (function () {
    function DynamicFormItemComponent() {
        this.tupleSelected = new core_1.EventEmitter();
    }
    Object.defineProperty(DynamicFormItemComponent.prototype, "isValid", {
        get: function () {
            return this.form.controls[this.formItem.key].valid;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormItemComponent.prototype.ngOnInit = function () {
        if (this.formItem.required) {
            this.requiredMarker = '*';
        }
        this.expanded = false;
    };
    DynamicFormItemComponent.prototype.calculateSpaces = function (level) {
        level = 10 * level;
        return level + 'px';
    };
    DynamicFormItemComponent.prototype.displayErrorMessage = function () {
        return this.form.controls[this.formItem.key].hasError('required') ? this.getErrorMessage('required') :
            this.form.controls[this.formItem.key].hasError('requiredtrue') ? this.getErrorMessage('requiredtrue') :
                this.form.controls[this.formItem.key].hasError('pattern') ? this.getErrorMessage('pattern') :
                    this.form.controls[this.formItem.key].hasError('minlength') ? this.getErrorMessage('minlength') :
                        this.form.controls[this.formItem.key].hasError('maxlength') ? this.getErrorMessage('maxlength') :
                            this.form.controls[this.formItem.key].hasError('max') ? this.getErrorMessage('max') :
                                this.form.controls[this.formItem.key].hasError('min') ? this.getErrorMessage('min') :
                                    this.form.controls[this.formItem.key].hasError('mindate') ? this.getErrorMessage('mindate') :
                                        this.form.controls[this.formItem.key].hasError('requiredother') ? this.getErrorMessage('requiredother') :
                                            this.form.controls[this.formItem.key].hasError('maxother') ? this.getErrorMessage('maxother') :
                                                this.form.controls[this.formItem.key].hasError('minother') ? this.getErrorMessage('minother') :
                                                    '';
    };
    DynamicFormItemComponent.prototype.getRemainingLength = function (errorName) {
        return this.getRequiredLength(errorName) - this.getActualLength(errorName);
    };
    DynamicFormItemComponent.prototype.getRequiredLength = function (errorName) {
        return this.form.controls[this.formItem.key].getError(errorName).requiredLength;
    };
    DynamicFormItemComponent.prototype.getActualLength = function (errorName) {
        return this.form.controls[this.formItem.key].getError(errorName).actualLength;
    };
    DynamicFormItemComponent.prototype.getPattern = function () {
        return this.form.controls[this.formItem.key].getError('pattern').requiredPattern;
    };
    DynamicFormItemComponent.prototype.getErrorMessage = function (errorName) {
        if (util_1.isNullOrUndefined(this.formItem.messages)) {
            return this.getStandardMessage(errorName);
        }
        var msg = this.formItem.messages.get(errorName);
        if (!util_1.isNullOrUndefined(msg)) {
            return msg;
        }
        else {
            return this.getStandardMessage(errorName);
        }
    };
    DynamicFormItemComponent.prototype.getStandardMessage = function (errorName) {
        switch (errorName) {
            case 'required':
                return 'You must enter a value';
            case 'requiredtrue':
                return this.formItem.label + ' has to be true';
            case 'pattern':
                return 'Not a valid ' + this.formItem.label + ' -- Requried Pattern: ' + this.getPattern();
            case 'minlength':
                return 'String not long enough - minimum length: ' + this.getRequiredLength('minlength') + ' characters required: ' + this.getRemainingLength('minlength');
            case 'maxlength':
                return 'String too long - maximum length: ' + this.getRequiredLength('maxlength') + 'characters to remove: ' + (this.getRemainingLength('maxlength') * -1);
            case 'min':
                return 'Value below minimum';
            case 'max':
                return 'Value exceeds maximum';
            case 'mindate':
                return 'Invalid Date';
            case 'requiredother':
                return "Field '" + this.getFormItemLabel(this.form.controls[this.formItem.key].getError('requiredother').keyThis) +
                    "' requires '" + this.getFormItemLabel(this.form.controls[this.formItem.key].getError('requiredother').keyOther) + "' to be filled in";
            case 'maxother':
                return "Value " + this.form.controls[this.formItem.key].getError('maxother').valueOther +
                    " in Field '" + this.getFormItemLabel(this.form.controls[this.formItem.key].getError('maxother').keyOther) +
                    "' exceeds " + this.form.controls[this.formItem.key].getError('maxother').valueThis +
                    " in Field '" + this.getFormItemLabel(this.form.controls[this.formItem.key].getError('maxother').keyThis) +
                    "' ";
            case 'minother':
                return "Value " + this.form.controls[this.formItem.key].getError('minother').valueOther +
                    " in Field '" + this.getFormItemLabel(this.form.controls[this.formItem.key].getError('minother').keyOther) +
                    "' is below " + this.form.controls[this.formItem.key].getError('minother').valueThis +
                    " in Field '" + this.getFormItemLabel(this.form.controls[this.formItem.key].getError('minother').keyThis) +
                    "' ";
            default:
                return '';
        }
    };
    DynamicFormItemComponent.prototype.getNestedFormGroups = function () {
        return this.form.get(this.formItem.key);
    };
    DynamicFormItemComponent.prototype.getFormItemLabel = function (key) {
        var ret = this.formItem.subItems.find(function (q) {
            return q.key === key;
        }).label;
        return ret;
    };
    DynamicFormItemComponent.prototype.handleTupleSelection = function (opt) {
        this.tupleSelected.emit({ selection: opt, formGroup: this.form });
    };
    return DynamicFormItemComponent;
}());
exports.DynamicFormItemComponent = DynamicFormItemComponent;
