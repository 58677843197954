"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var material_1 = require("@angular/material");
var flat_property_definition_service_1 = require("app/main/content/_service/meta/core/property/flat-property-definition.service");
var collections_1 = require("@angular/cdk/collections");
var util_1 = require("util");
var relationship_1 = require("app/main/content/_model/configurator/relationship");
var tree_property_definition_service_1 = require("app/main/content/_service/meta/core/property/tree-property-definition.service");
var class_property_service_1 = require("app/main/content/_service/meta/core/property/class-property.service");
var property_creation_dialog_component_1 = require("../../../configuration/class-configurator/_dialogs/property-creation-dialog/property-creation-dialog.component");
var AddPropertyDialogComponent = /** @class */ (function () {
    function AddPropertyDialogComponent(dialogRef, data, flatPropertyDefinitionService, classPropertyService, treePropertyDefinitionService, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.flatPropertyDefinitionService = flatPropertyDefinitionService;
        this.classPropertyService = classPropertyService;
        this.treePropertyDefinitionService = treePropertyDefinitionService;
        this.dialog = dialog;
        this.flatPropertyDataSource = new material_1.MatTableDataSource();
        this.treePropertyDataSource = new material_1.MatTableDataSource();
        this.displayedColumns = ['checkbox', 'label', 'type'];
        this.flatPropertySelection = new collections_1.SelectionModel(true, []);
        this.treePropertySelection = new collections_1.SelectionModel(true, []);
    }
    AddPropertyDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.tabIndex = 0;
                Promise.all([
                    this.flatPropertyDefinitionService.getAllPropertyDefinitonsForTenant(this.data.tenantId)
                        .toPromise()
                        .then(function (ret) {
                        var _a, _b;
                        _this.flatPropertyDataSource.data = ret;
                        _this.allFlatPropertyDefinitions = ret;
                        _this.initialFlatPropertyDefinitions = ret.filter(function (p) {
                            return _this.data.classDefinition.properties.find(function (q) { return q.id === p.id; });
                        });
                        var parentClassProperties = _this.findParentProperties();
                        var parentProperties = ret.filter(function (p) {
                            return parentClassProperties.find(function (q) { return q.id === p.id; });
                        });
                        _this.disabledFlatPropertyDefinitions = [];
                        (_a = _this.disabledFlatPropertyDefinitions).push.apply(_a, __spread(_this.initialFlatPropertyDefinitions, parentProperties));
                        (_b = _this.flatPropertySelection).select.apply(_b, __spread(_this.initialFlatPropertyDefinitions));
                    }),
                    this.treePropertyDefinitionService
                        .getAllPropertyDefinitionsForTenant(this.data.tenantId)
                        .toPromise()
                        .then(function (ret) {
                        var _a, _b;
                        _this.treePropertyDataSource.data = ret;
                        _this.allTreePropertyDefinitions = ret;
                        _this.initialTreePropertyDefinitions = ret.filter(function (e) {
                            return _this.data.classDefinition.properties.find(function (f) { return f.id === e.id; });
                        });
                        _this.disabledTreePropertyDefinitions = [];
                        (_a = _this.disabledTreePropertyDefinitions).push.apply(_a, __spread(_this.initialTreePropertyDefinitions));
                        (_b = _this.treePropertySelection).select.apply(_b, __spread(_this.initialTreePropertyDefinitions));
                    }),
                ]).then(function () {
                    _this.loaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    AddPropertyDialogComponent.prototype.findParentProperties = function () {
        var currentClassDefinition = this.data.classDefinition;
        var parentProperties = [];
        var _loop_1 = function () {
            var relationship = this_1.data.allRelationships.find(function (r) { return r.target === currentClassDefinition.id; });
            if (util_1.isNullOrUndefined(relationship) || relationship.relationshipType === relationship_1.RelationshipType.ASSOCIATION) {
                return "break";
            }
            currentClassDefinition = this_1.data.allClassDefinitions.find(function (cd) { return cd.id === relationship.source; });
            parentProperties.push.apply(parentProperties, __spread(currentClassDefinition.properties));
        };
        var this_1 = this;
        do {
            var state_1 = _loop_1();
            if (state_1 === "break")
                break;
        } while (!currentClassDefinition.root);
        return parentProperties;
    };
    AddPropertyDialogComponent.prototype.isFlatPropertyRowDisabled = function (flatPropertyDefinition) {
        return !util_1.isNullOrUndefined(this.disabledFlatPropertyDefinitions.find(function (p) { return p.id === flatPropertyDefinition.id; }));
    };
    AddPropertyDialogComponent.prototype.isTreePropertyRowDisabled = function (treePropertyDefinition) {
        return !util_1.isNullOrUndefined(this.disabledTreePropertyDefinitions.find(function (p) { return p.id === treePropertyDefinition.id; }));
    };
    AddPropertyDialogComponent.prototype.applyFlatPropertyFilter = function (event) {
        var filterValue = event.target.value;
        this.flatPropertyDataSource.filter = filterValue.trim().toLowerCase();
    };
    AddPropertyDialogComponent.prototype.applyTreePropertyFilter = function (event) {
        var filterValue = event.target.value;
        this.treePropertyDataSource.filter = filterValue.trim().toLowerCase();
    };
    AddPropertyDialogComponent.prototype.onFlatRowClick = function (row) {
        if (this.isFlatPropertyRowDisabled(row)) {
            return;
        }
        this.flatPropertySelection.isSelected(row) ? this.flatPropertySelection.deselect(row) : this.flatPropertySelection.select(row);
    };
    AddPropertyDialogComponent.prototype.onTreeRowClick = function (row) {
        if (this.isTreePropertyRowDisabled(row)) {
            return;
        }
        this.treePropertySelection.isSelected(row) ? this.treePropertySelection.deselect(row)
            : this.treePropertySelection.select(row);
    };
    AddPropertyDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        // Add selected, but filter out existing ones
        var addedFlatProperties = this.flatPropertySelection.selected.filter(function (p) {
            return _this.data.classDefinition.properties.findIndex(function (q) { return p.id === q.id; }) ===
                -1;
        });
        var addedTreeProperties = this.treePropertySelection
            .selected.filter(function (e) {
            return _this.data.classDefinition.properties.findIndex(function (q) { return e.id === q.id; }) ===
                -1;
        });
        this.classPropertyService
            .getClassPropertyFromDefinitionById(addedFlatProperties.map(function (p) { return p.id; }), addedTreeProperties.map(function (e) { return e.id; }))
            .toPromise()
            .then(function (ret) {
            var _a;
            (_a = _this.data.classDefinition.properties).push.apply(_a, __spread(ret));
            _this.dialogRef.close(_this.data);
        });
    };
    AddPropertyDialogComponent.prototype.createNewClicked = function (type) {
        var _this = this;
        var dialogRef = this.dialog.open(property_creation_dialog_component_1.PropertyCreationDialogComponent, {
            width: '70vw',
            minWidth: '70vw',
            height: '90vh',
            minHeight: '90vh',
            data: {
                allFlatPropertyDefinitions: this.flatPropertyDataSource.data,
                builderType: type,
                tenantId: this.data.tenantId,
            },
            disableClose: true,
        });
        dialogRef.beforeClose().toPromise()
            .then(function (result) {
            if (!util_1.isNullOrUndefined(result)) {
                if (!util_1.isNullOrUndefined(result.flatPropertyDefinition)) {
                    _this.allFlatPropertyDefinitions.push(result.flatPropertyDefinition);
                    _this.flatPropertyDataSource.data = _this.allFlatPropertyDefinitions;
                }
                else if (!util_1.isNullOrUndefined(result.treePropertyDefinition)) {
                    _this.allTreePropertyDefinitions.push(result.treePropertyDefinition);
                    _this.treePropertyDataSource.data = _this.allTreePropertyDefinitions;
                }
            }
        });
    };
    return AddPropertyDialogComponent;
}());
exports.AddPropertyDialogComponent = AddPropertyDialogComponent;
