"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-dialog-title[_ngcontent-%COMP%]{margin:0}.mat-dialog-title[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{padding:0 0 6px;margin:0}mat-dialog-content[_ngcontent-%COMP%]{height:calc(100% - 52px - 20px);overflow:scroll;max-height:none}mat-dialog-content[_ngcontent-%COMP%]   div#graphContainer[_ngcontent-%COMP%]{width:100%;height:100%;border:2px solid #000;border-radius:4px}"];
exports.styles = styles;
