"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var config_service_1 = require("@fuse/services/config.service");
var FuseModule = /** @class */ (function () {
    function FuseModule(parentModule) {
        if (parentModule) {
            throw new Error('FuseModule is already loaded. Import it in the AppModule only!');
        }
    }
    FuseModule.forRoot = function (config) {
        return {
            ngModule: FuseModule,
            providers: [
                {
                    provide: config_service_1.FUSE_CONFIG,
                    useValue: config
                }
            ]
        };
    };
    return FuseModule;
}());
exports.FuseModule = FuseModule;
