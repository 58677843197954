"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var util_1 = require("util");
var PropertyCreationDialogComponent = /** @class */ (function () {
    function PropertyCreationDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.loaded = false;
    }
    PropertyCreationDialogComponent.prototype.ngOnInit = function () {
        this.loaded = true;
    };
    PropertyCreationDialogComponent.prototype.handleResultEvent = function (event) {
        if (util_1.isNullOrUndefined(event)) {
            this.handleCloseClick();
        }
        else {
            if (event.builderType === 'tree') {
                this.data.treePropertyDefinition = event.value;
            }
            else if (event.builderType === 'flat') {
                this.data.flatPropertyDefinition = event.value;
            }
            this.dialogRef.close(this.data);
        }
    };
    PropertyCreationDialogComponent.prototype.handleCloseClick = function () {
        this.dialogRef.close();
    };
    return PropertyCreationDialogComponent;
}());
exports.PropertyCreationDialogComponent = PropertyCreationDialogComponent;
