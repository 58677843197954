"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var configurator_component_1 = require("./configurator.component");
var routes = [
    { path: '', component: configurator_component_1.ConfiguratorComponent }
];
var ConfiguratorModule = /** @class */ (function () {
    function ConfiguratorModule() {
    }
    return ConfiguratorModule;
}());
exports.ConfiguratorModule = ConfiguratorModule;
