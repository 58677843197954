"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var MatchingConfigurationService = /** @class */ (function () {
    function MatchingConfigurationService(http) {
        this.http = http;
    }
    MatchingConfigurationService.prototype.getAllMatchingConfigurations = function () {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/all");
    };
    MatchingConfigurationService.prototype.getAllMatchingConfigurationsByTenantId = function (tenantId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/all/tenant/" + tenantId);
    };
    MatchingConfigurationService.prototype.getOneMatchingConfiguration = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/" + id);
    };
    MatchingConfigurationService.prototype.getMatchingConfigurationByClassConfigurationIds = function (leftClassConfiguratorId, rightClassConfiguratorId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/by-class-configurators/" + leftClassConfiguratorId + "/" + rightClassConfiguratorId);
    };
    MatchingConfigurationService.prototype.getMatchingConfigurationByUnorderedClassConfigurationIds = function (configuratorId1, configuratorId2) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/by-class-configurators/" + configuratorId1 + "/" + configuratorId2 + "/unordered");
    };
    MatchingConfigurationService.prototype.saveMatchingConfiguration = function (matchingConfiguration) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/save", matchingConfiguration);
    };
    MatchingConfigurationService.prototype.saveMatchingConfigurationMeta = function (id, name) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/save-meta", [id, name]);
    };
    MatchingConfigurationService.prototype.deleteMatchingConfiguration = function (id) {
        return this.http.delete(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/" + id + "/delete");
    };
    MatchingConfigurationService.prototype.deleteMatchingConfigurations = function (ids) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/matching-configuration/delete-multiple", ids);
    };
    MatchingConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatchingConfigurationService_Factory() { return new MatchingConfigurationService(i0.ɵɵinject(i1.HttpClient)); }, token: MatchingConfigurationService, providedIn: "root" });
    return MatchingConfigurationService;
}());
exports.MatchingConfigurationService = MatchingConfigurationService;
