"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var ObjectIdService = /** @class */ (function () {
    function ObjectIdService() {
    }
    ObjectIdService.prototype.getNewObjectId = function () {
        var _this = this;
        return this.toHex(Date.now() / 1000) +
            ' '.repeat(16).replace(/./g, function () { return _this.toHex(Math.random() * 16); });
    };
    ObjectIdService.prototype.toHex = function (value) {
        return Math.floor(value).toString(16);
    };
    ObjectIdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectIdService_Factory() { return new ObjectIdService(); }, token: ObjectIdService, providedIn: "root" });
    return ObjectIdService;
}());
exports.ObjectIdService = ObjectIdService;
