"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ResponseService = /** @class */ (function () {
    function ResponseService(http) {
        this.http = http;
    }
    ResponseService.prototype.sendClassConfiguratorResponse = function (url, saveRequest, idsToDelete, action, actionContext) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/send-response/class-configurator", { url: url, saveRequest: saveRequest, idsToDelete: idsToDelete, action: action, actionContext: actionContext });
    };
    ResponseService.prototype.sendClassInstanceConfiguratorResponse = function (url, classInstance) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/send-response/class-instance-configurator", { url: url, classInstance: classInstance });
    };
    ResponseService.prototype.sendMatchingConfiguratorResponse = function (url, saveRequest, idsToDelete, action) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/send-response/matching-configurator", { url: url, saveRequest: saveRequest, idsToDelete: idsToDelete, action: action });
    };
    ResponseService.prototype.sendPropertyConfiguratorResponse = function (url, flatPropertyDefinitions, treePropertyDefinitions, action) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/send-response/property-configurator", { url: url, flatPropertyDefinitions: flatPropertyDefinitions, treePropertyDefinitions: treePropertyDefinitions, action: action });
    };
    ResponseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResponseService_Factory() { return new ResponseService(i0.ɵɵinject(i1.HttpClient)); }, token: ResponseService, providedIn: "root" });
    return ResponseService;
}());
exports.ResponseService = ResponseService;
