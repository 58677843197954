"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var MatchingEntityDataService = /** @class */ (function () {
    function MatchingEntityDataService(http) {
        this.http = http;
    }
    MatchingEntityDataService.prototype.getMatchingData = function (matchingConfiguration) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/matching-entity-data/", matchingConfiguration);
    };
    MatchingEntityDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatchingEntityDataService_Factory() { return new MatchingEntityDataService(i0.ɵɵinject(i1.HttpClient)); }, token: MatchingEntityDataService, providedIn: "root" });
    return MatchingEntityDataService;
}());
exports.MatchingEntityDataService = MatchingEntityDataService;
