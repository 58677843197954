"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Relationship = /** @class */ (function () {
    function Relationship() {
    }
    return Relationship;
}());
exports.Relationship = Relationship;
var RelationshipType;
(function (RelationshipType) {
    RelationshipType["INHERITANCE"] = "INHERITANCE";
    RelationshipType["ASSOCIATION"] = "ASSOCIATION";
    RelationshipType["AGGREGATION"] = "AGGREGATION";
})(RelationshipType = exports.RelationshipType || (exports.RelationshipType = {}));
(function (RelationshipType) {
    function getLabelFromRelationshipType(relationshipType) {
        switch (relationshipType) {
            case RelationshipType.INHERITANCE: return 'Vererbung';
            case RelationshipType.ASSOCIATION: return 'Assoziation';
            case RelationshipType.AGGREGATION: return 'Aggreation';
        }
    }
    RelationshipType.getLabelFromRelationshipType = getLabelFromRelationshipType;
})(RelationshipType = exports.RelationshipType || (exports.RelationshipType = {}));
var AssociationCardinality;
(function (AssociationCardinality) {
    AssociationCardinality["NONE"] = "";
    AssociationCardinality["ONE"] = "ONE";
    AssociationCardinality["N"] = "N";
})(AssociationCardinality = exports.AssociationCardinality || (exports.AssociationCardinality = {}));
(function (AssociationCardinality) {
    function getLabelForAssociationCardinality(cardinality) {
        return cardinality === AssociationCardinality.ONE ? '1' :
            cardinality === AssociationCardinality.N ? 'N' : '';
    }
    AssociationCardinality.getLabelForAssociationCardinality = getLabelForAssociationCardinality;
})(AssociationCardinality = exports.AssociationCardinality || (exports.AssociationCardinality = {}));
