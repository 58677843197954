"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var matching_configuration_service_1 = require("app/main/content/_service/configuration/matching-configuration.service");
var browse_sub_dialog_component_1 = require("app/main/content/_components/configuration/matching-configurator/_dialogs/browse-sub-dialog/browse-sub-dialog.component");
var OpenMatchingDialogComponent = /** @class */ (function () {
    function OpenMatchingDialogComponent(dialogRef, data, matchingConfigurationService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.matchingConfigurationService = matchingConfigurationService;
    }
    OpenMatchingDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.matchingConfigurationService
                    .getAllMatchingConfigurationsByTenantId(this.data.tenantId)
                    .toPromise().then(function (matchingConfigurations) {
                    _this.recentMatchingConfigurations = matchingConfigurations;
                    _this.allMatchingConfigurations = matchingConfigurations;
                    // ----DEBUG
                    // this.recentMatchingConfigurations.push(...this.recentMatchingConfigurations);
                    // this.recentMatchingConfigurations.push(...this.recentMatchingConfigurations);
                    // ----
                    _this.recentMatchingConfigurations = _this.recentMatchingConfigurations.sort(function (a, b) { return b.timestamp.valueOf() - a.timestamp.valueOf(); });
                    if (_this.recentMatchingConfigurations.length > 5) {
                        _this.recentMatchingConfigurations = _this.recentMatchingConfigurations.slice(0, 5);
                    }
                    _this.loaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    OpenMatchingDialogComponent.prototype.handleRowClick = function (matchingConfiguration) {
        this.data.matchingConfiguration = matchingConfiguration;
        this.dialogRef.close(this.data);
    };
    OpenMatchingDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    OpenMatchingDialogComponent.prototype.handleBrowseClick = function () {
        var e_1, _a;
        this.browseDialogData = new browse_sub_dialog_component_1.MatchingBrowseSubDialogData();
        this.browseDialogData.title = 'Durchsuchen';
        this.browseDialogData.entries = [];
        try {
            for (var _b = __values(this.allMatchingConfigurations), _c = _b.next(); !_c.done; _c = _b.next()) {
                var matchingConfiguration = _c.value;
                this.browseDialogData.entries.push({
                    id: matchingConfiguration.id,
                    name: matchingConfiguration.name,
                    leftMatchingEntity: matchingConfiguration.leftSideName,
                    rightMatchingEntity: matchingConfiguration.rightSideName,
                    date: matchingConfiguration.timestamp,
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.browseMode = true;
    };
    OpenMatchingDialogComponent.prototype.handleBrowseBackClick = function () {
        this.browseMode = false;
    };
    OpenMatchingDialogComponent.prototype.handleReturnFromBrowse = function (event) {
        this.browseMode = false;
        if (!event.cancelled) {
            this.data.matchingConfiguration = this.allMatchingConfigurations.find(function (c) { return c.id === event.entryId; });
            this.dialogRef.close(this.data);
        }
    };
    return OpenMatchingDialogComponent;
}());
exports.OpenMatchingDialogComponent = OpenMatchingDialogComponent;
