"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var task_select_component_1 = require("./task-select.component");
var routes = [{ path: "", component: task_select_component_1.FuseTaskSelectComponent }];
var FuseTaskSelectModule = /** @class */ (function () {
    function FuseTaskSelectModule() {
    }
    return FuseTaskSelectModule;
}());
exports.FuseTaskSelectModule = FuseTaskSelectModule;
