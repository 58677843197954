"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_1 = require("@angular/cdk/platform");
var config_service_1 = require("@fuse/services/config.service");
var FuseMainComponent = /** @class */ (function () {
    function FuseMainComponent(_renderer, _elementRef, fuseConfig, platform, document) {
        var _this = this;
        this._renderer = _renderer;
        this._elementRef = _elementRef;
        this.fuseConfig = fuseConfig;
        this.platform = platform;
        this.document = document;
        this.onConfigChanged = this.fuseConfig.onConfigChanged.subscribe(function (newSettings) {
            _this.fuseSettings = newSettings;
            _this.layoutMode = _this.fuseSettings.layout.mode;
        });
        if (this.platform.ANDROID || this.platform.IOS) {
            this.document.body.className += ' is-mobile';
        }
    }
    FuseMainComponent.prototype.ngOnDestroy = function () {
        this.onConfigChanged.unsubscribe();
        this.onRoleChanged.unsubscribe();
    };
    FuseMainComponent.prototype.addClass = function (className) {
        this._renderer.addClass(this._elementRef.nativeElement, className);
    };
    FuseMainComponent.prototype.removeClass = function (className) {
        this._renderer.removeClass(this._elementRef.nativeElement, className);
    };
    return FuseMainComponent;
}());
exports.FuseMainComponent = FuseMainComponent;
