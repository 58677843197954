"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var item_1 = require("app/main/content/_model/dynamic-forms/item");
var forms_1 = require("@angular/forms");
var ComputedItemComponent = /** @class */ (function () {
    function ComputedItemComponent() {
    }
    ComputedItemComponent.prototype.ngOnInit = function () {
        this.loaded = false;
        this.loaded = true;
    };
    return ComputedItemComponent;
}());
exports.ComputedItemComponent = ComputedItemComponent;
