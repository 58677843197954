"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./nav-vertical-group.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./nav-vertical-group.component");
var i3 = require("../nav-collapse/nav-vertical-collapse.component.ngfactory");
var i4 = require("../nav-collapse/nav-vertical-collapse.component");
var i5 = require("../../navigation.service");
var i6 = require("@angular/router");
var i7 = require("../nav-item/nav-vertical-item.component.ngfactory");
var i8 = require("../nav-item/nav-vertical-item.component");
var i9 = require("@angular/common");
var i10 = require("@ngx-translate/core");
var styles_FuseNavVerticalGroupComponent = [i0.styles];
var RenderType_FuseNavVerticalGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseNavVerticalGroupComponent, data: {} });
exports.RenderType_FuseNavVerticalGroupComponent = RenderType_FuseNavVerticalGroupComponent;
function View_FuseNavVerticalGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, View_FuseNavVerticalGroupComponent_0, RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 49152, null, 0, i2.FuseNavVerticalGroupComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavVerticalGroupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-collapse", [], [[8, "className", 0], [2, "open", null]], null, null, i3.View_FuseNavVerticalCollapseComponent_0, i3.RenderType_FuseNavVerticalCollapseComponent)), i1.ɵdid(1, 114688, null, 0, i4.FuseNavVerticalCollapseComponent, [i5.FuseNavigationService, i6.Router], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavVerticalGroupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-item", [], [[8, "className", 0]], null, null, i7.View_FuseNavVerticalItemComponent_0, i7.RenderType_FuseNavVerticalItemComponent)), i1.ɵdid(1, 49152, null, 0, i8.FuseNavVerticalItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavVerticalGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_3)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_4)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_5)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapse"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavVerticalGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "group-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "hint-text"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i10.TranslateDirective, [i10.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "group-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_2)), i1.ɵdid(7, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.translate; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.item.children; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.title; _ck(_v, 4, 0, currVal_1); }); }
function View_FuseNavVerticalGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_FuseNavVerticalGroupComponent_0 = View_FuseNavVerticalGroupComponent_0;
function View_FuseNavVerticalGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, View_FuseNavVerticalGroupComponent_0, RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 49152, null, 0, i2.FuseNavVerticalGroupComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
exports.View_FuseNavVerticalGroupComponent_Host_0 = View_FuseNavVerticalGroupComponent_Host_0;
var FuseNavVerticalGroupComponentNgFactory = i1.ɵccf("fuse-nav-vertical-group", i2.FuseNavVerticalGroupComponent, View_FuseNavVerticalGroupComponent_Host_0, { item: "item" }, {}, []);
exports.FuseNavVerticalGroupComponentNgFactory = FuseNavVerticalGroupComponentNgFactory;
