<div #overlayDiv *ngIf="displayOverlay" class="mat-elevation-z4 overlay-layout">

    <div fxLayout="row" fxLayoutAlign="space-between center" class="overlay-title-bar">
        <div class="pl-4">Einstellungen</div>
        <div></div>
        <mat-icon color="primary" aria-label="Schließen" (click)="closeOverlay()">
            close
        </mat-icon>

    </div>

    <class-options-overlay-content *ngIf="overlayType == 'CLASS'" [inputData]="model"
        (resultData)="handleResultEvent($event)">
    </class-options-overlay-content>

    <relationship-options-overlay-content *ngIf="overlayType == 'RELATIONSHIP'" [inputData]="model"
        (resultData)="handleResultEvent($event)"></relationship-options-overlay-content>
</div>