"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var platform_1 = require("@angular/cdk/platform");
var rxjs_1 = require("rxjs");
var _ = require("lodash");
// Create the injection token for the custom config
exports.FUSE_CONFIG = new core_1.InjectionToken('fuseCustomConfig');
var FuseConfigService = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param router
     * @param platform
     * @param config
     */
    function FuseConfigService(router, platform, config) {
        var _this = this;
        this.router = router;
        this.platform = platform;
        this.isSetConfigRan = false;
        // Set the default config from the user provided one (forRoot)
        this.defaultConfig = config;
        /**
         * Disable Custom Scrollbars if Browser is Mobile
         */
        if (this.platform.ANDROID || this.platform.IOS) {
            this.defaultConfig.customScrollbars = false;
        }
        // Set the config from the default config
        this.config = _.cloneDeep(this.defaultConfig);
        // Reload the default settings for the
        // layout on every navigation start
        router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                _this.isSetConfigRan = false;
            }
            if (event instanceof router_1.NavigationEnd) {
                if (_this.isSetConfigRan) {
                    return;
                }
                _this.setConfig({
                    layout: _this.defaultConfig.layout
                });
            }
        });
        // Create the behavior subject
        this.onConfigChanged = new rxjs_1.BehaviorSubject(this.config);
    }
    /**
     * Set the new config from given object
     *
     * @param config
     */
    FuseConfigService.prototype.setConfig = function (config) {
        // Set the SetConfigRan true
        this.isSetConfigRan = true;
        // Merge the config
        this.config = _.merge({}, this.config, config);
        // Trigger the event
        this.onConfigChanged.next(this.config);
    };
    return FuseConfigService;
}());
exports.FuseConfigService = FuseConfigService;
