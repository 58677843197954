"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var objectid_service_1 = require("app/main/content/_service/objectid.service.");
var myMxCell_1 = require("../../../../myMxCell");
var tree_property_1 = require("app/main/content/_model/configurator/property/tree-property");
var utils_and_constants_1 = require("../../../../class-configurator/utils-and-constants");
var util_1 = require("util");
var options_overlay_content_component_1 = require("./options-overlay/options-overlay-content/options-overlay-content.component");
var ENTRY_CELL_WIDTH = 110;
var ENTRY_CELL_HEIGHT = 70;
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var TreePropertyGraphEditorComponent = /** @class */ (function () {
    function TreePropertyGraphEditorComponent(objectIdService) {
        this.objectIdService = objectIdService;
        this.result = new core_1.EventEmitter();
        this.management = new core_1.EventEmitter();
        this.overlayContent = undefined;
        this.overlayEvent = undefined;
        this.displayOverlay = false;
    }
    /**
     * ******INITIALIZATION******
     */
    TreePropertyGraphEditorComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (!mx.mxClient.isBrowserSupported()) {
            mx.mxUtils.error('Browser is not supported!', 200, false);
            return;
        }
        this.graph = new mx.mxGraph(this.graphContainer.nativeElement);
        this.graph.getCursorForCell = function (cell) {
            if (cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON || cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_SAME_LEVEL_ICON) {
                return mx.mxConstants.CURSOR_TERMINAL_HANDLE;
            }
        };
        mx.mxEvent.disableContextMenu(this.graphContainer.nativeElement);
        // tslint:disable-next-line: no-unused-expression
        new mx.mxRubberband(this.graph);
        this.graph.setPanning(true);
        this.graph.tooltipHandler = new mx.mxTooltipHandler(this.graph, 100);
        /**
         * ******EVENT LISTENERS******
         */
        this.graph.addListener(mx.mxEvent.CLICK, function (sender, evt) {
            var mouseEvent = evt.getProperty('event');
            if (mouseEvent.button === 0) {
                _this.handleMXGraphLeftClickEvent(evt);
            }
            else if (mouseEvent.button === 2) {
                // Handle Right Click
            }
        });
        this.createGraph();
        this.setLayout();
        this.executeLayout();
    };
    TreePropertyGraphEditorComponent.prototype.createGraph = function () {
        this.graph.getModel().beginUpdate();
        this.createRootCell();
        this.createEntryCells(this.treePropertyDefinition.entries);
        this.createRelationshipCells(this.treePropertyDefinition.relationships);
        this.graph.getModel().endUpdate();
    };
    TreePropertyGraphEditorComponent.prototype.createRootCell = function () {
        var rootCell = this.graph.insertVertex(this.graph.getDefaultParent(), this.treePropertyDefinition.id, this.treePropertyDefinition.name, 0, 0, ENTRY_CELL_WIDTH, ENTRY_CELL_HEIGHT, utils_and_constants_1.CConstants.mxStyles.classTree);
        rootCell.root = true;
        rootCell.cellType = myMxCell_1.MyMxCellType.TREE_HEAD;
        this.rootCell = rootCell;
        var nextIcon = this.graph.insertVertex(rootCell, 'add_class_next_level_icon', 'Eintrag hinzufügen', 85, 45, 20, 20, utils_and_constants_1.CConstants.mxStyles.addClassNewLevelIcon);
        nextIcon.setConnectable(false);
        nextIcon.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON;
    };
    TreePropertyGraphEditorComponent.prototype.createEntryCells = function (treePropertyEntries) {
        var e_1, _a;
        try {
            for (var treePropertyEntries_1 = __values(treePropertyEntries), treePropertyEntries_1_1 = treePropertyEntries_1.next(); !treePropertyEntries_1_1.done; treePropertyEntries_1_1 = treePropertyEntries_1.next()) {
                var entry = treePropertyEntries_1_1.value;
                this.createEntryCell(entry);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (treePropertyEntries_1_1 && !treePropertyEntries_1_1.done && (_a = treePropertyEntries_1.return)) _a.call(treePropertyEntries_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TreePropertyGraphEditorComponent.prototype.createEntryCell = function (treePropertyEntry, position) {
        if (util_1.isNullOrUndefined(treePropertyEntry)) {
            treePropertyEntry = this.createNewTreePropertyEntry();
        }
        if (util_1.isNullOrUndefined(position)) {
            position = { x: 0, y: 0 };
        }
        var cell = this.graph.insertVertex(this.graph.getDefaultParent(), treePropertyEntry.id, treePropertyEntry.value, position.x, position.y, ENTRY_CELL_WIDTH, ENTRY_CELL_HEIGHT, utils_and_constants_1.CConstants.mxStyles.classTree);
        cell.root = false;
        cell.cellType = myMxCell_1.MyMxCellType.TREE_ENTRY;
        var nextIcon = this.graph.insertVertex(cell, 'add_class_next_level_icon', 'Eintrag hinzufügen', 85, 45, 20, 20, utils_and_constants_1.CConstants.mxStyles.addClassNewLevelIcon);
        nextIcon.setConnectable(false);
        nextIcon.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON;
        var sameIcon = this.graph.insertVertex(cell, 'add_class_same_level_icon', 'Eintrag hinzufügen', 65, 45, 20, 20, utils_and_constants_1.CConstants.mxStyles.addClassSameLevelIcon);
        sameIcon.setConnectable(false);
        sameIcon.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_SAME_LEVEL_ICON;
        var optionsIcon = this.graph.insertVertex(cell, 'options', 'options', 5, 45, 20, 20, utils_and_constants_1.CConstants.mxStyles.optionsIcon);
        optionsIcon.setConnectable(false);
        optionsIcon.cellType = myMxCell_1.MyMxCellType.OPTIONS_ICON;
        return cell;
    };
    TreePropertyGraphEditorComponent.prototype.createNewTreePropertyEntry = function () {
        var treePropertyEntry = new tree_property_1.TreePropertyEntry();
        treePropertyEntry.id = this.objectIdService.getNewObjectId();
        treePropertyEntry.selectable = true;
        treePropertyEntry.value = 'neuer Eintrag';
        this.treePropertyDefinition.entries.push(treePropertyEntry);
        return treePropertyEntry;
    };
    TreePropertyGraphEditorComponent.prototype.createRelationship = function (sourceId, targetId) {
        var relationship = new tree_property_1.TreePropertyRelationship();
        relationship.id = this.objectIdService.getNewObjectId();
        relationship.sourceId = sourceId;
        relationship.targetId = targetId;
        this.treePropertyDefinition.relationships.push(relationship);
        return relationship;
    };
    TreePropertyGraphEditorComponent.prototype.createRelationshipCells = function (treePropertyRelationships) {
        var e_2, _a;
        try {
            for (var treePropertyRelationships_1 = __values(treePropertyRelationships), treePropertyRelationships_1_1 = treePropertyRelationships_1.next(); !treePropertyRelationships_1_1.done; treePropertyRelationships_1_1 = treePropertyRelationships_1.next()) {
                var relationship = treePropertyRelationships_1_1.value;
                this.createRelationshipCellById(relationship);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (treePropertyRelationships_1_1 && !treePropertyRelationships_1_1.done && (_a = treePropertyRelationships_1.return)) _a.call(treePropertyRelationships_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    TreePropertyGraphEditorComponent.prototype.createRelationshipCellById = function (treePropertyRelationships) {
        var source = this.graph.getModel()
            .getCell(treePropertyRelationships.sourceId);
        var target = this.graph.getModel()
            .getCell(treePropertyRelationships.targetId);
        return this.createRelationshipCell(treePropertyRelationships.id, source, target);
    };
    TreePropertyGraphEditorComponent.prototype.createRelationshipCell = function (id, source, target) {
        var cell = this.graph.insertEdge(this.graph.getDefaultParent(), id, '', source, target, utils_and_constants_1.CConstants.mxStyles.genericConnection);
        cell.cellType = myMxCell_1.MyMxCellType.TREE_CONNECTOR;
        return cell;
    };
    TreePropertyGraphEditorComponent.prototype.handleMXGraphLeftClickEvent = function (event) {
        var eventCell = event.getProperty('cell');
        if (util_1.isNullOrUndefined(eventCell) || this.displayOverlay) {
            if (this.displayOverlay) {
                this.handleOverlayClosed();
            }
            return;
        }
        if (eventCell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_NEXT_LEVEL_ICON) {
            var newCell = this.createEntryCell();
            var relationship = this.createRelationship(eventCell.getParent().id, newCell.id);
            this.createRelationshipCell(relationship.id, eventCell.getParent(), newCell);
            this.executeLayout();
        }
        else if (eventCell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_SAME_LEVEL_ICON) {
            var newCell = this.createEntryCell();
            var parentCell = this.graph.getIncomingEdges(eventCell.getParent())[0].source;
            var relationship = this.createRelationship(parentCell.id, newCell.id);
            this.createRelationshipCell(relationship.id, parentCell, newCell);
            this.executeLayout();
        }
        else if (eventCell.cellType === myMxCell_1.MyMxCellType.OPTIONS_ICON) {
            this.openOverlay(eventCell.getParent(), event.getProperty('event'));
        }
    };
    TreePropertyGraphEditorComponent.prototype.onSaveClick = function () {
        this.updateModel();
        this.result.emit({ type: 'save', payload: this.treePropertyDefinition });
    };
    TreePropertyGraphEditorComponent.prototype.updateModel = function () {
        var e_3, _a, e_4, _b;
        var vertices = this.graph
            .getModel()
            .getChildVertices(this.graph.getDefaultParent());
        var edges = this.graph
            .getModel()
            .getChildEdges(this.graph.getDefaultParent());
        // update head
        this.treePropertyDefinition.name = this.rootCell.value;
        // update entries
        var newTreePropertyEntries = [];
        var _loop_1 = function (vertice) {
            if (vertice.id === this_1.rootCell.id) {
                return "continue";
            }
            var treePropertyEntry = this_1.treePropertyDefinition.entries.find(function (e) { return e.id === vertice.id; });
            treePropertyEntry.value = vertice.value;
            newTreePropertyEntries.push(treePropertyEntry);
        };
        var this_1 = this;
        try {
            for (var vertices_1 = __values(vertices), vertices_1_1 = vertices_1.next(); !vertices_1_1.done; vertices_1_1 = vertices_1.next()) {
                var vertice = vertices_1_1.value;
                _loop_1(vertice);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (vertices_1_1 && !vertices_1_1.done && (_a = vertices_1.return)) _a.call(vertices_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.treePropertyDefinition.entries = newTreePropertyEntries;
        var newTreePropertyRelationships = [];
        var _loop_2 = function (edge) {
            var relationship = this_2.treePropertyDefinition.relationships.find(function (r) { return r.id === edge.id; });
            relationship.sourceId = edge.source.id;
            relationship.targetId = edge.target.id;
            newTreePropertyRelationships.push(relationship);
        };
        var this_2 = this;
        try {
            for (var edges_1 = __values(edges), edges_1_1 = edges_1.next(); !edges_1_1.done; edges_1_1 = edges_1.next()) {
                var edge = edges_1_1.value;
                _loop_2(edge);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (edges_1_1 && !edges_1_1.done && (_b = edges_1.return)) _b.call(edges_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.treePropertyDefinition.relationships = newTreePropertyRelationships;
    };
    TreePropertyGraphEditorComponent.prototype.onBackClick = function () {
        this.result.emit({ type: 'back', payload: this.treePropertyDefinition });
    };
    TreePropertyGraphEditorComponent.prototype.onSaveAndBackClick = function () {
        this.updateModel();
        this.result.emit({ type: 'saveAndBack', payload: this.treePropertyDefinition });
    };
    TreePropertyGraphEditorComponent.prototype.setLayout = function () {
        if (util_1.isNullOrUndefined(this.rootCell.edges) || this.rootCell.edges.length <= 0) {
            return;
        }
        this.layout = new mx.mxCompactTreeLayout(this.graph, false, false);
        this.layout.levelDistance = 50;
        this.layout.alignRanks = true;
        this.layout.minEdgeJetty = 50;
        this.layout.prefHozEdgeSep = 5;
        this.layout.resetEdges = false;
        this.layout.edgeRouting = true;
    };
    TreePropertyGraphEditorComponent.prototype.executeLayout = function () {
        util_1.isNullOrUndefined(this.layout) ? this.setLayout()
            : this.layout.execute(this.graph.getDefaultParent(), this.rootCell);
    };
    /**
     * ******KEY LISTENER/HANDLER******
     */
    TreePropertyGraphEditorComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.key === 'Delete') {
            var cells = this.graph.getSelectionCells();
            this.performDelete(cells);
        }
    };
    TreePropertyGraphEditorComponent.prototype.performDelete = function (cells) {
        cells = cells.filter(function (c) { return !c.writeProtected; });
        this.graph.removeCells(cells, false);
    };
    TreePropertyGraphEditorComponent.prototype.openOverlay = function (cell, event) {
        this.overlayEvent = event;
        this.overlayContent = new options_overlay_content_component_1.TreePropertyOptionsOverlayContentData();
        this.overlayContent.treePropertyEntry =
            this.treePropertyDefinition.entries.find(function (e) { return e.id === cell.id; });
        this.management.emit('disableScroll');
        this.graph.setEnabled(false);
        this.displayOverlay = true;
    };
    TreePropertyGraphEditorComponent.prototype.handleOverlayClosed = function (event) {
        if (!util_1.isNullOrUndefined(event)) {
            var i = this.treePropertyDefinition.entries.findIndex(function (e) { return e.id === event.id; });
            this.treePropertyDefinition.entries[i] = event;
        }
        this.overlayContent = undefined;
        this.overlayEvent = undefined;
        this.displayOverlay = false;
        this.management.emit('enableScroll');
        this.graph.setEnabled(true);
    };
    return TreePropertyGraphEditorComponent;
}());
exports.TreePropertyGraphEditorComponent = TreePropertyGraphEditorComponent;
