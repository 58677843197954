"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var util_1 = require("util");
var ClassBrowseSubDialogData = /** @class */ (function () {
    function ClassBrowseSubDialogData() {
    }
    return ClassBrowseSubDialogData;
}());
exports.ClassBrowseSubDialogData = ClassBrowseSubDialogData;
var BrowseClassSubDialogComponent = /** @class */ (function () {
    function BrowseClassSubDialogComponent() {
        this.subDialogReturn = new core_1.EventEmitter();
        this.datasource = new material_1.MatTableDataSource();
        this.currentSortType = 'az';
    }
    BrowseClassSubDialogComponent.prototype.ngOnInit = function () {
        this.datasource.data = this.data.entries;
        this.sortClicked('date');
    };
    BrowseClassSubDialogComponent.prototype.handleRowClick = function (entry) {
        this.subDialogReturn.emit({
            cancelled: false,
            entryId: entry.id,
            sourceReference: this.data.sourceReference
        });
    };
    BrowseClassSubDialogComponent.prototype.handleBackClick = function () {
        this.subDialogReturn.emit({
            cancelled: true,
            entryId: undefined,
            sourceReference: this.data.sourceReference
        });
    };
    BrowseClassSubDialogComponent.prototype.applyFilter = function (event) {
        this.currentFilter = event.target.value;
        this.datasource.filter = this.currentFilter.trim().toLowerCase();
    };
    BrowseClassSubDialogComponent.prototype.sortClicked = function (sortKey) {
        if (this.currentSortKey === sortKey) {
            this.switchSortType();
        }
        else {
            this.currentSortType = 'az';
        }
        if (sortKey === 'date') {
            this.datasource.data = this.data.entries.sort(function (a, b) { return b.date.valueOf() - a.date.valueOf(); });
        }
        if (sortKey === 'name') {
            this.datasource.data = this.data.entries.sort(function (a, b) {
                return b.name.trim().localeCompare(a.name.trim());
            });
        }
        if (this.currentSortType === 'za') {
            this.datasource.data.reverse();
        }
        this.currentSortKey = sortKey;
        if (!util_1.isNullOrUndefined(this.currentFilter)) {
            this.datasource.filter = this.currentFilter.trim().toLowerCase();
        }
    };
    BrowseClassSubDialogComponent.prototype.switchSortType = function () {
        this.currentSortType === 'az'
            ? (this.currentSortType = 'za')
            : (this.currentSortType = 'az');
    };
    return BrowseClassSubDialogComponent;
}());
exports.BrowseClassSubDialogComponent = BrowseClassSubDialogComponent;
