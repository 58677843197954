"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".add-table[_ngcontent-%COMP%]{height:400px;overflow:auto}table[_ngcontent-%COMP%]{width:100%}.mat-header-row[_ngcontent-%COMP%]{position:-webkit-sticky;position:sticky;top:0;z-index:100;background:#fff}div#graphContainer[_ngcontent-%COMP%]{z-index:1;overflow:hidden;height:65vh;width:100%;background:#fff;border:2px solid #000;border-radius:4px}"];
exports.styles = styles;
