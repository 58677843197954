"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dynamic_form_item_service_1 = require("app/main/content/_service/dynamic-form-item.service");
var dynamic_form_item_control_service_1 = require("app/main/content/_service/dynamic-form-item-control.service");
var class_1 = require("app/main/content/_model/configurator/class");
var router_1 = require("@angular/router");
var class_definition_service_1 = require("app/main/content/_service/meta/core/class/class-definition.service");
var class_instance_service_1 = require("app/main/content/_service/meta/core/class/class-instance.service");
var objectid_service_1 = require("app/main/content/_service/objectid.service.");
var property_1 = require("app/main/content/_model/configurator/property/property");
var util_1 = require("util");
var ClassInstanceFormEditorComponent = /** @class */ (function () {
    function ClassInstanceFormEditorComponent(router, route, classDefinitionService, classInstanceService, formItemService, formItemControlService, objectIdService) {
        this.router = router;
        this.route = route;
        this.classDefinitionService = classDefinitionService;
        this.classInstanceService = classInstanceService;
        this.formItemService = formItemService;
        this.formItemControlService = formItemControlService;
        this.objectIdService = objectIdService;
        this.loaded = false;
        this.finishClicked = false;
        this.showResultPage = false;
        this.canSubmitForm = true;
        this.errorOccurredInForm = false;
    }
    ClassInstanceFormEditorComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var childClassIds;
            var _this = this;
            return __generator(this, function (_a) {
                childClassIds = [];
                this.returnedClassInstances = [];
                this.route.queryParams.subscribe(function (params) {
                    if (util_1.isNullOrUndefined(params['tenantId']) || util_1.isNullOrUndefined(params['redirect'] || util_1.isNullOrUndefined(params['cdId']))) {
                        _this.router.navigate(['main/invalid-parameters']);
                    }
                    else {
                        _this.tenantId = params['tenantId'];
                        _this.redirectUrl = params['redirect'];
                        childClassIds.push(params['cdId']);
                    }
                });
                this.classDefinitionService
                    .getFormConfigurations(childClassIds)
                    .toPromise().then(function (formConfigurations) {
                    var e_1, _a;
                    _this.formConfigurations = formConfigurations;
                    try {
                        for (var _b = __values(_this.formConfigurations), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var config = _c.value;
                            config.formEntry = _this.addFormItemsAndFormGroup(config.formEntry, config.formEntry.id);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }).then(function () {
                    _this.currentFormConfiguration = _this.formConfigurations.pop();
                    _this.loaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    ClassInstanceFormEditorComponent.prototype.addFormItemsAndFormGroup = function (formEntry, idPrefix) {
        var e_2, _a;
        formEntry.formItems = this.formItemService.getFormItemsFromProperties(formEntry.classProperties, idPrefix);
        formEntry.formGroup = this.formItemControlService.toFormGroup(formEntry.formItems);
        if (!util_1.isNullOrUndefined(formEntry.subEntries)) {
            try {
                for (var _b = __values(formEntry.subEntries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var subEntry = _c.value;
                    var newIdPrefix = subEntry.id;
                    subEntry = this.addFormItemsAndFormGroup(subEntry, newIdPrefix);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return formEntry;
    };
    ClassInstanceFormEditorComponent.prototype.handleTupleSelection = function (evt) {
        var _this = this;
        var unableToContinueControl;
        var unableToContinueControlKey;
        var pathPrefix;
        Object.keys(evt.formGroup.controls).forEach(function (c) {
            if (c.endsWith('unableToContinue')) {
                unableToContinueControlKey = c;
                unableToContinueControl = evt.formGroup.controls[c];
                pathPrefix = c.replace(/\.[^.]*unableToContinue/, '');
            }
        });
        this.classDefinitionService
            .getFormConfigurationChunk(pathPrefix, evt.selection.id).toPromise().then(function (retFormEntry) {
            var currentFormEntry = _this.getFormEntry(pathPrefix, _this.currentFormConfiguration.formEntry.id, _this.currentFormConfiguration.formEntry);
            retFormEntry = _this.addFormItemsAndFormGroup(retFormEntry, pathPrefix);
            currentFormEntry.classDefinitions = retFormEntry.classDefinitions;
            currentFormEntry.classProperties = retFormEntry.classProperties;
            currentFormEntry.formGroup = retFormEntry.formGroup;
            currentFormEntry.imagePath = retFormEntry.imagePath;
            currentFormEntry.formItems = retFormEntry.formItems;
            currentFormEntry.subEntries = retFormEntry.subEntries;
        });
    };
    ClassInstanceFormEditorComponent.prototype.getFormEntry = function (pathString, currentPath, currentFormEntry) {
        if (currentPath === pathString) {
            return currentFormEntry;
        }
        currentFormEntry = currentFormEntry.subEntries.find(function (e) {
            return pathString.startsWith(e.id);
        });
        return this.getFormEntry(pathString, currentFormEntry.id, currentFormEntry);
    };
    ClassInstanceFormEditorComponent.prototype.handleFinishClick = function () {
        var _this = this;
        this.canSubmitForm = false;
        this.errorOccurredInForm = false;
        setTimeout(function () {
            if (!_this.canSubmitForm) {
                _this.finishClicked = true;
                _this.canSubmitForm = true;
            }
        }, 200);
    };
    ClassInstanceFormEditorComponent.prototype.handleErrorEvent = function (event) {
        this.errorOccurredInForm = this.errorOccurredInForm || event;
        this.canSubmitForm = true;
        this.finishClicked = false;
    };
    ClassInstanceFormEditorComponent.prototype.handleResultEvent = function (event) {
        var _this = this;
        setTimeout(function () {
            if (!_this.errorOccurredInForm) {
                _this.createInstanceFromResults(event);
            }
            _this.finishClicked = false;
        });
    };
    ClassInstanceFormEditorComponent.prototype.createInstanceFromResults = function (result) {
        var _this = this;
        var classInstances = [];
        var classInstance = this.createClassInstance(this.currentFormConfiguration.formEntry, result.value[this.currentFormConfiguration.formEntry.id]);
        classInstance.tenantId = this.tenantId;
        classInstance.issuerId = this.tenantId;
        classInstances.push(classInstance);
        this.classInstanceService.createNewClassInstances(classInstances, this.redirectUrl).then(function (ret) {
            _this.resultClassInstance = ret;
            _this.contentDiv.nativeElement.scrollTo(0, 0);
            _this.showResultPage = true;
        });
    };
    ClassInstanceFormEditorComponent.prototype.createClassInstance = function (formEntry, result, forceAddProperties, resultIndex) {
        var e_3, _a;
        if (util_1.isNullOrUndefined(resultIndex)) {
            resultIndex = 0;
        }
        var keys = Object.keys(result[resultIndex]);
        var propertyInstances = [];
        if (forceAddProperties || !formEntry.multipleAllowed) {
            propertyInstances = this.addPropertyInstances(formEntry.classProperties, result, keys);
        }
        var classInstance = new class_1.ClassInstance(formEntry.classDefinitions[0], propertyInstances);
        classInstance.childClassInstances = [];
        classInstance.id = this.objectIdService.getNewObjectId();
        if (formEntry.multipleAllowed) {
            if (!forceAddProperties) {
                for (var i = 0; i < result.length; i++) {
                    var childClassInstance = this.createClassInstance(formEntry, result, true, i);
                    classInstance.childClassInstances.push(childClassInstance);
                }
            }
        }
        if (!util_1.isNullOrUndefined(formEntry.subEntries)) {
            try {
                for (var _b = __values(formEntry.subEntries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var subEntry = _c.value;
                    var next = this.findSubEntryResult(subEntry.id, result);
                    var subClassInstance = this.createClassInstance(subEntry, next[subEntry.id]);
                    classInstance.childClassInstances.push(subClassInstance);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        return classInstance;
    };
    ClassInstanceFormEditorComponent.prototype.addPropertyInstances = function (classProperties, values, keys) {
        var e_4, _a;
        var propertyInstances = [];
        var _loop_1 = function (classProperty) {
            // skip "unableToContinue" Properties
            if (classProperty.id.endsWith('unableToContinue')) {
                return "continue";
            }
            var value = values[0][keys.find(function (k) { return k.endsWith(classProperty.id); })];
            if (!classProperty.computed && classProperty.type === property_1.PropertyType.FLOAT_NUMBER) {
                value = Number(value);
            }
            else if (!classProperty.computed && classProperty.type === property_1.PropertyType.WHOLE_NUMBER) {
                value = Number.parseInt(value, 10);
            }
            var l = propertyInstances.push(new property_1.PropertyInstance(classProperty, [value]));
        };
        try {
            for (var classProperties_1 = __values(classProperties), classProperties_1_1 = classProperties_1.next(); !classProperties_1_1.done; classProperties_1_1 = classProperties_1.next()) {
                var classProperty = classProperties_1_1.value;
                _loop_1(classProperty);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (classProperties_1_1 && !classProperties_1_1.done && (_a = classProperties_1.return)) _a.call(classProperties_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return propertyInstances;
    };
    ClassInstanceFormEditorComponent.prototype.findSubEntryResult = function (subEntryId, result) {
        return result.find(function (r) { return Object.keys(r)[0] === subEntryId; });
    };
    ClassInstanceFormEditorComponent.prototype.handleCancelEvent = function () {
        var returnParam;
        this.route.queryParams.subscribe(function (params) {
            returnParam = params['returnTo'];
        });
        if (!util_1.isNullOrUndefined(returnParam)) {
            if (returnParam === 'classConfigurator') {
                this.router.navigate(["main/class-configurator"], { queryParams: { ccId: this.currentFormConfiguration.formEntry.classDefinitions[0].configurationId } });
            }
        }
        else {
            history.back();
        }
    };
    ClassInstanceFormEditorComponent.prototype.printAnything = function (anything) {
        console.log(anything);
    };
    ClassInstanceFormEditorComponent.prototype.printDebug = function () {
        console.log(this.currentFormConfiguration);
        console.log(this.currentFormConfiguration.formEntry.formGroup);
        console.log(this.currentFormConfiguration.formEntry.formItems);
        console.log(this.currentFormConfiguration.formEntry.subEntries);
    };
    return ClassInstanceFormEditorComponent;
}());
exports.ClassInstanceFormEditorComponent = ClassInstanceFormEditorComponent;
