"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["mat-dialog-content[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%], mat-dialog-content[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{padding:0;margin:0}mat-dialog-content[_ngcontent-%COMP%]   tr.entry[_ngcontent-%COMP%]:hover{margin:5px;background-color:#3e7ddb;color:#fff}mat-dialog-content[_ngcontent-%COMP%]   tr.entry[_ngcontent-%COMP%]:hover   td.entrybutton[_ngcontent-%COMP%]{display:block;cursor:pointer}mat-dialog-content[_ngcontent-%COMP%]   tr.entry[_ngcontent-%COMP%]{cursor:default;background-color:#e6e4e4}mat-dialog-content[_ngcontent-%COMP%]   td.entrybutton[_ngcontent-%COMP%]{display:none}mat-dialog-content[_ngcontent-%COMP%]   td.entrybutton[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{outline-style:solid;outline-color:#fff;color:#fff}mat-dialog-content[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]{padding-top:5px;padding-bottom:5px}mat-dialog-content[_ngcontent-%COMP%]   td.right[_ngcontent-%COMP%]{padding-top:25px;padding-bottom:25px}"];
exports.styles = styles;
