<div #graphContainer id="graphContainer">
</div>
<div class="mt-8" fxLayout="row" fxLayoutAlign="start center">
    <button class="primary-button-dark" mat-stroked-button type="button" (click)="onSaveClick()">Speichern</button>
    <button class="primary-button-dark ml-8" mat-stroked-button type="button" (click)="onSaveAndBackClick()">
        Speichern und Schließen
    </button>
    <button class="secondary-button-dark ml-28" mat-stroked-button type="button" (click)="onBackClick()">Zurück</button>
</div>

<tree-property-options-overlay-control [displayOverlay]="displayOverlay" [overlayContent]="overlayContent"
    [overlayEvent]="overlayEvent" (overlayClosed)="handleOverlayClosed($event)">
</tree-property-options-overlay-control>