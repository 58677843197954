"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var util_1 = require("util");
var rxjs_1 = require("rxjs");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var RelationshipService = /** @class */ (function () {
    function RelationshipService(http) {
        this.http = http;
    }
    RelationshipService.prototype.getAllRelationships = function () {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/all");
    };
    RelationshipService.prototype.getRelationshipById = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/" + id);
    };
    RelationshipService.prototype.getRelationshipsById = function (ids) {
        if (!util_1.isNullOrUndefined(ids)) {
            return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/multiple", ids);
        }
        else {
            return rxjs_1.of(null);
        }
    };
    RelationshipService.prototype.getRelationshipsByStartId = function (startId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/start/" + startId + "/all");
    };
    RelationshipService.prototype.getRelationshipsByEndId = function (endId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/end/" + endId + "/all");
    };
    RelationshipService.prototype.addRelationshipsInOneGo = function (relationships) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/add", relationships);
    };
    RelationshipService.prototype.addAndUpdateRelationships = function (relationships) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/add-or-update", relationships);
    };
    RelationshipService.prototype.deleteRelationships = function (ids) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/relationship/delete", ids);
    };
    RelationshipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RelationshipService_Factory() { return new RelationshipService(i0.ɵɵinject(i1.HttpClient)); }, token: RelationshipService, providedIn: "root" });
    return RelationshipService;
}());
exports.RelationshipService = RelationshipService;
