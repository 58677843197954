"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var animations_1 = require("@angular/animations");
var router_1 = require("@angular/router");
var FuseSplashScreenService = /** @class */ (function () {
    function FuseSplashScreenService(animationBuilder, document, router) {
        var _this = this;
        this.animationBuilder = animationBuilder;
        this.document = document;
        this.router = router;
        // Get the splash screen element
        this.splashScreenEl = this.document.body.querySelector('#fuse-splash-screen');
        // If the splash screen element exists...
        if (this.splashScreenEl) {
            // Hide it on the first NavigationEnd event
            var hideOnLoad_1 = this.router.events.subscribe(function (event) {
                if (event instanceof router_1.NavigationEnd) {
                    setTimeout(function () {
                        _this.hide();
                        // Unsubscribe from this event so it
                        // won't get triggered again
                        hideOnLoad_1.unsubscribe();
                    }, 0);
                }
            });
        }
    }
    FuseSplashScreenService.prototype.show = function () {
        var _this = this;
        this.player =
            this.animationBuilder
                .build([
                animations_1.style({
                    opacity: '0',
                    zIndex: '99999'
                }),
                animations_1.animate('400ms ease', animations_1.style({ opacity: '1' }))
            ]).create(this.splashScreenEl);
        setTimeout(function () {
            _this.player.play();
        }, 0);
    };
    FuseSplashScreenService.prototype.hide = function () {
        var _this = this;
        this.player =
            this.animationBuilder
                .build([
                animations_1.style({ opacity: '1' }),
                animations_1.animate('400ms ease', animations_1.style({
                    opacity: '0',
                    zIndex: '-10'
                }))
            ]).create(this.splashScreenEl);
        setTimeout(function () {
            _this.player.play();
        }, 0);
    };
    return FuseSplashScreenService;
}());
exports.FuseSplashScreenService = FuseSplashScreenService;
