"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// const routes = [
//   { path: ':marketplaceId', component: ClassInstanceFormPreviewDialogComponent }
// ];
var ClassInstanceFormPreviewExportDialogModule = /** @class */ (function () {
    function ClassInstanceFormPreviewExportDialogModule() {
    }
    return ClassInstanceFormPreviewExportDialogModule;
}());
exports.ClassInstanceFormPreviewExportDialogModule = ClassInstanceFormPreviewExportDialogModule;
