"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["mat-dialog-title[_ngcontent-%COMP%]{margin:0!important}mat-dialog-content[_ngcontent-%COMP%]{height:250px;overflow:scroll;margin:0;padding:0}mat-grid-tile[_ngcontent-%COMP%]{background:#f0f0f0;cursor:pointer}img[_ngcontent-%COMP%]{position:absolute;margin:auto;top:0;left:0;right:0;bottom:0}mat-grid-tile[_ngcontent-%COMP%]:hover{background:#3e7ddb}mat-grid-list[_ngcontent-%COMP%]{overflow:scroll}"];
exports.styles = styles;
