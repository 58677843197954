"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var MyMxCell = /** @class */ (function (_super) {
    __extends(MyMxCell, _super);
    function MyMxCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MyMxCell;
}(mx.mxCell));
exports.MyMxCell = MyMxCell;
var MyMxCellType;
(function (MyMxCellType) {
    MyMxCellType["CLASS"] = "CLASS";
    MyMxCellType["INHERITANCE"] = "INHERITANCE";
    MyMxCellType["ASSOCIATION"] = "ASSOCIATION";
    MyMxCellType["AGGREGATION"] = "AGGREGATION";
    MyMxCellType["COMPOSITION"] = "COMPOSITION";
    MyMxCellType["ASSOCIATION_LABEL"] = "ASSOCIATION_LABEL";
    MyMxCellType["FLAT_PROPERTY"] = "FLAT_PROPERTY";
    MyMxCellType["TREE_PROPERTY"] = "TREE_PROPERTY";
    MyMxCellType["ADD_PROPERTY_ICON"] = "ADD_PROPERTY_ICON";
    MyMxCellType["ADD_ASSOCIATION_ICON"] = "ADD_ASSOCIATION_ICON";
    MyMxCellType["ADD_CLASS_SAME_LEVEL_ICON"] = "ADD_CLASS_SAME_LEVEL_ICON";
    MyMxCellType["ADD_CLASS_NEXT_LEVEL_ICON"] = "ADD_CLASS_NEXT_LEVEL_ICON";
    MyMxCellType["REMOVE_ICON"] = "REMOVE_ICON";
    MyMxCellType["OPTIONS_ICON"] = "OPTIONS_ICON";
    MyMxCellType["ADD_CLASS_BUTTON"] = "ADD_CLASS_BUTTON";
    MyMxCellType["MATCHING_OPERATOR"] = "MATCHING_OPERATOR";
    MyMxCellType["MATCHING_CONNECTOR"] = "MATCHING_CONNECTOR";
    MyMxCellType["TREE_ENTRY"] = "TREE_ENTRY";
    MyMxCellType["TREE_CONNECTOR"] = "TREE_CONNECTOR";
    MyMxCellType["TREE_HEAD"] = "TREE_HEAD";
})(MyMxCellType = exports.MyMxCellType || (exports.MyMxCellType = {}));
(function (MyMxCellType) {
    function isRelationship(s) {
        return (s === MyMxCellType.INHERITANCE || s === MyMxCellType.ASSOCIATION || s === MyMxCellType.COMPOSITION || s === MyMxCellType.AGGREGATION);
    }
    MyMxCellType.isRelationship = isRelationship;
})(MyMxCellType = exports.MyMxCellType || (exports.MyMxCellType = {}));
