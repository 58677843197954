"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-dialog-title[_ngcontent-%COMP%]{margin:0}.mat-dialog-title[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{padding:0 0 6px;margin:0}mat-dialog-content[_ngcontent-%COMP%]{height:calc(100% - 52px - 20px);overflow:hidden}mat-dialog-content[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%], mat-dialog-content[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{padding:0;margin:0}mat-dialog-content[_ngcontent-%COMP%]   .browse-button[_ngcontent-%COMP%]{width:200px;background:#e6e4e4;margin:0 auto;padding-top:4px;padding-bottom:4px;cursor:pointer;border-radius:2px}mat-dialog-content[_ngcontent-%COMP%]   .browse-button[_ngcontent-%COMP%]   .browse-button-text[_ngcontent-%COMP%]{text-align:center}mat-dialog-content[_ngcontent-%COMP%]   .browse-button[_ngcontent-%COMP%]:hover{background-color:#3e7ddb;color:#fff}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-infix{padding:0 0 10px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-flex{padding-left:5px;padding-right:5px}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-wrapper{padding:0}"];
exports.styles = styles;
