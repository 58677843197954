"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".folded:not(.unfolded)   [_nghost-%COMP%]   .nav-link[_ngcontent-%COMP%] > .nav-link-badge[_ngcontent-%COMP%], .folded:not(.unfolded)   [_nghost-%COMP%]   .nav-link[_ngcontent-%COMP%] > .nav-link-title[_ngcontent-%COMP%]{opacity:0;transition:opacity .2s ease}.nav-link-title[_ngcontent-%COMP%]{font-size:17px}"];
exports.styles = styles;
