"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var util_1 = require("util");
var rxjs_1 = require("rxjs");
var form_1 = require("app/main/content/_model/configurator/form");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ClassDefinitionService = /** @class */ (function () {
    function ClassDefinitionService(http) {
        this.http = http;
    }
    ClassDefinitionService.prototype.getAllClassDefinitions = function () {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/all");
    };
    ClassDefinitionService.prototype.getClassDefinitionById = function (id) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/" + id);
    };
    ClassDefinitionService.prototype.getClassDefinitionsById = function (ids) {
        if (!util_1.isNullOrUndefined(ids)) {
            return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/multiple/", ids);
        }
        else {
            return rxjs_1.of(null);
        }
    };
    // TODO
    ClassDefinitionService.prototype.createNewClassDefinition = function (clazz) {
        return this.http.post(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/new", clazz);
    };
    ClassDefinitionService.prototype.addOrUpdateClassDefintions = function (classDefinitions) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/add-or-update", classDefinitions);
    };
    ClassDefinitionService.prototype.changeClassDefinitionName = function (id, newName) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/" + id + "/change-name", newName);
    };
    ClassDefinitionService.prototype.deleteClassDefinitions = function (ids) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/delete", ids);
    };
    ClassDefinitionService.prototype.getByArchetype = function (archetype, tenantId) {
        return this.http.get(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/archetype/" + archetype + "/tenant/" + tenantId);
    };
    ClassDefinitionService.prototype.getByArchetypes = function (archetypes, tenantId) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/archetypes/tenant/" + tenantId, archetypes);
    };
    ClassDefinitionService.prototype.getFormConfigurations = function (ids) {
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/form-configuration", ids);
    };
    ClassDefinitionService.prototype.getFormConfigurationPreview = function (classDefinitions, relationships, rootClassDefinition) {
        var formConfigurationPreviewRequest = new form_1.FormConfigurationPreviewRequest(classDefinitions, relationships, rootClassDefinition);
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/form-configuration-preview", formConfigurationPreviewRequest);
    };
    ClassDefinitionService.prototype.getFormConfigurationChunk = function (currentClassDefinitionId, choiceId) {
        var params = [currentClassDefinitionId, choiceId];
        return this.http.put(environment_1.environment.CONFIGURATOR_URL + "/meta/core/class/definition/form-configuration-chunk", params);
    };
    ClassDefinitionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassDefinitionService_Factory() { return new ClassDefinitionService(i0.ɵɵinject(i1.HttpClient)); }, token: ClassDefinitionService, providedIn: "root" });
    return ClassDefinitionService;
}());
exports.ClassDefinitionService = ClassDefinitionService;
