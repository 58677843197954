"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var flex_layout_1 = require("@angular/flex-layout");
var rxjs_1 = require("rxjs");
var FuseMatchMediaService = /** @class */ (function () {
    function FuseMatchMediaService(observableMedia) {
        var _this = this;
        this.observableMedia = observableMedia;
        this.onMediaChange = new rxjs_1.BehaviorSubject('');
        this.activeMediaQuery = '';
        this.observableMedia.media$.subscribe(function (change) {
            if (_this.activeMediaQuery !== change.mqAlias) {
                _this.activeMediaQuery = change.mqAlias;
                _this.onMediaChange.next(change.mqAlias);
            }
        });
    }
    return FuseMatchMediaService;
}());
exports.FuseMatchMediaService = FuseMatchMediaService;
