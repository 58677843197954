"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var config_service_1 = require("../../../../services/config.service");
var FuseNavHorizontalCollapseComponent = /** @class */ (function () {
    function FuseNavHorizontalCollapseComponent(fuseConfig) {
        var _this = this;
        this.fuseConfig = fuseConfig;
        this.isOpen = false;
        this.classes = 'nav-item nav-collapse';
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(function (newSettings) {
                _this.fuseSettings = newSettings;
            });
    }
    FuseNavHorizontalCollapseComponent.prototype.open = function () {
        this.isOpen = true;
    };
    FuseNavHorizontalCollapseComponent.prototype.close = function () {
        this.isOpen = false;
    };
    FuseNavHorizontalCollapseComponent.prototype.ngOnDestroy = function () {
        this.onConfigChanged.unsubscribe();
    };
    return FuseNavHorizontalCollapseComponent;
}());
exports.FuseNavHorizontalCollapseComponent = FuseNavHorizontalCollapseComponent;
