"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TreePropertyOptionsOverlayContentData = /** @class */ (function () {
    function TreePropertyOptionsOverlayContentData() {
    }
    return TreePropertyOptionsOverlayContentData;
}());
exports.TreePropertyOptionsOverlayContentData = TreePropertyOptionsOverlayContentData;
var TreePropertyOptionsOverlayContentComponent = /** @class */ (function () {
    function TreePropertyOptionsOverlayContentComponent() {
        this.result = new core_1.EventEmitter();
    }
    TreePropertyOptionsOverlayContentComponent.prototype.ngOnInit = function () {
        this.inputData.treePropertyEntry.selectable
            ? (this.typeSelection = 'selector')
            : (this.typeSelection = 'label');
    };
    TreePropertyOptionsOverlayContentComponent.prototype.onSubmit = function () {
        this.inputData.treePropertyEntry.selectable = this.typeSelection === 'selector';
        var outer = this;
        window.setTimeout(function () {
            outer.result.emit(this.inputData);
        }, 400);
    };
    return TreePropertyOptionsOverlayContentComponent;
}());
exports.TreePropertyOptionsOverlayContentComponent = TreePropertyOptionsOverlayContentComponent;
