<div mat-dialog-title style="margin: 0">
  <table>
    <tr>
      <td>
        <mat-icon>search</mat-icon>
      </td>
      <td>
        <h1>Choose Icon</h1>
      </td>
    </tr>
  </table>
</div>
<mat-dialog-content>

  <mat-grid-list cols="8" rowHeight="1:1">
    <mat-grid-tile *ngFor="let imagePath of imagePaths" #tile>
      <div style="width: 65px; height: 65px;">
        <img [src]="imagePath.path" width="45" height="45" (click)="onSelectionClick($event, tile, imagePath)"
          matTooltip="icon">
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</mat-dialog-content>
<mat-dialog-actions>
  <button class="primary-button-dark" mat-stroked-button type="button" [mat-dialog-close]="data">Auswählen</button>
  <button class="secondary-button-dark" mat-stroked-button type="button" [mat-dialog-close]="undefined">Icon
    Entfernen</button>
</mat-dialog-actions>