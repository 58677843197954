"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var fuse_perfect_scrollbar_directive_1 = require("@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var sidebar_service_1 = require("@fuse/components/sidebar/sidebar.service");
var navigation_service_1 = require("@fuse/components/navigation/navigation.service");
var navigation_configurator_1 = require("app/navigation/navigation_configurator");
var FuseNavbarComponent = /** @class */ (function () {
    function FuseNavbarComponent(sidebarService, navigationService, router) {
        this.sidebarService = sidebarService;
        this.navigationService = navigationService;
        this.router = router;
        // TODO
        // Navigation data
        this.navigation = navigation_configurator_1.navigation_configurator;
        // this.navigation = navigation_configurator_debug;
        // Default layout
        this.layout = 'vertical';
    }
    Object.defineProperty(FuseNavbarComponent.prototype, "directive", {
        set: function (theDirective) {
            if (!theDirective) {
                return;
            }
        },
        enumerable: true,
        configurable: true
    });
    FuseNavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navigationServiceWatcher = this.navigationService.onItemCollapseToggled.subscribe(function () {
            _this.fusePerfectScrollbarUpdateTimeout = setTimeout(function () {
                _this.fusePerfectScrollbar.update();
            }, 310);
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                if (_this.sidebarService.getSidebar('navbar')) {
                    _this.sidebarService.getSidebar('navbar').close();
                }
            }
        });
    };
    FuseNavbarComponent.prototype.ngOnDestroy = function () {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }
        if (this.navigationServiceWatcher) {
            this.navigationServiceWatcher.unsubscribe();
        }
        this.onRoleChanged.unsubscribe();
    };
    FuseNavbarComponent.prototype.toggleSidebarOpened = function () {
        this.sidebarService.getSidebar('navbar').toggleOpen();
    };
    FuseNavbarComponent.prototype.toggleSidebarFolded = function () {
        this.sidebarService.getSidebar('navbar').toggleFold();
    };
    return FuseNavbarComponent;
}());
exports.FuseNavbarComponent = FuseNavbarComponent;
