"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var util_1 = require("util");
var dialog_factory_component_1 = require("app/main/content/_components/_shared/dialogs/_dialog-factory/dialog-factory.component");
var configurations_1 = require("app/main/content/_model/configurator/configurations");
var rootMenuItems = [
    { id: 1, label: 'Menü', icon: 'menu' }
    // { id: 2, label: 'Bearbeiten' },
    // { id: 3, label: 'Ansicht' },
    // { id: 4, label: 'Extras' },
    // { id: 5, label: 'Hilfe' },
];
var subMenuItems = [
    {
        rootId: 1,
        id: 1,
        label: 'Neues Matching',
        clickAction: 'newClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 2,
        label: 'Matching öffnen',
        clickAction: 'openClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 3,
        label: 'Matching speichern',
        clickAction: 'saveClicked',
        icon: undefined
    },
    {
        rootId: 1,
        id: 4,
        label: 'Matching löschen',
        clickAction: 'deleteClicked',
        icon: undefined
    }
    // { rootId: 2, id: 1, label: 'Test Entry 21', clickAction: 'test', icon: undefined },
    // { rootId: 2, id: 2, label: 'Test Entry 22', clickAction: 'test', icon: undefined },
    // { rootId: 2, id: 3, label: 'Test Entry 23', clickAction: 'test', icon: undefined },
    // { rootId: 2, id: 4, label: 'Test Entry 24', clickAction: 'test', icon: undefined },
    // { rootId: 3, id: 1, label: 'Test Entry 31', clickAction: 'test', icon: undefined },
    // { rootId: 3, id: 2, label: 'Test Entry 32', clickAction: 'test', icon: undefined },
    // { rootId: 3, id: 3, label: 'Test Entry 33', clickAction: 'test', icon: undefined },
    // { rootId: 4, id: 1, label: 'Test Entry 41', clickAction: 'test', icon: undefined },
    // { rootId: 4, id: 2, label: 'Test Entry 42', clickAction: 'test', icon: undefined },
    // { rootId: 4, id: 3, label: 'Test Entry 43', clickAction: 'test', icon: undefined },
    // { rootId: 4, id: 4, label: 'Test Entry 44', clickAction: 'test', icon: undefined },
    // { rootId: 4, id: 5, label: 'Test Entry 45', clickAction: 'test', icon: undefined },
    // { rootId: 5, id: 1, label: 'Test Entry 51', clickAction: 'test', icon: undefined },
];
var MatchingTopMenuBarComponent = /** @class */ (function () {
    function MatchingTopMenuBarComponent(dialogFactory) {
        this.dialogFactory = dialogFactory;
        this.isLoaded = false;
        this.rootMenuItems = rootMenuItems;
        this.subMenuItems = subMenuItems;
        this.currentRootId = 1;
        this.menuOptionClickedEvent = new core_1.EventEmitter();
    }
    MatchingTopMenuBarComponent.prototype.ngOnInit = function () { };
    MatchingTopMenuBarComponent.prototype.ngAfterViewInit = function () {
        this.submenuContainer.nativeElement.style.position = 'absolute';
        this.submenuContainer.nativeElement.style.overflow = 'hidden';
        this.submenuContainer.nativeElement.style.padding = '0px';
        this.submenuContainer.nativeElement.style.top = '29px';
        this.submenuContainer.nativeElement.style.left = '10px';
        this.submenuContainer.nativeElement.style.height = 'auto';
        this.submenuContainer.nativeElement.style.width = '200px';
        this.submenuContainer.nativeElement.style.background = 'white';
        this.submenuContainer.nativeElement.style.font =
            'Arial, Helvetica, sans-serif';
        this.submenuContainer.nativeElement.style.display = 'none';
        this.menubarContainer.nativeElement.style.position = 'absolute';
        this.menubarContainer.nativeElement.style.overflow = 'hidden';
        // this.menubarContainer.nativeElement.style.padding = '2px';
        this.menubarContainer.nativeElement.style.right = '0px';
        this.menubarContainer.nativeElement.style.top = '0px';
        this.menubarContainer.nativeElement.style.left = '0px';
        this.menubarContainer.nativeElement.style.height = '35px';
        this.menubarContainer.nativeElement.style.background = 'white';
        this.menubarContainer.nativeElement.style.font =
            'Arial, Helvetica, sans-serif';
        this.titleBarTextContainer.nativeElement.style.maxWidth =
            // clientwidth of titlebar - margin left/right - icon left
            this.menubarContainer.nativeElement.clientWidth - 50 - 15 + 'px';
        var outer = this;
        document.addEventListener('click', function (event) {
            outer.handleHTMLClickEvent(event);
        });
    };
    MatchingTopMenuBarComponent.prototype.handleHTMLClickEvent = function (event) {
        if (event.srcElement.className !== 'menuitem' &&
            event.srcElement.className !==
                'menuitem-icon mat-icon notranslate material-icons mat-icon-no-color') {
            this.submenuContainer.nativeElement.style.display = 'none';
        }
    };
    MatchingTopMenuBarComponent.prototype.openSubmenu = function (event, rootItemId) {
        this.currentRootId = rootItemId;
        this.submenuContainer.nativeElement.style.display = 'block';
        var leftPosition = this.calculateLeftSpace(event.srcElement.offsetParent, rootItemId);
        this.submenuContainer.nativeElement.style.left = leftPosition + 'px';
    };
    MatchingTopMenuBarComponent.prototype.calculateLeftSpace = function (offsetParent, rootItemId) {
        var e_1, _a;
        var space = 10;
        if (!util_1.isNullOrUndefined(offsetParent.children)) {
            try {
                for (var _b = __values(offsetParent.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var child = _c.value;
                    if (rootItemId - 1 <= 0) {
                        return space;
                    }
                    space += child.clientWidth;
                    rootItemId--;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    MatchingTopMenuBarComponent.prototype.newClicked = function (event, item) {
        var _this = this;
        this.dialogFactory.openNewMatchingDialog(this.tenantId).then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.menuOptionClickedEvent.emit({ id: 'editor_new', payload: ret });
            }
        });
    };
    MatchingTopMenuBarComponent.prototype.editClicked = function () {
        this.performEdit();
    };
    MatchingTopMenuBarComponent.prototype.performEdit = function () {
        var _this = this;
        this.dialogFactory
            .openEditMetaMatchingDialog(this.tenantId, this.currentMatchingConfiguration)
            .then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.menuOptionClickedEvent.emit({
                    id: 'editor_meta_edit',
                    payload: {
                        name: ret.matchingConfiguration.name,
                    }
                });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    MatchingTopMenuBarComponent.prototype.openClicked = function (event, item) {
        var _this = this;
        this.dialogFactory.openOpenMatchingDialog(this.tenantId).then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.menuOptionClickedEvent.emit({ id: 'editor_open', payload: ret });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    MatchingTopMenuBarComponent.prototype.saveClicked = function (event, item) {
        var _this = this;
        this.dialogFactory
            .confirmationDialog('Save', 'Are you sure you want to save? The existing model will be overidden...')
            .then(function (result) {
            if (result) {
                _this.menuOptionClickedEvent.emit({ id: 'editor_save' });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    MatchingTopMenuBarComponent.prototype.deleteClicked = function (event, item) {
        var _this = this;
        this.dialogFactory.openDeleteMatchingDialog(this.tenantId, this.redirectUrl).then(function (ret) {
            if (!util_1.isNullOrUndefined(ret)) {
                _this.menuOptionClickedEvent.emit({ id: 'editor_delete', payload: ret });
            }
            else {
                _this.menuOptionClickedEvent.emit({ id: 'cancelled' });
            }
        });
    };
    return MatchingTopMenuBarComponent;
}());
exports.MatchingTopMenuBarComponent = MatchingTopMenuBarComponent;
