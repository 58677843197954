"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_factory_component_1 = require("../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var matching_collector_configuration_service_1 = require("app/main/content/_service/configuration/matching-collector-configuration.service");
var matching_configuration_service_1 = require("app/main/content/_service/configuration/matching-configuration.service");
var matching_operator_relationship_service_1 = require("app/main/content/_service/configuration/matching-operator-relationship.service");
var objectid_service_1 = require("app/main/content/_service/objectid.service.");
var configurations_1 = require("app/main/content/_model/configurator/configurations");
var utils_and_constants_1 = require("../class-configurator/utils-and-constants");
var matching_1 = require("app/main/content/_model/matching");
var myMxCell_1 = require("../myMxCell");
var popup_menu_1 = require("./popup-menu");
var property_1 = require("app/main/content/_model/configurator/property/property");
var util_1 = require("util");
var router_1 = require("@angular/router");
var response_service_1 = require("app/main/content/_service/response.service");
var environment_1 = require("environments/environment");
var HEADER_WIDTH = 400;
var HEADER_HEIGHT = 50;
var HEADER_Y = 20;
var SPACE_Y = 20;
var SPACE_X = 20;
var CLASSDEFINITION_HEAD_HEIGHT = 40;
var CLASSDEFINITION_WIDTH = 200;
var CLASSDEFINTIION_OFFSET_X = 100;
var CLASSDEFINTIION_SPACE_Y = 10;
var PROPERTY_HEIGHT = 20;
var PROPERTY_SPACE_Y = 5;
var PROPERTY_SPACE_X = 5;
var BUTTON_WIDTH = 40;
var Button_HEIGHT = 40;
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var MatchingConfiguratorComponent = /** @class */ (function () {
    function MatchingConfiguratorComponent(route, router, matchingCollectorConfigurationService, matchingConfigurationService, matchingOperatorRelationshipService, objectIdService, dialogFactory, responseService) {
        this.route = route;
        this.router = router;
        this.matchingCollectorConfigurationService = matchingCollectorConfigurationService;
        this.matchingConfigurationService = matchingConfigurationService;
        this.matchingOperatorRelationshipService = matchingOperatorRelationshipService;
        this.objectIdService = objectIdService;
        this.dialogFactory = dialogFactory;
        this.responseService = responseService;
        this.matchingOperatorPalettes = utils_and_constants_1.CConstants.matchingOperatorPalettes;
        this.matchingConnectorPalettes = utils_and_constants_1.CConstants.matchingConnectorPalettes;
        this.deleteOperationPalette = utils_and_constants_1.CConstants.deleteOperationPalette;
    }
    MatchingConfiguratorComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.route.queryParams.subscribe(function (params) {
                    if (((environment_1.environment.MODE === 'iVolunteer' || environment_1.environment.MODE === 'all') && (util_1.isNullOrUndefined(params['tenantId']) || util_1.isNullOrUndefined(params['redirect'])))
                        ||
                            (environment_1.environment.MODE === 'flexprod' && util_1.isNullOrUndefined(params['tenantId']))) {
                        _this.router.navigate(['main/invalid-parameters']);
                    }
                    else {
                        _this.tenantId = params['tenantId'];
                        _this.redirectUrl = params['redirect'];
                    }
                });
                this.confirmDelete = true;
                this.includeConnectors = true;
                return [2 /*return*/];
            });
        });
    };
    MatchingConfiguratorComponent.prototype.loadClassesAndRelationships = function (matchingConfiguration) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.matchingCollectorConfigurationService
                    .getMatchingData(matchingConfiguration)
                    .toPromise().then(function (data) {
                    _this.data = data;
                    _this.redrawContent();
                });
                return [2 /*return*/];
            });
        });
    };
    MatchingConfiguratorComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.graph = new mx.mxGraph(this.graphContainer.nativeElement);
        this.graph.isCellSelectable = function (cell) {
            var state = this.view.getState(cell);
            var style = state != null ? state.style : this.getCellStyle(cell);
            return (this.isCellsSelectable() && !this.isCellLocked(cell) && style['selectable'] !== 0);
        };
        this.graph.getCursorForCell = function (cell) {
            if (util_1.isNullOrUndefined(cell.cellType)) {
                return 'default';
            }
            if (cell.cellType.startsWith('ADD_CLASS_BUTTON')) {
                return mx.mxConstants.CURSOR_TERMINAL_HANDLE;
            }
        };
        this.graph.getEdgeValidationError = function (edge, source, target) {
            var e_1, _a, e_2, _b;
            if (!util_1.isNullOrUndefined(source) && !util_1.isNullOrUndefined(source.edges) && source.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR &&
                !util_1.isNullOrUndefined(edge.target) && edge.target.id === target.id) {
                if (source.edges.length >= 2) {
                    return '';
                }
                try {
                    for (var _c = __values(source.edges), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var e = _d.value;
                        if (!util_1.isNullOrUndefined(e.source) && e.source.id === source.id) {
                            return '';
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            else if (!util_1.isNullOrUndefined(target) && !util_1.isNullOrUndefined(target.edges) && target.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR
                && !util_1.isNullOrUndefined(edge.source) && edge.source.id === source.id) {
                if (target.edges.length >= 2) {
                    return '';
                }
                if (source.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR) {
                    return '';
                }
                try {
                    for (var _e = __values(target.edges), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var e = _f.value;
                        if (!util_1.isNullOrUndefined(e.target) && e.target.id === target.id) {
                            return '';
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        };
        if (!mx.mxClient.isBrowserSupported()) {
            mx.mxUtils.error('Browser is not supported!', 200, false);
        }
        else {
            // Disables the built-in context menu
            mx.mxEvent.disableContextMenu(this.graphContainer.nativeElement);
            this.graph.popupMenuHandler = this.createPopupMenu(this.graph);
            this.graph.setPanning(true);
            this.graph.useScrollbarsForPanning = true;
            this.graph.addListener(mx.mxEvent.CLICK, function (sender, evt) {
                _this.handleClickEvent(evt);
            });
            this.graph.addListener(mx.mxEvent.DOUBLE_CLICK, function (sender, evt) {
                _this.handleDoubleClickEvent(evt);
            });
        }
    };
    MatchingConfiguratorComponent.prototype.createPopupMenu = function (graph) {
        var popupMenu = new popup_menu_1.MatchingConfiguratorPopupMenu(graph, this);
        return popupMenu.createPopupMenuHandler(graph);
    };
    MatchingConfiguratorComponent.prototype.clearEditor = function () {
        this.graph.getModel().beginUpdate();
        try {
            this.graph.getModel().clear();
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    MatchingConfiguratorComponent.prototype.redrawContent = function () {
        this.clearEditor();
        this.insertClassDefinitionsLeft();
        this.insertClassDefinitionsRight();
        this.insertMatchingOperatorsAndRelationships();
    };
    MatchingConfiguratorComponent.prototype.insertClassDefinitionsLeft = function () {
        var title = this.graph.insertVertex(this.graph.getDefaultParent(), 'left_header', this.data.matchingConfiguration.leftSideName, SPACE_X, HEADER_Y, HEADER_WIDTH, HEADER_HEIGHT, utils_and_constants_1.CConstants.mxStyles.matchingRowHeader);
        title.setConnectable(false);
        var y = title.geometry.y + title.geometry.height + CLASSDEFINTIION_SPACE_Y;
        var cell = this.insertClassDefinitionsIntoGraph(this.data.matchingConfiguration.leftAddedClassDefinitionPaths, this.data.leftMappingConfigurations.mappings, new mx.mxGeometry(SPACE_X + CLASSDEFINTIION_OFFSET_X, y, CLASSDEFINITION_WIDTH, CLASSDEFINITION_HEAD_HEIGHT), 'l');
        if (!util_1.isNullOrUndefined(cell)) {
            y = cell.geometry.y + cell.geometry.height + CLASSDEFINTIION_SPACE_Y;
        }
        var addButton = this.graph.insertVertex(this.graph.getDefaultParent(), 'left_add', '+', SPACE_X + CLASSDEFINTIION_OFFSET_X + (CLASSDEFINITION_WIDTH / 2) - (BUTTON_WIDTH / 2), y, BUTTON_WIDTH, Button_HEIGHT, utils_and_constants_1.CConstants.mxStyles.matchingAddButton);
        addButton.setConnectable(false);
        addButton.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_BUTTON;
    };
    MatchingConfiguratorComponent.prototype.insertClassDefinitionsRight = function () {
        var x = this.graphContainer.nativeElement.offsetWidth;
        var xHeader = x - HEADER_WIDTH - SPACE_X;
        var xClassDefinition = x - CLASSDEFINITION_WIDTH - CLASSDEFINTIION_OFFSET_X - SPACE_X;
        var y = HEADER_Y;
        var title = this.graph.insertVertex(this.graph.getDefaultParent(), 'right_header', this.data.matchingConfiguration.rightSideName, xHeader, y, HEADER_WIDTH, HEADER_HEIGHT, utils_and_constants_1.CConstants.mxStyles.matchingRowHeader);
        title.setConnectable(false);
        y = title.geometry.y + title.geometry.height + SPACE_Y;
        var cell = this.insertClassDefinitionsIntoGraph(this.data.matchingConfiguration.rightAddedClassDefinitionPaths, this.data.rightMappingConfigurations.mappings, new mx.mxGeometry(xClassDefinition, y, CLASSDEFINITION_WIDTH, CLASSDEFINITION_HEAD_HEIGHT), 'r');
        if (!util_1.isNullOrUndefined(cell)) {
            y = cell.geometry.y + cell.geometry.height + SPACE_Y;
        }
        var addButton = this.graph.insertVertex(this.graph.getDefaultParent(), 'right_add', '+', xClassDefinition + (CLASSDEFINITION_WIDTH / 2) - (BUTTON_WIDTH / 2), y, BUTTON_WIDTH, Button_HEIGHT, utils_and_constants_1.CConstants.mxStyles.matchingAddButton);
        addButton.setConnectable(false);
        addButton.cellType = myMxCell_1.MyMxCellType.ADD_CLASS_BUTTON;
    };
    MatchingConfiguratorComponent.prototype.insertClassDefinitionsIntoGraph = function (ids, mappings, geometry, pathPrefix) {
        var e_3, _a;
        var cell;
        var _loop_1 = function (id) {
            var mapping = mappings.entities.find(function (e) { return e.path === id; });
            cell = this_1.insertClassDefinition(id, mapping, geometry, pathPrefix);
            geometry = new mx.mxGeometry(cell.geometry.x, cell.geometry.y + cell.geometry.height + CLASSDEFINTIION_SPACE_Y, cell.geometry.width, cell.geometry.height);
        };
        var this_1 = this;
        try {
            for (var ids_1 = __values(ids), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
                var id = ids_1_1.value;
                _loop_1(id);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (ids_1_1 && !ids_1_1.done && (_a = ids_1.return)) _a.call(ids_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return cell;
    };
    MatchingConfiguratorComponent.prototype.insertClassDefinition = function (id, matchingEntity, geometry, pathPrefix) {
        var cell = new mx.mxCell(matchingEntity.classDefinition.name, geometry, utils_and_constants_1.CConstants.mxStyles.matchingClassNormal);
        cell.setVertex(true);
        cell.cellType = myMxCell_1.MyMxCellType.CLASS;
        var idPath = id;
        if (!util_1.isNullOrUndefined(pathPrefix)) {
            idPath = pathPrefix + matchingEntity.pathDelimiter + idPath;
        }
        cell.setId(idPath);
        cell = this.addPropertiesToCell(cell, matchingEntity, PROPERTY_SPACE_X, CLASSDEFINITION_HEAD_HEIGHT + PROPERTY_SPACE_Y, pathPrefix);
        var overlay = this.constructRemoveOverlay();
        this.graph.addCellOverlay(cell, overlay);
        var numberOfProperties = util_1.isNullOrUndefined(matchingEntity.classDefinition.properties.length) || matchingEntity.classDefinition.properties.length === 0
            ? 0 : matchingEntity.classDefinition.properties.length;
        var rectHeight = numberOfProperties === 0
            ? CLASSDEFINITION_HEAD_HEIGHT
            : CLASSDEFINITION_HEAD_HEIGHT + PROPERTY_SPACE_Y + (numberOfProperties * PROPERTY_HEIGHT) + PROPERTY_SPACE_Y;
        cell.geometry.setRect(cell.geometry.x, cell.geometry.y, cell.geometry.width, rectHeight);
        return this.graph.addCell(cell);
    };
    MatchingConfiguratorComponent.prototype.constructRemoveOverlay = function () {
        var _this = this;
        var overlay = new mx.mxCellOverlay(new mx.mxImage('/assets/icons/class_editor/times-solid_white.png', 14, 20), 'Overlay', mx.mxConstants.ALIGN_RIGHT, mx.mxConstants.ALIGN_TOP, new mx.mxPoint(-9, 10));
        overlay.tooltip = 'entfernen';
        overlay.cursor = 'pointer';
        overlay.addListener(mx.mxEvent.CLICK, function (sender, evt) {
            _this.handleRemoveOverlayClickEvent(evt);
        });
        return overlay;
    };
    MatchingConfiguratorComponent.prototype.addPropertiesToCell = function (cell, entry, startX, startY, pathPrefix) {
        var e_4, _a;
        var classDefinition = entry.classDefinition;
        var lastPropertyGeometry = new mx.mxGeometry(40, 40);
        if (!util_1.isNullOrUndefined(classDefinition.properties)) {
            try {
                for (var _b = __values(classDefinition.properties), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var p = _c.value;
                    var idPath = entry.path + entry.pathDelimiter + p.id;
                    if (!util_1.isNullOrUndefined(pathPrefix)) {
                        idPath = pathPrefix + entry.pathDelimiter + idPath;
                    }
                    var propertyEntry = this.graph.insertVertex(cell, idPath, p.name, startX, startY + lastPropertyGeometry.height, 190, 20, utils_and_constants_1.CConstants.mxStyles.matchingProperty);
                    if (p.type === property_1.PropertyType.TREE) {
                        propertyEntry.cellType = myMxCell_1.MyMxCellType.TREE_PROPERTY;
                        propertyEntry.setStyle(utils_and_constants_1.CConstants.mxStyles.propertyTree);
                    }
                    else {
                        propertyEntry.cellType = myMxCell_1.MyMxCellType.FLAT_PROPERTY;
                    }
                    propertyEntry.setConnectable(true);
                    lastPropertyGeometry = propertyEntry.geometry;
                    startX = lastPropertyGeometry.x;
                    startY = lastPropertyGeometry.y;
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return cell;
    };
    MatchingConfiguratorComponent.prototype.insertMatchingOperatorsAndRelationships = function () {
        var e_5, _a;
        try {
            for (var _b = __values(this.data.relationships), _c = _b.next(); !_c.done; _c = _b.next()) {
                var entry = _c.value;
                var operatorCell = this.insertMatchingOperator(entry);
                var leftCell = void 0;
                if (!util_1.isNullOrUndefined(entry.leftMatchingEntityPath)) {
                    var leftCellId = 'l' + this.data.pathDelimiter + entry.leftMatchingEntityPath;
                    leftCell = this.graph.getModel().getCell(leftCellId);
                }
                var rightCell = void 0;
                if (!util_1.isNullOrUndefined(entry.rightMatchingEntityPath)) {
                    var rightCellId = 'r' + this.data.pathDelimiter + entry.rightMatchingEntityPath;
                    rightCell = this.graph.getModel().getCell(rightCellId);
                }
                this.insertRelationship(leftCell, operatorCell);
                this.insertRelationship(operatorCell, rightCell);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    MatchingConfiguratorComponent.prototype.insertMatchingOperator = function (relationship) {
        var cell = this.graph.insertVertex(this.graph.getDefaultParent(), relationship.id, null, relationship.coordX, relationship.coordY, 50, 50, "shape=image;image=" + this.getPathForMatchingOperatorType(relationship.matchingOperatorType) + ";" + utils_and_constants_1.CConstants.mxStyles.matchingOperator);
        cell.cellType = myMxCell_1.MyMxCellType.MATCHING_OPERATOR;
        cell.matchingOperatorType = relationship.matchingOperatorType;
        return cell;
    };
    MatchingConfiguratorComponent.prototype.insertRelationship = function (sourceCell, targetCell) {
        if (util_1.isNullOrUndefined(sourceCell) || util_1.isNullOrUndefined(targetCell)) {
            return;
        }
        var edge = this.graph.insertEdge(this.graph.getDefaultParent(), null, null, sourceCell, targetCell, utils_and_constants_1.CConstants.mxStyles.matchingConnector);
        edge.cellType = myMxCell_1.MyMxCellType.MATCHING_CONNECTOR;
        return edge;
    };
    MatchingConfiguratorComponent.prototype.getPathForMatchingOperatorType = function (matchingOperatorType) {
        var paletteItem = utils_and_constants_1.CConstants.matchingOperatorPalettes.find(function (ret) { return ret.id === matchingOperatorType; });
        return paletteItem.imgPath;
    };
    /*
     * .........Top Menu Bar Options..........
     */
    MatchingConfiguratorComponent.prototype.consumeMenuOptionClickedEvent = function (event) {
        var _this = this;
        this.graph.setEnabled(true);
        this.displayOverlay = false;
        this.overlayEvent = undefined;
        this.overlayRelationship = undefined;
        switch (event.id) {
            case 'editor_save':
                this.performSave();
                break;
            case 'editor_open':
                this.performOpen(event.payload);
                break;
            case 'editor_new':
                this.performNew(event.payload);
                break;
            case 'editor_delete':
                if (!util_1.isNullOrUndefined(event.payload) &&
                    !util_1.isNullOrUndefined(this.data.matchingConfiguration) &&
                    event.payload.find(function (id) { return id === _this.data.matchingConfiguration.id; })) {
                    this.data = undefined;
                    this.clearEditor();
                }
                break;
            case 'editor_meta_edit':
                this.data.matchingConfiguration.name = event.payload.name;
                break;
        }
    };
    MatchingConfiguratorComponent.prototype.performSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.updateModel();
                this.responseService.sendMatchingConfiguratorResponse(this.redirectUrl, { matchingConfiguration: this.data.matchingConfiguration, matchingOperatorRelationships: this.data.relationships, tenantId: this.tenantId }, null, 'save').toPromise().then(function (ret) {
                    _this.data.matchingConfiguration = ret;
                });
                this.redrawContent();
                return [2 /*return*/];
            });
        });
    };
    MatchingConfiguratorComponent.prototype.updateModel = function () {
        var e_6, _a;
        var cells = this.graph.getChildCells(this.graph.getDefaultParent());
        var matchingOperatorCells = cells.filter(function (cell) { return cell.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR; });
        var updatedRelationships = [];
        var _loop_2 = function (operatorCell) {
            var e_7, _a;
            if (util_1.isNullOrUndefined(operatorCell.edges)) {
                return "continue";
            }
            var relationship = this_2.data.relationships.find(function (r) { return r.id === operatorCell.id; });
            relationship.matchingOperatorType = operatorCell.matchingOperatorType;
            relationship.coordX = operatorCell.geometry.x;
            relationship.coordY = operatorCell.geometry.y;
            var leftSet = false;
            var rightSet = false;
            try {
                for (var _b = (e_7 = void 0, __values(operatorCell.edges)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var edge = _c.value;
                    if (util_1.isNullOrUndefined(edge.source) || util_1.isNullOrUndefined(edge.target)) {
                        continue;
                    }
                    if (edge.target.id === operatorCell.id) {
                        relationship.leftMatchingEntityPath = edge.source.id.substr(2);
                        relationship.leftMatchingEntityType =
                            edge.source.cellType === myMxCell_1.MyMxCellType.FLAT_PROPERTY
                                ? matching_1.MatchingEntityType.PROPERTY
                                : matching_1.MatchingEntityType.CLASS;
                        leftSet = true;
                    }
                    else if (edge.source.id === operatorCell.id) {
                        relationship.rightMatchingEntityPath = edge.target.id.substr(2);
                        relationship.rightMatchingEntityType =
                            edge.target.cellType === myMxCell_1.MyMxCellType.FLAT_PROPERTY
                                ? matching_1.MatchingEntityType.PROPERTY
                                : matching_1.MatchingEntityType.CLASS;
                        rightSet = true;
                        relationship.matchingConfigurationId = this_2.data.matchingConfiguration.id;
                    }
                    if (leftSet && rightSet) {
                        break;
                    }
                }
            }
            catch (e_7_1) { e_7 = { error: e_7_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_7) throw e_7.error; }
            }
            updatedRelationships.push(relationship);
        };
        var this_2 = this;
        try {
            for (var matchingOperatorCells_1 = __values(matchingOperatorCells), matchingOperatorCells_1_1 = matchingOperatorCells_1.next(); !matchingOperatorCells_1_1.done; matchingOperatorCells_1_1 = matchingOperatorCells_1.next()) {
                var operatorCell = matchingOperatorCells_1_1.value;
                _loop_2(operatorCell);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (matchingOperatorCells_1_1 && !matchingOperatorCells_1_1.done && (_a = matchingOperatorCells_1.return)) _a.call(matchingOperatorCells_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        this.data.relationships = updatedRelationships;
    };
    MatchingConfiguratorComponent.prototype.performOpen = function (matchingConfiguration) {
        this.loadClassesAndRelationships(matchingConfiguration);
    };
    MatchingConfiguratorComponent.prototype.performNew = function (dialogData) {
        var _this = this;
        var rightClassConfiguration = dialogData.rightClassConfiguration, rightIsUser = dialogData.rightIsUser, leftClassConfiguration = dialogData.leftClassConfiguration, leftIsUser = dialogData.leftIsUser, label = dialogData.label;
        var matchingConfiguration = new configurations_1.MatchingConfiguration({
            rightSideId: rightClassConfiguration.id, rightSideName: rightClassConfiguration.name, rightIsUser: rightIsUser,
            leftSideId: leftClassConfiguration.id, leftSideName: leftClassConfiguration.name, leftIsUser: leftIsUser,
            name: label, tenantId: this.tenantId
        });
        this.matchingConfigurationService.saveMatchingConfiguration(matchingConfiguration)
            .toPromise().then(function (ret) {
            matchingConfiguration.id = ret.id;
            _this.loadClassesAndRelationships(matchingConfiguration);
        });
    };
    /**
     * ...........Mouse Events..............
     */
    MatchingConfiguratorComponent.prototype.handleMousedownEvent = function (event, item, graph) {
        var _this = this;
        var positionEvent;
        var onDragstart = function (evt) {
            evt.dataTransfer.setData('text', item.id);
            evt.dataTransfer.effect = 'move';
        };
        var onDragOver = function (evt) {
            positionEvent = evt;
            evt.dataTransfer.dropEffect = 'none';
        };
        var onDragend = function (evt) {
            var addObjectToGraph = function (paletteItem) {
                var coords = graph.getPointForEvent(positionEvent, false);
                graph.getModel().beginUpdate();
                if (paletteItem.type === 'matchingOperator') {
                    var cell = _this.constructOperator(coords, paletteItem);
                    var relationship = new matching_1.MatchingOperatorRelationship();
                    relationship.id = cell.id;
                    relationship.coordX = cell.geometry.x;
                    relationship.coordY = cell.geometry.y;
                    relationship.matchingOperatorType = cell.matchingOperatorType;
                    _this.data.relationships.push(relationship);
                }
                else if (paletteItem.type === 'connector') {
                    graph.addCell(_this.constructConnector(coords, -100, 100));
                }
            };
            evt.dataTransfer.getData('text');
            try {
                addObjectToGraph(item);
            }
            finally {
                graph.getModel().endUpdate();
                removeEventListeners();
            }
        };
        var onMouseUp = function (evt) {
            removeEventListeners();
        };
        event.srcElement.addEventListener('dragend', onDragend);
        event.srcElement.addEventListener('mouseup', onMouseUp);
        event.srcElement.addEventListener('dragstart', onDragstart);
        this.graphContainer.nativeElement.addEventListener('dragover', onDragOver);
        var removeEventListeners = function () {
            event.srcElement.removeEventListener('dragend', onDragend);
            event.srcElement.removeEventListener('mouseup', onMouseUp);
            event.srcElement.removeEventListener('dragstart', onDragstart);
            _this.graphContainer.nativeElement.removeEventListener('dragover', onDragOver);
        };
    };
    MatchingConfiguratorComponent.prototype.constructConnector = function (coords, sourceOffsetX, targetOffsetX) {
        var cell = new mx.mxCell(undefined, new mx.mxGeometry(coords.x, coords.y, 0, 0), utils_and_constants_1.CConstants.mxStyles.matchingConnector);
        cell.cellType = myMxCell_1.MyMxCellType.MATCHING_CONNECTOR;
        cell.setEdge(true);
        cell.setVertex(false);
        if (!util_1.isNullOrUndefined(sourceOffsetX)) {
            cell.geometry.setTerminalPoint(new mx.mxPoint(coords.x + sourceOffsetX, coords.y), true);
        }
        if (!util_1.isNullOrUndefined(targetOffsetX)) {
            cell.geometry.setTerminalPoint(new mx.mxPoint(coords.x + targetOffsetX, coords.y), false);
        }
        cell.geometry.relative = true;
        return cell;
    };
    MatchingConfiguratorComponent.prototype.constructOperator = function (coords, paletteItem) {
        var cell = this.graph.insertVertex(this.graph.getDefaultParent(), null, null, coords.x, coords.y, 50, 50, "shape=image;image=" + paletteItem.imgPath + ";" + utils_and_constants_1.CConstants.mxStyles.matchingOperator);
        cell.cellType = myMxCell_1.MyMxCellType.MATCHING_OPERATOR;
        cell.matchingOperatorType = paletteItem.id;
        cell.id = this.objectIdService.getNewObjectId();
        if (this.includeConnectors) {
            var edge1 = this.constructConnector(coords, -100, undefined);
            cell.insertEdge(edge1, false);
            this.graph.addCell(edge1);
            var edge2 = this.constructConnector(coords, undefined, 150);
            cell.insertEdge(edge2, true);
            this.graph.addCell(edge2);
        }
        return cell;
    };
    MatchingConfiguratorComponent.prototype.handleDoubleClickEvent = function (event) {
        var cell = event.properties.cell;
        if (!util_1.isNullOrUndefined(cell) && cell.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR &&
            !this.displayOverlay && event.properties.event.button === 0) {
            this.handleOverlayOpened(event, cell);
        }
    };
    MatchingConfiguratorComponent.prototype.handleClickEvent = function (event) {
        var _this = this;
        var cell = event.properties.cell;
        if (util_1.isNullOrUndefined(cell) || util_1.isNullOrUndefined(cell.cellType) || this.displayOverlay) {
            return;
        }
        if (event.properties.event.button === 0) {
            if (cell.cellType === myMxCell_1.MyMxCellType.ADD_CLASS_BUTTON) {
                var entityMappingConfiguration = void 0;
                var matchingConfiguration = void 0;
                var existingEntityPaths = [];
                if (cell.id === 'left_add') {
                    entityMappingConfiguration = this.data.leftMappingConfigurations;
                    matchingConfiguration = this.data.matchingConfiguration;
                    existingEntityPaths.push.apply(existingEntityPaths, __spread(this.data.matchingConfiguration.leftAddedClassDefinitionPaths));
                }
                else {
                    entityMappingConfiguration = this.data.rightMappingConfigurations;
                    matchingConfiguration = this.data.matchingConfiguration;
                    existingEntityPaths.push.apply(existingEntityPaths, __spread(this.data.matchingConfiguration.rightAddedClassDefinitionPaths));
                }
                this.dialogFactory.openAddClassDefinitionDialog(entityMappingConfiguration, existingEntityPaths).then(function (ret) {
                    var _a, _b;
                    if (!util_1.isNullOrUndefined(ret) && !util_1.isNullOrUndefined(ret.addedEntities)) {
                        if (cell.id === 'left_add') {
                            (_a = _this.data.matchingConfiguration.leftAddedClassDefinitionPaths).push.apply(_a, __spread(ret.addedEntities.map(function (e) { return e.path; })));
                        }
                        else {
                            (_b = _this.data.matchingConfiguration.rightAddedClassDefinitionPaths).push.apply(_b, __spread(ret.addedEntities.map(function (e) { return e.path; })));
                        }
                        _this.redrawContent();
                    }
                });
            }
        }
    };
    MatchingConfiguratorComponent.prototype.handleRemoveOverlayClickEvent = function (event) {
        var _this = this;
        var cell = event.properties['cell'];
        if (this.confirmDelete) {
            this.dialogFactory.confirmationDialog('Löschen bestätigen', 'Soll die Klasse wirklich entfernt werden?').then(function (ret) {
                if (ret) {
                    _this.deleteClassDefinitionCell(cell);
                }
            });
        }
        else {
            this.deleteClassDefinitionCell(cell);
        }
    };
    /**
     * ...........Delete Mode..............
     */
    MatchingConfiguratorComponent.prototype.handleDeleteRelationship = function (cells) {
        var operatorsToRemove = cells.filter(function (c) { return c.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR; });
        this.deleteOperators(operatorsToRemove);
        var connectorsToRemove = cells.filter(function (c) { return c.cellType === myMxCell_1.MyMxCellType.MATCHING_CONNECTOR; });
        this.deleteConnectors(connectorsToRemove);
    };
    MatchingConfiguratorComponent.prototype.deleteOperators = function (cells) {
        var cellsToRemove = cells;
        try {
            this.graph.getModel().beginUpdate();
            var removedCells = this.graph.removeCells(cellsToRemove, true);
            this.data.relationships = this.data.relationships.filter(function (r) { return cellsToRemove.findIndex(function (c) { return c.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR && r.id === c.id; }) < 0; });
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    MatchingConfiguratorComponent.prototype.deleteConnectors = function (cells) {
        var cellsToRemove = cells;
        try {
            this.graph.getModel().beginUpdate();
            var removeCells = this.graph.removeCells(cellsToRemove, false);
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    MatchingConfiguratorComponent.prototype.deleteClassDefinitionCell = function (deleteCell) {
        this.updateModel();
        try {
            this.graph.getModel().beginUpdate();
            this.graph.removeCells([deleteCell], true);
            var actualId_1 = deleteCell.id.substr(2);
            if (deleteCell.id.startsWith('l')) {
                this.data.matchingConfiguration.leftAddedClassDefinitionPaths = this.data.matchingConfiguration.leftAddedClassDefinitionPaths.filter(function (path) { return path !== actualId_1; });
                var relationship = this.data.relationships.find(function (r) { return r.leftMatchingEntityPath.startsWith(actualId_1); });
                if (!util_1.isNullOrUndefined(relationship)) {
                    relationship.leftMatchingEntityPath = undefined;
                    relationship.leftMatchingEntityType = undefined;
                }
            }
            else if (deleteCell.id.startsWith('r')) {
                this.data.matchingConfiguration.rightAddedClassDefinitionPaths = this.data.matchingConfiguration.rightAddedClassDefinitionPaths.filter(function (path) { return path !== actualId_1; });
                var relationship = this.data.relationships.find(function (r) { return r.rightMatchingEntityPath.startsWith(actualId_1); });
                if (!util_1.isNullOrUndefined(relationship)) {
                    relationship.rightMatchingEntityPath = undefined;
                    relationship.rightMatchingEntityType = undefined;
                }
            }
            this.redrawContent();
        }
        finally {
            this.graph.getModel().endUpdate();
        }
    };
    /**
     * ...........Overlay..............
     */
    MatchingConfiguratorComponent.prototype.handleOverlayOpened = function (event, cell) {
        this.overlayRelationship = this.data.relationships.find(function (r) { return r.id === cell.id; });
        this.overlayEvent = event.properties.event;
        this.displayOverlay = true;
        this.graphContainer.nativeElement.style.overflow = 'hidden';
    };
    MatchingConfiguratorComponent.prototype.handleOverlayClosedEvent = function (event) {
        this.displayOverlay = false;
        this.overlayRelationship = undefined;
        this.overlayEvent = undefined;
        this.graphContainer.nativeElement.style.overflow = 'scroll';
        if (!util_1.isNullOrUndefined(event)) {
            var index = this.data.relationships.findIndex(function (r) { return r.id === event.id; });
            this.data.relationships[index] = event;
            try {
                this.graph.getModel().beginUpdate();
                var cell = this.graph.getModel().getChildVertices(this.graph.getDefaultParent()).find(function (c) { return c.id === event.id; });
                cell.matchingOperatorType = event.matchingOperatorType;
                this.graph.setCellStyle("shape=image;image=" + this.getPathForMatchingOperatorType(cell.matchingOperatorType) + ";" +
                    utils_and_constants_1.CConstants.mxStyles.matchingOperator, [cell]);
                cell = this.graph.getModel().getCell(event.id);
            }
            finally {
                this.graph.getModel().endUpdate();
            }
        }
    };
    /**
     * ...........Key Handler..............
     */
    MatchingConfiguratorComponent.prototype.handleKeyboardEvent = function (event) {
        var _this = this;
        if (event.key === 'Delete') {
            var cells_1 = this.graph.getSelectionCells();
            if (this.confirmDelete) {
                this.dialogFactory.confirmationDialog('Löschen bestätigen', 'Soll das Objekt wirklich gelöscht werden?').then(function (ret) {
                    if (ret) {
                        _this.handleDeleteRelationship(cells_1);
                    }
                });
            }
            else {
                this.handleDeleteRelationship(cells_1);
            }
        }
    };
    MatchingConfiguratorComponent.prototype.getMatchingConfiguration = function () {
        if (!util_1.isNullOrUndefined(this.data)) {
            return this.data.matchingConfiguration;
        }
        else {
            return undefined;
        }
    };
    return MatchingConfiguratorComponent;
}());
exports.MatchingConfiguratorComponent = MatchingConfiguratorComponent;
