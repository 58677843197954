"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("util");
// validation function
function stringUniqueValidator(strings, ignoreList) {
    return function (control) {
        if (util_1.isNullOrUndefined(strings)) {
            return null;
        }
        if (util_1.isNullOrUndefined(control.value) || control.value === '') {
            return null;
        }
        var item;
        item = strings.find(function (s) {
            return s.trim().toLocaleLowerCase() === control.value.trim().toLocaleLowerCase();
        });
        if (!util_1.isNullOrUndefined(ignoreList) && ignoreList.findIndex(function (s) { return s === item; }) !== -1) {
            return null;
        }
        return !util_1.isNullOrUndefined(item) ? { 'stringunique': { 'stringunique': item, 'actual': control.value } } : null;
    };
}
exports.stringUniqueValidator = stringUniqueValidator;
