"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var flex_layout_1 = require("@angular/flex-layout");
var ngx_cookie_service_1 = require("ngx-cookie-service");
var match_media_service_1 = require("@fuse/services/match-media.service");
var navigation_service_1 = require("@fuse/components/navigation/navigation.service");
var config_service_1 = require("@fuse/services/config.service");
var FuseShortcutsComponent = /** @class */ (function () {
    function FuseShortcutsComponent(renderer, observableMedia, fuseMatchMedia, fuseNavigationService, fuseConfig, cookieService) {
        var _this = this;
        this.renderer = renderer;
        this.observableMedia = observableMedia;
        this.fuseMatchMedia = fuseMatchMedia;
        this.fuseNavigationService = fuseNavigationService;
        this.fuseConfig = fuseConfig;
        this.cookieService = cookieService;
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(function (newSettings) {
                _this.toolbarColor = newSettings.colorClasses.toolbar;
            });
    }
    FuseShortcutsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this.fuseNavigationService.getFlatNavigation(this.navigation);
        var cookieExists = this.cookieService.check('FUSE2.shortcuts');
        if (cookieExists) {
            this.shortcutItems = JSON.parse(this.cookieService.get('FUSE2.shortcuts'));
        }
        else {
            // User's shortcut items
            this.shortcutItems = [];
        }
        this.matchMediaSubscription =
            this.fuseMatchMedia.onMediaChange.subscribe(function () {
                if (_this.observableMedia.isActive('gt-sm')) {
                    _this.hideMobileShortcutsPanel();
                }
            });
    };
    FuseShortcutsComponent.prototype.ngOnDestroy = function () {
        this.matchMediaSubscription.unsubscribe();
    };
    FuseShortcutsComponent.prototype.search = function (event) {
        var value = event.target.value.toLowerCase();
        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;
            return;
        }
        this.searching = true;
        this.filteredNavigationItems = this.navigationItems.filter(function (navigationItem) {
            return navigationItem.title.toLowerCase().includes(value);
        });
    };
    FuseShortcutsComponent.prototype.toggleShortcut = function (event, itemToToggle) {
        event.stopPropagation();
        for (var i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);
                // Save to the cookies
                this.cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
                return;
            }
        }
        this.shortcutItems.push(itemToToggle);
        // Save to the cookies
        this.cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    };
    FuseShortcutsComponent.prototype.isInShortcuts = function (navigationItem) {
        return this.shortcutItems.find(function (item) {
            return item.url === navigationItem.url;
        });
    };
    FuseShortcutsComponent.prototype.onMenuOpen = function () {
        var _this = this;
        setTimeout(function () {
            _this.searchInputField.nativeElement.focus();
        });
    };
    FuseShortcutsComponent.prototype.showMobileShortcutsPanel = function () {
        this.mobileShortcutsPanelActive = true;
        this.renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    };
    FuseShortcutsComponent.prototype.hideMobileShortcutsPanel = function () {
        this.mobileShortcutsPanelActive = false;
        this.renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    };
    return FuseShortcutsComponent;
}());
exports.FuseShortcutsComponent = FuseShortcutsComponent;
