"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var splash_screen_service_1 = require("@fuse/services/splash-screen.service");
var translation_loader_service_1 = require("@fuse/services/translation-loader.service");
var navigation_service_1 = require("@fuse/components/navigation/navigation.service");
var AppComponent = /** @class */ (function () {
    function AppComponent(translate, fuseNavigationService, fuseSplashScreen, fuseTranslationLoader) {
        // Add languages
        // this.translate.addLangs(['en', 'tr']);
        this.translate = translate;
        this.fuseNavigationService = fuseNavigationService;
        this.fuseSplashScreen = fuseSplashScreen;
        this.fuseTranslationLoader = fuseTranslationLoader;
        // Set the default language
        // this.translate.setDefaultLang('en');
        // Set the navigation translations
        // this.fuseTranslationLoader.loadTranslations(navigationEnglish, navigationTurkish);
        // Use a language
        // this.translate.use('en');
        this.fuseSplashScreen.hide();
    }
    return AppComponent;
}());
exports.AppComponent = AppComponent;
