"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dashboard.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./dashboard.component");
var i3 = require("@angular/router");
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
exports.RenderType_DashboardComponent = RenderType_DashboardComponent;
function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
exports.View_DashboardComponent_0 = View_DashboardComponent_0;
function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashboardComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DashboardComponent_Host_0 = View_DashboardComponent_Host_0;
var DashboardComponentNgFactory = i1.ɵccf("dashboard", i2.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
exports.DashboardComponentNgFactory = DashboardComponentNgFactory;
