<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center">
  <mat-icon>
    touch_app
  </mat-icon>
  <div class="pl-4">Klasse auswählen</div>
</div>

<mat-dialog-content>
  <div #graphContainer id="graphContainer"></div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="loaded">
  <button class="primary-button-dark" mat-stroked-button type="button" [mat-dialog-close]="dialogData">Fertig</button>
  <button class="secondary-button-dark" mat-stroked-button type="button" [mat-dialog-close]="undefined">Zurück</button>
</mat-dialog-actions>