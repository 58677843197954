"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var util_1 = require("util");
var property_1 = require("../../../../../_model/configurator/property/property");
var constraint_1 = require("../../../../../_model/configurator/constraint");
var response_service_1 = require("../../../../../_service/response.service");
var flat_property_definition_service_1 = require("app/main/content/_service/meta/core/property/flat-property-definition.service");
var property_name_unique_validator_1 = require("app/main/content/_validator/property-name-unique.validator");
var strings_unique_validator_1 = require("app/main/content/_validator/strings-unique.validator");
var availablePropertyTypes = [property_1.PropertyType.TEXT, property_1.PropertyType.LONG_TEXT, property_1.PropertyType.WHOLE_NUMBER, property_1.PropertyType.FLOAT_NUMBER, property_1.PropertyType.BOOL, property_1.PropertyType.DATE];
var availableConstraints = [
    { type: property_1.PropertyType.TEXT, constraints: [constraint_1.ConstraintType.MAX_LENGTH, constraint_1.ConstraintType.MIN_LENGTH, constraint_1.ConstraintType.PATTERN] },
    { type: property_1.PropertyType.LONG_TEXT, constraints: [constraint_1.ConstraintType.MAX_LENGTH, constraint_1.ConstraintType.MIN_LENGTH, constraint_1.ConstraintType.PATTERN] },
    { type: property_1.PropertyType.WHOLE_NUMBER, constraints: [constraint_1.ConstraintType.MAX, constraint_1.ConstraintType.MIN, constraint_1.ConstraintType.MAX_LENGTH, constraint_1.ConstraintType.MIN_LENGTH] },
    { type: property_1.PropertyType.FLOAT_NUMBER, constraints: [constraint_1.ConstraintType.MAX, constraint_1.ConstraintType.MIN] },
    { type: property_1.PropertyType.BOOL, constraints: [] },
    { type: property_1.PropertyType.DATE, constraints: [constraint_1.ConstraintType.MAX, constraint_1.ConstraintType.MIN] }
];
var FlatPropertyBuilderComponent = /** @class */ (function () {
    function FlatPropertyBuilderComponent(formBuilder, propertyDefinitionService, responseService) {
        this.formBuilder = formBuilder;
        this.propertyDefinitionService = propertyDefinitionService;
        this.responseService = responseService;
        this.result = new core_1.EventEmitter();
    }
    FlatPropertyBuilderComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.preparePropertyTypeOptions();
                this.clearForm();
                this.dropdownToggled = false;
                if (!util_1.isNullOrUndefined(this.entryId)) {
                    Promise.all([
                        this.getAllPropertyDefinitions(),
                        this.getCurrentPropertyDefinition(),
                    ]).then(function () {
                        _this.populateForm();
                        _this.loaded = true;
                    });
                }
                else {
                    this.getAllPropertyDefinitions().then(function () {
                        _this.loaded = true;
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    FlatPropertyBuilderComponent.prototype.getAllPropertyDefinitions = function () {
        var _this = this;
        return this.propertyDefinitionService
            .getAllPropertyDefinitons()
            .toPromise()
            .then(function (ret) {
            _this.allPropertyDefinitions = ret;
        });
    };
    FlatPropertyBuilderComponent.prototype.getCurrentPropertyDefinition = function () {
        var _this = this;
        return this.propertyDefinitionService
            .getPropertyDefinitionById(this.entryId)
            .toPromise()
            .then(function (ret) {
            _this.propertyDefinition = ret;
        });
    };
    // ----------------------------------------------------
    FlatPropertyBuilderComponent.prototype.preparePropertyTypeOptions = function () {
        var e_1, _a;
        this.propertyTypeOptions = [];
        try {
            for (var availablePropertyTypes_1 = __values(availablePropertyTypes), availablePropertyTypes_1_1 = availablePropertyTypes_1.next(); !availablePropertyTypes_1_1.done; availablePropertyTypes_1_1 = availablePropertyTypes_1.next()) {
                var propertyType = availablePropertyTypes_1_1.value;
                this.propertyTypeOptions.push({
                    type: propertyType,
                    label: property_1.PropertyType.getLabelForPropertyType(propertyType),
                    display: true
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (availablePropertyTypes_1_1 && !availablePropertyTypes_1_1.done && (_a = availablePropertyTypes_1.return)) _a.call(availablePropertyTypes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    FlatPropertyBuilderComponent.prototype.prepareConstraintTypeOptions = function (propertyType) {
        var e_2, _a;
        this.currentConstraintOptions = [];
        if (util_1.isNullOrUndefined(propertyType)) {
            return;
        }
        var constraintsForType = availableConstraints.find(function (c) { return c.type === propertyType; });
        try {
            for (var _b = __values(constraintsForType.constraints), _c = _b.next(); !_c.done; _c = _b.next()) {
                var constraint = _c.value;
                this.currentConstraintOptions.push({
                    type: constraint,
                    label: constraint_1.ConstraintType.getLabelForConstraintType(constraint),
                    display: true
                });
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    FlatPropertyBuilderComponent.prototype.handleTypeSelectionChange = function () {
        this.clearAllowedValues();
        this.clearConstraints();
        this.prepareConstraintTypeOptions(this.form.controls['type'].value);
    };
    FlatPropertyBuilderComponent.prototype.handleComputedToggled = function () {
        this.dropdownToggled = false;
        this.clearFormArrays();
    };
    FlatPropertyBuilderComponent.prototype.clearForm = function () {
        this.form = this.formBuilder.group({
            name: this.formBuilder.control('', [
                forms_1.Validators.required,
                property_name_unique_validator_1.propertyNameUniqueValidator(this.allPropertyDefinitions, this.propertyDefinition),
            ]),
            type: this.formBuilder.control('', forms_1.Validators.required),
            computed: this.formBuilder.control(false),
            allowedValues: this.formBuilder.array([]),
            description: this.formBuilder.control(''),
            required: this.formBuilder.control(''),
            requiredMessage: this.formBuilder.control(''),
            constraints: this.formBuilder.array([])
        });
        if (!util_1.isNullOrUndefined(this.propertyDefinition)) {
            this.populateForm();
        }
    };
    FlatPropertyBuilderComponent.prototype.populateForm = function () {
        var e_3, _a, e_4, _b;
        this.form.get('name').setValue(this.propertyDefinition.name);
        this.form.get('type').setValue(this.propertyDefinition.type);
        this.form.get('description').setValue(this.propertyDefinition.description);
        this.form.get('computed').setValue(this.propertyDefinition.computed);
        if (!util_1.isNullOrUndefined(this.propertyDefinition.allowedValues) && this.propertyDefinition.allowedValues.length > 0) {
            this.dropdownToggled = true;
            var i = 0;
            try {
                for (var _c = __values(this.propertyDefinition.allowedValues), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var value = _d.value;
                    this.addAllowedValue();
                    this.form.get('allowedValues').get('' + i).get('value').setValue(value);
                    i++;
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        this.constraintsToggled = this.propertyDefinition.required || this.propertyDefinition.propertyConstraints.length > 0;
        this.form.get('required').setValue(this.propertyDefinition.required);
        this.form.get('requiredMessage').setValue(this.propertyDefinition.requiredMessage);
        if (!util_1.isNullOrUndefined(this.propertyDefinition.propertyConstraints) && this.propertyDefinition.propertyConstraints.length > 0) {
            var i = 0;
            this.prepareConstraintTypeOptions(this.propertyDefinition.type);
            try {
                for (var _e = __values(this.propertyDefinition.propertyConstraints), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var constraint = _f.value;
                    this.addConstraint();
                    this.form.get('constraints').get('' + i).get('type').setValue(constraint.constraintType);
                    this.form.get('constraints').get('' + i).get('value').setValue(constraint.value);
                    this.form.get('constraints').get('' + i).get('message').setValue(constraint.message);
                    i++;
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
    };
    FlatPropertyBuilderComponent.prototype.clearFormArrays = function () {
        this.clearAllowedValues();
        this.clearConstraints();
    };
    // ----------------------------------------------------
    // --------------Allowed Values Array------------------
    // ----------------------------------------------------
    FlatPropertyBuilderComponent.prototype.addAllowedValue = function () {
        this.allowedValues = this.form.get('allowedValues');
        this.allowedValues.push(this.createAllowedValue());
    };
    FlatPropertyBuilderComponent.prototype.createAllowedValue = function () {
        return this.formBuilder.group({
            value: [undefined, forms_1.Validators.required],
        });
    };
    FlatPropertyBuilderComponent.prototype.markAllowedValuesAsTouched = function () {
        var _this = this;
        if (!util_1.isNullOrUndefined(this.form.get('allowedValues'))) {
            Object.keys(this.form.get('allowedValues').controls).forEach(function (key) {
                _this.form
                    .get('allowedValues')
                    .get(key).controls.value.markAsTouched();
            });
        }
    };
    FlatPropertyBuilderComponent.prototype.removeAllowedValue = function (i) {
        this.allowedValues.removeAt(i);
    };
    FlatPropertyBuilderComponent.prototype.clearAllowedValues = function () {
        this.form.removeControl('allowedValues');
        this.form.addControl('allowedValues', this.formBuilder.array([]));
    };
    // ----------------------------------------------------
    // -----------------Constraint Array-------------------
    // ----------------------------------------------------
    FlatPropertyBuilderComponent.prototype.addConstraint = function () {
        this.constraints = this.form.get('constraints');
        this.constraints.push(this.createConstraintValue());
    };
    FlatPropertyBuilderComponent.prototype.createConstraintValue = function () {
        return this.formBuilder.group({
            type: ['', forms_1.Validators.required],
            value: ['', forms_1.Validators.required],
            message: ['']
        });
    };
    FlatPropertyBuilderComponent.prototype.removeConstraint = function (i) {
        this.constraints.removeAt(i);
    };
    FlatPropertyBuilderComponent.prototype.clearConstraints = function () {
        this.form.removeControl('constraints');
        this.form.addControl('constraints', this.formBuilder.array([]));
    };
    FlatPropertyBuilderComponent.prototype.checkConstraintTypeVisiblity = function (type) {
        var e_5, _a;
        try {
            for (var _b = __values(this.form.get('constraints').controls), _c = _b.next(); !_c.done; _c = _b.next()) {
                var control = _c.value;
                if (control.get('type').value === type) {
                    return true;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
        return false;
    };
    FlatPropertyBuilderComponent.prototype.markConstraintsAsTouched = function () {
        var _this = this;
        if (!util_1.isNullOrUndefined(this.form.get('constraints'))) {
            Object.keys(this.form.get('constraints').controls).forEach(function (key) {
                Object.keys(_this.form
                    .get('constraints')
                    .get(key).controls).forEach(function (innerKey) {
                    _this.form.get('constraints').get(key).get(innerKey).markAsTouched();
                });
            });
        }
    };
    // --Validity Checks and Queries
    FlatPropertyBuilderComponent.prototype.isFieldInvalid = function (value) {
        if (util_1.isNullOrUndefined(value)) {
            return false;
        }
        return value.invalid;
    };
    FlatPropertyBuilderComponent.prototype.isDropdownListDisplayed = function () {
        return this.dropdownToggled && this.form.get('type').value !== '';
    };
    // ---
    FlatPropertyBuilderComponent.prototype.trackByFn = function (index, item) {
        return index;
    };
    FlatPropertyBuilderComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.form.valid) {
            this.form.disable();
            var property = this.createPropertyFromForm();
            property.tenantId = this.tenantId;
            // this.propertyDefinitionService.createNewPropertyDefinition(property).toPromise().then((ret: FlatPropertyDefinition<any>) => {
            this.responseService.sendPropertyConfiguratorResponse(this.redirectUrl, [property], undefined, 'save').toPromise().then(function (ret) {
                if (!util_1.isNullOrUndefined(ret) && ret.length === 1) {
                    _this.result.emit({ builderType: 'property', value: ret[0] });
                }
                else {
                    _this.result.emit(undefined);
                }
                // });
            }).catch(function (error) {
                _this.form.enable();
                var str = '' + _this.form.value.name;
                _this.form.controls['name'].setValidators([forms_1.Validators.required, strings_unique_validator_1.stringsUnique(str, _this.form.value.name)]);
                _this.form.controls['name'].updateValueAndValidity();
            });
        }
        else {
            this.markAllowedValuesAsTouched();
            this.markConstraintsAsTouched();
        }
    };
    FlatPropertyBuilderComponent.prototype.handleNameKeyUp = function () {
        if (this.form.controls['name'].hasError('stringsUnique')) {
            this.form.controls['name'].setValidators([forms_1.Validators.required]);
            this.form.controls['name'].updateValueAndValidity();
        }
    };
    FlatPropertyBuilderComponent.prototype.handleCancelClick = function () {
        this.result.emit(undefined);
    };
    FlatPropertyBuilderComponent.prototype.createPropertyFromForm = function () {
        var e_6, _a, e_7, _b;
        var property = new property_1.FlatPropertyDefinition();
        property.custom = true;
        property.computed = this.form.value.computed;
        if (util_1.isNullOrUndefined(this.propertyDefinition)) {
            property.id = null;
        }
        else {
            property.id = this.propertyDefinition.id;
        }
        property.name = this.form.get('name').value;
        property.allowedValues = [];
        if (!util_1.isNullOrUndefined(this.form.get('allowedValues'))) {
            try {
                for (var _c = __values(this.form.get('allowedValues').value), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var value = _d.value;
                    property.allowedValues.push(value.value);
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_6) throw e_6.error; }
            }
        }
        property.type = this.form.get('type').value;
        property.description = this.form.get('description').value;
        property.propertyConstraints = [];
        property.required = this.form.get('required').value;
        if (property.required) {
            property.requiredMessage = this.form.get('requiredMessage').value;
        }
        if (!util_1.isNullOrUndefined(this.form.get('constraints'))) {
            try {
                for (var _e = __values(this.form.get('constraints').controls), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var control = _f.value;
                    var propertyConstraint = new constraint_1.PropertyConstraint();
                    propertyConstraint.constraintType = control.get('type').value;
                    propertyConstraint.value = control.get('value').value;
                    if (!util_1.isNullOrUndefined(control.get('message').value) && control.get('message').value.length > 0) {
                        propertyConstraint.message = control.get('message').value;
                    }
                    propertyConstraint.propertyType = property.type;
                    property.propertyConstraints.push(propertyConstraint);
                }
            }
            catch (e_7_1) { e_7 = { error: e_7_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_7) throw e_7.error; }
            }
        }
        return property;
    };
    return FlatPropertyBuilderComponent;
}());
exports.FlatPropertyBuilderComponent = FlatPropertyBuilderComponent;
