"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var tree_property_1 = require("app/main/content/_model/configurator/property/tree-property");
var tree_property_definition_service_1 = require("app/main/content/_service/meta/core/property/tree-property-definition.service");
var util_1 = require("util");
var material_1 = require("@angular/material");
var response_service_1 = require("app/main/content/_service/response.service");
var strings_unique_validator_1 = require("app/main/content/_validator/strings-unique.validator");
var TreePropertyBuilderComponent = /** @class */ (function () {
    function TreePropertyBuilderComponent(dialog, formBuilder, treePropertyDefinitionService, responseService) {
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.treePropertyDefinitionService = treePropertyDefinitionService;
        this.responseService = responseService;
        this.result = new core_1.EventEmitter();
        this.management = new core_1.EventEmitter();
    }
    TreePropertyBuilderComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.form = this.formBuilder.group({
                    name: this.formBuilder.control('', forms_1.Validators.required),
                    description: this.formBuilder.control(''),
                    multiple: this.formBuilder.control(''),
                    required: this.formBuilder.control(''),
                    requiredMessage: this.formBuilder.control(''),
                });
                if (!util_1.isNullOrUndefined(this.entryId)) {
                    this.treePropertyDefinitionService
                        .getPropertyDefinitionById(this.entryId)
                        .toPromise().then(function (ret) {
                        _this.treePropertyDefinition = ret;
                        _this.form.get('name').setValue(_this.treePropertyDefinition.name);
                        _this.form
                            .get('description')
                            .setValue(_this.treePropertyDefinition.description);
                        _this.form.get('required').setValue(_this.treePropertyDefinition.required);
                        _this.form.get('requiredMessage').setValue(_this.treePropertyDefinition.requiredMessage);
                        _this.multipleToggled = _this.treePropertyDefinition.multiple;
                        _this.showEditor = true;
                        _this.loaded = true;
                    });
                }
                else {
                    this.loaded = true;
                }
                return [2 /*return*/];
            });
        });
    };
    TreePropertyBuilderComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    TreePropertyBuilderComponent.prototype.populateEditor = function () { };
    TreePropertyBuilderComponent.prototype.handleCreateClick = function () {
        var _this = this;
        this.form.controls['name'].markAsTouched();
        if (this.form.invalid) {
            this.form.markAllAsTouched();
        }
        else {
            this.form.disable();
            var newTreePropertyDefinition = new tree_property_1.TreePropertyDefinition({
                name: this.form.controls['name'].value,
                description: this.form.controls['description'].value,
                multiple: this.form.controls['multiple'].value,
                required: this.form.controls['required'].value,
                requiredMessage: this.form.controls['requiredMessage'].value
            });
            if (!newTreePropertyDefinition.required) {
                newTreePropertyDefinition.requiredMessage = null;
            }
            newTreePropertyDefinition.custom = true;
            newTreePropertyDefinition.tenantId = this.tenantId;
            // this.treePropertyDefinitionService.newPropertyDefinition(newTreePropertyDefinition).toPromise().then((treePropertyDefinition: TreePropertyDefinition) => {
            //   if (!isNullOrUndefined(treePropertyDefinition)) {
            //     this.treePropertyDefinition = treePropertyDefinition;
            this.responseService.sendPropertyConfiguratorResponse(this.redirectUrl, undefined, [newTreePropertyDefinition], 'save').toPromise().then(function (ret) {
                _this.treePropertyDefinition = ret[0];
                _this.showEditor = true;
                // });
                // }
            }).catch(function (error) {
                if (error.status === 406) {
                    _this.form.enable();
                    var str = '' + _this.form.value.name;
                    _this.form.controls['name'].setValidators([forms_1.Validators.required, strings_unique_validator_1.stringsUnique(str, _this.form.value.name)]);
                    _this.form.controls['name'].updateValueAndValidity();
                }
                else {
                    console.error(error);
                }
            });
        }
    };
    TreePropertyBuilderComponent.prototype.handleNameKeyUp = function () {
        if (this.form.controls['name'].hasError('stringsUnique')) {
            this.form.controls['name'].setValidators([forms_1.Validators.required]);
            this.form.controls['name'].updateValueAndValidity();
        }
    };
    TreePropertyBuilderComponent.prototype.handleCancelClick = function () {
        this.result.emit(undefined);
    };
    TreePropertyBuilderComponent.prototype.handleResult = function (event) {
        var _this = this;
        event.payload.tenantId = this.tenantId;
        event.payload.required = this.form.value.required;
        event.payload.requiredMessage = event.payload.required ? this.form.value.requiredMessage : undefined;
        if (event.type === 'save') {
            event.payload.description = this.form.controls['description'].value;
            event.payload.multiple = this.multipleToggled;
            // this.treePropertyDefinitionService
            //   .savePropertyDefinition(event.payload)
            //   .toPromise().then((ret: TreePropertyDefinition) => {
            this.responseService.sendPropertyConfiguratorResponse(this.redirectUrl, undefined, [event.payload], 'save').toPromise().then(function (ret) {
                return; // don't emit result
            });
            // });
        }
        else if (event.type === 'back') {
            this.result.emit({ builderType: 'tree', value: event.payload });
        }
        else if (event.type === 'saveAndBack') {
            event.payload.description = this.form.controls['description'].value;
            event.payload.multiple = this.multipleToggled;
            // this.treePropertyDefinitionService
            //   .savePropertyDefinition(event.payload)
            //   .toPromise().then((ret: TreePropertyDefinition) => {
            this.responseService.sendPropertyConfiguratorResponse(this.redirectUrl, undefined, [event.payload], 'save').toPromise().then(function (ret) {
                _this.result.emit({ builderType: 'tree', value: ret[0] });
            });
            // });
        }
    };
    TreePropertyBuilderComponent.prototype.handleManagementEvent = function (event) {
        this.management.emit(event);
    };
    return TreePropertyBuilderComponent;
}());
exports.TreePropertyBuilderComponent = TreePropertyBuilderComponent;
