"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var class_definition_service_1 = require("app/main/content/_service/meta/core/class/class-definition.service");
var util_1 = require("util");
var class_1 = require("../../_model/configurator/class");
var environment_1 = require("environments/environment");
var FuseTaskSelectComponent = /** @class */ (function () {
    function FuseTaskSelectComponent(formBuilder, router, route, classDefinitionService) {
        this.router = router;
        this.route = route;
        this.classDefinitionService = classDefinitionService;
        this.dataSource = new material_1.MatTableDataSource();
        this.displayedColumns = ["name", "configuration"];
    }
    FuseTaskSelectComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var archetypes, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.environmentMode = environment_1.environment.MODE;
                        this.route.queryParams.subscribe(function (params) {
                            if (util_1.isNullOrUndefined(params["tenantId"]) ||
                                util_1.isNullOrUndefined(params["redirect"])) {
                                _this.router.navigate(["main/invalid-parameters"]);
                            }
                            else {
                                _this.tenantId = params["tenantId"];
                                _this.redirectUrl = params["redirect"];
                            }
                        });
                        archetypes = [];
                        if (environment_1.environment.MODE === 'flexprod') {
                            archetypes = [class_1.ClassArchetype.FLEXPROD];
                        }
                        else if (environment_1.environment.MODE === 'iVolunteer') {
                            archetypes = [class_1.ClassArchetype.TASK, class_1.ClassArchetype.FUNCTION, class_1.ClassArchetype.ACHIEVEMENT, class_1.ClassArchetype.COMPETENCE];
                        }
                        else {
                            archetypes = [class_1.ClassArchetype.TASK, class_1.ClassArchetype.FUNCTION, class_1.ClassArchetype.ACHIEVEMENT, class_1.ClassArchetype.COMPETENCE, class_1.ClassArchetype.FLEXPROD];
                        }
                        _a = this;
                        return [4 /*yield*/, this.classDefinitionService
                                .getByArchetypes(archetypes, this.tenantId).toPromise()];
                    case 1:
                        _a.allClassDefinitions = (_b.sent());
                        this.dropdownFilterValue = 'ALL';
                        this.dataSource.data = this.allClassDefinitions
                            .filter(function (t) { return t.configurationId != null; })
                            .sort(function (c1, c2) { return c1.configurationId.localeCompare(c2.configurationId); });
                        return [2 /*return*/];
                }
            });
        });
    };
    FuseTaskSelectComponent.prototype.applyArchetypeFilter = function () {
        var _this = this;
        if (this.dropdownFilterValue === 'ALL') {
            this.dataSource.data = this.allClassDefinitions;
        }
        else {
            this.dataSource.data = this.allClassDefinitions.filter(function (cd) { return cd.classArchetype === _this.dropdownFilterValue; });
        }
    };
    FuseTaskSelectComponent.prototype.onRowSelect = function (row) {
        this.router.navigate(["main/instance-editor"], {
            queryParams: {
                cdId: row.id,
                tenantId: this.tenantId,
                redirect: this.redirectUrl
            }
        });
    };
    return FuseTaskSelectComponent;
}());
exports.FuseTaskSelectComponent = FuseTaskSelectComponent;
