"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var myMxCell_1 = require("../myMxCell");
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var EditorPopupMenu = /** @class */ (function () {
    function EditorPopupMenu(editorInstance) {
        this.editorInstance = editorInstance;
    }
    EditorPopupMenu.prototype.createPopupMenuHandler = function (graph) {
        var _this = this;
        return new mx.mxPopupMenuHandler(graph, function (menu, cell, evt) {
            return _this.createPopupMenu(menu, cell, evt);
        });
    };
    EditorPopupMenu.prototype.createPopupMenu = function (menu, cell, evt) {
        var _this = this;
        if (cell != null) {
            if (cell.isVertex() && cell.cellType === myMxCell_1.MyMxCellType.CLASS) {
                menu.addItem('Eintrag erfassen', null, function () {
                    _this.editorInstance.showInstanceForm();
                }, null, null, true, true);
                menu.addItem('JSON Exportieren', null, function () {
                    _this.editorInstance.showExportDialog();
                }, null, null, true, true);
            }
            // Options present in every cell (vertexes as well as edges)
            menu.addItem('DEBUG: Print cell to console', null, function () {
                console.log(cell);
            }, null, null, true, true);
        }
    };
    return EditorPopupMenu;
}());
exports.EditorPopupMenu = EditorPopupMenu;
