"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var myMxCell_1 = require("../myMxCell");
var mx = require('mxgraph')({
// mxDefaultLanguage: 'de',
// mxBasePath: './mxgraph_resources',
});
var MatchingConfiguratorPopupMenu = /** @class */ (function () {
    function MatchingConfiguratorPopupMenu(graph, editorInstance) {
        this.graph = graph;
        this.editorInstance = editorInstance;
    }
    MatchingConfiguratorPopupMenu.prototype.createPopupMenuHandler = function (graph) {
        var outer = this;
        return new mx.mxPopupMenuHandler(graph, function (menu, cell, evt) {
            return outer.createPopupMenu(this.graph, menu, cell, evt);
        });
    };
    MatchingConfiguratorPopupMenu.prototype.createPopupMenu = function (graph, menu, cell, evt) {
        var outer = this;
        if (cell != null) {
            // Options present in every cell (vertexes as well as edges)
            var printCellItem = menu.addItem('Print cell to console', null, function () {
                console.log(cell);
            }, null, null, true, true);
            if (cell.cellType === myMxCell_1.MyMxCellType.MATCHING_OPERATOR || cell.cellType === myMxCell_1.MyMxCellType.MATCHING_CONNECTOR) {
                var deleteItem = menu.addItem('Delete', null, function () {
                    outer.editorInstance.handleDeleteRelationship([cell]);
                }, null, null, true, true);
            }
        }
        else {
            // console.log('clicked empty canvas space');
            // console.log(event);
            // console.log(graph);
        }
    };
    return MatchingConfiguratorPopupMenu;
}());
exports.MatchingConfiguratorPopupMenu = MatchingConfiguratorPopupMenu;
