"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var animations_1 = require("@angular/animations");
var flex_layout_1 = require("@angular/flex-layout");
var sidebar_service_1 = require("./sidebar.service");
var match_media_service_1 = require("@fuse/services/match-media.service");
var config_service_1 = require("@fuse/services/config.service");
var FuseSidebarComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {Renderer2} renderer
     * @param {ElementRef} elementRef
     * @param {AnimationBuilder} animationBuilder
     * @param {MediaObserver} observableMedia
     * @param {FuseConfigService} fuseConfigService
     * @param {FuseSidebarService} fuseSidebarService
     * @param {FuseMatchMediaService} fuseMatchMediaService
     */
    function FuseSidebarComponent(renderer, elementRef, animationBuilder, observableMedia, fuseConfigService, fuseSidebarService, fuseMatchMediaService) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.animationBuilder = animationBuilder;
        this.observableMedia = observableMedia;
        this.fuseConfigService = fuseConfigService;
        this.fuseSidebarService = fuseSidebarService;
        this.fuseMatchMediaService = fuseMatchMediaService;
        this._backdrop = null;
        // Set the defaults
        this.opened = false;
        this.folded = false;
        this.align = 'left';
    }
    Object.defineProperty(FuseSidebarComponent.prototype, "folded", {
        get: function () {
            return this._folded;
        },
        // Folded
        set: function (value) {
            // Only work if the sidebar is not closed
            if (!this.opened) {
                return;
            }
            // Set the folded
            this._folded = value;
            // Programmatically add/remove margin to the element
            // that comes after or before based on the alignment
            var sibling, styleRule;
            var styleValue = '64px';
            // Get the sibling and set the style rule
            if (this.align === 'left') {
                sibling = this.elementRef.nativeElement.nextElementSibling;
                styleRule = 'marginLeft';
            }
            else {
                sibling = this.elementRef.nativeElement.previousElementSibling;
                styleRule = 'marginRight';
            }
            // If there is no sibling, return...
            if (!sibling) {
                return;
            }
            // If folded...
            if (value) {
                // Set the style
                this.renderer.setStyle(sibling, styleRule, styleValue);
            }
            // If unfolded...
            else {
                // Remove the style
                this.renderer.removeStyle(sibling, styleRule);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * On init
     */
    FuseSidebarComponent.prototype.ngOnInit = function () {
        // Register the sidebar
        this.fuseSidebarService.register(this.name, this);
        // Setup alignment
        this._setupAlignment();
        // Setup lockedOpen
        this._setupLockedOpen();
    };
    /**
     * On destroy
     */
    FuseSidebarComponent.prototype.ngOnDestroy = function () {
        // If the sidebar is folded, unfold it to revert modifications
        if (this.folded) {
            this.unfold();
        }
        // Unregister the sidebar
        this.fuseSidebarService.unregister(this.name);
        // Unsubscribe from the media watcher subscription
        this._onMediaChangeSubscription.unsubscribe();
    };
    /**
     * Set the sidebar alignment
     *
     * @private
     */
    FuseSidebarComponent.prototype._setupAlignment = function () {
        // Add the correct class name to the sidebar
        // element depending on the align attribute
        if (this.align === 'right') {
            this.renderer.addClass(this.elementRef.nativeElement, 'right-aligned');
        }
        else {
            this.renderer.addClass(this.elementRef.nativeElement, 'left-aligned');
        }
    };
    /**
     * Setup the lockedOpen handler
     *
     * @private
     */
    FuseSidebarComponent.prototype._setupLockedOpen = function () {
        var _this = this;
        // Return if the lockedOpen wasn't set
        if (!this.lockedOpen) {
            return;
        }
        // Set the wasActive for the first time
        this._wasActive = false;
        // Act on every media change
        this._onMediaChangeSubscription =
            this.fuseMatchMediaService.onMediaChange.subscribe(function () {
                // Get the active status
                var isActive = _this.observableMedia.isActive(_this.lockedOpen);
                // If the both status are the same, don't act
                if (_this._wasActive === isActive) {
                    return;
                }
                // Activate the lockedOpen
                if (isActive) {
                    // Set the lockedOpen status
                    _this.isLockedOpen = true;
                    // Force the the opened status to true
                    _this.opened = true;
                    // Read the folded setting from the config
                    // and fold the sidebar if it's true
                    if (_this.fuseConfigService.config.layout.navigationFolded) {
                        _this.fold();
                    }
                    // Hide the backdrop if any exists
                    _this.hideBackdrop();
                }
                // De-Activate the lockedOpen
                else {
                    // Set the lockedOpen status
                    _this.isLockedOpen = false;
                    // Unfold the sidebar in case if it was folded
                    _this.unfold();
                    // Force the the opened status to close
                    _this.opened = false;
                }
                // Store the new active status
                _this._wasActive = isActive;
            });
    };
    /**
     * Open the sidebar
     */
    FuseSidebarComponent.prototype.open = function () {
        if (this.opened || this.isLockedOpen) {
            return;
        }
        // Show the backdrop
        this.showBackdrop();
        // Set the opened status
        this.opened = true;
    };
    /**
     * Close the sidebar
     */
    FuseSidebarComponent.prototype.close = function () {
        if (!this.opened || this.isLockedOpen) {
            return;
        }
        // Hide the backdrop
        this.hideBackdrop();
        // Set the opened status
        this.opened = false;
    };
    /**
     * Toggle open/close the sidebar
     */
    FuseSidebarComponent.prototype.toggleOpen = function () {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    };
    /**
     * Mouseenter
     */
    FuseSidebarComponent.prototype.onMouseEnter = function () {
        // Only work if the sidebar is folded
        if (!this.folded) {
            return;
        }
        // Unfold the sidebar temporarily
        this.unfolded = true;
    };
    /**
     * Mouseleave
     */
    FuseSidebarComponent.prototype.onMouseLeave = function () {
        // Only work if the sidebar is folded
        if (!this.folded) {
            return;
        }
        // Fold the sidebar back
        this.unfolded = false;
    };
    /**
     * Fold the sidebar permanently
     */
    FuseSidebarComponent.prototype.fold = function () {
        // Only work if the sidebar is not folded
        if (this.folded) {
            return;
        }
        // Fold
        this.folded = true;
    };
    /**
     * Unfold the sidebar permanently
     */
    FuseSidebarComponent.prototype.unfold = function () {
        // Only work if the sidebar is folded
        if (!this.folded) {
            return;
        }
        // Unfold
        this.folded = false;
    };
    /**
     * Toggle the sidebar fold/unfold permanently
     */
    FuseSidebarComponent.prototype.toggleFold = function () {
        if (this.folded) {
            this.unfold();
        }
        else {
            this.fold();
        }
    };
    /**
     * Show the backdrop
     */
    FuseSidebarComponent.prototype.showBackdrop = function () {
        var _this = this;
        // Create the backdrop element
        this._backdrop = this.renderer.createElement('div');
        // Add a class to the backdrop element
        this._backdrop.classList.add('fuse-sidebar-overlay');
        // Append the backdrop to the parent of the sidebar
        this.renderer.appendChild(this.elementRef.nativeElement.parentElement, this._backdrop);
        // Create the enter animation and attach it to the player
        this._player =
            this.animationBuilder
                .build([
                animations_1.animate('300ms ease', animations_1.style({ opacity: 1 }))
            ]).create(this._backdrop);
        // Play the animation
        this._player.play();
        // Add an event listener to the overlay
        this._backdrop.addEventListener('click', function () {
            _this.close();
        });
    };
    /**
     * Hide the backdrop
     */
    FuseSidebarComponent.prototype.hideBackdrop = function () {
        var _this = this;
        if (!this._backdrop) {
            return;
        }
        // Create the leave animation and attach it to the player
        this._player =
            this.animationBuilder
                .build([
                animations_1.animate('300ms ease', animations_1.style({ opacity: 0 }))
            ]).create(this._backdrop);
        // Play the animation
        this._player.play();
        // Once the animation is done...
        this._player.onDone(function () {
            // If the backdrop still exists...
            if (_this._backdrop) {
                // Remove the backdrop
                _this._backdrop.parentNode.removeChild(_this._backdrop);
                _this._backdrop = null;
            }
        });
    };
    return FuseSidebarComponent;
}());
exports.FuseSidebarComponent = FuseSidebarComponent;
