"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var item_1 = require("app/main/content/_model/dynamic-forms/item");
var MultipleSelectTreeItemComponent = /** @class */ (function () {
    function MultipleSelectTreeItemComponent() {
    }
    MultipleSelectTreeItemComponent.prototype.ngOnInit = function () {
    };
    MultipleSelectTreeItemComponent.prototype.calculateSpaces = function (level) {
        level = 10 * level;
        return level + 'px';
    };
    return MultipleSelectTreeItemComponent;
}());
exports.MultipleSelectTreeItemComponent = MultipleSelectTreeItemComponent;
