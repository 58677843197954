"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var relationship_1 = require("app/main/content/_model/configurator/relationship");
var core_1 = require("@angular/core");
var utils_and_constants_1 = require("../../utils-and-constants");
var options_overlay_control_component_1 = require("../options-overlay-control/options-overlay-control.component");
var RelationshipOptionsOverlayContentComponent = /** @class */ (function () {
    function RelationshipOptionsOverlayContentComponent() {
        this.resultData = new core_1.EventEmitter();
        this.relationshipPalettes = utils_and_constants_1.CConstants.relationshipPalettes;
    }
    RelationshipOptionsOverlayContentComponent.prototype.ngOnInit = function () {
        console.log(this.inputData);
        this.initSourceAndTargetClasses();
    };
    RelationshipOptionsOverlayContentComponent.prototype.initSourceAndTargetClasses = function () {
        var _this = this;
        this.sourceClass = this.inputData.allClassDefinitions.find(function (c) { return c.id === _this.inputData.relationship.source; });
        this.targetClass = this.inputData.allClassDefinitions.find(function (c) { return c.id === _this.inputData.relationship.target; });
    };
    RelationshipOptionsOverlayContentComponent.prototype.onSubmit = function () {
        this.resultData.emit(this.inputData);
    };
    RelationshipOptionsOverlayContentComponent.prototype.onCancel = function () {
        this.resultData.emit(undefined);
    };
    RelationshipOptionsOverlayContentComponent.prototype.getImagePathForRelationship = function (relationshipType) {
        return this.relationshipPalettes.rows.find(function (r) { return r.id === relationshipType; })
            .imgPath;
    };
    RelationshipOptionsOverlayContentComponent.prototype.getLabelForRelationship = function (relationshipType) {
        return relationship_1.RelationshipType.getLabelFromRelationshipType(relationshipType);
    };
    RelationshipOptionsOverlayContentComponent.prototype.onRelationshipTypeSelect = function (relationshipType) {
        this.inputData.relationship.relationshipType = relationshipType;
        if (relationshipType === relationship_1.RelationshipType.ASSOCIATION) {
            this.onChangeCardinalities('1:1');
        }
    };
    RelationshipOptionsOverlayContentComponent.prototype.getCardinalityLabel = function (key) {
        if (key === 'source') {
            return relationship_1.AssociationCardinality.getLabelForAssociationCardinality(this.inputData.relationship.sourceCardinality);
        }
        else if (key === 'target') {
            return relationship_1.AssociationCardinality.getLabelForAssociationCardinality(this.inputData.relationship.targetCardinality);
        }
        return '';
    };
    RelationshipOptionsOverlayContentComponent.prototype.onChangeCardinalities = function (key) {
        if (key === '1:1') {
            this.inputData.relationship.sourceCardinality = relationship_1.AssociationCardinality.ONE;
            this.inputData.relationship.targetCardinality = relationship_1.AssociationCardinality.ONE;
        }
        else if (key === '1:N') {
            this.inputData.relationship.sourceCardinality = relationship_1.AssociationCardinality.ONE;
            this.inputData.relationship.targetCardinality = relationship_1.AssociationCardinality.N;
        }
        else {
            this.inputData.relationship.sourceCardinality = null;
            this.inputData.relationship.targetCardinality = null;
        }
    };
    return RelationshipOptionsOverlayContentComponent;
}());
exports.RelationshipOptionsOverlayContentComponent = RelationshipOptionsOverlayContentComponent;
